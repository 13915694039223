import { useCallback, useMemo, useState } from "react";

import { useChargesToReceive } from "../../../../ChargesToReceiveProvider";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import { useChargesFilters } from "../../../../store";

export function usePagination() {
  const { countTotal } = useChargesToReceive();
  const { updatePagination } = useChargesFilters();
  const [page, setPage] = useState(1);

  const pageCount = useMemo(() => Math.ceil(countTotal / DEFAULT_PAGE_SIZE), [countTotal]);
  const pages = useMemo(() => Array.from({ length: pageCount }, (_, x) => x + 1), [pageCount]);
  const pagesToShow = useMemo(() => {
    const startPage = Math.max(0, page - 3);
    const endPage = Math.min(pageCount, page + 2);

    return pages.slice(startPage, endPage);
  }, [page, pageCount, pages]);

  const hasPreviousAndNextButton = useMemo(() => pageCount > 1, [pageCount]);
  const isOnFirstPage = useMemo(() => page === 1, [page]);
  const isOnLastPage = useMemo(() => page === pageCount, [page, pageCount]);
  const rangeOfItems = useMemo(() => {
    const start = ((page - 1) * DEFAULT_PAGE_SIZE) + 1
    const end = Math.min(
      page * DEFAULT_PAGE_SIZE,
      countTotal
    );

    return {
      start,
      end,
    }
  }, [countTotal, page]);

  const handleChangePage = useCallback((newPage: number) => {
    const start = (newPage - 1) * DEFAULT_PAGE_SIZE;
    const end = DEFAULT_PAGE_SIZE;

    setPage(newPage);
    updatePagination({ start, end });
  }, [updatePagination]);

  const goToPreviousPage = useCallback(() => {
    if (page > 1) {
      handleChangePage(page - 1);
    }
  }, [page, handleChangePage]);

  const goToNextPage = useCallback(() => {
    if (page < pageCount) {
      handleChangePage(page + 1);
    }
  }, [page, pageCount, handleChangePage]);

  return {
    countTotal,
    hasPreviousAndNextButton,
    isOnFirstPage,
    isOnLastPage,
    goToPreviousPage,
    goToNextPage,
    handleChangePage,
    page,
    pagesToShow,
    rangeOfItems
  }
}