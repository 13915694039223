import { Form, Modal, Button, Row, Col, FloatingLabel, InputGroup, Accordion } from 'react-bootstrap';
import { getPropertyOwner, getPropertyExpense, getSinglePropertyExpense, insertPropertyExpense, setPropertyExpenseId, propertyExpOpen, propertyExpClose } from "../../../../redux/slice/propertyDataSlice";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newExpenseForm } from '../../../Interface';
import { openErrorbox, numericOnly } from './actionPerform';
import Swal from 'sweetalert2';
import moment from "moment";
import { commonService } from "../../../../services/commonService";
import { converter, convertToNumber, formatNumber } from '../../../constants/utils';
import i18n from '../../../../i18n';

const PropertyExpensesService = (props) => {
    const { accountData, accountStatus, billingData, billingStatus } = useSelector((state: RootState) => state.expenditureData);
    const { propertyOwnerData, propertyData, ownerData, propertyExpenseId, propertyExpShow } = useSelector((state: RootState) => state.propertyData);
    const { serviceData, suppliersData } = useSelector((state: RootState) => state.contractData);
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const [double, setDouble] = useState(false);
    const [hide, setHide] = useState(true);
    const [portionHide, portionSetHide] = useState(true);

    const [debitHide, debitSetHide] = useState(true);
    const [creditHide, creditSetHide] = useState(true);
    const [aarinConfig, setAarinConfig] = useState<any>("1");
    const [aarinSuppConfig, setaarinSuppConfig] = useState<any>("1");
    const [valueCon, setValueCon] = useState('')

    const { id } = useParams();
    let { state, noneHandleSelectAll } = props;
    const [hideAllOrDisable, setHideAllOrDisable] = useState(true);
    const [contractIdValue, setcontractIdValue] = useState("");

    useEffect(() => {
        dispatch(getPropertyOwner({ "id": id, "start": 0, "end": 100 }));
        getPropertyExpenses(propertyExpenseId, id);
    }, [dispatch, propertyExpenseId])
    const getPropertyExpenses = async (propertyExpenseId, id) => {
        setHideAllOrDisable(true);
        if (!propertyExpenseId && propertyExpenseId != 0) {
            dispatch(propertyExpOpen(true));
            portionSetHide(true);
        }
        if (propertyExpenseId && propertyExpenseId != 0) {
            const fields: string[] = ['id', 'propertyId', 'maturity', 'service', 'complement', 'debit', 'debitOwner', 'credit', 'creditOwner', 'supplier', 'formOfPayment', 'debitMonth', 'bankAccount', 'administrationFee', 'nextTransfer']
            let data = await dispatch(getSinglePropertyExpense({ "id": Number(propertyExpenseId), "propertyId": Number(id), "start": 0, "end": 10 }))
            if (data.payload.data.length > 0) {
                fields.forEach((field: any) => {
                    if (field == "debitMonth") {
                        let result = getCurrentMonth(data.payload.data[0][field]);
                        setValue("debitMonth", result);
                    } else {
                        setValue(field, data.payload.data[0][field])
                    }
                });
                setValue('value', converter(`${data.payload.data[0].value}`))
                setValueCon(data.payload.data[0].value)
                portionSetHide(false);
                debitOwner(data.payload.data[0]['debit'])
                creditSuppliers(data.payload.data[0]['credit'])
                setValue('credit', data.payload.data[0]['credit']);
                dispatch(propertyExpOpen(true))
                if ((data.payload.data[0]["status"] !== 0 && data.payload.data[0]["status"] !== "0")) {
                    setHideAllOrDisable(false);
                }
            }
        }
    }
    const propertyExpClosed = () => {
        dispatch(propertyExpClose(false));
        dispatch(setPropertyExpenseId('0'));
        setDouble(false);
        debitSetHide(true);
        setHide(true);
        creditSetHide(true);
        setValue('id', '');
        setPortionData([]);
        setMoreOptions(false);
        reset();
    }
    const [adminFeeHide, setAdminFeeHide] = useState(false);
    const [isMoreOptions, setMoreOptions] = useState(false);
    const creditSuppliers = (val) => {
        // setAarinConfig(1);
        // setaarinSuppConfig(1);
        setHide(true);
        creditSetHide(true);
        if (val == 4) {
            let disableddta: any = document.querySelector('[name="supplier"]');
            if (disableddta) {
                disableddta.disabled = false;
            }
            // setValue('creditOwner', '');
            // setValue('supplier', '');
            setHide(false);
            if (suppliersData && suppliersData.length == 1) {
                let setSupplierId: any = suppliersData[0]["id"];
                setValue('supplier', setSupplierId);
                if (disableddta) {
                    disableddta.disabled = true;
                }
            }

        } else if (val == 1) {
            setValue('supplier', '');
            setValue('formOfPayment', '');
            creditSetHide(false);

            let disableddta: any = document.querySelector('[name="creditOwner"]');
            if (disableddta) {
                disableddta.disabled = false;
            }
            setValue('creditOwner', '');

            if (propertyOwnerData && propertyOwnerData.length == 1) {
                let setOwnerId: any = propertyOwnerData[0]["id"];
                setValue('creditOwner', setOwnerId);
                if (disableddta) {
                    disableddta.disabled = true;
                }
            }

        } else if (val == 3) {
            // Real Estate then hide Admin fee checkobx, 100% to Admin
            setAdminFeeHide(true);
            setMoreOptions(false);
        } else {
            setValue('supplier', '');
            setValue('formOfPayment', '');
            setValue('creditOwner', '');
        }
        let getOwner: any = Number(getValues("debit"));
        checksameNumber(getOwner, Number(val));
    }

    /*-------------Onchange debit functionality-----------*/
    const issContract = (evt) => {
        if (evt.target.checked === true) {
            setMoreOptions(true);
        } else {
            setMoreOptions(false);
        }
    };

    const [ifpropertynot, setifpropertynot] = useState(true);
    const debitOwner = (val) => {
        debitSetHide(true);
        setifpropertynot(true);
        if (val == 1) {
            // setifpropertynot(false);
            debitSetHide(false);
            if (propertyOwnerData && propertyOwnerData.length == 1) {
                let setOwnerId: any = propertyOwnerData[0]["id"];
                setValue('debitOwner', setOwnerId);
                let disableddta: any = document.querySelector('[name="debitOwner"]');
                if (disableddta) {
                    disableddta.disabled = true;
                }
            }
            creditSetHide(true);
            setValue('creditOwner', '');
            setValue("credit", "");
        } else {
            if (val == 5) {
                setifpropertynot(false);
                debitSetHide(false);
            }
            let disableddta: any = document.querySelector('[name="debitOwner"]');
            if (disableddta) {
                disableddta.disabled = false;
            }
            setValue('debitOwner', '');
        }
        let getCredit: any = getValues("credit");
        checksameNumber(Number(val), Number(getCredit));
    }

    function checksameNumber(debit: any, credit: any) {
        if (debit == credit) {
            setError("debit", {
                type: "manual",
                message: i18n.language == "en" ? "Debit cannot be identical to credit" : "O débito não pode ser idêntico ao crédito",
            });
            setError("credit", {
                type: "manual",
                message: i18n.language == "en" ? "Credit cannot be identical to debit" : "O crédito não pode ser idêntico ao débito",
            });
        } else {
            clearErrors("debit")
            clearErrors("credit")
        }
    }
    let form11 = {
        maturity: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required(t('requiredMmessage.fieldRequired')),
        value: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .min(2, "Please enter a value more than 2 digit"),
        service: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // complement: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        debit: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        credit: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),

    }
    let form111 = {
        debitMonth: Yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required(t('requiredMmessage.fieldRequired')),
        // .min(Yup.ref('maturity'), t('requiredMmessage.Enddatecantbebeforestartdate'))
        bankAccount: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    if (isMoreOptions) {
        // form11 = { ...form11,...form111}
    }
    form11 = { ...form11, ...form111 }
    let form22 = {}
    if (portionHide) {
        form22 = {
            portion2: Yup.number()
                .required(t('requiredMmessage.fieldRequired'))
                .positive()
                .typeError(t('requiredMmessage.Thevaluemustbeanumber'))
                .integer(),
            in2: Yup.number()
                .required(t('requiredMmessage.fieldRequired'))
                .positive()
                .typeError(t('requiredMmessage.Thevaluemustbeanumber'))
                .integer()
        }
    }
    let form1 = { ...form11, ...form22 }
    let form2 = {
        supplier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        formOfPayment: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    let form3 = {
        debitOwner: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    let form4 = {
        creditOwner: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    }
    let form5 = { ...form1 };
    if (!hide) {
        if (!debitHide) {
            form5 = { ...form1, ...form2, ...form3 };
        }
        else {
            form5 = { ...form1, ...form2 };
        }
    }
    else if (!creditHide) {
        if (!debitHide) {
            form5 = { ...form1, ...form3, ...form4 };
        }
        else {
            form5 = { ...form1, ...form4 };
        }
    }
    else {
        if (!debitHide) {
            form5 = { ...form1, ...form3 };
        }
    }
    const expenseValidation = Yup.object().shape(form5);
    let frm = {
        resolver: yupResolver(expenseValidation)
    }

    /*-------------Insert/Update the property expense data-----------*/

    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<newExpenseForm>(frm);
    const onSubmit = async (data: newExpenseForm, e: any) => {
        setComplementDisplay(false);
        if (data?.["complement"] && data["complement"].length > 15) {
            setError("complement", {
                type: "manual",
                message: i18n.language == "en" ? "Maximum 15 characters." : "Máximo 15 caracteres",
            })
            return false
        }
        data["contractid"] = propertyData && propertyData.length > 0 && propertyData?.[0] &&
            propertyData[0]["contractId"] !== "" ? propertyData[0]["contractId"] : "";
        let checkmaturt: any = moment(data.maturity).format("YYYY-MM-DD")
        if (checkmaturt.length > 10 || checkmaturt == "" || checkmaturt == "Invalid date") {
            setError("maturity", {
                type: "manual",
                message: i18n.language == "en" ? "Please enter valid date." : "Insira uma data válida.",
            })
            return false
        }

        if (Number(getValues("debit")) == Number(getValues("credit"))) {
            checksameNumber(Number(getValues("debit")), Number(getValues("credit")))
            return false
        }

        if (portion2 > in2) {
            setError("portion2", {
                type: "manual",
                message: i18n.language == "en" ? "Invalid installment number" : "Número de parcela inválido",
            });
            return false;
        }

        let dbmth = moment(data.debitMonth).format('YYYY-MM');
        if (dbmth.length > 7) {
            setError("debitMonth", {
                type: "manual",
                message: i18n.language == "en" ? "Please enter valid date." : "Insira uma data válida.",
            })
            return false;
        }

        data.maturity = moment(data.maturity).format('YYYY-MM-DD');
        data.debitMonth = moment(data.debitMonth).format('YYYY-MM-DD');
        e.preventDefault();
        setDouble(true)
        let values: any = convertToNumber(valueCon);
        data["value"] = values
        let response = await dispatch(insertPropertyExpense(data));
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: response.payload.message,
                confirmButtonText: "OK",
                timer: 2000
            })
            propertyExpClosed();
            dispatch(getPropertyExpense(state))
        } else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: response.payload.message,
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    const [PortionData, setPortionData] = useState([]);
    const [in2, setin2] = useState(0);
    const [portion2, setportion2] = useState(0);
    const [maturity, setmaturity] = useState("");
    const [value, setvalue] = useState<any>(0);
    const handleExpensesChange = (e: any, type: any) => {
        if (type == "value") {
            let { value } = e.target;
            value = value.replace(/[^\d,]/g, '');
            setValue("value", formatNumber(value));
            setvalue(formatNumber(value));
            setValueCon(value);
        } else {
            if (type == "portion2") {
                setportion2(e.target.value)
            } else if (type == "in2") {
                setin2(e.target.value)
            } else if (type == "maturity") {
                let valDta = e.target.value;
                setmaturity(e.target.value)
                if (valDta.length > 10) {
                    setError("maturity", {
                        type: "manual",
                        message: i18n.language == "en" ? "Please enter valid date." : "Insira uma data válida.",
                    })
                    setValue("debitMonth", "");
                } else {
                    let result = getCurrentMonth(valDta);
                    setValue("debitMonth", result);
                    clearErrors("maturity")
                }
            }
            setValue(type, e.target.value);
        }
    }

    const [complementDisplay, setComplementDisplay] = useState(false);
    const handleComplement = (e: any) => {
        let valueDta = e.target.value;
        let boolTest = false;
        if (valueDta.length >= 15) {
            boolTest = true
        } else {
            boolTest = false
        }

        if (boolTest) {
            // setError("complement", {
            //     type: "manual",
            //     message: i18n.language == "en" ? "Maximum 15 characters." : "Máximo 15 caracteres",
            // })
            setComplementDisplay(true);
        } else {
            setComplementDisplay(false);
            // clearErrors("complement")
        }
    }

    useEffect(() => {
        if (maturity !== "" && portion2 > 0 && in2 > 0 && value !== "") {
            if (portion2 > in2) {
                setError("portion2", {
                    type: "manual",
                    message: i18n.language == "en" ? "Invalid installment number" : "Número de parcela inválido",
                });
            } else {
                clearErrors("portion2")
                let arrayportion: any = [];
                let amt2: any = convertToNumber(value);
                let amt = Number(amt2);
                let Value = Number(in2);
                let inum: number = 0;
                [...Array(Number(Value))].forEach((item, index) => {
                    if ((index + 1) >= portion2) {
                        arrayportion.push({
                            maturity: moment(maturity).add(inum, "months").format("YYYY-MM-DD"),
                            value: (Number(amt) / Number(Value)).toFixed(2),
                            complement: `Parcela ${index + 1} de ${Value}`
                        })
                        inum++;
                    }
                })
                if (arrayportion && arrayportion.length && arrayportion.length == 1) {
                    arrayportion[0].complement = '';
                }
                setPortionData(arrayportion)
            }
        } else {
            if (PortionData && PortionData.length > 0) {
                setPortionData([]);
            }
        }
    }, [portion2, in2, maturity, value]);

    useEffect(() => {
        if (PortionData && PortionData.length > 0) {
            setTimeout(() => {
                PortionData.forEach((item, index) => {
                    setValue(`data.install_${index}.maturity`, item?.["maturity"]);
                    setValue(`data.install_${index}.value`, item?.["value"]);
                    setValue(`data.install_${index}.complement`, item?.["complement"]);
                })
            }, 100)
        }
    }, [PortionData]);

    useEffect(() => {
        setValue("portion2", 1)
        setValue("in2", 1)
        setportion2(1);
        setin2(1);
    }, [propertyExpShow])

    function getCurrentMonth(matur: any) {
        let getMaturyt: any = matur
        const today = (getMaturyt && getMaturyt !== "") ? new Date(getMaturyt) : new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // Months are zero-based
        let result = `${year}-${month.toString().padStart(2, '0')}`;
        return result
    }

    const checkSuppliersAarin = (e) => {
        let val = e.target.value;
        let sAcc = suppliersData.filter(
            (item: any, index: any) => Number(e.target.value) == Number(item.id)
        );
        if (sAcc.length) {
            val = sAcc[0]?.id;
            commonService
                .isUserBankConfigured({ userId: val })
                .then((res) => {
                    if (res.resCode == 201) {
                        // setaarinSuppConfig(1);
                    } else {
                        // setaarinSuppConfig(0);
                    }
                }).catch((err) => {
                    console.log("err", err);
                });
        }
    };

    const handleDebitMonth = (e: any) => {
        let matu = getValues("maturity");
        if (matu && matu.length > 0 && matu !== "") {
            let month = moment(matu).format("MM");
            let deb = moment(e.target.value).format("MM");
            if (deb > "12") {
                setError("debitMonth", {
                    type: "manual",
                    message: i18n.language == "en" ? "Please enter valid date" : "Por favor, insira uma data válida",
                });
            } else {
                // if (month > deb) {
                //     setError("debitMonth", {
                //         type: "manual",
                //         message: i18n.language == "en" ? "The debit month must be greater than the maturity month" : "O mês de débito deve ser posterior ao mês de vencimento",
                //     });
                // } else {
                clearErrors("debitMonth");
                // }
            }
        }
    }

    return (
        <>
            <Modal show={propertyExpShow} className="NewPropertyExpensestsx" onHide={propertyExpClosed}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" value={id} {...register('propertyId')} />
                    <input type="hidden" {...register('id')} />
                    <Modal.Header closeButton>
                        <Modal.Title>{t("expenditure.newExpense")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label={t("maturity") + " *"}>
                                    <Form.Control type="date" placeholder={t("maturity")} className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} maxLength={10} max={10} onChange={(e) => handleExpensesChange(e, "maturity")} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                    <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label={t("value") + " *"}>
                                    <Form.Control type="text" placeholder={t("value")} className={`form-control ${errors.value ? 'is-invalid' : ''}`} {...register('value')} onChange={(e) => handleExpensesChange(e, "value")} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingInput" label={t("contractsettings.Service") + " *"}>
                                        <Form.Select className={`form-control ${errors.service ? 'is-invalid' : ''}`} {...register('service')} disabled={!hideAllOrDisable} >
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {
                                                serviceData.map((item: any, index: number) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.service?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingInput" label={t("complement")}>
                                    <Form.Control type="text" placeholder={t("complement")} className={`form-control ${errors.complement ? 'is-invalid' : ''}`} max={15} maxLength={15} {...register('complement')} onChange={handleComplement} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                    {/* <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback> */}
                                    {
                                        complementDisplay &&
                                        <span style={{ color: 'red' }}>{i18n.language == "en" ? "Maximum 15 characters." : "Máximo 15 caracteres"}</span>
                                    }
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.debit") + " *"}>
                                    <Form.Select className={`form-control ${errors.debit ? 'is-invalid' : ''}`} {...register('debit')} aria-label="Debit" onChange={(e) => debitOwner(e.target.value)} disabled={!hideAllOrDisable} >
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="1">{t('notifications.owner')}</option>
                                        <option value="3">{t('realEstate')}</option>
                                        {
                                            propertyData && propertyData.length > 0 && propertyData?.[0] &&
                                            propertyData[0]["contractId"] !== "" &&
                                            <option value="5">{t('Property')}</option>
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.debit?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6} style={{ display: debitHide ? 'none' : 'block' }}>
                                <FloatingLabel controlId="floatingInput" label={t("formLabel.debit") + " *" + t("notifications.owner") + " *"}>
                                    <Form.Select className={`form-control ${errors.debitOwner ? 'is-invalid' : ''}`} {...register('debitOwner')} disabled={!hideAllOrDisable} >
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {
                                            propertyOwnerData && propertyOwnerData.length > 0 &&
                                            propertyOwnerData.map((item: any, index: number) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.debitOwner?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.credit") + " *"}>
                                    <Form.Select className={`form-control ${errors.credit ? 'is-invalid' : ''}`} {...register('credit')} onChange={(e) => creditSuppliers(e.target.value)} aria-label="Credit" disabled={!hideAllOrDisable} >
                                        <option value="" selected>{t('dropdownItem.select')}</option>
                                        <option value="1" style={{ display: debitHide ? 'block' : 'none' }}>{t('notifications.owner')}</option>
                                        <option value="3">{t('realEstate')}</option>
                                        <option value="4">{t('services.third')}</option>
                                        {
                                            propertyData && propertyData.length > 0 && propertyData?.[0] &&
                                            propertyData[0]["contractId"] !== "" && ifpropertynot &&
                                            <option value="5">{t('Property')}</option>
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.credit?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6} style={{ display: creditHide ? 'none' : !debitHide ? 'none' : 'block' }}>
                                <FloatingLabel controlId="floatingInput" label={t("formLabel.credit") + " *" + t("notifications.owner") + " *"}>
                                    <Form.Select className={`form-control ${errors.creditOwner ? 'is-invalid' : ''}`} {...register('creditOwner')} disabled={!hideAllOrDisable}  >
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {
                                            propertyOwnerData.map((item: any, index: number) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.creditOwner?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col md={6} style={{ display: hide ? 'none' : 'block' }}>
                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.Supplier") + " *"}>
                                    <Form.Select className={`form-control ${errors.supplier ? 'is-invalid' : ''}`} {...register('supplier')} aria-label="Supplier" onChange={(e) => checkSuppliersAarin(e)} disabled={!hideAllOrDisable} >
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {
                                            suppliersData.map((item: any, index: number) => (
                                                <option key={index} value={item.favorecidoId}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.supplier?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6} style={{ display: hide ? 'none' : 'block' }}>
                                <FloatingLabel controlId="floatingSelect" label={t("formLabel.billingMethod") + " *"}>
                                    <Form.Select className={`form-control ${errors.formOfPayment ? 'is-invalid' : ''}`} {...register('formOfPayment')} aria-label="Billing method" disabled={!hideAllOrDisable} >
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {
                                            billingData.data && billingData.data.length > 0 ? billingData.data.map((item: any, index: number) => (
                                                <option key={index} value={Number(item.id)}>{item.name}</option>
                                            )) : ''
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.formOfPayment?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {
                            portionHide ?
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3" >
                                    <Col md={6}>
                                        <FloatingLabel controlId="Portion" label={t("formLabel.portion") + " *"}>
                                            <Form.Control type="text" className={`form-control ${errors.portion2 ? 'is-invalid' : ''}`} {...register('portion2')} onChange={(e) => handleExpensesChange(e, "portion2")} placeholder="Portion" maxLength={2} onKeyUp={numericOnly} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                            <Form.Control.Feedback type="invalid">{errors.portion2?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="in" label={t("formLabel.in") + " *"}>
                                            <Form.Control type="text" className={`form-control ${errors.in2 ? 'is-invalid' : ''}`} {...register('in2')} placeholder="In" onChange={(e) => handleExpensesChange(e, "in2")} maxLength={2} onKeyUp={numericOnly} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                            <Form.Control.Feedback type="invalid">{errors.in2?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                :
                                ''
                        }

                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("banks.moreOptions")}</Accordion.Header>
                                <Accordion.Body>
                                    {/* {isMoreOptions && ( */}
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t("formLabel.debitmonth") + " *"}>
                                                <Form.Control type="month" placeholder={t("formLabel.debitmonth")} className={`form-control ${errors.debitMonth ? 'is-invalid' : ''}`} {...register('debitMonth')} maxLength={7} max={7} onChange={(e) => handleDebitMonth(e)} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                                <Form.Control.Feedback type="invalid">{errors.debitMonth?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingSelect" label={t("bankAccount") + " *"}>
                                                <Form.Select className={`form-control ${errors.bankAccount ? 'is-invalid' : ''}`} {...register('bankAccount')} aria-label="Bank account" disabled={!hideAllOrDisable}  >
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    {
                                                        accountData.data && accountData.data.length > 0 ? accountData.data.map((item: any, index: number) => (
                                                            <option key={index} value={Number(item.id)}>{item.account} -- {item.accountRef}</option>
                                                        )) : ''
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.bankAccount?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    {/*)} */}

                                    {!adminFeeHide && (
                                        <Form.Check className="mb-lg-4 mb-3" onClick={issContract} id="administration_fee" {...register('administrationFee')} label={t("services.calculateAdministration")} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                    )}
                                    <Form.Check id="next_transfer" label={t("otherstran.Includeinthenexttransfer")} {...register('nextTransfer')} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {
                            PortionData && PortionData.length > 0 &&
                            <>
                                <hr />
                                <h4 className='mb-3'>{t("Dashboard.Expenses")} {t("otherstran.installment")}</h4>
                            </>
                        }
                        {
                            PortionData && PortionData.length > 0 && PortionData.map((item, index) => {
                                return (
                                    <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3" key={index}>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingInput" label={t("maturity")}>
                                                <Form.Control type="date" placeholder={t("maturity")} className={`form-control`} {...register(`data.install_${index}.maturity`)} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingInput" label={t("value")}>
                                                <Form.Control type="text" placeholder={t("value")} className={`form-control`} {...register(`data.install_${index}.value`)} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingInput" label={t("complement")}>
                                                <Form.Control type="text" placeholder={t("complement")} className={`form-control`} {...register(`data.install_${index}.complement`)} readOnly={!hideAllOrDisable} disabled={!hideAllOrDisable} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Modal.Body>
                    {
                        hideAllOrDisable &&
                        <Modal.Footer className="d-flex align-items-center justify-content-end">
                            <Button className="me-2 me-lg-3" variant="outline-primary" onClick={propertyExpClosed}>
                                {t("button.close")}
                            </Button>
                            {/* {aarinConfig == 1 && aarinSuppConfig == 1 && ( */}
                            <Button type="submit" variant="primary" onClick={openErrorbox}>
                                {t("button.toSave")}
                            </Button>
                            {/* )} */}
                        </Modal.Footer>
                    }
                </Form>
            </Modal>
        </>
    )
}
export default PropertyExpensesService;