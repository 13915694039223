import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup, ListGroup } from 'react-bootstrap';
import { getTax, getCostCenter, setFinancialExpClose, getAccountCategory, insertFinancialExpense, UpdateFinancialExpense, setFinancialExpOpen, getAccountCategory1 } from "../../../../redux/slice/financialDataSlice";
import { getAllSuppliers, showSuppliers } from "../../../../redux/slice/contractDataSlice";
import { getAllBillMethodListing, getAllAccounts } from "../../../../redux/slice/expenditureDataSlice";
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { newExpenseForm } from '../../../Interface';
import Swal from 'sweetalert2';
import { openErrorbox, numericOnly } from './actionPerform';
import '../../../../App.css';
import { fetchBankTransaction } from '../../../../redux/slice/BankTransactionDataSlice';
import NewSuppliers from '../Common/NewSuppliers';
import { transferToBePaid, getPaidExpense } from '../../../../redux/slice/transferDataSlice';
import { converter, convertToNumber, formatNumber, minDate } from '../../../constants/utils';
import moment from "moment-timezone";
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';
import NewRetention from './NewRetention';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const FinancialExpenseService = (props: any) => {
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t, i18n] = useTranslation();
  const { taxData, taxStatus, financialExpOpen, accountCatData, accountCatData1 } = useSelector((state: RootState) => state.financialData)
  const { suppliersData } = useSelector((state: RootState) => state.contractData)
  const { billingData, accountData } = useSelector((state: RootState) => state.expenditureData)
  const { userbanklist, status, banklist } = useSelector((state: RootState) => state.bank)
  const { state, paidDetail, type } = props;
  const [repeatVal, setRepatVal] = useState(false);
  const [repetName, setrepetName] = useState("monthly");
  const [PortionData, setPortionData] = useState<any>([]);
  const [dateVal, setDateVal] = useState<any>()
  const [install, setInstall] = useState<any>("")
  const [minVal, setMinVal] = useState<any>(new Date);
  const [startDate, setStartDate] = useState<any>('');
  const [loader_on, setloader_on] = useState(false);

  const inputRef = useRef<any>(null);
  useEffect(() => {
    if (financialExpOpen) {
      dispatch(getAllSuppliers());
      dispatch(getAllAccounts({ "start": 0, "end": 1000 }))
      dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
      dispatch(getAccountCategory());
      dispatch(getAccountCategory1());
      dispatch(getTax({ "tax": "", "start": 0, "end": 1000 }));
      dispatch(getCostCenter({ "name": "", "start": 0, "end": 1000 }));
      getEditPaidExpense(paidDetail);
      dispatch(getBankList({
        start: "0",
        end: "1000"
      }))
      dispatch(add_Bank())
    }
  }, [dispatch, financialExpOpen, paidDetail])

  /*------------Form validation-----------*/
  const expenseValidation = Yup.object().shape({
    maturity: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    supplier: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    billingMethod: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    bankAccount: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    expense_value: Yup.array().of(
      Yup.object().shape({
        accountCategory: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        value: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
        amount: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
      })
    ).nullable().required(t("requiredMmessage.fieldRequired")),
    // expense_withoutholding: Yup.array().of(
    //   Yup.object().shape({
    //     accountCategory: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    //     value: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
    //     maturity: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
    //   })
    // ).nullable().required(t("requiredMmessage.fieldRequired")),
    // expense_discount: Yup.array().of(
    //   Yup.object().shape({
    //     accountCategory: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    //     value: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
    //   })
    // ).nullable().required(t("requiredMmessage.fieldRequired")),
  });

  let frm = {
    resolver: yupResolver(expenseValidation)
  }

  /*------------Submit Financial expnese form data-----------*/
  const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<newExpenseForm>(frm);
  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    setloader_on(true);
    setDouble(true);

    let langAmt: any = i18n.language;
    let expense_value: any = NewExpenseArray && Object.values(NewExpenseArray['value']).length > 0 && Object.values(NewExpenseArray['value']) || [];
    let expense_withoutholding: any = NewExpenseArray && Object.values(NewExpenseArray['withoutholding']).length > 0 && Object.values(NewExpenseArray['withoutholding']) || [];
    let expense_discount: any = NewExpenseArray && Object.values(NewExpenseArray['discount']).length > 0 && Object.values(NewExpenseArray['discount']) || [];

    data.expense_value = expense_value;
    data.expense_withoutholding = expense_withoutholding;
    data.expense_discount = expense_discount;

    data["ID_CONTABANCO_MOV"] = contmovId;
    data["typeforaction"] = currentStatusFor
    data['ID_ORIGEMVIRTUAL_MOV'] = 1;
    var date1 = moment(data["start_date"]);
    var date2 = moment(data["final_date"]);
    let repetvaloj: any = data["repeat"]
    if (repetvaloj !== 0 || repetvaloj !== "0") {
      var diffMonths = date2.diff(date1, (repetvaloj == 5 || repetvaloj == "5") ? "years" : "months");
      let repetNames = {
        monthly_yearly: (repetvaloj == 5 || repetvaloj == "5") ? "years" : "months",
        increse: diffMonths,
        divide_by: repetName["divide_by"]
      }
      data['repeat_value'] = repetNames;
    } else {
      data['repeat_value'] = repetName;
    }

    for (const key in data.expense_value) {
      if (data.expense_value.hasOwnProperty(key)) {
        const item = data.expense_value[key];
        // Check for 'value' and 'amount' properties
        if (item.hasOwnProperty('value') && item.hasOwnProperty('amount')) {
          // item.value = convertToNumber(item.value);
          // item.amount = convertToNumber(item.amount);

          let a: any = item.value ? item.value.toString() : '';
          let b: any = item.amount ? item.amount.toString() : '';
          if (a) {
            // if(a.split(".").length==2){
            //   a = a.replace(/\./g, ",");
            // }
            a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
          }
          if (b) {
            // if(b.split(".").length==2){
            //   b = b.replace(/\./g, ",");
            // }
            b = i18n.language == "pt" ? b?.replace(/\./g, "")?.replace(",", ".") : b?.replace(/\./g, "")?.replace(",", ".");
          }
          item.value = a;
          item.amount = b;
          // Ensure accountCategory is a string and contains ' - '
          if (typeof item.accountCategory === 'string' && (item.accountCategory.includes('-') || item.accountCategory.includes(' - '))) {
            if (item.accountCategory.includes('-')) {
              item.accountCategory = item.accountCategory.split('-')[0];
            }
            if (item.accountCategory.includes(' - ')) {
              item.accountCategory = item.accountCategory.split(' - ')[0];
            }
          }
        }
        // if(a.split(".").length==2){
        //   a = a.replace(/\./g, ",");
        // }
      }
    }

    for (const key in data.expense_withoutholding) {
      if (data.expense_withoutholding[key].hasOwnProperty('value')) {
        // data.expense_withoutholding[key].value = convertToNumber(data.expense_withoutholding[key].value);
        let a: any = data.expense_withoutholding[key].value ? data.expense_withoutholding[key].value.toString() : '';
        if (a) {
          // if(a.split(".").length==2){
          //   a = a.replace(/\./g, ",");
          // }
          a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
        }
        data.expense_withoutholding[key].value = a;
      }
    }
    for (const key in data.expense_discount) {
      if (data.expense_discount[key].hasOwnProperty('value')) {
        // data.expense_discount[key].value = convertToNumber(data.expense_discount[key].value);
        let a: any = data.expense_discount[key].value ? data.expense_discount[key].value.toString() : '';
        if (a) {
          // if(a.split(".").length==2){
          //   a = a.replace(/\./g, ",");
          // }
          a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
        }
        data.expense_discount[key].value = a;
      }
    }
    for (let key in data.data) {
      if (data.data[key].hasOwnProperty('value')) {
        //data.data[key].value = convertToNumber(data.data[key].value);
        let a: any = data.data[key].value ? data.data[key].value.toString() : '';
        if (a) {
          // if(a.split(".").length==2){
          //   a = a.replace(/\./g, ",");
          // }
          a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
        }
        data.data[key].value = a;
      }
    }
    let totalAmt: any = `${data.totalAmount}`;
    //let vl_emitido_recb: any = convertToNumber(totalAmt);

    let a: any = totalAmt ? totalAmt.toString() : '';
    if (a) {
      // if(a.split(".").length==2){
      //   a = a.replace(/\./g, ",");
      // }
      a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
    }
    data["totalAmount"] = a;
    data.PortionData = PortionData.length;
    if(Math.sign(data.totalAmount)!= -1){
      if (data.id !== "") {
        let response = await dispatch(insertFinancialExpense(data));
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 200 || response.payload.resCode === 202)) {
          setloader_on(false);
          setDouble(false);
          financialExpClosed();
          setInstall("")
          setPortionData([])

          if (state?.["from"] == "tobepaid") {
            dispatch(transferToBePaid(state))
          } else if (state?.["from"] == "banktransaction") {
            dispatch(fetchBankTransaction(state))
          }
          Swal.fire({
            // icon: 'success', 
            title: t("Sweetalert.Success"),
            // text: response.payload.message, 
            text: t("Sweetalert.Recordinserted"),
            confirmButtonText: "OK", timer: 2000
          })
          let histData = {
            "start": 0,
            "end": 10,
            "propertyId": response.payload.data
          }
          financialExpClosed();
          setInstall("")
          setPortionData([])
        } else {
          setDouble(false);
          setloader_on(false)
          Swal.fire({
            title: t("Sweetalert.Error"),
            text: response.payload.message,
            //icon: "error", 
            confirmButtonText: "OK",
            timer: 2000
          })
        }
      } else {
        let response = await dispatch(insertFinancialExpense(data));
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
          setDouble(false);
          setloader_on(false)
          financialExpClosed();
          setInstall("")
          setPortionData([])
          if (state?.["from"] == "tobepaid") {
            dispatch(transferToBePaid(state))
          } else if (state?.["from"] == "banktransaction") {
            dispatch(fetchBankTransaction(state))
          }
          Swal.fire({
            // icon: 'success', 
            title: t("Sweetalert.Success"),
            // text: response.payload.message,
            text: t("Sweetalert.Recordinserted"),
            confirmButtonText: "OK",
            timer: 2000
          })
          let histData = {
            "start": 0,
            "end": 10,
            "propertyId": response.payload.data
          }

        }
        else {
          setDouble(false)
          setloader_on(false)

          Swal.fire({
            title: t("Sweetalert.Error"),
            text: response.payload.message,
            //icon: "error", 
            confirmButtonText: "OK",
            timer: 2000
          })
        }
      }
    }
    else{
      Swal.fire({
          title: t("Sweetalert.Error"),
          text: t("paidAount"),
          confirmButtonText: "OK",
          timer: 2000
      })
    }

  }

  let valueInsert = {
    id: "",
    accountCategory: "",
    complement: "",
    amount: "",
    value: "",
  }

  let withoutholdingInsert = {
    id: "",
    maturity: "",
    tax: "",
    value: "",
    // bankAccount: "",
    accountCategory: "",
    complement: "",
  }

  let discountInsert = {
    id: "",
    accountCategory: "",
    complement: "",
    value: "",
  }

  const [NewExpenseArray, setNewExpenseArray] = useState<any>({
    value: {
      index_0: valueInsert
    },
    withoutholding: {
      index_0: withoutholdingInsert
    },
    discount: {
      index_0: discountInsert
    }
  })
  const [nonEdit, setNoEdit] = useState(false);
  const [minDT, setMinDT] = useState<any>(moment(dateBrazil).format("YYYY-MM"));
  const [currentStatusFor, setCurrentStatusFor] = useState("insert");
  const [contmovId, setcontmovId] = useState("");

  const getEditPaidExpense = async (paidDetail) => {
    setCurrentStatusFor("insert");
    if (paidDetail) {
      if (paidDetail.id) {
        setcontmovId(paidDetail.id);
        setDouble(true);
        setRepatVal(false);
        setNoEdit(false);
        let postData = { id: paidDetail.id }
        const fields: string[] = ['maturity', 'supplier', 'billingMethod', 'historic', 'comments', 'bankAccount', 'docNo', 'issuance', 'type', 'installments', 'repeat', 'start_date', 'final_date', 'newExpence', 'totalAmount']
        let data = await dispatch(getPaidExpense(postData));
        if (data.payload.resCode == 201) {
          setCurrentStatusFor("update");
          setDouble(false);
          fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));
          if (type == "tobepaid") {
            setMinDT(moment(data.payload.data[0].start_date).format("YYYY-MM"))
            setMinVal(moment(data.payload.data[0].final_date).format("YYYY-MM"))
            setValue('ID_RECEBIMENTO_RECB', data.payload.data[0].ID_RECEBIMENTO_RECB)
            setDateVal(data.payload.data[0].maturity)
            setValue('id', data.payload.data[0].ID_CONTABANCO_MOV ? data.payload.data[0].ID_CONTABANCO_MOV : "")
          } else {
            setDouble(true);
          }

          if (data.payload.data[0].installments) {
            setValue("installments", "1")
            setInstall(1)
            setRepatVal(false);
          }
          if (typeof data.payload.data[0].repeat !== "undefined" && data.payload.data[0].repeat != 0 && data.payload.data[0].installments == 0) {
            setRepatVal(true);
            //setNoEdit(true);
          }

          let value: any = {}
          if (typeof data.payload.data[0].expenseValue !== "undefined" && data.payload.data[0].expenseValue.length) {
            data.payload.data[0]?.expenseValue.forEach((items: any, indx: any) => {
              let amt:any = items.value;
              if(amt){
                amt = amt.toString().replace(/\./g,",")
              }
              setValue(`expense_value.${indx}.id`, items.id ? items.id : "");
              setValue(`expense_value.${indx}.accountCategory`, items.accountCategory ? items.accountCategory + '-' + items.accountTitle : '');
              setValue(`expense_value.${indx}.complement`, items.complement ? items.complement : '');
              setValue(`expense_value.${indx}.amount`, items.amount);
              setValue(`expense_value.${indx}.value`,amt);
              let dt: any = {
                id: items.id ? items.id : "",
                accountCategory: items.accountCategory ? items.accountCategory : '',
                complement: items.complement ? items.complement : '',
                amount: items.amount ? items.amount : '',
                value: amt,
              }
              value[`index_${indx}`] = dt;
            })
          }
          if (Object.keys(value).length) {
            NewExpenseArray['value'] = value;
            setNewExpenseArray({
              ...NewExpenseArray
            });
          }


          let discount: any = {};
          if (typeof data.payload.data[0].expenseDiscount !== "undefined" && data.payload.data[0].expenseDiscount.length) {
            data.payload.data[0]?.expenseDiscount.forEach((items, indx) => {
              let amt:any = items.value;
              if(amt){
                amt = amt.toString().replace(/\./g,",")
              }

              setValue(`expense_discount.${indx}.id`, items.id ? items.id : "");
              setValue(`expense_discount.${indx}.accountCategory`, items.accountCategory ? items.accountCategory : '');
              setValue(`expense_discount.${indx}.complement`, items.complement ? items.complement : '');
              setValue(`expense_discount.${indx}.amount`, items.amount);
              setValue(`expense_discount.${indx}.value`, amt);

              let dt: any = {
                id: items.id ? items.id : "",
                accountCategory: items.accountCategory ? items.accountCategory : '',
                complement: items.complement ? items.complement : '',
                amount: items.amount ? items.amount : '',
                value: amt,
              }
              discount[`index_${indx}`] = dt;
            })
          }
          if (Object.keys(discount).length) {
            NewExpenseArray['discount'] = discount;
            setNewExpenseArray({
              ...NewExpenseArray
            });
          }

          let withoutholding: any = {};
          if (typeof data.payload.data[0].expenseWithout !== "undefined" && data.payload.data[0].expenseWithout.length) {
            data.payload.data[0]?.expenseWithout.forEach((items, indx) => {
              let amt:any = items.value;
              if(amt){
                amt = amt.toString().replace(/\./g,",")
              }
              setValue(`expense_withoutholding.${indx}.id`, items.id ? items.id : "");
              setValue(`expense_withoutholding.${indx}.accountCategory`, items.accountCategory ? items.accountCategory : 1.1);
              setValue(`expense_withoutholding.${indx}.complement`, items.complement ? items.complement : '');
              setValue(`expense_withoutholding.${indx}.amount`, items.amount);
              setValue(`expense_withoutholding.${indx}.value`,amt);
              setValue(`expense_withoutholding.${indx}.tax`, items.tax);
              setValue(`expense_withoutholding.${indx}.maturity`, items.maturity && items.maturity !== "items.maturity" ? items.maturity : "");
              let dt: any = {
                id: items.id ? items.id : "",
                accountCategory: items.accountCategory ? items.accountCategory : '1.1',
                complement: items.complement ? items.complement : '',
                amount: items.amount ? items.amount : '',
                value: amt,
                tax: items.tax ? items.tax : '',
                maturity: items.maturity ? items.maturity : ''
              }
              withoutholding[`index_${indx}`] = dt;
            })

          }
          if (Object.keys(withoutholding).length) {
            NewExpenseArray['withoutholding'] = withoutholding;
            setNewExpenseArray({
              ...NewExpenseArray
            });
          }
        } else {
          setCurrentStatusFor("insert");
        }
      }
    }
  }


  const financialExpClosed = () => {
    reset();
    setcontmovId("");
    setNewExpenseArray({
      value: {
        index_0: valueInsert
      },
      withoutholding: {
        index_0: withoutholdingInsert
      },
      discount: {
        index_0: discountInsert
      }
    })
    setRepatVal(false);
    setNoEdit(false);
    setloader_on(false);
    setDouble(false);

    setInstall(0);
    setValue("start_date", new Date(dateBrazil));
    setValue("final_date", new Date(dateBrazil));
    dispatch(setFinancialExpClose(false));
    dispatch(fetchBankTransaction(state))
    setPortionData([])
    setDouble(false);
    
  }

  const [hide, hideSet] = useState(true);
  const checkExpence = (evt) => {
    hideSet(true);
    setValue('dateDaleOff', '');
    if (evt.target.checked) {
      hideSet(false);
    }
  }
  const handleSupplier = () => {
    dispatch(showSuppliers(true))
    dispatch(setFinancialExpOpen(false));
  }

  const addAnother = (e: any, type: string) => {
    e.preventDefault();
    let length_of_type = Object.keys(NewExpenseArray[`${type}`]).length;
    setNewExpenseArray({
      ...NewExpenseArray,
      [`${type}`]: {
        ...NewExpenseArray[`${type}`],
        [`index_${length_of_type}`]: type == "value" ? valueInsert :
          type == "discount" ? discountInsert : withoutholdingInsert
      }
    })
    setIsInputFocused([
      ...isInputFocused,
      { focus: false }
    ])
  }

  const DeleteAnother = (e: any, type: string, index: string | number) => {
    e.preventDefault();
    //reset();
    let arrayOfSelectdObject: any = NewExpenseArray && Object.values(NewExpenseArray[`${type}`]).length > 0 && Object.values(NewExpenseArray[`${type}`]) || [];
    if (arrayOfSelectdObject && arrayOfSelectdObject.length > 0) {
      arrayOfSelectdObject.splice(index, 1);
      let pushObject: any = {};
      arrayOfSelectdObject.forEach((item: any, index: number) => {
        pushObject[`index_${index}`] = item;
        // Object.keys(item).forEach((iteme: any, indexe: number) => {
        //   let valueDattt = `expense_${type}.${index}.${iteme}` as `expense`;
        //   setValue(valueDattt, item[`${iteme}`]);
        // })
      })
      NewExpenseArray[`${type}`] = pushObject;
      setNewExpenseArray({
        ...NewExpenseArray,
        [`${type}`]: pushObject
      });
      ReCalculate();
      // let Alldata = ["value", "withoutholding", "discount"];
      let Alldata = [type]
      Alldata.forEach((typeDattta: any) => {
        let arrayOfSelectdObject: any = NewExpenseArray && Object.values(NewExpenseArray[`${typeDattta}`]).length > 0 && Object.values(NewExpenseArray[`${typeDattta}`]) || [];
        if (arrayOfSelectdObject && arrayOfSelectdObject.length > 0) {
          arrayOfSelectdObject.forEach((item: any, index: number) => {
            Object.keys(item).forEach((iteme: any, indexe: number) => {
              let valueDattt = `expense_${type}.${index}.${iteme}` as `expense`;
              setValue(valueDattt, item[`${iteme}`]);
            })
          })
        }
      })
    }
  }

  const [filteredData, setFilteredData] = useState(accountCatData1);

  const handleCategoryInsert = (value: any, desc: any, type: string, index: number) => {
    let name = "accountCategory"
    let valueDattt = `expense_${type}.${index}.${name}` as `expense`;
    let valDesc: any = value + " - " + desc;
    setValue(valueDattt, valDesc);
    NewExpenseArray[`${type}`][`index_${index}`][`${name}`] = value;
  }

  let typerr: any
  const handleInputInsert = (e: any, type: string, index: number) => {
    let name = e.target.getAttribute("data-name");
    let name1 = e.target.getAttribute("data-name1");
    let value = e.target.value;

    if (name == "value" && name1 == "value3") {
      clearTimeout(typerr);
      typerr = setTimeout(() => {
        let { value } = e.target;
        NewExpenseArray[`${type}`][`index_${index}`][`${name}`] = value;
        let valueDattt = `expense_${type}.${index}.${name}` as `expense`;
        setValue(valueDattt, value);
        let values = value.replace(/[^\d,]/g, '');
        setValue(`expense_value.${index}.value`, formatNumber(values))
        ReCalculate();
      }, 1500);
    }


    // if (name !== "value" && name1 !== "value3") {
    if (name1 !== "value3") {
      NewExpenseArray[`${type}`][`index_${index}`][`${name}`] = value;
      let valueDattt = `expense_${type}.${index}.${name}` as `expense`;
      setValue(valueDattt, value);
      if (name1 == "value1") {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        setValue(`expense_withoutholding.${index}.value`, formatNumber(value))
        ReCalculate();
      }
      if (name1 == "value2") {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        setValue(`expense_discount.${index}.value`, formatNumber(value))
        ReCalculate();
      }
      if (name == "amount") {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        setValue(`expense_value.${index}.amount`, formatNumber(value))
        e.target.focus();
      }

      let typingTimer1;
      if (name == "accountCategory") {
        clearTimeout(typingTimer1);
        const query = e.target.value.toLowerCase();
        // Filter the data based on the search query
        const filtered = accountCatData1.filter((item) => {
          const description = item.ST_DESCRICAO_CONT.toLowerCase();
          const code = item.ST_CONTA_CONT.toLowerCase();
          return description.includes(query) || code.includes(query);
        });
        typingTimer1 = setTimeout(() => {
          setFilteredData(filtered);
        }, 1500);
      }
    }
  }

  useEffect(() => {
    ReCalculate();
  }, [NewExpenseArray])

  const Installment = (e) => {
    let { value } = e.target
    setInstall(value)
    calculateInstallment(value);
  };

  const calculateInstallment = (vl: any) => {
    //let totalAmount = Number(totalVal);
    let value = vl;
    let totalAmount = getValues("totalAmount") ? parseFloat(getValues("totalAmount").toString().replace(",", ".")) : 0;
    let installmentValue = totalAmount / value;
    // Create an array with `value` number of objects, each containing the installment value
    let arrayportion: any = [];
    // Generate dates for each installment
    for (let i = 0; i < value; i++) {
      let addt = new Date(dateBrazil)
      addt = dateVal
      let installmentDate = new Date(addt); // Start with the initial date
      installmentDate = addMonthsPreserveEndOfMonth(installmentDate, i);
      arrayportion.push({
        maturity: formatDate(installmentDate), // Format date as YYYY-MM-DD
        value: installmentValue.toFixed(2),
      });
    }
    setPortionData(arrayportion);
  }

  function formatDate(d) {
    let year = d.getFullYear();
    let month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Function to add months to a date, preserving end-of-month dates
  function addMonthsPreserveEndOfMonth(date, months) {
    let d = new Date(date.getTime()); // Clone the date
    d.setMonth(d.getMonth() + months);

    // Check if we need to adjust to the end of the month
    if (d.getDate() < date.getDate()) {
      // If the new month has fewer days, set to the last day of the month
      d.setDate(0); // Set to the last day of the previous month
    }

    return d;
  }
  const [showNewRetentionModal, setNewRetentionModal] = useState(false);
  const newRetentionModalClose = () => {
    setNewRetentionModal(false);
  }
  const handleRetention = () => {
    setNewRetentionModal(true);
  }

  const [totalVal, setTotalVal] = useState<any>('')
  let typingTimer;
  function ReCalculate() {
    let arrayRun = ["value", "withoutholding", "discount"];
    let TotalAmount: any = 0;
    arrayRun.forEach((type: any, index: number) => {
      NewExpenseArray[`${type}`] &&
        Object.keys(NewExpenseArray[`${type}`]).forEach((item1: any, indexitem: number) => {
          if (type !== "discount" && type !== "withoutholding") {
            let a: any = NewExpenseArray[`${type}`][`${item1}`]["value"] ? NewExpenseArray[`${type}`][`${item1}`]["value"].toString() : "";
            if (a) {
              a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
              TotalAmount += parseFloat(a);
            }
          } else {
            if(type !== "withoutholding" || contmovId==""){
              let a: any = NewExpenseArray[`${type}`][`${item1}`]["value"] ? NewExpenseArray[`${type}`][`${item1}`]["value"].toString() : "";
              if (a) {
                a = i18n.language == "pt" ? a?.replace(/\./g, "")?.replace(",", ".") : a?.replace(/\./g, "")?.replace(",", ".");
                TotalAmount -= parseFloat(a);
              }
            }
          }
        })
    })

    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      //setTotalVal(TotalAmount)
      TotalAmount = TotalAmount ? TotalAmount.toString().replace(".", ",") : 0;
      setValue("totalAmount", TotalAmount);
      if (getValues("installments")) {
        calculateInstallment(install)
      }
    }, 1500);

    // if (getValues("installments")) {
    //   setTotalVal(TotalAmount)
    // }
  }



  useEffect(() => {
    if (financialExpOpen) {
      let arrayportion: any = [];
      // Calculate the value for each installment
      // let totalAmount = Number(totalVal);
      let totalAmount = getValues("totalAmount") ? parseFloat(getValues("totalAmount").toString().replace(",", ".")) : 0;
      let installmentValue = totalAmount / install;
      // Create an array with `value` number of objects, each containing the installment value
      // Generate dates for each installment
      for (let i = 0; i < install; i++) {
        let addt = new Date(dateBrazil)
        addt = dateVal
        let installmentDate = new Date(addt); // Start with the initial date
        installmentDate = addMonthsPreserveEndOfMonth(installmentDate, i);
        arrayportion.push({
          maturity: formatDate(installmentDate), // Format date as YYYY-MM-DD
          value: installmentValue.toFixed(2),
        });
      }
      setPortionData(arrayportion);
    }
  }, [financialExpOpen]); // make sure getValues is stable or memoized if passed here Removed -> totalAmount


  useEffect(() => {
    if (PortionData && PortionData.length > 0) {
      setTimeout(() => {
        PortionData.forEach((item, index) => {
          setValue(`data.install_${index}.maturity`, item?.["maturity"]);
          //setValue(`data.install_${index}.value`, item?.["value"] ? converter(convertToNumber(`${item?.["value"]}`)) : "0,00");
          setValue(`data.install_${index}.value`, item?.["value"] ? item?.["value"].toString().replace(/\./g, ",") : "0,00");
        })
      }, 500)
    }
  }, [PortionData])

  const handleDate = (e: any) => {
    setDateVal(e.target.value)
    const accountDate = new Date(startDate);
    // Get the value and convert to Date if it's a string
    const vencimentoRecbValue: any = e.target.value;
    const vencimentoDate = new Date(vencimentoRecbValue);
    // Compare the dates
    if (vencimentoDate < accountDate) {
      setError("maturity", {
        type: "manual",
        message: `${t("requiredMmessage.maturityDate")}`,
      });
    } else {
      clearErrors('maturity')
      let arrayportion: any = [];
      // Calculate the value for each installment
      let totalAmount = Number(getValues("totalAmount"));
      let installmentValue = totalAmount / install;
      // Create an array with `value` number of objects, each containing the installment value
      // Generate dates for each installment
      for (let i = 0; i < install; i++) {
        let addt = new Date(dateBrazil)
        addt = e.target.value
        let installmentDate = new Date(addt); // Start with the initial date
        installmentDate = addMonthsPreserveEndOfMonth(installmentDate, i);
        arrayportion.push({
          maturity: formatDate(installmentDate), // Format date as YYYY-MM-DD
          value: installmentValue.toFixed(2),
        });
      }
      setPortionData(arrayportion);
    }
  }

  const AccountCat = () => {
    return (
      <>
        {
          accountCatData && accountCatData.length > 0 &&
          accountCatData.map((item: any, index: number) => {
            if ((item.childData).length > 0) {
              return (
                <optgroup key={index} label={`${item.ST_CONTA_CONT} ${item.ST_DESCRICAO_CONT}`}>
                  {
                    item.childData && (item.childData).length > 0 &&
                    item.childData.map((firstItem: any, index: number) => {
                      if ((firstItem.childData).length > 0) {
                        return (
                          <React.Fragment key={index}>
                            <option value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                            {
                              (firstItem.childData).map((seconditem: any, index: number) => {
                                if ((seconditem.childData).length > 0) {
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                      {
                                        (seconditem.childData).map((thirditem: any, index: number) => {
                                          if ((thirditem.childData).length > 0) {
                                            (thirditem.childData).map((fourthitem, index) => {
                                              return (
                                                <React.Fragment key={index}>
                                                  <option value={fourthitem.ST_CONTA_CONT}>{fourthitem.ST_CONTA_CONT} {fourthitem.ST_DESCRICAO_CONT}</option>
                                                </React.Fragment>
                                              )
                                            })
                                          } else {
                                            return (
                                              <React.Fragment key={index}>
                                                <option value={thirditem.ST_CONTA_CONT}>{thirditem.ST_CONTA_CONT} {thirditem.ST_DESCRICAO_CONT}</option>
                                              </React.Fragment>
                                            )
                                          }
                                        })
                                      }
                                    </React.Fragment>
                                  )
                                } else {
                                  return (
                                    <option key={index} value={seconditem.ST_CONTA_CONT}>{seconditem.ST_CONTA_CONT} {seconditem.ST_DESCRICAO_CONT}</option>
                                  )
                                }
                              })
                            }
                          </React.Fragment>
                        )
                      } else {
                        return (
                          <option key={index} value={firstItem.ST_CONTA_CONT}>{firstItem.ST_CONTA_CONT} {firstItem.ST_DESCRICAO_CONT}</option>
                        )
                      }
                    })
                  }
                </optgroup>
              )
            }
          })
        }
      </>
    )
  };

  const ElementLoop = (props: any) => {
    let { type } = props;
    return (
      <>
        {
          NewExpenseArray &&
          Object.keys(NewExpenseArray[`${type}`]).map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Row>
                  {
                    Object.keys(NewExpenseArray[`${type}`]).length > 1 &&
                    <span className='d-flex justify-content-end mb-1'>
                      <Button variant="link" className="fw-bold p-0" onClick={(e) => DeleteAnother(e, type, index)}>
                        {t("Sweetalert.Delete")}
                      </Button>
                    </span>
                  }

                  {
                    (type == "value") &&
                    <>
                      <Col md={6}>
                        <input type="hidden"  {...register(`expense_value.${index}.id`)} data-name="id" />
                        <InputGroup>
                          <Form.Floating ref={inputRef} >
                            <Form.Control
                              type="text"
                              placeholder="Type to search..."
                              className={`form-control ${errors?.expense_value?.[index]?.accountCategory ? 'is-invalid' : ''}`}
                              aria-label="Account (category)" {...register(`expense_value.${index}.accountCategory`)} data-name="accountCategory" onChange={(e) => handleInputInsert(e, type, index)}
                              onFocus={() => handleInputFocus(index)}
                              autoComplete="off"
                            />
                            {isInputFocused && display && (
                              <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}   >
                                {filteredData && filteredData?.map((item: any, ind) => (
                                  <ListGroup.Item key={ind} className={`px-3 cursor-pe`} disabled={item.ST_CONTA_CONT.length === 1} onClick={(e) => { HandleAccountcate(e, item.ST_CONTA_CONT, item.ST_DESCRICAO_CONT, index); handleCategoryInsert(item.ST_CONTA_CONT, item.ST_DESCRICAO_CONT, type, index); }}>
                                    {item.ST_CONTA_CONT}  {item.ST_DESCRICAO_CONT}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            )}
                            <Form.Label htmlFor="floatingInputValue">{t("formLabel.accountCategory") + " *"}</Form.Label >
                            <Form.Control.Feedback type="invalid">{errors?.expense_value?.[index]?.accountCategory?.message}</Form.Control.Feedback>
                          </Form.Floating>
                        </InputGroup>
                        {/* <FloatingLabel label={t("formLabel.accountCategory") + " *"} className="mb-3 mb-lg-4">
                          <Form.Select className={`form-control ${errors?.expense_value?.[index]?.accountCategory ? 'is-invalid' : ''}`}
                            aria-label="Account (category)" {...register(`expense_value.${index}.accountCategory`)} data-name="accountCategory" onChange={(e) => handleInputInsert(e, type, index)}>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <AccountCat />
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors?.expense_value?.[index]?.accountCategory?.message}</Form.Control.Feedback>
                        </FloatingLabel> */}
                      </Col>
                      <Col md={6}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("Amount") + " *"}>
                          <Form.Control type="Text" aria-label="Amount"
                            className={`form-control ${errors?.expense_value?.[index]?.amount ? 'is-invalid' : ''}`} {...register(`expense_value.${index}.amount`)} data-name="amount" placeholder={t("contractlistother.Amount")} onChange={(e) => handleInputInsert(e, type, index)} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_value?.[index]?.amount?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                          <Form.Control type="Text" className={`form-control ${errors?.expense_value?.[index]?.complement ? 'is-invalid' : ''}`}
                            aria-label="Complement" {...register(`expense_value.${index}.complement`)} data-name="complement" placeholder={t("complement")} onChange={(e) => handleInputInsert(e, type, index)} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_value?.[index]?.complement?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("value") + " *"}>
                          <Form.Control type="Text" aria-label="Value" className={`form-control ${errors?.expense_value?.[index]?.value ? 'is-invalid' : ''}`}
                            {...register(`expense_value.${index}.value`)} data-name="value" data-name1="value3" onChange={(e) => { handleInputInsert(e, type, index); }} placeholder={t("value")} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_value?.[index]?.value?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </>
                  }
                  {
                    type == "withoutholding" && 
                    <>
                      <Col md={6}>
                        <input type="hidden"  {...register(`expense_withoutholding.${index}.id`)} />
                        <FloatingLabel controlId="maturity" className="mb-3 mb-lg-4" label={t("maturity")}>
                          <Form.Control type="date" className={`form-control ${errors?.expense_withoutholding?.[index]?.maturity ? 'is-invalid' : ''}`} {...register(`expense_withoutholding.${index}.maturity`)} data-name="maturity" placeholder={t("maturity")} onChange={(e) => handleInputInsert(e, type, index)} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_withoutholding?.[index]?.maturity?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <InputGroup className="mb-3 mb-lg-4">
                          <FloatingLabel label={t("contractlistother.Tax")} >
                            <Form.Select aria-label="Tax" className={`form-control ${errors?.expense_withoutholding?.[index]?.tax ? 'is-invalid' : ''}`} {...register(`expense_withoutholding.${index}.tax`)} data-name="tax" onChange={(e) => handleInputInsert(e, type, index)} >
                              <option value="">{t('dropdownItem.select')}..</option>
                              {taxData.map((item: any, index: number) => (
                                <option key={index} value={item.id}>{item.name}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors?.expense_withoutholding?.[index]?.tax?.message}</Form.Control.Feedback>
                          </FloatingLabel>
                          <InputGroup.Text title={t("formLabel.Supplier")}>
                            <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" onClick={handleRetention} />
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                          <Form.Select className={`form-control ${errors?.expense_withoutholding?.[index]?.accountCategory ? 'is-invalid' : ''}`}
                            aria-label="Account (category)" {...register(`expense_withoutholding.${index}.accountCategory`)} data-name="accountCategory" onChange={(e) => handleInputInsert(e, type, index)}>
                            <option value="">{t('dropdownItem.select')}..</option>
                            {/*<AccountCat />*/}
                            {accountData.data && accountData.data.length > 0
                            ? accountData.data.map((item, index) => (
                              <option key={index} value={Number(item.id)}>
                                {item.account} -- {item.accountRef}
                              </option>
                            ))
                            : ""}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors?.expense_withoutholding?.[index]?.accountCategory?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                          <Form.Control type="Text" aria-label="Complement" className={`form-control ${errors?.expense_withoutholding?.[index]?.complement ? 'is-invalid' : ''}`} {...register(`expense_withoutholding.${index}.complement`)} data-name="complement" placeholder={t("complement")} onChange={(e) => handleInputInsert(e, type, index)} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_withoutholding?.[index]?.complement?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("value")}>
                          <Form.Control type="Text" aria-label="Value" className={`form-control ${errors?.expense_withoutholding?.[index]?.value ? 'is-invalid' : ''}`} {...register(`expense_withoutholding.${index}.value`)} data-name="value" data-name1="value1" onChange={(e) => { handleInputInsert(e, type, index); }} placeholder={t("value")} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_withoutholding?.[index]?.value?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </>
                  }

                  {
                    type == "discount" &&
                    <>
                      <Col md={6}>
                        <input type="hidden"  {...register(`expense_discount.${index}.id`)} />
                        <FloatingLabel label={t("formLabel.accountCategory")} className="mb-3 mb-lg-4">
                          <Form.Select className={`form-control ${errors?.expense_discount?.[index]?.accountCategory ? 'is-invalid' : ''}`} aria-label="Account (category)" {...register(`expense_discount.${index}.accountCategory`)} data-name="accountCategory" onChange={(e) => handleInputInsert(e, type, index)}>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <AccountCat />
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors?.expense_discount?.[index]?.accountCategory?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("complement")}>
                          <Form.Control type="Text" aria-label="Complement" className={`form-control ${errors?.expense_discount?.[index]?.complement ? 'is-invalid' : ''}`} {...register(`expense_discount.${index}.complement`)} data-name="complement" placeholder={t("complement")} onChange={(e) => handleInputInsert(e, type, index)} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_discount?.[index]?.complement?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("value")}>
                          <Form.Control type="Text" aria-label="Value" className={`form-control ${errors?.expense_discount?.[index]?.value ? 'is-invalid' : ''}`} {...register(`expense_discount.${index}.value`)} data-name="value" data-name1="value2" onChange={(e) => { handleInputInsert(e, type, index); }} placeholder={t("value")} />
                          <Form.Control.Feedback type="invalid">{errors?.expense_discount?.[index]?.value?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </>
                  }
                </Row>
              </React.Fragment>
            )
          })}
        {
          Object.keys(NewExpenseArray[`${type}`]).length < 5 &&
          <Button variant="Primary" className="fw-bold p-0 text-secondary" onClick={(e) => addAnother(e, type)}  >
            {t("newContractDev.AddAnother")}
          </Button>
        }
      </>
    )
  }

  const handleRepeat = (e: any) => {
    let valuesee = e.target.value;
    if (String(valuesee) == "0") {
      setRepatVal(false)
    } else {
      setRepatVal(true)
    }

    if (getValues("start_date")) {
      let returnFinalDate: any = calculateFinalDate(getValues("start_date"), valuesee);
      let finalDay: any = moment(returnFinalDate["final_date"]).format("YYYY-MM");
      setValue("final_date", finalDay);
      setMinVal(getCurrentMonth(finalDay));
      setrepetName(returnFinalDate["optionData"]);
    }
  }

  const handleStartMin = (e: any) => {
    let returnFinalDate: any = calculateFinalDate(e.target.value, getValues("repeat"));
    let finalDay: any = moment(returnFinalDate["final_date"]).format("YYYY-MM");
    let finalFormate: any = getCurrentMonth(finalDay);
    setValue("final_date", finalFormate);
    setrepetName(returnFinalDate["optionData"]);
    setMinVal(finalDay);
  }

  function calculateFinalDate(start_date: any, option: any) {
    const final_date = new Date(start_date); // Initialize final_date with the start_date
    let optionNumber = Number(option);

    let optionData: any = {
      monthly_yearly: (option == 5 || option == "5") ? "years" : "months",
      increse: 0,
      divide_by: 0
    }

    switch (optionNumber) {
      case 1: // Monthly
        final_date.setMonth(final_date.getMonth() + 1); // Add one month
        optionData["divide_by"] = 1;
        optionData["increse"] = 1;
        break;
      case 2: // Bimonthly
        final_date.setMonth(final_date.getMonth() + 2); // Add two months
        optionData["divide_by"] = 2;
        optionData["increse"] = 2;
        break;
      case 3: // Quarterly
        final_date.setMonth(final_date.getMonth() + 3); // Add three months
        optionData["divide_by"] = 3;
        optionData["increse"] = 3;
        break;
      case 4: // Biannually
        final_date.setMonth(final_date.getMonth() + 6); // Add six months
        optionData["divide_by"] = 6;
        optionData["increse"] = 6;
        break;
      case 5: // Annually
        final_date.setFullYear(final_date.getFullYear() + 1); // Add one year
        optionData["divide_by"] = 1;
        optionData["increse"] = 1;
        break;
      default:
        final_date.setMonth(final_date.getMonth() + 1); // Add one month
        optionData["divide_by"] = 1;
        optionData["increse"] = 1;
    }
    setrepetName(optionData);
    return {
      final_date,
      optionData
    }
  }

  function getCurrentMonth(value: any) {
    const today = new Date(value);
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-based
    // Format the current date to "YYYY-MM" (e.g., "2023-10")
    return `${year}-${month.toString().padStart(2, '0')}`;
  }

  // if (paidDetail) {
  //   const maturityDate = new Date(paidDetail.maturity);
  //   const isoMaturityDate = maturityDate.toISOString().split('T')[0]; // Extract YYYY-MM-DD
  //   // setValue("maturity", isoMaturityDate);
  // }
  const [isInputFocused, setIsInputFocused] = useState([{ focus: false }]);
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    if (!display) {
      isInputFocused.forEach((item: any, index: any) => {
        isInputFocused[index]["focus"] = false
      })
      setIsInputFocused(isInputFocused);
    }
  }, [display])
  const HandleAccountcate = (e: any, val1, val2, index) => {
    setValue(`expense_value.${index}.accountCategory`, val1 + " - " + val2)
    setDisplay(false)
    isInputFocused[index]["focus"] = false
  }
  const handleInputFocus = (indexs: any) => {
    isInputFocused.forEach((item: any, index: any) => {
      if (indexs == index) {
        isInputFocused[index]["focus"] = true
      } else {
        isInputFocused[index]["focus"] = false
      }
    })
    setIsInputFocused(isInputFocused);
    setDisplay(true);
    if (accountCatData1 && accountCatData1.length > 0) {
      setFilteredData(accountCatData1)
    }
  };
  const hanldMaturity = (e) => {
    let { value } = e.target;
    const account = userbanklist?.find(acc => acc?.id == value);
    if (account) {
      setStartDate(account.date)
      const accountDate = new Date(account.date);
      // Get the value and convert to Date if it's a string
      const vencimentoRecbValue: any = getValues('maturity');
      const vencimentoDate = new Date(vencimentoRecbValue);
      // Compare the dates
      if (vencimentoDate < accountDate) {
        setError("maturity", {
          type: "manual",
          message: `${t("requiredMmessage.maturityDate")}`,
        });
      } else {
        clearErrors('maturity')
      }
    } else {
      setStartDate('')
    }
  }


  const [selectedBankAccount, setSelectedBankAccount] = useState("");

  // Set default bank account to the main account if available
  useEffect(() => {
    const mainAccount = accountData?.data?.find((item) => item.main_ac === 1)?.id;
    if (mainAccount) {
      setSelectedBankAccount(mainAccount);
    }
  }, [accountData]);

  const handleBankChange = (e) => {
    setSelectedBankAccount(e.target.value);
    hanldMaturity(e); // Call the handler for further processing
  };


  return (
    <>
      {/* <!-- New branch modal start from here --> */}
      <Modal show={financialExpOpen} onHide={financialExpClosed}>
        <Form onSubmit={handleSubmit(onSubmit)} className="NewFinancialExpensetsx" >
          <Modal.Header closeButton>
            <Modal.Title>{t("expenditure.newExpense")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <input type='hidden' {...register("ID_RECEBIMENTO_RECB")} />
              <input type='hidden' {...register("id")} />
              <Col md={6}>
                <InputGroup className="mb-3 mb-lg-4">
                  <FloatingLabel controlId="floatingSelect" label={t("formLabel.Supplier") + " *"}>
                    <Form.Select className={`form-control ${errors.supplier ? 'is-invalid' : ''}`} {...register('supplier')} aria-label="Supplier">
                      <option value="">{t('dropdownItem.select')}..</option>
                      {suppliersData && suppliersData.length > 0 && suppliersData.map((item: any, index: number) => (
                        <option key={index} value={item.favorecidoId}>{item.name}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.supplier?.message}</Form.Control.Feedback>
                  </FloatingLabel>
                  <InputGroup.Text title="Supplier">
                    <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" onClick={handleSupplier} />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={6}>
                <FloatingLabel label={`${t("banks.bankAccounts")} *`} className="mb-3 mb-lg-4">
                  <Form.Select
                    aria-label="Bank account"
                    className={`form-control ${errors.bankAccount ? "is-invalid" : ""}`}
                    {...register("bankAccount")}
                    onChange={handleBankChange}
                    value={selectedBankAccount}
                  >
                    <option value="">{t("dropdownItem.select")}</option>
                    {accountData.data && accountData.data.length > 0
                      ? accountData.data.map((item, index) => (
                        <option key={index} value={Number(item.id)}>
                          {item.account} -- {item.accountRef}
                        </option>
                      ))
                      : ""}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.bankAccount?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="maturity" className="mb-3 mb-lg-4" label={t("maturity") + " *"}>
                  <Form.Control type="date" placeholder={t("maturity")} min={startDate} className={`form-control ${errors.maturity ? 'is-invalid' : ''}`} {...register('maturity')} onChange={(e) => handleDate(e)} />
                  <Form.Control.Feedback type="invalid">{errors.maturity?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel controlId="floatingSelect" label={t("bankTransaction.Paymentmethod") + " *"}>
                  <Form.Select className={`form-control ${errors.billingMethod ? 'is-invalid' : ''}`} {...register('billingMethod')} aria-label="Billing method">
                    <option value="">{t('dropdownItem.select')}..</option>
                    {
                      billingData.data && billingData.data.length > 0 ?
                        billingData.data.map((item: any, index: number) => (
                          <option key={index} value={Number(item.id)}>{item.name}</option>
                        ))
                        :
                        ''
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.billingMethod?.message}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Accordion className="accordion-custom mt-lg-4 mt-3">
              <Accordion.Item eventKey="17">
                <Accordion.Header>{t("value")}</Accordion.Header>
                <Accordion.Body>
                  <ElementLoop type={"value"} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header>{t("documents")}</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <FloatingLabel controlId="issuance" className="mb-3 mb-lg-4" label={t("bankTransaction.Issuance")}>
                        <Form.Control type="date" {...register('issuance')} placeholder={t("bankTransaction.Issuance")} />
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel label={t("type")} className="mb-3 mb-lg-4">
                        <Form.Select aria-label="Type" {...register('type')} >
                          <option value="0">Outros</option>
                          <option value="1">Sálario, Férias e etc</option>
                          <option value="2">Imposto</option>
                          <option value="3">Fundo fixo</option>
                          <option value="4">Nota fiscal</option>
                          <option value="5">Recibo</option>
                          <option value="6">Fatura</option>
                          <option value="7">Invoice</option>
                          <option value="8">Cupom fiscal</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={12}>
                      <FloatingLabel label={t("bankTransaction.Docno")}>
                        <Form.Control type="Text" aria-label="Doc no." {...register('docNo')} placeholder={t("bankTransaction.Docno")} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              { contmovId=="" &&
                  <Accordion.Item eventKey="19">
                    <Accordion.Header>{t("bankTransaction.Withoutholdingtaxes")}</Accordion.Header>
                    <Accordion.Body>
                      <ElementLoop type={"withoutholding"} />
                    </Accordion.Body>
                  </Accordion.Item>
              }
              <Accordion.Item eventKey="21">
                <Accordion.Header>{t("bankTransaction.Discounts")}</Accordion.Header>
                <Accordion.Body>
                  <ElementLoop type={"discount"} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="22">
                <Accordion.Header>{t("formLabel.installments")}</Accordion.Header>
                <Accordion.Body>
                  <FloatingLabel label={t("bankTransaction.Noofinstallments")}>
                    <Form.Control type="Text" aria-label="No. of installments" disabled={nonEdit ? true : false} {...register('installments')} placeholder={t("bankTransaction.Noofinstallments")} onChange={(e) => Installment(e)} />
                  </FloatingLabel>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="23">
                <Accordion.Header>{t("bankTransaction.Repeat")}</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={repeatVal ? 4 : 12}>
                      <FloatingLabel label={t("bankTransaction.Repeat")} >
                        <Form.Select aria-label="Repeat" {...register('repeat')} onChange={handleRepeat} disabled={nonEdit ? true : install == "" ? false : true}>
                          <option value="0">{t("Nao")}</option>
                          <option value="1">{t("Mensalmente")}</option>
                          <option value="2">{t("Bimestralmente")}</option>
                          <option value="3">{t("Trimestralmente")}</option>
                          <option value="4">{t("Semestralmente")}</option>
                          <option value="5">{t("Anualmente")}</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    {
                      repeatVal &&
                      <>
                        <Col md={4}>
                          <FloatingLabel label={t("start")} >
                            <Form.Control type="month" min={minDT} aria-label="Comments" {...register('start_date')} placeholder={t("Start")} onChange={handleStartMin} disabled={nonEdit ? true : false} />
                          </FloatingLabel>
                        </Col>
                        <Col md={4}>
                          <FloatingLabel label={t("Final")} >
                            <Form.Control type="month" min={minVal} aria-label="Comments" {...register('final_date')} placeholder={t("Final")} disabled={nonEdit ? true : false} />
                          </FloatingLabel>
                        </Col>
                      </>
                    }
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="24">
                <Accordion.Header>{t("bankTransaction.MoreFileds")}</Accordion.Header>
                <Accordion.Body>
                  <Row>

                    <Col md={6}>
                      <FloatingLabel label={t("historic")}>
                        <Form.Control type="Text" aria-label="Comments" {...register('historic')} placeholder={t("Historic")} />
                      </FloatingLabel>
                    </Col>
                    <Col md={12} className="mt-2">
                      <FloatingLabel label={t("formLabel.comments")}>
                        <Form.Control as="textarea" rows={5} type="Text" aria-label="Comments" {...register('comments')} placeholder={t("formLabel.comments")} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  {/* <FloatingLabel className="mb-3 mb-lg-4" label={t("Historic")}>
                    <Form.Control type="Text" aria-label="Historic" className={`form-control ${errors.historic ? 'is-invalid' : ''}`} {...register('historic')} placeholder={t("Historic")} />
                    <Form.Control.Feedback type="invalid">{errors.historic?.message}</Form.Control.Feedback>
                  </FloatingLabel> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Row className='d-flex align-items-center'>

              {
                install == "" ?
                  <Col md={6} >
                    <FloatingLabel className="mb-3 mb-lg-4" label={t("contractlistother.Amount")}>
                      <Form.Control type="Text" disabled aria-label="Amount" {...register('totalAmount')} placeholder={t("contractlistother.Amount")} />
                    </FloatingLabel>
                  </Col>
                  :
                  PortionData && PortionData.length > 0 &&
                  PortionData.map((item, index) => {
                    return (
                      <Row className="mt-1" key={index}>
                        <Col md={6} >
                          <FloatingLabel controlId="floatingInput" label={t("formLabel.DateofSettlement")} >
                            <Form.Control type="date" className={`form-control`} {...register(`data.install_${index}.maturity`)} disabled={nonEdit ? true : false} />
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel controlId="floatingInput" label={t("amount")} >
                            <Form.Control type="text" placeholder={t("value")} className={`form-control`} {...register(`data.install_${index}.value`)} disabled={nonEdit ? true : false} />
                          </FloatingLabel>
                        </Col>

                      </Row>
                    )
                  })
              }
              <Col md={6}>
                <Form.Check type="checkbox" className="mb-lg-4 mb-3 mt-2" {...register('newExpence')} onChange={checkExpence} id="newExpence" label={t("paidTobepaid.Ithasbeenpaid")} />
              </Col>
              {
                !hide &&
                <Col md={12}>
                  <FloatingLabel controlId="maturity" className="mb-3 mb-lg-4" label={t("formLabel.Settlementdate")} style={{ display: hide ? 'none' : 'block' }}>
                    <Form.Control type="date" placeholder={t("formLabel.Settlementdate")} {...register('dateDaleOff')} />
                  </FloatingLabel>
                </Col>
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-sm-flex justify-content-end w-100 align-items-center">
              <div>
                <Button variant="outline-primary" onClick={financialExpClosed}>
                  {t("button.close")}
                </Button>
                {loader_on ?
                  <Button className="btn-width">
                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                  </Button>
                  :
                  <Button variant="primary" className="NewFinancialExpensetsx" type="submit" onClick={openErrorbox}>
                    {t("button.done")}
                  </Button>
                }
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New branch modal end --> */}
      <NewSuppliers type="supplierexpense" getAllSuppliers={getAllSuppliers} />
      <NewRetention showNewRetentionModal={showNewRetentionModal} newRetentionModalClose={newRetentionModalClose} accountCatData1={accountCatData1} />
    </>
  )
}
export default FinancialExpenseService;


