import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import type { BankSlip, ChargeSummary } from "./schemas";
import { bankSlipService, chargeService } from "./services";
import { useChargesFilters } from "./store";

type ChargesToReceiveContextState = {
  charges: any[];
  bankSlips: BankSlip[];
  countTotal: number;
  isFetchingCharges: boolean;
  fetchAllCharges: () => void;
  homologatedChargesSummary: ChargeSummary;
  allChargesSummary: ChargeSummary;
};

const ChargesToReceiveContext =
  React.createContext<ChargesToReceiveContextState>(
    {} as ChargesToReceiveContextState
  );

export const ChargesToReceiveProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [homologatedChargesSummary, setHomologatedChargesSummary] = useState<ChargeSummary>({} as ChargeSummary);
  const [allChargesSummary, setAllChargesSummary] = useState<ChargeSummary>({} as ChargeSummary);
  const [charges, setCharges] = useState<any[]>([]);
  const [bankSlips, setBankSlips] = useState<BankSlip[]>([]);
  const [countTotal, setCountTotal] = useState<number>(0);
  const { chargeFilters, pagination } = useChargesFilters();
  const [isFetchingCharges, setIsFetchingCharges] = useState(false);

  const fetchAllCharges = useCallback(async () => {
    if (!chargeFilters) {
      return;
    }

    setIsFetchingCharges(true);
    chargeService
      .getAllCharges({
        ...chargeFilters,
        start: pagination.start,
        end: pagination.end,
        filter: {
          ...chargeFilters.filter,
          billing_payment: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,28],
        },
      })
      .then((res) => {
        if (res.type === "Success" && res.resCode === 201) {
          setAllChargesSummary({
            countReceivable: res.dashboardResult.totalRowCount,
            countReceived: res.dashboardResult.chargeCount,
            amountReceivable: res.dashboardResult.totalCharge,
            amountReceived: res.dashboardResult.chargeAmount,
          });
        }
      });
    chargeService
      .getAllCharges({
        ...chargeFilters,
        start: pagination.start,
        end: pagination.end,
        filter: {
          ...chargeFilters.filter,
          billing_payment: [28],
        },
      })
      .then((res) => {
        if (res.type === "Success" && res.resCode === 201) {
          setHomologatedChargesSummary(
            res.dashboardResult.homologated
          );
          setCountTotal(res.totalRow);
          setCharges(res.data);
        }
      })
      .finally(() => {
        setIsFetchingCharges(false);
      });
  }, [chargeFilters, pagination]);

  const fetchAllHomologatedBankslip = useCallback(async () => {
    bankSlipService.getAllHomologatedBankslip().then((res) => {
      if (res.type === "success" && res.resCode === 201) {
        setBankSlips(res.data.bankSlips);
      }
    });
  }, []);

  useEffect(() => {
    fetchAllCharges();
    fetchAllHomologatedBankslip();
  }, [fetchAllCharges, fetchAllHomologatedBankslip]);

  return (
    <ChargesToReceiveContext.Provider
      value={{
        homologatedChargesSummary,
        allChargesSummary,
        bankSlips,
        charges,
        countTotal,
        isFetchingCharges,
        fetchAllCharges,
      }}
    >
      {children}
    </ChargesToReceiveContext.Provider>
  );
};

export const useChargesToReceive = () => {
  const context = useContext(ChargesToReceiveContext);

  if (context === undefined) {
    throw new Error(
      "useChargesToReceive must be used within a ChargesToReceiveProvider"
    );
  }

  return context;
};
