import { costCenterForm,newExpenseForm,contractSearchForm } from '../../component/Interface';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config,gettoken } from "../../Api/Contact";
import transferService     from '../../services/transferService';
import Swal                from 'sweetalert2';

export const STATUSES = Object.freeze({
  IDLE    : "idle",
  LOADING : "loading",
  SUCEESS : "success",
  ERROR   : "error"
});

export interface Columns {
  id            : number;
  name          : string;
  email         : string;
  telephone     : string;
  responsible   : string;  
  observation   : string;
  status        : number;
  transferLoad  : number;
  itemName      : string;
  ledgerAccount : string;
}

interface SliceState {  
	financialExpOpen 		  : boolean,
  transferDrawerOpen    : boolean,
  accountCatData   		  : Columns[] | any,
  accountCatData1   		: Columns[] | any,
  taxData 						  : Columns[] | any,
  costCenterData 			  : Columns[] | any,
  compareToLastData     : Columns[] | any,
  costSingleCenterData  : Columns[] | any,
  accountStatus 			  : string,
  taxStatus 					  : string,
  status 						    : string, 
  costCenterStatus      : string,
  compareToLastStatus   : string, 
  costCenterOpen        : boolean,
  costCenterId          : string,
}

const initialState: SliceState = {  
	financialExpOpen 	   : false,
  transferDrawerOpen   : false,
	accountCatData 		   : [],
  accountCatData1 		   : [],
	taxData 					   : [],
	costCenterData 		   : [],
  compareToLastData    : [],
  costSingleCenterData : [],
  status 						   : STATUSES.IDLE,
  accountStatus 		   : STATUSES.IDLE,
  taxStatus 				   : STATUSES.IDLE,
  costCenterStatus     : STATUSES.IDLE,
  compareToLastStatus  : STATUSES.IDLE,
  costCenterOpen       : false,
  costCenterId         : '',
}

const user_id = gettoken.userId;
const financialDataSlice = createSlice ({
	name 	 : "financialDataSlice",
	initialState,
	reducers : {
		setTransferDrawerOpen: (state, action) => {
	    state.transferDrawerOpen = action.payload
	  },
    setTransferDrawerClose: (state, action) => {
	    state.transferDrawerOpen = action.payload
	  },
    setFinancialExpOpen: (state, action) => {
	    state.financialExpOpen = action.payload
	  },
	  setFinancialExpClose: (state, action) => {
	    state.financialExpOpen = action.payload
	  },
    setCostCenterOpen: (state, action) => {
      state.costCenterOpen = action.payload
    },
    setCostCenterClose: (state, action) => {
      state.costCenterOpen = action.payload
    },
    setCostCenterId: (state, action) => {
      state.costCenterId = action.payload
    },
    sortData: (state, action) =>{
      state.compareToLastData.data = action.payload
    }
	},
	extraReducers: (builder) => {
		builder
		  .addCase(insertFinancialExpense.pending, (state) => {
		    state.status = STATUSES.LOADING;
		  })
		  .addCase(insertFinancialExpense.fulfilled, (state, action) => {
		    let response = action.payload
		    state.status = STATUSES.SUCEESS;
		  })
		  .addCase(insertFinancialExpense.rejected, (state, action) => {
		    state.status = STATUSES.ERROR;
		});
		builder
      .addCase(getAccountCategory.pending, (state, action) => {
        state.accountStatus = STATUSES.LOADING;
      })
      .addCase(getAccountCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.accountCatData = action.payload.data
        state.accountStatus = STATUSES.SUCEESS;
      })
      .addCase(getAccountCategory.rejected, (state, action: PayloadAction<any>) => {
        state.accountStatus = STATUSES.ERROR;
    });
    builder
      .addCase(getAccountCategory1.pending, (state, action) => {
        state.accountStatus = STATUSES.LOADING;
      })
      .addCase(getAccountCategory1.fulfilled, (state, action: PayloadAction<any>) => {
        state.accountCatData1 = action.payload.data
        state.accountStatus = STATUSES.SUCEESS;
      })
      .addCase(getAccountCategory1.rejected, (state, action: PayloadAction<any>) => {
        state.accountStatus = STATUSES.ERROR;
    });
    builder
      .addCase(getTax.pending, (state, action) => {
        state.taxStatus = STATUSES.LOADING;
      })
      .addCase(getTax.fulfilled, (state, action: PayloadAction<any>) => {
        state.taxData = action.payload.data
        state.taxStatus = STATUSES.SUCEESS;
      })
      .addCase(getTax.rejected, (state, action: PayloadAction<any>) => {
        state.taxStatus = STATUSES.ERROR;
    });
    builder
      .addCase(getCostCenter.pending, (state, action) => {
        state.costCenterStatus = STATUSES.LOADING;
      })
      .addCase(getCostCenter.fulfilled, (state, action: PayloadAction<any>) => {
        state.costCenterData = action.payload
        state.costCenterStatus = STATUSES.SUCEESS;
      })
      .addCase(getCostCenter.rejected, (state, action: PayloadAction<any>) => {
        state.costCenterStatus = STATUSES.ERROR;
    });
    builder
      .addCase(getCompareToLastMonth.pending, (state, action) => {
        state.compareToLastStatus = STATUSES.LOADING;
      })
      .addCase(getCompareToLastMonth.fulfilled, (state, action: PayloadAction<any>) => {
        state.compareToLastData = action.payload
        state.compareToLastStatus = STATUSES.SUCEESS;
      })
      .addCase(getCompareToLastMonth.rejected, (state, action: PayloadAction<any>) => {
        state.compareToLastStatus = STATUSES.ERROR;
    });
    builder
      .addCase(getSingleCostCenter.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getSingleCostCenter.fulfilled, (state, action: PayloadAction<any>) => {
        state.costSingleCenterData = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(getSingleCostCenter.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR;
    });
    builder
      .addCase(insertCostCenter.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(insertCostCenter.fulfilled, (state, action) => {
        let response = action.payload
        state.status = STATUSES.SUCEESS;
      })
      .addCase(insertCostCenter.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
    });
    builder
    .addCase(inserttax.pending, (state) => {
      state.status = STATUSES.LOADING;
    })
    .addCase(inserttax.fulfilled, (state, action) => {
      state.status = STATUSES.SUCEESS;
    })
    .addCase(inserttax.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
  });
	}
});
export const { setCostCenterId,setCostCenterOpen,setCostCenterClose,setFinancialExpOpen,setFinancialExpClose, setTransferDrawerOpen, setTransferDrawerClose, sortData } = financialDataSlice.actions;
export default financialDataSlice.reducer

/*----------Insert the cost center data-------------*/

export const insertCostCenter = createAsyncThunk(
  'financial/insertCostCenterData',
  async (postData:costCenterForm)=>{
    postData.userId = gettoken.userId
    let resp = await transferService.insertCostCenter(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Update the transfer contract maturity data api-----------*/

export const insertFinancialExpense = createAsyncThunk(
  'contract/insertFinancialExpenseData',
  async (postData:newExpenseForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.insertFinancialExpense(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);

export const UpdateFinancialExpense = createAsyncThunk(
  'contract/UpdateFinancialExpense',
  async (postData:newExpenseForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.updateFinancialExpense(postData);
    let data =  JSON.parse(JSON.stringify(resp)).data;
    return data;    
  }
);
/*----------Get the Account category listing api-----------*/

export const getAccountCategory = createAsyncThunk(
  'property/fetchAccountCategoryData',
  async () => {
    let categoryData = { start: 0, end: 1000 }
    let resp = await transferService.getAccountCategory(categoryData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

export const getAccountCategory1 = createAsyncThunk(
  'property/fetchAccountCategoryData1',
  async () => {
    let categoryData = { start: 0, end: 1000 }
    let resp = await transferService.getAccountCategory1(categoryData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);
/*----------Get the Tax listing api-----------*/

export const getTax = createAsyncThunk(
  'property/fetchTaxData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.getTax(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the Cost center listing api-----------*/

export const getCostCenter = createAsyncThunk(
  'property/fetchCostCenterData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.getCostCenter(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the Cost center listing api-----------*/

export const getCompareToLastMonth = createAsyncThunk(
  'property/fetchCompareToLastMonthrData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.getCompareToLastMonth(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);

/*----------Get the Single Cost center api-----------*/

export const getSingleCostCenter = createAsyncThunk(
  'property/fetchSingleCostCenterData',
  async (postData:contractSearchForm) => {
    postData.userId = gettoken.userId
    let resp = await transferService.getCostCenter(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);


export const inserttax = createAsyncThunk(
  'financial/inserttax',
  async (postData:costCenterForm)=>{
    postData.userId = gettoken.userId
    let resp = await transferService.insertTax(postData);
    let data = JSON.parse(JSON.stringify(resp)).data;
    return data;
  }
);