import { useCallback } from "react";
import { useHomologatedChargesList } from "./HomologatedChargesListProvider";
import { bankSlipService } from "../../services";

const STATUS_ALLOWED_TO_DISCARD = [
  "EMITIDO",
  "FALHA",
  "REJEITADO",
];

const STATUS_ALLOWED_TO_CANCEL = "REGISTRADO"

export function useBankSlips() {
  const { homologatedCharges } = useHomologatedChargesList();

  const handleDiscardOrCancelBankSlips = useCallback(async (chargesId: number[]) => {
    const bankSlipsToInvalidate = homologatedCharges
      .filter((charge) => chargesId.includes(charge.id))
      .reduce(
        (current, charge) => {
          if (charge?.statusBankSlip?.toUpperCase() === STATUS_ALLOWED_TO_CANCEL) {
            current.bankSlipsToCancelInBank.push(charge.integrationId);
          }

          if (STATUS_ALLOWED_TO_DISCARD.includes(charge?.statusBankSlip?.toUpperCase())
          ) {
            current.bankSlipsToDiscard.push(charge.integrationId);
          }

          return {
            ...current,
          };
        },
        {
          bankSlipsToDiscard: [],
          bankSlipsToCancelInBank: [],
        }
      );

    const promises: Promise<any>[] = [];

    if (bankSlipsToInvalidate.bankSlipsToDiscard.length > 0) {
      promises.push(
        bankSlipService.discardBankSlip(
          bankSlipsToInvalidate.bankSlipsToDiscard
        )
      );
    }

    if (bankSlipsToInvalidate.bankSlipsToCancelInBank.length > 0) {
      promises.push(
        bankSlipService.cancelInBank(
          bankSlipsToInvalidate.bankSlipsToCancelInBank
        )
      );
    }

    if (promises.length > 0) {
      Promise.allSettled(promises)
        .catch((error) => {
          console.error(error);
        });
    }
  }, [homologatedCharges])

  return {
    handleDiscardOrCancelBankSlips,
  }
}