import React, { ChangeEvent, useState } from "react";

import { Button, FloatingLabel, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { Icon } from "../../../../../components/Icon";
import { bankSlipService } from "../../../services";
import { SuccessToast } from "../../../../../components/SuccessToast";

type ImportRemittanceModalProps = {
  show: boolean;
  onClose: () => void;
}

export function ImportRemittanceModal({ onClose, show }: ImportRemittanceModalProps) {
  const [isImporting, setIsImporting] = useState(false);
  const [selectedFileText, setSelectedFileText] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [successToast, setSuccessToast] = useState({
    visible: false,
    message: "",
  });

  function handleClose() {
    setIsImporting(false);
    setSelectedFileName("");
    setSelectedFileText("");
    setSuccessToast({ visible: false, message: "" });
    onClose();
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    setSelectedFileName(file?.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event?.target?.result as string;
      setSelectedFileText(text);
    };
    reader.readAsText(file);
  };

  const handleUploadFile = async () => {
    if (!selectedFileText) {
      return;
    }

    setIsImporting(true);
    try {
      const response = await bankSlipService.uploadReturnFile(selectedFileText);
      if (response.type === "success") {
        setSuccessToast({
          visible: true,
          message: "Importação concluída",
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsImporting(false);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Importar retorno bancário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <FloatingLabel
            controlId="filenameInput"
            label="Nome do arquivo"
          >
            <Form.Control
              className="border bg-transparent"
              value={selectedFileName}
              onChange={(e) => setSelectedFileName(e.target.value)}
            />
            <input
              id="fileInput"
              type="file"
              className="d-none"
              accept=".ret,.txt"
              onChange={handleFileChange}
            />
          </FloatingLabel>
          <Button
            className="d-flex align-items-center gap-1 border"
            variant="link"
            onClick={() => {
              document?.getElementById('fileInput')?.click();
            }}
          >
            <Icon name="search" />
            Procurar arquivo
          </Button>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          variant="outline-primary"
        >
          Cancelar
        </Button>
        <Button
          className="d-flex align-items-center gap-1"
          variant="primary"
          onClick={handleUploadFile}
        >
          {isImporting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>
              <Icon name="documentArrowUp" />
              Importar
            </>
          )}
        </Button>
      </Modal.Footer>
      <SuccessToast
        show={successToast.visible}
        message={successToast.message}
        onClose={() => setSuccessToast({ visible: false, message: "" })}
      />
    </Modal>
  )
}