import React, { useState, MouseEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Modal, Button, Row, Col, Dropdown, Table, Badge, Collapse, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Profile/Company/Loader';
import IndexService from '../Common/IndexService';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllIndexs, deleteIndexs, setIndexId, fetch_allFinancialIndexing } from "../../../../redux/slice/expenditureDataSlice";
import customerService from '../../../../services/customerService';
import { encrypt_Decrypt } from '../../../constants/utils';

export function ClllThis(Params: any, type: any) {
    let arrayObj: any = [];
    let checkRepeatName: any = []
    if (Params.totalRow > 0 && type !== "mysql") {
        Params.collectionNames.forEach((item: any, index: number) => {
            let ItemName = item.toLowerCase();
            if (Number(checkRepeatName.indexOf(ItemName)) == -1) {
                checkRepeatName.push(ItemName)
                let nameIs = ItemName.includes("_") ? ItemName.replaceAll("_", " ").toUpperCase() : ItemName.toUpperCase();
                arrayObj.push({ id: index, slug: ItemName.toLowerCase(), status: 0, name: nameIs })
            }
        })
        return arrayObj
    } else {
        // if (Params.length && Params[0].id >= 0) {
        Params.forEach(item => {
            let ItemName = (item.name).toLowerCase();
            if (Number(checkRepeatName.indexOf(ItemName)) == -1) {
                checkRepeatName.push(ItemName)
                let nameIs = ItemName.includes("_") ? ItemName.replaceAll("_", " ").toUpperCase() : ItemName.toUpperCase();
                arrayObj.push({ id: item.id, slug: ItemName.toLowerCase(), status: 0, name: nameIs })
            }
        });
        arrayObj.sort(function (a, b) {
            return a.id - b.id;
        });
        return arrayObj
        // }
    }
}

const ReadjustmentIndexes = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { indexData, indexStatus, fetch_allFinancialIndexing_data } = useSelector((state: RootState) => state.expenditureData)
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    const [readName, setReadName] = useState([]);

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2030) === -1 && userResult.indexOf(2070) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
        setLoading(true);
        let IntervalTime;
        (async () => {
            IntervalTime = setInterval(async () => {
                let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
                if (dataOutPut["payload"]) {
                    clearInterval(IntervalTime)
                    let dataOutPut_result = await ClllThis(dataOutPut["payload"]["data"], "");
                    setReadName(dataOutPut_result);
                    setLoading(false);
                }
            }, 3000)
        })()
    }, [])

    useEffect(() => {
        // dispatch(getAllIndexs({ "status": "", "start": 0, "end": 10 }));
    }, [dispatch])

    const editIndex = (id) => {
        if (typeof id !== "undefined") {
            dispatch(setIndexId(id))
        }
    }
    const deleteIndex = (id: any) => {
        let data = { id: id || "" }
        Swal.fire({
            title: t("Sweetalert.Areyousure"),
            text: t("Sweetalert.Youwontbeabletorevert"),
            // icon: 'success','warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, it!'
        }).then(async (result) => {
            if (result.value) {
                dispatch(deleteIndexs(data)).then(response => {
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.deletesuccess"),
                        // icon: 'success',"success",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                    // dispatch(getAllIndexs({ "status": "", "start": 0, "end": 10 }));

                }).catch(e => {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.deletefailed"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                })
            }
        })
    }
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    const handleIndexClick = (e: any, id: any, name: string) => {
        e.preventDefault();
        navigate(`/ReadjustmentedIndexes/${id}`, { state: name })
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className="w-100 overflow-hidden">
                                    <h1 className="mb-0 lh-base text-truncate">{t('readjIndex.readjustment_indexes')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                {/* <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div> */}
                            </div>
                        </Col>
                        {
                            (userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) || userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1)) &&
                            <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                                <ul className="list-inline mb-0">
                                    {/* <li className="list-inline-item">
                                        <a className="fw-bold cursor-pe" onClick={() => editIndex('0')}>
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t('readjIndex.newIndexes')}
                                        </a>
                                    </li> */}
                                    {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li>
                                <li className="list-inline-item">
                                    <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li> */}
                                </ul>
                            </Col>
                        }
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        {/*<Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                        <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Status
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="px-4">
                                    <div className="py-2">
                                        {t("inProgress")}
                                    </div>
                                    <div className="py-2">
                                        {t("active")}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>*/}
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end --> */}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*<!-- Table start from here --> */}
                {
                    (loading) ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("readjIndex.table")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // indexData && indexData.data &&
                                            //     indexData.data.length > 0 ?
                                            //     indexData.data.map((row: any, index: number) => {
                                            readName &&
                                                readName.length > 0 ?
                                                readName.map((row: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link to={"#"} onClick={(e) => handleIndexClick(e, row.id, row.slug)}><span className="d-block text-dark-70">{row.name}</span></Link>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="d-flex align-items-center justify-content-end">
                                                                    {
                                                                        (row.status == 0 || row.status == "0") ?
                                                                            <Badge bg="success-10" className="text-dark me-2 me-xxl-4 me-lg-3">{t("readjIndex.Active")}</Badge>
                                                                            :
                                                                            <Badge bg="warning-10" className="text-dark me-2 me-xxl-4 me-lg-3">{t("readjIndex.Outofdate")}</Badge>
                                                                    }
                                                                    {/* {
                                                                        (userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) || userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1)) &&
                                                                        <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                                                            <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                                                                <Button variant="link" className="p-0" onClick={() => editIndex(row.id)} >
                                                                                    <span className="icon"><img src="assets/images/icons/edit-primary.svg" alt="Edit" title="Edit" className="h-20 cursor-pe" /></span>
                                                                                </Button>
                                                                            </li>
                                                                            <li className="list-inline-item">
                                                                                <Button variant="link" className="p-0" onClick={() => deleteIndex(row.id)}>
                                                                                    <span className="icon"><img src="./assets/images/icons/delete-danger.svg" alt="Delete" title="Delete" className="h-20 cursor-pe" /></span>
                                                                                </Button>
                                                                            </li>
                                                                        </ul>
                                                                    } */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={3}>
                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/*<!-- /Table end --> */}
                <div className="mt-lg-4 mt-3"> {t("formLabel.listing")} {readName && readName.length > 0 ? readName.length : 0} {t("readjIndex.readjustment_indexes")}.</div>
            </main>
            {/*<!-- /Main content start end -->*/}
            {/* New index modal start from here */}
            <IndexService />
        </section>
    )
}
export default ReadjustmentIndexes;