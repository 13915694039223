import React, { createContext, ReactNode, useContext, useState } from "react";

type FeedbackDrawerContextState = {
  isShowingFeedbackDrawer: boolean;
  showFeedbackDrawer: () => void;
  hideFeedbackDrawer: () => void;
};

const FeedbackDrawerContext = createContext({} as FeedbackDrawerContextState);

export function FeedbackDrawerProvider({ children }: { children: ReactNode }) {
  const [show, setShow] = useState(false);

  function showFeedbackDrawer() {
    setShow(true);
  };

  function hideFeedbackDrawer() {
    setShow(false);
  };

  return (
    <FeedbackDrawerContext.Provider value={{
      isShowingFeedbackDrawer: show,
      showFeedbackDrawer,
      hideFeedbackDrawer,
    }}>
      {children}
    </FeedbackDrawerContext.Provider>
  );
}

export function useFeedbackDrawer() {
   const context = useContext(FeedbackDrawerContext);

  if (context === undefined) {
    throw new Error("useFeedbackDrawer must be used within a FeedbackDrawerProvider");
  }

  return context;
}