import { config } from "../../../../services/apiConfig";

type transferPayload = {
  originAccount: any;
  destinationAccount: any;
  date: string;
  transferAmount: number;
  complement?: string;
};

export async function createNewTransfer({ ...transferPayload }: transferPayload) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

   const response = await fetch(`${config.companyV2_URL}/finance/bank_transactions/company/new`, {
    method: "POST",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
    body: JSON.stringify(transferPayload),
  });

  const data = await response.json();

  if (data.resCode === 201 || data.resCode === 200) return data.data;

  throw new Error(data.data?.message || data.data);
}
