import { create } from "zustand";
import { DEFAULT_PAGE_SIZE } from "./constants";

type Pagination = {
  start: number;
  end: number;
}

type ChargesFiltersState = {
  chargeFilters: any;
  updateChargeFilters: (newFilters: any) => void;
  pagination: Pagination;
  updatePagination: (newPagination: Pagination) => void;
};

const useChargesFiltersStore = create<ChargesFiltersState>((set) => ({
  chargeFilters: null,
  updateChargeFilters: (newFilters: any) => set({ chargeFilters: newFilters }),
  pagination: { start: 0, end: DEFAULT_PAGE_SIZE },
  updatePagination: (newPagination: Pagination) => set({ pagination: newPagination }),
}));

export function useChargesFilters() {
  return useChargesFiltersStore((state) => state);
}
