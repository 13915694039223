import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type Account = {
  id: number;
  nameReference: string;
  bankname: string;
  agency: string;
  account: string;
  accountType: number;
  status: number;
};

type TransferAccountContextType = {
  resetContextValues: () => void;
  selectedOriginAccount: Account | null;
  setSelectedOriginAccount: Dispatch<SetStateAction<Account | null>>;
  selectedDestinationAccount: Account | null;
  setSelectedDestinationAccount: Dispatch<SetStateAction<Account | null>>;
};

const TransferAccountContext = createContext({} as TransferAccountContextType);

export function TransferAccountProvider({ children }: { children: ReactNode }) {
  const [selectedOriginAccount, setSelectedOriginAccount] = useState<Account | null>(null);
  const [selectedDestinationAccount, setSelectedDestinationAccount] = useState<Account | null>(null);

  function resetContextValues() {
    setSelectedOriginAccount(null);
    setSelectedDestinationAccount(null);
  }

  const value = () => ({
    resetContextValues,
    selectedOriginAccount,
    setSelectedOriginAccount,
    selectedDestinationAccount,
    setSelectedDestinationAccount,
  });

  return (
    <TransferAccountContext.Provider value={value()}>
      {children}
    </TransferAccountContext.Provider>
  );
}

export function useTransferAccount() {
  const context = useContext(TransferAccountContext);

  if (!context) {
    throw new Error("useTransferAccount must be used within a TransferAccountProvider");
  }

  return context;
}
