import React, { useEffect, useState } from 'react';
import { Dropdown, Col, Row, InputGroup, Form, Button, Modal, Accordion, Alert, FloatingLabel, Collapse } from 'react-bootstrap';
import SendEmailOverview from './SendEmailOverview';
import SendEmailListing from './SendEmailListing';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { allEmaillist } from '../../../../redux/slice/SendEmailSlice';
// const [t] = useTranslation();

const SentEmail = () => {
    //Bouncing email modal initialization
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const [showBouncingEmailModal, setBouncingEmailModal] = useState(false);
    const bouncingEmailModalClose = () => setBouncingEmailModal(false);
    const bouncingEmailModalShow = () => setBouncingEmailModal(true);
	const [searchUserFilter, setSearchFilter] = useState("");
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);

    let typingTimer;
    const searchFilter = (change) => {
		clearTimeout(typingTimer);
		typingTimer = setTimeout(() => {
			// setStatus("loading");
			setSearchFilter(change); 
		}, 800);
	};

    return (
        <section className="content-wrapper content-wrapper-primary sendemlsearchHeader">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t("formLabel.sendEmail")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                {/* <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                /> */}
                                <Form.Control
									placeholder={t("formLabel.typeSearch")}
									onChange={(e) => {
										searchFilter(e.target.value);
									}}
									aria-label="Search"
									aria-describedby="search_icon"
								/>

                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0"> 
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div> 
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div> 
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- overview -->*/}
                {/* <SendEmailOverview /> */}
                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                <SendEmailListing searchUserFilter={searchUserFilter} />
                {/*<!-- /Table end -->*/} 
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Bouncing email modal start from here */}
            <Modal show={showBouncingEmailModal} onHide={bouncingEmailModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("sendEmail.BouncingEmail")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Accordion defaultActiveKey="0" className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("sendEmail.RemoveEmailFromList")}</Accordion.Header>
                                <Accordion.Body>
                                    <Alert className="mb-lg-4 mb-3" variant="warning">
                                        {t("sendEmail.RemoveEmailFromListContent")}
                                    </Alert>
                                    <FloatingLabel className="mb-lg-4 mb-3" controlId="Email" label="Email">
                                        <Form.Control type="email" placeholder="Email" />
                                    </FloatingLabel>
                                    <img src="assets/images/temp/code-img.jpg" alt="code" className="img-fluid mb-lg-4 mb-3" />
                                    <FloatingLabel controlId="enter_code" label="Enter the code from above">
                                        <Form.Control type="text" placeholder="Enter the code from above" />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={bouncingEmailModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={bouncingEmailModalClose}>
                            {t("sendEmail.ToRemove")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Bouncing email modal end */}
        </section>
    )
}

export default SentEmail