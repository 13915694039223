export const invalidationReasonsOptions = [
  { value: "0", label: "Cobrança indevida" },
  { value: "1", label: "Cobrança substituída" },
  { value: "2", label: "Cancelamento" },
  { value: "3", label: "Bonificação" },
  { value: "4", label: "Suspensão" },
  { value: "5", label: "Outros" },
  {
    value: "6",
    label: "Proposta Cancelada",
  },
  {
    value: "7",
    label: "Provisão para Devedores Duvidosos (PDD)",
  },
  { value: "8", label: "Negociação" },
  { value: "9", label: "Pagamento a menor" },
];
