import React from "react";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";
import { z } from "zod";

export const BancoDoBrasilSchema = z.object({
  ContaCodigoBeneficiario: z.string().min(1, "Campo obrigatório"),
  ConvenioNumero: z.string().min(1, "Campo obrigatório"),
  ConvenioDescricao: z.string().min(1, "Campo obrigatório"),
  ConvenioCarteira: z.string().min(1, "Campo obrigatório"),
  ConvenioPadraoCNAB: z.string().min(1, "Campo obrigatório"),
  TituloVariacaoCarteira: z.string().min(1, "Campo obrigatório"),
});

type BancoDoBrasilFormType = z.infer<typeof BancoDoBrasilSchema>;

export function BancoDoBrasilFields() {
  const { register } = useFormContext();
  const { errors } = useFormState<BancoDoBrasilFormType>();

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ContaCodigoBeneficiario"
            label="Código beneficiário"
          >
            <Form.Control
              placeholder="Código beneficiário"
              {...register("ContaCodigoBeneficiario")}
              className={`${errors.ContaCodigoBeneficiario && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ContaCodigoBeneficiario?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioNumero" label="Convênio">
            <Form.Control
              placeholder="Convênio"
              {...register("ConvenioNumero")}
              className={`${errors.ConvenioNumero && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioNumero?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioDescricao"
            label="Descrição do convênio"
          >
            <Form.Control
              placeholder="Descrição do convênio"
              {...register("ConvenioDescricao")}
              className={`${errors.ConvenioDescricao && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioDescricao?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioPadraoCNAB" label="CNAB">
            <Form.Select {...register("ConvenioPadraoCNAB")}>
              <option disabled>CNAB</option>
              <option value="240" defaultChecked>
                240 (Recomendado)
              </option>
              <option value="400">400</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioCarteira" label="Tipo de carteira">
            <Form.Select {...register("ConvenioCarteira")}>
              <option disabled>Tipo de carteira</option>
              <option value="11" defaultChecked>
                11
              </option>
              <option value="22">12</option>
              <option value="15">15</option>
              <option value="17">
                17 - Cobrança com registro (Convênio até 7 dígitos)
              </option>
              <option value="18">
                18 - Cobrança sem registro (Convênio até 7 dígitos)
              </option>
              <option value="31">31</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="TituloVariacaoCarteira"
            label="Variação da carteira"
          >
            <Form.Control
              placeholder="Variação da carteira"
              {...register("TituloVariacaoCarteira")}
              className={`${errors.TituloVariacaoCarteira && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.TituloVariacaoCarteira?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
