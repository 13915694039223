import React from "react";
import { Col, Row } from "react-bootstrap";

import { useChargesToReceive } from "../../ChargesToReceiveProvider";
import { SummaryCard } from "./SummaryCard";
import { RemittanceCard } from "./RemittanceCard/RemittanceCard";

export function Summary() {
  const { allChargesSummary, homologatedChargesSummary } = useChargesToReceive();

  return (
    <Row>
      <Col>
        <SummaryCard title="Resumo de cobranças" summary={allChargesSummary} />
      </Col>
      <Col>
        <SummaryCard
          title="Conta homologada"
          summary={homologatedChargesSummary}
        />
      </Col>
      <Col>
        <RemittanceCard />
      </Col>
    </Row>
  );
}
