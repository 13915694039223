import { Accordion, Dropdown, FloatingLabel, InputGroup, Col, Row, Table, Modal, Form, Button, Card, Badge, ListGroup, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getPropertyExpense, setPropertyExpenseId, setPropertyChargeId, getPropertyCharge, registerData, getManager, getAdministrators, getCondos, getBroker, getOwner, updateProperty, inspectionOpen, maintenanceOpen, envelopeOpen, updateProperty_serviceProvision, deleteProperties, settleReverseReceipt, getSinglePropertyExpense } from "../../../../redux/slice/propertyDataSlice";
import { numericOnly, HandleSubmit, handleEdit, SubmitData, dotInsert, openErrorbox } from '../Common/actionPerform';
import { newExtraChargeOpen, setExtraCharge, setExtraChargeLabel, contractsData, getAllService, getAllSuppliers, newPropertyElectronicEnvelopeOpen, contractDatails } from "../../../../redux/slice/contractDataSlice";
import { getAllAccounts, getAllBillMethodListing } from "../../../../redux/slice/expenditureDataSlice";
import React, { useState, MouseEvent, useEffect } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Outlet, Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Profile/Company/Loader';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getBraches } from '../../../../redux/slice/branchSlice';
import ChargeService from '../Common/ChargeService';
import InspectionService from '../Common/InspectionService';
import MaintenanceService from '../Common/MaintenanceService';
import EnvelopeService from '../Common/EnvelopeService';
import PropertyExpensesService from '../Common/PropertyExpensesService';
import Swal from 'sweetalert2';
import Properties_serviceprov from './Properties_serviceprov';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
//import moment from "moment";
import ExtraChargeService from '../Common/ExtraChargeService';
import { incomeService } from '../../../../services/incomeServices';
import { config, gettoken } from '../../../../Api/Contact';
import '../../../../App.css';
import { converter, convertToNumber, encrypt_Decrypt, encrypt_Decrypt_Plan, formatNumber } from '../../../constants/utils';
import PropertyElectronicEnvelopeService from '../Common/PropertyElectronicEnvelopeService';
import i18n from '../../../../i18n';
import moment from "moment-timezone";

const Property_detail = (CustomersAndEmployeesForm: any) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const location = useLocation();
    const [extraAndDiscount, setExtraAndDiscount] = useState({});
    const [hide, setHide] = useState(true);
    const navigate = useNavigate();
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate);
    let endDt = moment(newDate).add(1, 'M');
    const [own, setOwn] = useState(true);
    const [electronicEnvelopeListing, setElectronicEnvelopeListing] = useState<any>([]);
    const [userJson, setUserJson] = useState<any>([]);
    const [isEditVisible, setIsEditVisible] = useState(true);
    const [propertyIdet, setPropertyIdet] = useState(true)
    const [multipleHobby, setmMultipleHobby] = useState([{
        huby_identification: "",
        huby_observation: "",
    }])
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2070) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const handleCommon = (evt) => {
        handleEdit(evt)
        if (evt == "cancel") {
            setOwn(true)
        }
        if (evt == "edit") {
            setOwn(false)
        }
    }

    const [userPlanString, setUserPlanString] = useState("");
    useEffect(() => {
        let userPLAN = encrypt_Decrypt_Plan();
        // setUserPlanString(userPLAN)
        setUserPlanString("300")
    }, [location])

    /*------------Property modal show function-----------*/

    const newInspectionShow = () => { dispatch(inspectionOpen(true)); }
    const newMaintenanceShow = () => { dispatch(maintenanceOpen(true)); }
    const newEnvelopeShow = () => { dispatch(envelopeOpen(true)); }
    const servicesProvisionModalShow = () => setServicesProvisionModal(true);
    const [showServicesProvisionModal, setServicesProvisionModal] = useState(false);

    /*------------Form validation-----------*/

    const propertyValidation = Yup.object().shape({
        property_type: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        zipcode: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        address: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')).max(40, t("requiredMmessage.40characters"))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        number: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        // complement: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        neighborhood: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        city: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        state: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
        property_identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .test('not-select', "requiredMmessage.fieldRequired", (value) => value !== "slct"),
    });
    let frm = {
        resolver: yupResolver(propertyValidation)
    }
    const { propertyChargeData, chargeStatus, propertyExpenseData, propertyExpStatus, registryChargeShow, propertyData, managerData, administData, condoData, brokerData, ownerData } = useSelector((state: RootState) => state.propertyData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const { serviceData, contractData, contractDatail } = useSelector((state: RootState) => state.contractData)
    const { billingData } = useSelector((state: RootState) => state.expenditureData);
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    const [state, setState] = useState({
        id: "",
        propertyId: Number(id),
        startDate: startDt,
        endDate: endDt,
        start: 0,
        end: 10
    })

    const filterExpense = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = moment(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                endDt = moment(currentDate).subtract(1, 'M');
            } else {
                startDt = moment(currentDate).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt
            })
        }
    }
    useEffect(() => {
        (async () => {
            let response = await dispatch(getOwner())
            if (response.payload.totalRow > 0) {
                getPropertyDetails(id, "");
            }
        })()
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllSuppliers());
        dispatch(getAllService());
        dispatch(getManager());
        dispatch(getAdministrators());
        dispatch(getCondos());
        dispatch(getBroker());
        dispatch(getPropertyCharge({ "id": "", "propertyId": Number(id), "start": 0, "end": 10 }));
        dispatch(getAllAccounts({ "start": 0, "end": 1000 }));
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }));
        dispatch(contractsData({ "propertyId": Number(id), "start": 0, "end": 3 }));
    }, [dispatch])

    const electronicEnvelopeListingCall = async () => {
        const response = await customerService.allElectronicEnvelopeForSpecificProperty(id);
        if (response.data.resCode == 201 || response.data.resCode == 202) {
            const data = response.data.data;
            setElectronicEnvelopeListing(data);
        }
    }

    useEffect(() => {
        const values = {
            ...state,
            endDate: state.endDate.toISOString(),
            startDate: state.startDate.toISOString(),
        }
        dispatch(getBraches(values));
        dispatch(getPropertyExpense(values));
        electronicEnvelopeListingCall();
    }, [dispatch, state])

    useEffect(() => {
        (async () => {
            let response = await incomeService.getDiscountData(gettoken.userId);
            if (response.status == "success" || response.status == "Success") {
                setExtraAndDiscount(response.data);
            }
        })()
    }, [])

    const [heading, setHeading] = useState("");
    const [allStates, setAllStates] = useState([]);
    const getPropertyDetails = async (propertyId, type) => {
        let data = await dispatch(registerData(propertyId))
        const fields: string[] = ['id', 'property_identifier', 'rent_amount', 'zipcode', 'address', 'neighborhood', 'complement', 'city', 'state', 'number', 'usable_area', 'size', 'comments', 'registry', 'registration', 'administrator', 'branch', 'type_of_electrical', 'taxpayer_code', 'firefighter_code', 'spu_code', 'sequential_code', 'sale_value', 'transfer_guaranteed', 'location', 'listed_property', 'building', 'gas_code', 'energy_code', 'water_code', 'garbage_fee_code', 'property_type', 'condominium', 'status'];
        const managerFiels: string[] = ['managerId', 'manager'];
        const garbageFiels: string[] = ['garageId', 'identification', 'observation'];
        const hobbyFiels: string[] = ['hobbyId', 'huby_identification', 'huby_observation'];
        const ownerFiels: string[] = ['ownerId', 'name', 'owner_type', 'property'];
        const fundrsrFiels: string[] = ['fundraiserId', 'fundraiser_name', 'fundraiser_value', 'fundraiser_type', 'fundraiser_installments'];

        if (data.payload.type == "success" || data.payload.type == "Success") {
            if (typeof data.payload !== "undefined") {
                let ownersData: any = [];
                setHide(true);
                if (data.payload.data[0]['contractId']) {
                    setHide(false);
                }
                let propertyType = data.payload.data[0]['propertyType'] ? data.payload.data[0]['propertyType'] + ' : ' : '';
                let number = data.payload.data[0]['number'] ? data.payload.data[0]['number'] + ' -' : '';
                let address = data.payload.data[0]['address'] ? data.payload.data[0]['address'] + ',' : '';
                let city = data.payload.data[0]['city'] ? data.payload.data[0]['city'] + ',' : '';
                let state = data.payload.data[0]['state'] ? data.payload.data[0]['state'] + ',' : '';
                setValue('admin_rate', data?.payload?.data[0].admin_rate ? converter(`${data.payload.data[0].admin_rate}`) : "0,00")
                let headText = propertyType + '' + address + ' ' + number + ' ' + city + '' + state + ' CEP:' + data.payload.data[0]['zipcode'];
                setHeading(headText);
                fields.forEach((field: any) => setValue(field, data.payload.data[0][field]));

                setTimeout(() => {
                    setValue('state', data.payload.data[0]["state"])
                }, 1000)

                data.payload.data[0]?.owner.forEach((items, indx) => {
                    let resultData = ownerData && ownerData.length > 0 && ownerData.filter(item => Number(item.id) === Number(items.value));
                    ownersData.push({
                        value: items.value,
                        label: resultData[0]?.["name"],
                        main: items.main,
                        percentage: items.percentage
                    })
                    setValue(`owner.${indx}.name`, items.value);
                    setValue(`owner.${indx}.main_owner`, items.main);
                    setValue(`owner.${indx}.percentage_on_receipt`, items.percentage);
                })
                setMultipleOwner(ownersData)
                if (typeof data.payload.data[0].manager !== "undefined" && data.payload.data[0].manager.length > 0) {
                    managerFiels.forEach((field: any) => setValue(field, data.payload.data[0].manager[0][field]));
                }
                if (typeof data.payload.data[0].garage !== "undefined" && data.payload.data[0].garage.length > 0) {
                    garbageFiels.forEach((field: any) => setValue(field, data.payload.data[0].garage[0][field]));
                }
                if (typeof data.payload.data[0].hobby !== "undefined" && data.payload.data[0].hobby.length > 0) {
                    const updatedFormData = [...multipleHobby];
                    data.payload.data[0].hobby.forEach((data: any, index: number) => {
                        hobbyFiels.forEach((field: string) => {
                            updatedFormData[index] = { ...updatedFormData[index], [field]: data[field] }
                            let fieldsAre: any = `hobbybox.${index}.${field}`;
                            setValue(fieldsAre, data[field]);
                        });
                    });
                    setmMultipleHobby(updatedFormData)
                }
                // if (typeof data.payload.data[0].owner !== "undefined" && data.payload.data[0].owner.length > 0) {
                //     ownerFiels.forEach((field: any) => setValue(field, data.payload.data[0].owner[0][field]));
                // }
                if (typeof data.payload.data[0].fundraiser !== "undefined" && data.payload.data[0].fundraiser.length > 0) {
                    fundrsrFiels.forEach((field: any) => setValue(field, data.payload.data[0].fundraiser[0][field]));
                }
                if (typeof data.payload.data[0].fundraiser !== "undefined" && data.payload.data[0].fundraiser.length > 0) {
                    fundrsrFiels.forEach((field: any) => setValue(field, data.payload.data[0].fundraiser[0][field]));
                }

                if (type != "") {
                    let data = type[0].target.parentElement.parentElement.parentElement.parentElement;
                    SubmitData(data, "cancel");
                }
            }
        }
    }

    /*------------Submit property form data-----------*/
    function removeBlankEntries(data) {
        return data.filter(entry => Object.values(entry).some(value => value !== ""));
    }
    const validationOwner = () => {
        let vaid = true;
        multipleOwner.forEach((item: any, indexs) => {
            let name: any = `owner.${indexs}.name`;
            let percent: any = `owner.${indexs}.percentage_on_receipt`;
            let mainOwner: any = `owner.${indexs}.main_owner`;

            let ownerBene: any = document.querySelector('.ownersAndBeneficiariestsx  button');
            if (!item.value && multipleOwner.length >= 1) {
                setError(name, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }
            if (!item.percentage && multipleOwner.length >= 1) {
                setError(percent, {
                    type: "manual",
                    message: t("requiredMmessage.fieldRequired"),
                });
                vaid = false;
            }

            if (!!item.value) {
                let pickData = ownerData && ownerData.length > 0 && ownerData.filter(val => Number(val.id) === item?.value);
                if (contractDatail[0]?.automatic_payment === 1 && !pickData[0]?.hasSplitPaymentIntegrated) {
                    setError(name, {
                        type: "manual",
                        message: 'Não é possível adicionar o proprietário selecionado, pois ele não possui forma de pagamento compatível com o Split configurado no contrato vigente.',
                    });
                    vaid = false;
                }
            }
    })
        return vaid;
    }
    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, setError, clearErrors } = useForm<CustomersAndEmployeesForm>(frm);
    const onSubmit = async (data: any, e: any) => {
        e.preventDefault();
        let valid = validationOwner();
        if (!valid) {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Ownerarerequired"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        } else {
            if (propertyIdet) {
                const cleanedData: any = removeBlankEntries(multipleHobby);
                // if (multipleOwner.length == 1) {
                //     multipleOwner[0]["main"] = 1;
                //     multipleOwner[0]["percentage"] = "100";
                //     setValue(`owner.0.main_owner`, true);
                //     setValue(`owner.0.percentage_on_receipt`, 100);
                // }
                for (let key in data) {
                    if (data[key] === "slct") {
                        data[key] = ""; // Set the value to an empty string if it's "slct"
                    }
                }
                data["hobbybox"] = cleanedData
                let fundraiser_val: any = `${data.fundraiser_value}`;
                let fundraiser_value: any = convertToNumber(fundraiser_val);
                let admin_rate_val: any = `${data.admin_rate}`;
                let admin_rate_value: any = convertToNumber(admin_rate_val);
                data["fundraiser_value"] = fundraiser_value;
                data["admin_rate"] = admin_rate_value;
                const totalPercentage = multipleOwner?.reduce((acc, owner) => acc + parseFloat(owner.percentage), 0);
                if (totalPercentage < 100) {
                    Swal.fire({
                        // icon: 'success', 
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.lesstotalpercentage"),
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                } else if (totalPercentage > 100) {
                    Swal.fire({
                        // icon: 'success', 
                        title: t("Sweetalert.Success"),
                        text: t("Sweetalert.moretotalpercentage"),
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                } else {
                    data.owner = multipleOwner;
                    reset();
                    let response = await dispatch(updateProperty(data));
                    if (typeof response.payload !== "undefined" && (response.payload.resCode === 200 || response.payload.resCode === 202)) {
                        Swal.fire({
                            // icon: 'success','success',
                            title: t("Sweetalert.Success"),
                            text: response.payload.message,
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                        setOwn(true);
                        document.querySelector(".edit")?.removeAttribute("hidden");
                        document.querySelector(".update")?.setAttribute("hidden", "");
                        document.querySelector(".cancel")?.setAttribute("hidden", "");
                        getPropertyDetails(id, "");
                        SubmitData(e, "");
                        setIsEditVisible(true);
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: response.payload.message,
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        })

                    }
                }
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.Thisidentifier"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        }

    }

    const showChargeModal = (val) => {
        dispatch(setPropertyChargeId(val));
    }

    const propertyExpensesOpened = (val) => {
        dispatch(setPropertyExpenseId(val));
    }

    /*-------Checked/Unchecked multiple checkbox functionality--------*/

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);

    const handleSelectAll = e => {
        setshowDelete(true);
        setIsCheckAll(!isCheckAll);
        setIsCheck(propertyExpenseData.data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }

        setTimeout(() => {
            let TrueorFalse: boolean = true;
            let targDta: any = document.querySelectorAll(".LoopOnceToCheckStatus tr .check_Checkbox");
            targDta.forEach((item: any, index: number) => {
                if (TrueorFalse) {
                    let inputChecked: any = item.querySelector('input');
                    if (inputChecked && inputChecked instanceof HTMLInputElement && inputChecked.checked) {
                        let chek: any = item.getAttribute("data-status");
                        let chekreceid: any = item.getAttribute("data-receid");
                        if ((chek !== 0 && chek !== "0") || (chekreceid && chekreceid !== "")) {
                            TrueorFalse = false;
                        }
                    }
                }
            })
            setshowDelete(TrueorFalse);
        }, 800)
    };

    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }

    const [showDelete, setshowDelete] = useState(true);
    const handleClick = (e: any) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseFloat(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseFloat(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }

        let TrueorFalse: boolean = true;
        let targDta: any = document.querySelectorAll(".LoopOnceToCheckStatus tr .check_Checkbox");
        targDta.forEach((item: any, index: number) => {
            if (TrueorFalse) {
                let inputChecked: any = item.querySelector('input');
                if (inputChecked && inputChecked instanceof HTMLInputElement && inputChecked.checked) {
                    let chek: any = item.getAttribute("data-status");
                    let chekreceid: any = item.getAttribute("data-receid");
                    if ((chek !== 0 && chek !== "0") || (chekreceid && chekreceid !== "")) {
                        TrueorFalse = false;
                    }
                }
            }
        })
        setshowDelete(TrueorFalse);
    };
    /*------------Generate billing-------------*/

    const generateBilling = () => {
        if (isCheck.length > 0) {
            dispatch(setExtraChargeLabel('New Charge'));
            dispatch(setExtraCharge(isCheck))
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }
    /*-------Delete functionality--------*/

    const deletePropertyExpenses = () => {
        if (isCheck.length > 0) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.value) {
                    customerService.deletePropertyExpenses({ id: isCheck }).then(res => {
                        /*-------Check the api response--------*/
                        if (res.data.resCode === 408) {
                            Swal.fire({
                                title: t("Sweetalert.Success"),
                                text: t("Sweetalert.successget"),
                                // icon: 'success',"success",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                            dispatch(getPropertyExpense({
                                ...state,
                                endDate: state.endDate.toISOString(),
                                startDate: state.startDate.toISOString(),
                            }))
                        }
                        else {
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.somethingWent"),
                                // icon: 'success',"error",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                        }
                    }).catch(error => {
                        /*-------Error exception handling--------*/
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    });
                }
            })
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }
    /*--------------END----------------*/
    const [servShow, setServShow] = useState(true);
    const deletePropertiedata = async (e: any, id: any) => {
        e.preventDefault();
        Swal.fire({
            title: t("Sweetalert.Areyousure"),
            text: t("Sweetalert.Youwontbeabletorevert"),
            // icon: 'success','warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response: any = await dispatch(deleteProperties({ id }));
                if (response?.payload?.data?.resCode === 408) {
                    Swal.fire({
                        title: t("Sweetalert.Delete"),
                        text: t("Sweetalert.deletesuccess"),
                        // icon: 'success',"success",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    navigate("/properties")
                }
                else if (response?.payload?.data?.resCode === 415) {
                    Swal.fire({
                        title: t("Sweetalert.Delete"),
                        text: response?.payload?.data?.message,
                        // icon: 'success',"info",
                        showConfirmButton: true
                    })
                }
                else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    })
                }
            }
        })
    }

    const AnnouncedClick = (e: any) => {
        e.preventDefault();
        console.log("Thi si schangeddddd");
    }
    /*-----------Multiple owner----------*/

    const [multipleOwner, setMultipleOwner] = useState([
        {
            value: "",
            label: "",
            main: 0,
            percentage: "0"
        }
    ])
    const handleOwnerChange = (e, index, type) => {
        if (type == "owner" && e.target.value) {
            let pickData = ownerData && ownerData.length > 0 && ownerData.filter(item => Number(item.id) === Number(e.target.value));
            multipleOwner[`${index}`]["value"] = pickData[0]["id"];
            multipleOwner[`${index}`]["label"] = pickData[0]["name"];
        }
        if (type == "main") {
            document.querySelectorAll(".singleMainowner input").forEach((item) => {
                item["checked"] = false;
            })
            multipleOwner.forEach((item, indexs) => {
                setValue(`owner.${indexs}.main_owner`, false);
                multipleOwner[`${indexs}`]["main"] = 0;
            })
            e.target.checked = true;
            setValue(`owner.${index}.main_owner`, true);
            multipleOwner[`${index}`]["main"] = e.target.checked ? 1 : 0;
        }

        if (type == "percentage") {
            if (Number(e.target.value) < 100) {
                multipleOwner[`${index}`]["percentage"] = e.target.value;
            } else {
                multipleOwner[`${index}`]["percentage"] = "100";
            }
            setValue(`owner.${index}.percentage_on_receipt`, multipleOwner[`${index}`]["percentage"]);
            let totalPercent: number = 0;
            multipleOwner.forEach((item, indexss) => {
                if (item.percentage !== "") {
                    totalPercent += Number(item.percentage);
                }
            })
            if (totalPercent > 100) {
                multipleOwner[`${index}`]["percentage"] = 0;
                setValue(`owner.${index}.percentage_on_receipt`, '0');
            }
        }
        let newData = multipleOwner;
        setMultipleOwner(newData);
    }

    const addAnother = (e) => {
        setMultipleOwner([
            ...multipleOwner,
            {
                value: "",
                label: "",
                main: 0,
                percentage: "0"
            }
        ])
    }

    const handleDelete = (e, index) => {
        multipleOwner.splice(index, 1);
        reset({
            owner: [{ value: "", main: "", percentage: "" }],
        });
        setMultipleOwner([...multipleOwner]);
        multipleOwner.forEach((item, indx) => {
            setValue(`owner.${indx}.name`, item.value);
            setValue(`owner.${indx}.main_owner`, item.main);
            setValue(`owner.${indx}.percentage_on_receipt`, item.percentage);
        })
    }

    const isNumber = (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    const [service_provision_transfer_day, setService_Provision_Transfer_day] = useState([
        { value: 0, label: t("formLabel.select") },
        { value: 1, label: "Business days after rent payment" },
        { value: 2, label: "Fixed day" },
        { value: 3, label: "Calendar days after rent payment" },
    ])

    const [service_provision_guaranteed_transfer, setService_provision_guaranteed_transfer] = useState<any>([
        { value: "", label: t("formLabel.select") },
        { value: 2, label: "Does not have" },
        { value: 1, label: "Guarantee for few months" },
        { value: -1, label: "Guarantee for the whole contract" },
    ])

    const [service_provision_condominium, setService_Provision_Condominium] = useState<any>([
        { value: 0, label: "Not managed by the real estate agency" },
        { value: 1, label: "Managed by the real estate agency" },
        { value: 2, label: "Managed and paid by the real estate agency" }
    ])

    const [service_provision_adminfee, setService_provision_adminfee] = useState<any>([
        { value: 0, label: t("formLabel.select") },
        { value: 1, label: "Undefined" },
        { value: 2, label: "Focus only on rent" },
        { value: 3, label: "Focus on rent and other services" },
    ])

    useEffect(() => {
        if (i18n.language == "en") {
            setService_provision_guaranteed_transfer([
                { value: 0, label: t("formLabel.select") },
                { value: 1, label: "Business days after rent payment" },
                { value: 2, label: "Fixed day" },
                { value: 3, label: "Calendar days after rent payment" },
            ])

            setService_provision_adminfee([
                { value: 0, label: t("formLabel.select") },
                { value: 1, label: "Undefined" },
                { value: 2, label: "Focus only on rent" },
                { value: 3, label: "Focus on rent and other services" },
            ])

            setService_provision_guaranteed_transfer([
                { value: "", label: t("formLabel.select") },
                { value: 2, label: "Does not have" },
                { value: 1, label: "Guarantee for few months" },
                { value: -1, label: "Guarantee for the whole contract" },
            ])

            setService_Provision_Condominium([
                { value: 0, label: "Not managed by the real estate agency" },
                { value: 1, label: "Managed by the real estate agency" },
                { value: 2, label: "Managed and paid by the real estate agency" }
            ])

        } else {

            setService_Provision_Condominium([
                { value: 0, label: "Não administrado pela imobiliária" },
                { value: 1, label: "Administrado pela imobiliária" },
                { value: 2, label: "Administrado e pago pela imobiliária" },
            ])

            setService_Provision_Transfer_day([
                { value: 0, label: t("formLabel.select") },
                { value: 1, label: "Dias úteis após o pagamento do aluguel" },
                { value: 2, label: "Dia fixo" },
                { value: 3, label: "Dia corrido após o pagamento do aluguel" }
            ])

            setService_provision_adminfee([
                { value: 0, label: t("formLabel.select") },
                { value: 1, label: "Indefinido" },
                { value: 2, label: "Apenas foco no aluguel" },
                { value: 3, label: "Foco no aluguel e outros serviços" }
            ]);

            setService_provision_guaranteed_transfer([
                { value: "", label: t("formLabel.select") },
                { value: 2, label: "Não possui" },
                { value: 1, label: "Garantia por alguns meses" },
                { value: -1, label: "Garantia para todo o contrato" }
            ])
        }
    }, [i18n.language])

    useEffect(() => {
        //setService_Provision_Condominium(administData);
    }, [administData])

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.type == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item: any) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);

    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["type"] == "Success" || resp?.["data"]["type"] == "success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                zipCodeCheck.removeAttribute('disabled');
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                // Reset city, neighborhood, and address values
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
                zipCodeCheck.setAttribute('disabled', "")
            }
        } else {
            querySel.style.border = "0px solid red";
            zipCodeCheck.removeAttribute('disabled');
        }
    }
    // Aaditya aaditya code
    const [surveyListing, setSurveyListing] = useState<{ date_schedule: any; survey_id: number; surveyor_id: number }[]>([]);
    useEffect(() => {
        (async () => {
            let response = await customerService.getSurveyList(id);
            if (response.data.resCode == 201 || response.data.resCode == 200) {
                setSurveyListing(response.data.data);
            }
        })()
    }, []);

    function formatDateToDDMMYYYY(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    // Aaditya aaditya code end

    const numericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) return true
        else e.target.value = preval.substring(0, (preval.length - 1))
    }

    const electronicEnvelopeOpen = () => {
        dispatch(newPropertyElectronicEnvelopeOpen(true));
    }

    const handleContractClick = async (e, contract_id: any) => {
        e.preventDefault();
        let data: any = {
            contract_id: contract_id,
            type: "propert_Deatils"
        }
        await navigate(`/contract_details/${contract_id}`, { state: data });
        // await navigate(`/contract_details/${contract_id}`);
    }

    const handleHoobyBox = (e, index) => {
        let value = e.target.value;
        let name: any = (e.target.name).split(".");
        let finalName: any = name[name.length - 1] // hobbybox.0.huby_identification
        if (value !== "") {
            multipleHobby[`${index}`][finalName] = e.target.value;
        }
    }

    const addAnotherhobby = (e) => {
        reset();
        let AddNewForm = [
            ...multipleHobby,
            {
                huby_identification: "",
                huby_observation: "",
            }
        ]

        AddNewForm && AddNewForm.length > 0 &&
            AddNewForm.forEach((item: any, index: number) => {
                let huby_identification: any = `hobbybox.${index}.huby_identification`;
                let huby_observation: any = `hobbybox.${index}.huby_observation`;
                setValue(huby_identification, item["huby_identification"]);
                setValue(huby_observation, item["huby_observation"]);
            })
        setmMultipleHobby(AddNewForm);
    }

    const HandleDeleteHobbybox = (e, index) => {
        multipleHobby.splice(index, 1);
        setmMultipleHobby([...multipleHobby]);
        multipleHobby.forEach((item, indx) => {
            setValue(`hobbybox.${indx}.huby_identification`, item.huby_identification);
            setValue(`hobbybox.${indx}.huby_observation`, item.huby_observation);
        })
    }

    const propertyIdentifier = async (evt) => {
        if (evt.target.value) {
            let data = {
                identifier: evt.target.value
            }
            let response = await customerService.checkPropertyIdentifier(data);
            if (response.data && response.data.data.length > 0) {
                if (response.data.data[0].ID_IMOVEL_IMO == id && response.data.data[0].ST_IDENTIFICADOR_IMO == evt.target.value) {
                    clearErrors("property_identifier")
                    setPropertyIdet(true)
                } else {
                    setPropertyIdet(false)
                    setError("property_identifier", {
                        type: "manual",
                        message: t("Sweetalert.Thisidentifier"),
                    });
                }
            } else {
                clearErrors("property_identifier")
                setPropertyIdet(true)
            }
        }
    }

    const handleValue = (evt: any) => {
        let { value } = evt.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("fundraiser_value", formatNumber(value))
        } else {
            setValue("fundraiser_value", '')
        }
    }

    const handleRentValue = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        // Format number with thousands separator and 2 decimal places
        if (value) {
            setValue("admin_rate", formatNumber(value))
        } else {
            setValue("admin_rate", "")
        }
    }

    const handleAddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        if (numberOfCharacters > 40) {
            setError("address", {
                type: "manual",
                message: t("requiredMmessage.40characters")
            });
        } else {
            clearErrors("address")
        }
    }

    const [newLiquidateShow, setnewLiquidateShow] = useState(false);
    const [loader_on, setLoader_on] = useState(false);
    const liquidateClose = () => {
        setnewLiquidateShow(false);
    }

    useEffect(() => {
        let contractId = propertyData && propertyData.length > 0 && propertyData?.[0]?.["contractId"];
        if (contractId) {
            (async () => {
                await dispatch(contractDatails(contractId));
            })()
        }
    }, [propertyData])

    const handleSettleReverseReceipt = async (e: any, type: string) => {
        e.preventDefault();
        setLoader_on(true);
        if (isCheck && isCheck.length > 0) {
            let storeallItemData: any = isCheck.map(id => {
                return propertyExpenseData["data"].find((item: any) => item.id === id);
            }).filter(item => item !== undefined);

            let propertiesallData: any = [];
            let contractrallData: any = [];

            if (propertyData && propertyData.length > 0) {
                propertiesallData = propertyData[0]
            }

            if (contractDatail && contractDatail.length > 0) {
                contractrallData = contractDatail[0]
            }

            let makeObjectwhichtocarry: any = {
                dataobject: {
                    checkid: isCheck,
                    checkid_alldata: storeallItemData,
                    contractitem: contractrallData,
                    propertyitem: propertiesallData,
                    type: type,
                    billingmethod: billingData,
                    service_list: serviceData
                }
            }

            if (type !== "receipt") {
                let valueStatus = (type == "settle" ? "1" : type == "reverse" ? "0" : "")
                let checkPaidUnpaidData: any = storeallItemData.filter((item: any) => item.status === valueStatus);
                if (checkPaidUnpaidData && checkPaidUnpaidData.length > 0) {
                    Swal.fire({
                        title: t("Sweetalert.Info"),
                        text: type == "settle" ? i18n.language == "en" ? "Some of the selected entries have already been paid" : "Algumas das inscrições selecionadas já foram pagas" :
                            type == "reverse" ? i18n.language == "en" ? "Some of the selected entries are yet to be paid" : "Algumas das entradas selecionadas ainda não foram pagas" : "",
                        confirmButtonText: "OK",
                    });
                    setLoader_on(false);
                    return false;
                }

                let CompareWithThis: any = storeallItemData.filter((item: any) => String(item.credit) !== "4")
                if (CompareWithThis && CompareWithThis.length > 0) {
                    Swal.fire({
                        title: t("Sweetalert.Info"),
                        text: type == "settle" ? i18n.language == "en" ? "Some of the selected entries cannot be settled, as this entry has not been posted to your financial account"
                            : "Alguns dos lançamentos selecionados não podem ser liquidados, pois esse lançamento não foi lançado em sua conta financeira" :
                            type == "reverse" ? i18n.language == "en" ? "Some of the selected entries impossible to reverse, this entry is not in your finances"
                                : "Alguns dos lançamentos selecionados são impossíveis de reverter, esse lançamento não está em suas finanças" : "",
                        confirmButtonText: "OK",
                    });
                    setLoader_on(false);
                    return false;
                }
            }
            let resulttDta = await dispatch(settleReverseReceipt(makeObjectwhichtocarry));
            if (type === "receipt") {
                let fullPath = resulttDta["payload"]["data"]["0"]["receipt"];
                let url = `${config.CUSTOMER___API}/${fullPath}`;
                window.open(url, "_blank")
            }
            setLoader_on(false);
            dispatch(getPropertyExpense({
                ...state,
                endDate: state.endDate.toISOString(),
                startDate: state.startDate.toISOString(),
            }))
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                confirmButtonText: "OK",
                timer: 2000
            });
            setLoader_on(false);
        }
    }

    const onSubmitliquidate = async (data: any, e: any) => {
        //     setLoader_on(true);
        //     console.log(data, " <<<<<<<<<<< This is dataaa for liquidatee");
        //     setTimeout(() => {
        //         setLoader_on(false);
        //     }, 1500)
    }

    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ order: 0 }} className="mb-xl-0 mb-3 col-xl">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/properties"}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                                </Link>
                                <h1 className="mb-0 h4 lh-base ms-2 ms-lg-3 ms-xl-4 text-nowrap fw-medium">{t("Property")} {t('detail')}</h1>
                                <div className="w-100 ms-1 d-flex align-items-center">
                                    <h4 className="lh-base mb-0 text-dark-70 d-flex">{heading}</h4>
                                    {
                                        propertyData && propertyData.length > 0 && propertyData?.[0]?.["contractId"] &&
                                        propertyData[0]["contractId"] !== "" &&
                                        <span className="fs-12 ms-xl-4 ms-3 text-secondary fw-medium">{t("property.Leasedproperty")}</span>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3, span: "auto" }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 &&
                                    (userJson.indexOf(2071) !== -1) &&
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdown-basic">
                                                {t("button.action")}
                                                <img src="../assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Link className="dropdown-item" to={"/property_history/" + id}>{t("historic")}</Link>
                                                <Link className="dropdown-item cursor-pe" to="#" onClick={(e) => deletePropertiedata(e, id)}>{t("button.delete")}</Link>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                }

                                {
                                    userJson && userJson.length > 0 &&
                                    (userJson.indexOf(1090) !== -1) &&
                                    <li className="list-inline-item">
                                        <Link to={'/property_documents/' + id} className="btn btn-link btn-sm p-0 fw-bold" role="button">
                                            {t("documents")}
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                    <Col xl={8}>
                        <Card className="h-100">
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                {t("formLabel.propertyDetails")}

                                {
                                    userJson && userJson.length > 0 &&
                                    (userJson.indexOf(2071) !== -1) &&
                                    <span className="ms-2">
                                        <Button
                                            type="button"
                                            variant="link"
                                            size="sm"
                                            className={`fw-bold p-0 edit buttonclick ${isEditVisible ? '' : 'hidden'}`}
                                            onClick={(e) => handleCommon("edit")}
                                        >
                                            {t("button.edit")}
                                        </Button>
                                        <Button
                                            type="button"
                                            hidden
                                            variant="link"
                                            size="sm"
                                            className="fw-bold p-0 update buttonclick zipCodeCheck ms-2 me-lg-3 me-xl-4"
                                            onClick={(e) => handleCommon("update")}
                                        >
                                            {t("button.update")}
                                        </Button>
                                        <Button
                                            type="button"
                                            hidden
                                            variant="link"
                                            size="sm"
                                            className="fw-bold p-0 cancel buttonclick zipCodeCheck"
                                            onClick={(e) => handleCommon("cancel")}
                                        >
                                            {t("button.cancel")}
                                        </Button>
                                    </span>
                                }

                            </Card.Header>
                            <Card.Body className="pb-1">
                                <span className="d-block mb-3 fw-bold">{t("formLabel.address")}</span>
                                <Form onSubmit={handleSubmit(onSubmit)} id="editForm">
                                    <input type="hidden" {...register('hobbyId')} />
                                    <input type="hidden" {...register('garageId')} />
                                    <input type="hidden" {...register('id')} />
                                    <input type="hidden" {...register('managerId')} />
                                    <input type="hidden" {...register('ownerId')} />
                                    <input type="hidden" {...register('fundraiserId')} />

                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="propertyType" label={t('formLabel.propertyType') + " *"}>
                                                <Form.Select disabled className={`form-control ${errors.property_type ? 'is-invalid' : ''}`} {...register('property_type')} >
                                                    <option value="slct">{t('dropdownItem.select')}..</option>
                                                    <option value="1">{t("propertyTypeData.Casa")}</option>
                                                    <option value="4">{t("propertyTypeData.Apartamento")}</option>
                                                    <option value="33">{t("propertyTypeData.ApartamentoDuplex")}</option>
                                                    <option value="38">{t("propertyTypeData.ApartamentoTriplex")}</option>
                                                    <option value="9">{t("propertyTypeData.Áreacomum")}</option>
                                                    <option value="42">{t("propertyTypeData.Andarcorporativo")}</option>
                                                    <option value="29">{t("propertyTypeData.Bangalo")}</option>
                                                    <option value="13">{t("propertyTypeData.Barracao")}</option>
                                                    <option value="40">{t("propertyTypeData.BoxGaragem")}</option>
                                                    <option value="3">{t("propertyTypeData.Casacomercial")}</option>
                                                    <option value="2">{t("propertyTypeData.Casaemcondominio")}</option>
                                                    <option value="44">{t("propertyTypeData.CasaAssobradada")}</option>
                                                    <option value="5">{t("propertyTypeData.Cobertura")}</option>
                                                    <option value="14">{t("propertyTypeData.Chacara")}</option>
                                                    <option value="25">{t("propertyTypeData.Conjunto")}</option>
                                                    <option value="39">{t("propertyTypeData.Edicula")}</option>
                                                    <option value="10">{t("propertyTypeData.Escritorio")}</option>
                                                    <option value="20">{t("propertyTypeData.Fazenda")}</option>
                                                    <option value="6">{t("propertyTypeData.Flat")}</option>
                                                    <option value="16">{t("propertyTypeData.Galpao")}</option>
                                                    <option value="8">{t("propertyTypeData.Garagem")}</option>
                                                    <option value="43">{t("propertyTypeData.Garden")}</option>
                                                    <option value="21">{t("propertyTypeData.Haras")}</option>
                                                    <option value="35">{t("propertyTypeData.Hotel")}</option>
                                                    <option value="31">{t("propertyTypeData.Ilha")}</option>
                                                    <option value="7">{t("propertyTypeData.Kitnet")}</option>
                                                    <option value="27">{t("propertyTypeData.Laje")}</option>
                                                    <option value="30">{t("propertyTypeData.Loft")}</option>
                                                    <option value="12">{t("propertyTypeData.Loja")}</option>
                                                    <option value="41">{t("propertyTypeData.Pavilhao")}</option>
                                                    <option value="32">{t("propertyTypeData.Penthouse")}</option>
                                                    <option value="22">{t("propertyTypeData.Predio")}</option>
                                                    <option value="28">{t("propertyTypeData.Pousada")}</option>
                                                    <option value="19">{t("propertyTypeData.Ponto")}</option>
                                                    <option value="18">{t("propertyTypeData.Quiosque")}</option>
                                                    <option value="36">{t("propertyTypeData.Rancho")}</option>
                                                    <option value="34">{t("propertyTypeData.Resort")}</option>
                                                    <option value="15">{t("propertyTypeData.Salao")}</option>
                                                    <option value="11">{t("propertyTypeData.Salacomercial")}</option>
                                                    <option value="23">{t("propertyTypeData.Sitio")}</option>
                                                    <option value="24">{t("propertyTypeData.Sobrado")}</option>
                                                    <option value="37">{t("propertyTypeData.Studio")}</option>
                                                    <option value="17">{t("propertyTypeData.Terreno")}</option>
                                                    <option value="26">{t("propertyTypeData.Village")}</option>
                                                    <option value="45">{t("propertyTypeData.Consultorio")}</option>
                                                    <option value="1000">{t("propertyTypeData.Outro")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.property_type?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <InputGroup>
                                                <FloatingLabel controlId="zipCode" label={t('formLabel.zipCode') + " *"} className='getzipcode'>
                                                    <Form.Control disabled type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} onChange={(e) => getLocation(e, "zipcode")} maxLength={9} minLength={9} />
                                                </FloatingLabel>
                                                <InputGroup.Text>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} className="h-16" alt="Search Icon" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel controlId="address" label={t('formLabel.address') + " *"} >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                                                >
                                                    <Form.Control disabled type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.address ? 'is-invalid' : ''}`}  {...register('address')} onChange={(e) => handleAddress(e)} />
                                                </OverlayTrigger>
                                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={2}>
                                            <FloatingLabel controlId="number" label={t('formLabel.number') + " *"} >
                                                <Form.Control disabled type="text" placeholder={t('formLabel.number')} className={`form-control ${errors.number ? 'is-invalid' : ''}`}  {...register('number')} />
                                                <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel controlId="complement" label={t('formLabel.complement')} >
                                                <Form.Control disabled type="text" placeholder={t('formLabel.complement')} className={`form-control ${errors.complement ? 'is-invalid' : ''}`}  {...register('complement')} />
                                                <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel controlId="neighborhood" label={t('formLabel.neighborhood') + " *"}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.neighborhood ? 'is-invalid' : ''}`}  {...register('neighborhood')} />
                                                <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={5}>
                                            <FloatingLabel controlId="city" label={t('formLabel.city') + " *"}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`}  {...register('city')} />
                                                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={2}>
                                            <FloatingLabel controlId="state" className="mb-3" label={t('formLabel.state')}>
                                                <Form.Select disabled {...register('state')} aria-label={t('formLabel.state')}>
                                                    <option value="slct">{t('dropdownItem.select')}...</option>
                                                    {allStates?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <span className="d-block mb-3 fw-bold">
                                        {t('formLabel.ownerAndBenef')}
                                    </span>
                                    {
                                        multipleOwner && multipleOwner.length > 0 && multipleOwner?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Row className="gy-3 gy-lg-4 align-items-center mb-lg-4 mb-3">
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <FloatingLabel label={t('formLabel.name')}>
                                                                <Form.Select disabled={own ? true : false} className={`form-control ${errors?.owner?.[index]?.name ? 'is-invalid' : ''}`} {...register(`owner.${index}.name`)} onChange={(e) => handleOwnerChange(e, index, "owner")} >
                                                                    <option value="slct">{t('dropdownItem.select')}..</option>
                                                                    {ownerData?.map((item, index) => (
                                                                        <option key={index} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">{errors.owner?.[index]?.name?.message}</Form.Control.Feedback >
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                    </Col>

                                                    <>
                                                        <Col md={2}>
                                                            <Form.Check disabled={own ? true : false} className="singleMainowner" type="checkbox" {...register(`owner.${index}.main_owner`)} label={t("Report-Property.Main")} onChange={(e) => handleOwnerChange(e, index, "main")} />
                                                        </Col>
                                                        <Col md={2}>
                                                            <FloatingLabel className="mgetzipcode" controlId="floatingSelect " label={t('Report-Property.Perct') + "%"} >
                                                                <Form.Control disabled={own ? true : false} className={`${errors?.owner?.[index]?.percentage_on_receipt ? 'is-invalid' : ''}`} type="text" placeholder={t('Report-Property.Perct') + "%"}  {...register(`owner.${index}.percentage_on_receipt`)} onChange={(e) => handleOwnerChange(e, index, "percentage")} onKeyPress={isNumber} />
                                                                <Form.Control.Feedback type="invalid">{errors?.owner?.[index]?.percentage_on_receipt?.message}</Form.Control.Feedback >
                                                            </FloatingLabel>
                                                        </Col>
                                                        {
                                                            multipleOwner.length > 1 &&
                                                            <Col md={2}>
                                                                <Button disabled={own ? true : false} className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => handleDelete(e, index)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                                            </Col>
                                                        }
                                                    </>
                                                </Row>
                                            </React.Fragment>
                                        ))
                                    }

                                    {
                                        multipleOwner.length < 6 && propertyData.length > 0 &&
                                        <Row className="gy-3 gy-lg-4mb-lg-4 mb-3" >
                                            <Col md={6}>
                                                <Button disabled={own ? true : false} style={{ display: own ? "none" : "block" }} variant='link' className="fw-bold px-0" onClick={(e) => addAnother(e)}>
                                                    <img src="../assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t("formLabel.another")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                    <span className="d-block mb-3 fw-bold">
                                        {t('property.condominium')}
                                    </span>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={4}>
                                            <FloatingLabel controlId="administrator" label={t('formLabel.administrator')} >
                                                <Form.Select className={`form-control ${errors.administrator ? 'is-invalid' : ''}`} disabled {...register('administrator')} >
                                                    <option value="">{t('dropdownItem.select')}...</option>
                                                    {administData?.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.administrator?.message}</Form.Control.Feedback>
                                            </FloatingLabel>

                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="building" label={t('formLabel.buildingCondominium')} >
                                                <Form.Select className={`form-control ${errors.building ? 'is-invalid' : ''}`} disabled {...register('building')} >
                                                    <option value="">{t('dropdownItem.select')}...</option>
                                                    {condoData?.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.building?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="value" label={t('formLabel.value')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.value')} {...register('admin_rate')} onChange={(e) => handleRentValue(e)} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <span className="d-block mb-3 fw-bold">
                                        {t('formLabel.propertyDetails')}
                                    </span>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={4}>
                                            <FloatingLabel controlId="sequential" label={t('formLabel.sequentialCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.sequentialCode')} {...register('sequential_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="waterCode" label={t('formLabel.waterCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.waterCode')} {...register('water_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="energyCode" label={t('formLabel.energyCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.energyCode')} {...register('energy_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="electricalConnection" label={t('formLabel.electricalConnection')}>
                                                <Form.Select disabled {...register('type_of_electrical')} >
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    <option value="0">{t("uninformed")}</option>
                                                    <option value="1">{t("singlephase")}</option>
                                                    <option value="2">{t("biphasic")}</option>
                                                    <option value="3">{t("threephase")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="gasCode" label={t('formLabel.gasCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.gasCode')} {...register('gas_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="usableArea" label={t('formLabel.usableArea')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.usableArea')} {...register('usable_area')} onChange={numericOnly} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="size" label={t('formLabel.size')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.size')} {...register('size')} onChange={numericOnly} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="taxpayerCode" label={t('formLabel.taxpayerCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.taxpayerCode')} {...register('taxpayer_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="garbage" label={t('formLabel.garbageFeeCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.garbageFeeCode')} {...register('garbage_fee_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="firefighter" label={t('formLabel.firefighterCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.firefighterCode')} {...register('firefighter_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="spu" label={t('formLabel.spuCode')}>
                                                <Form.Control disabled type="text" placeholder={t('formLabel.spuCode')} {...register('spu_code')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="propertyDestination" label={t('formLabel.location')}>
                                                <Form.Select disabled {...register('location')} >
                                                    <option value="">{t('dropdownItem.select')}...</option>
                                                    <option value="U">{t('urbanproperty')}</option>
                                                    <option value="R">{t('ruralproperty')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="listedProperty" label={t('formLabel.listedProperty')}>
                                                <Form.Select disabled {...register('listed_property')}>
                                                    <option value="">{t('dropdownItem.select')}...</option>
                                                    <option value="1">{t("contractlistother.Not")} </option>
                                                    <option value="2">{t("yes")}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="status" label={t('dropdownItem.status')}>
                                                <Form.Select disabled {...register('status')}>
                                                    <option value="">{t('dropdownItem.select')}...</option>
                                                    <option value="0">{t('dropdownItem.available')}</option>
                                                    <option value="2">{t('dropdownItem.sold')}</option>
                                                    <option value="3">{t('dropdownItem.announced')}</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Accordion className="accordion-custom">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                {t('property.managers')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                    <Form.Select className={`form-control ${errors.manager ? 'is-invalid' : ''}`} disabled {...register('manager')} >
                                                        <option value="">{t('dropdownItem.select')}...</option>
                                                        {managerData?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.manager?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                {t('formLabel.registry')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row className="gy-lg-4 gy-3">
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="registry" label={t('formLabel.registry')}>
                                                            <Form.Control type="text" disabled placeholder={t('formLabel.registry')} {...register('registry')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="registration" label={t('formLabel.registration')}>
                                                            <Form.Control type="text" disabled placeholder={t('formLabel.registration')} {...register('registration')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                                {t('formLabel.fundraiser')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row className="gy-lg-4 gy-3">
                                                    <Col md={6} xl={4}>
                                                        <FloatingLabel controlId="name" label={t('formLabel.name')}>
                                                            <Form.Select disabled {...register('fundraiser_name')}>
                                                                <option value="slct">{t('dropdownItem.select')}...</option>
                                                                {brokerData?.map((item) => (
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6} xl={2}>
                                                        <FloatingLabel controlId="value" label={t('formLabel.value')}>
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.value')} {...register('fundraiser_value')} onChange={(e) => handleValue(e)} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6} xl={3}>
                                                        <FloatingLabel controlId="type" label={t('formLabel.type')}>
                                                            <Form.Select disabled {...register('fundraiser_type')}>
                                                                <option value="slct">{t('dropdownItem.select')}...</option>
                                                                <option value="1">{t("percentual")}</option>
                                                                <option value="2">{t("fixedvalue")}</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6} xl={3}>
                                                        <FloatingLabel controlId="installments" label={t('formLabel.installments')}>
                                                            <Form.Select disabled {...register('fundraiser_installments')}>
                                                                <option value="slct">{t('dropdownItem.select')}...</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="-1">{t("foreverycontract")}</option>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>
                                                {t('formLabel.identifier')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel label={t('formLabel.propertyIdentifier') + " *"}>
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="tooltip-property-id">{t('propertyIdentifierID')}</Tooltip>}
                                                    >
                                                        <Form.Control className={`form-control ${errors.property_identifier ? 'is-invalid' : ''}`} type="text" placeholder={t('formLabel.propertyIdentifier')} {...register('property_identifier')} onKeyUp={propertyIdentifier} disabled />
                                                    </OverlayTrigger>

                                                    <Form.Control.Feedback type="invalid">{errors.property_identifier?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>
                                                {t('formLabel.branch')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel controlId="branch" label={t('formLabel.branch')}>
                                                    <Form.Select {...register('branch')} disabled >
                                                        <option value="">{t('dropdownItem.select')}...</option>
                                                        {branchList && branchList.length > 0 ? branchList?.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        )) : ''}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>
                                                {t('formLabel.garages')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row className="gy-lg-4 gy-3">
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="identification" label={t('formLabel.identification')} >
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.identification')}  {...register('identification')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FloatingLabel controlId="observation" label={t('formLabel.observation')} >
                                                            <Form.Control disabled type="text" placeholder={t('formLabel.observation')} {...register('observation')} />
                                                        </FloatingLabel>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header>
                                                {t('formLabel.comments')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <FloatingLabel controlId="comments" label={t('formLabel.comments')}>
                                                    <Form.Control disabled as="textarea" {...register('comments')} rows={3} />
                                                </FloatingLabel>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header>
                                                {t('formLabel.hobbyBox')}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {multipleHobby && multipleHobby.length > 0 &&
                                                    multipleHobby.map((item, index) => (
                                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                                            <Col md={5}>
                                                                <FloatingLabel label={t('formLabel.identification')}>
                                                                    <Form.Control type="text" placeholder={t('formLabel.identification')} {...register(`hobbybox.${index}.huby_identification`)} onChange={(e) => handleHoobyBox(e, index)} disabled />
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FloatingLabel label={t('formLabel.observation')}>
                                                                    <Form.Control type="text" placeholder={t('formLabel.observation')} {...register(`hobbybox.${index}.huby_observation`)} onChange={(e) => handleHoobyBox(e, index)} disabled />
                                                                </FloatingLabel>
                                                            </Col>

                                                            <Col md={2}>
                                                                {multipleHobby.length > 1 &&
                                                                    userJson && userJson.length > 0 &&
                                                                    (userJson.indexOf(2071) !== -1) ?
                                                                    <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => HandleDeleteHobbybox(e, index)}>
                                                                        <img src="../assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                                                    </Button>
                                                                    : ""
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                    )}
                                                {
                                                    userJson && userJson.length > 0 &&
                                                    (userJson.indexOf(2071) !== -1) &&
                                                    <Row>
                                                        <Col md={6}>
                                                            <Button variant='outline-secondary' onClick={(e) => addAnotherhobby(e)}>
                                                                {t("formLabel.another")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    {
                                        userJson && userJson.length > 0 &&
                                        (userJson.indexOf(2071) !== -1) &&
                                        <>
                                            <Button variant="light" size="sm" className="Edit" onClick={(e) => HandleSubmit(e, "edit")} hidden>{t("button.edit")}</Button>
                                            <Button variant="light" size="sm" className="Update" type="submit" hidden>{t("button.update")}</Button>
                                            <Button variant="light" size="sm" className="CancelReset" onClick={(e) => getPropertyDetails(id, [e, "cancel"])} hidden>{t("button.cancel")}</Button>
                                        </>
                                    }
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={4}>
                        <Card className="mb-lg-4 mb-3">
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                <span className="me-1">{t("Servicesprovision")}</span>
                                {
                                    userJson && userJson.length > 0 &&
                                    (userJson.indexOf(2071) !== -1) &&
                                    <span className="ms-2 text-nowrap">
                                        <Button type="button" variant="link" size="sm" className="fw-bold p-0 edit" onClick={servicesProvisionModalShow}>{t("button.edit")}</Button>
                                        {/* <Button variant="link" className="p-0" type="button"><img src={process.env.PUBLIC_URL + "/assets/images/icons/print-light.svg"} alt="Print" className="h-20" /></Button> */}
                                    </span>
                                }
                            </Card.Header>
                            <Card.Body className="pb-1">
                                <Properties_serviceprov servShow={servShow} setServShow={setServShow} transfer_day={service_provision_transfer_day} guaranteed_transfer={service_provision_guaranteed_transfer} condominium={service_provision_condominium} adminfee={service_provision_adminfee} showServicesProvisionModal={showServicesProvisionModal} setServicesProvisionModal={setServicesProvisionModal} />
                            </Card.Body>
                        </Card>
                        <Card className="mb-lg-4 mb-3">
                            <Card.Header>{t("contractlistother.Latestcontracts")}</Card.Header>
                            {
                                contractData.data && contractData.data.length > 0 && !hide &&
                                    propertyData?.[0]?.["termination_complete"] !== 0 ?
                                    (contractData.data.map((item, index) => {
                                        return (<Card.Body key={index}>
                                            <div className="d-sm-flex align-items-center justify-content-between">
                                                <div>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/folder.svg"} alt="Folder" className="h-20 me-2" />
                                                    <Link className="text-dark ms-2" to="" onClick={(e) => handleContractClick(e, item.id)}> {item.type}  {item.id}/ {item.propertyId}</Link>
                                                    {/* <Link className="text-dark ms-2" to={`/contract_details/${item.id}`}>{item.type} {item.id}/{item.propertyId}</Link> */}
                                                    {/* onClick={(e) => handleContractClick(e, item.id)} */}

                                                    {/*<span className="text-secondary d-flex">
                                                    {t("customerAndEmployees.tenants")}: {item.id}/{item.tenantsName}
                                                </span>*/}
                                                </div>
                                                <Badge bg="success-10" className="text-dark ms-2">{item.active == "Active" ? t("active") : item.active == "Pending" ? t("pending") : item.active}</Badge>
                                            </div>
                                        </Card.Body>);
                                    }))
                                    :
                                    (<Card.Body>
                                        <div className="border text-secondary rounded-4 px-xl-4 p-3 text-center">
                                            {t("contractlistother.NoLatestcontractsfound")}.
                                        </div>
                                    </Card.Body>)
                            }
                        </Card>
                        <Accordion>
                            <Accordion.Item className="position-relative" eventKey="0">
                                <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                                    {
                                        (chargeStatus != "loading") ?
                                            userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) &&
                                            <Button className="fw-bold p-0 lh-1" onClick={() => showChargeModal(null)} type="button" variant="link" size="sm">
                                                {t("ReceiveandReceived.New")}
                                            </Button>
                                            : ''
                                    }
                                </div>
                                <Accordion.Header>{t("contractlistother.Tax")}</Accordion.Header>
                                <Accordion.Body>

                                    {
                                        (chargeStatus == "loading") ?
                                            <Loader />
                                            :

                                            <Table responsive className="table-custom table-custom-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{t("contractlistother.Code")}</th>
                                                        <th>{t("contractlistother.Category")}</th>
                                                        <th>{t("contractlistother.ValueBooklet")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        propertyChargeData.data && propertyChargeData.data.length > 0 ? propertyChargeData.data.map((item) => (
                                                            <tr key={item.id}>
                                                                {
                                                                    item.chargeManaged == 1 &&
                                                                        userJson && userJson.length > 0 &&
                                                                        (userJson.indexOf(2071) !== -1) ?
                                                                        <td onClick={() => showChargeModal(item.id)}>
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-light.svg"} className="cursor-pe" />
                                                                        </td>
                                                                        : <td></td>
                                                                }
                                                                <td>
                                                                    <span className="d-block">{item.chargeCode}</span>
                                                                </td>
                                                                <td>
                                                                    <span className="d-block text-dark-70">{item.chargeCategoryName}</span>
                                                                </td>
                                                                <td className="text-end">
                                                                    <span className="d-block text-nowrap">R$ {item.amounts}</span>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <div className="p-1 text-secondary text-center">
                                                                        {t('formLabel.noRecordsFounds')}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                    }
                                    <p className="d-block mb-0">{t('formLabel.listing')} {propertyChargeData.totalRow > 0 ? propertyChargeData.totalRow : 0} {t("contractlistother.charge")}.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className="position-relative" eventKey="3">
                                <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                                    {
                                        userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) &&
                                        <Button variant="link" className="fw-bold p-0 lh-1" size="sm" onClick={electronicEnvelopeOpen}>
                                            {t('Newenvelope')}
                                        </Button>
                                    }
                                </div>
                                <Accordion.Header>{t("Electronicenvelope")}</Accordion.Header>
                                <Accordion.Body className="pb-1">

                                    {
                                        electronicEnvelopeListing ?
                                            <Table responsive className="table-custom table-custom-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Envelope</th>
                                                        <th>Status</th>
                                                        <th>Cancelamento</th>
                                                        {/* Add more columns as needed */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {electronicEnvelopeListing && electronicEnvelopeListing.length > 0 && [...electronicEnvelopeListing].reverse().map(item => (
                                                        <tr>
                                                            <td> <Link to={"/electronic_envelope/" + item.ID_ENVELOPE_ENV} className="text-dark-70">Envelope {item.ID_ENVELOPE_ENV}</Link>  - {heading}</td>
                                                            {
                                                                item.FL_STATUS_ENV == 0 ? <td><Badge className="text-dark bg-warning-10">Pendente de envio</Badge></td> : ""
                                                            }
                                                            {
                                                                item.FL_STATUS_ENV == 1 ? <td><Badge className="text-dark bg-success-30">Assinado</Badge></td> : ""
                                                            }
                                                            <td></td>
                                                        </tr>

                                                    ))}
                                                </tbody>

                                            </Table>
                                            : <div className="border rounded-4 p-3 p-xl-4 text-secondary text-center">
                                                {t('Noenvelopefound')}
                                            </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Card className="mb-lg-4 mb-3">
                    <Card.Header className="d-flex align-items-center justify-content-between"><span>{t("Expensesfor")} <span className='capitalize'>{state.startDate.format('MMM/YYYY')}</span></span>
                        {
                            (propertyExpStatus != "loading") && userPlanString !== "100" && userPlanString !== "50" && userPlanString !== "200" ?
                                userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) &&
                                <span className="text-primary fw-bold h6 mb-0 ms-2 cursor-pe" onClick={() => propertyExpensesOpened(null)}>{t("newExpense")}</span>
                                : ''
                        }
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-lg-4 mb-3">
                            {/* <ButtonGroup size="sm" aria-label="Date">
                                <Button className='capitalize' variant="light" onClick={() => filterExpense(state.startDate, 'Subtract')}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-left.svg"} className="me-1" />
                                    {state.startDate.format('MMM/YYYY')}
                                </Button>
                                <Button className='capitalize' variant="light" onClick={() => filterExpense(state.endDate, 'Add')}>
                                    {state.endDate.format('MMM/YYYY')}
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-right.svg"} className="ms-1" />
                                </Button>
                            </ButtonGroup> */}

                            <ButtonGroup size="sm" aria-label="Date">
                                <Button variant="primary" className="btn-arrow" onClick={() => filterExpense(state.startDate, 'Subtract')}>
                                    {
                                        id && id !== null ?
                                            <img src={"../assets/images/icons/arrow-white.svg"} className="h-24" />
                                            :
                                            <img src={"./assets/images/icons/arrow-white.svg"} className="h-24" />

                                    }
                                    {/* {stateChange?.startDate} */}
                                </Button>
                                <Button variant="light" className="btn-date capitalize">
                                    {moment(state?.startDate).format('MMM/YYYY')}
                                </Button>
                                <Button variant="primary" className="btn-arrow" onClick={() => filterExpense(state.startDate, 'Add')}>
                                    {/* {stateChange?.endDate} */}
                                    {
                                        id && id !== null ?
                                            <img src={"../assets/images/icons/arrow-white-right.svg"} className="h-24" />

                                            :
                                            <img src={"./assets/images/icons/arrow-white-right.svg"} className="h-24" />

                                    }
                                </Button>
                            </ButtonGroup>
                        </div>
                        {
                            (propertyExpStatus == "loading" || loader_on) ?
                                <Loader />
                                :
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            {
                                                userJson && userJson.length > 0 &&
                                                (userJson.indexOf(3041) !== -1) &&
                                                <>
                                                    <th>
                                                        {
                                                            propertyExpenseData.data && propertyExpenseData.data.length > 0 ?
                                                                <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                                :
                                                                ''
                                                        }
                                                    </th>
                                                    <th></th>
                                                </>
                                            }
                                            <th>{t("maturity")}</th>
                                            <th>{t("Client")}</th>
                                            <th className="text-end">{t("formLabel.value")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='LoopOnceToCheckStatus'>
                                        {
                                            propertyExpenseData.data && propertyExpenseData.data.length > 0 ?
                                                propertyExpenseData.data.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            {
                                                                userJson && userJson.length > 0 &&
                                                                (userJson.indexOf(3041) !== -1) &&
                                                                <>
                                                                    <td className={"check_Checkbox"} data-status={item.status} data-receid={item.recebentoId}>
                                                                        <Checkbox key={item.id} type="checkbox" name={item.id} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                                    </td>

                                                                    <td>
                                                                        {/* {
                                                                    ((item["status"] !== 0 && item["status"] !== "0")) ?
                                                                        ""
                                                                        : */}
                                                                        <img onClick={() => propertyExpensesOpened(item.id)} className="me-2 pointer" title="Edit" src={process.env.PUBLIC_URL + "/assets/images/icons/edit-light.svg"} alt="Edit" />
                                                                        {/* } */}
                                                                        {/*<img onClick={() => propertyExpensesOpened(item.id)} title="Clone" src={process.env.PUBLIC_URL + "/assets/images/icons/clone.svg"} alt="Clone" />*/}
                                                                    </td>
                                                                </>
                                                            }

                                                            <td>
                                                                <span className="d-block text-dark-70 capitalize">
                                                                    {/* {(item.expireDate)} */}
                                                                    {moment(state?.startDate).format('MMM/YYYY')}
                                                                </span>
                                                                <span className="d-block">{t('contractlistother.Expiration')}</span>
                                                                <span className="d-block">{moment(item.expire).format("DD/MM/YYYY")}</span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    serviceData.map((itemm: any, index: number) => {
                                                                        return (
                                                                            (String(itemm.id) == String(item["service"])) &&
                                                                            <>
                                                                                <span className="text-dark-70">{itemm.name} </span> &nbsp;
                                                                                <span className="text-dark-70"> {item.complement}</span>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                <span className="d-block">
                                                                    {
                                                                        ((item["status"] !== 0 && item["status"] !== "0")) ?
                                                                            <>
                                                                                <img className="h-20" src={process.env.PUBLIC_URL + "/assets/images/icons/CheckmarkCircle.svg"} alt="paid" />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/clock.svg"} />
                                                                            </>
                                                                    }
                                                                    <span className="ps-1">{item.debitName} {t("formLabel.debit")} {((item.debitOwnerName && item.debitOwnerName !== "") && multipleOwner.length > 1) ? `(${item.debitOwnerName})` : ""} </span>
                                                                </span>
                                                                <span className="d-block">
                                                                    {/* <img className="h-20" src={process.env.PUBLIC_URL + "/assets/images/icons/CheckmarkCircle.svg"} alt="paid" />
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/clock.svg"} /> */}
                                                                    {
                                                                        ((item["status"] !== 0 && item["status"] !== "0")) ?
                                                                            <>
                                                                                <img className="h-20" src={process.env.PUBLIC_URL + "/assets/images/icons/CheckmarkCircle.svg"} alt="paid" />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/clock.svg"} />
                                                                            </>
                                                                    }
                                                                    <span className="ps-1">{item.creditName} {t("formLabel.credit")} {(item.creditOwnerName && item.creditOwnerName !== "" && multipleOwner.length > 1 ? item.creditOwnerName : "")} {item.supplierName}</span>
                                                                </span>
                                                            </td>
                                                            <td className="text-end text-dark-70">
                                                                <span className="d-block text-nowrap">R$ {converter(convertToNumber(item.valueLabel))}</span>
                                                                {/* <span className="d-block">{t('contractlistother.Incursfee')}</span> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className="p-1 text-secondary text-center">
                                                            {t('formLabel.noRecordsFounds')}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                        }
                        <p className="d-block py-2">{t('formLabel.listing')} {propertyExpenseData.totalRow > 0 ? propertyExpenseData.totalRow : 0} {t("expenditure.expenses")}.</p>
                        {/* <!-- Nav listing start from here -->*/}
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(2061) !== -1) &&
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" size="sm">
                                            {t('formLabel.mark')}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleSelectAll} href="">{t('formLabel.all')}</Dropdown.Item>
                                            <Dropdown.Item onClick={noneHandleSelectAll} href="">{t('formLabel.none')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                    <span className="px-1">
                                        {t('formLabel.withTagged')}
                                    </span>
                                </li>
                                {contractData.data && contractData.data.length > 0 &&
                                    propertyData && propertyData.length > 0 && propertyData?.[0]?.["contractId"] &&
                                    propertyData[0]["contractId"] !== "" &&
                                    <>
                                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                            <Button variant="light" size="sm" onClick={generateBilling}>{t('contractlistother.Generatebilling')}</Button>
                                        </li>
                                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                            <Button variant="light" size="sm" onClick={(e) => handleSettleReverseReceipt(e, "settle")}>{t('contractlistother.Settleonpayable')}</Button>
                                        </li>
                                        <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                            <Button variant="light" size="sm" onClick={(e) => handleSettleReverseReceipt(e, "reverse")}>{t('contractlistother.Reversepayable')}</Button>
                                        </li>
                                    </>
                                }
                                <li className="list-inline-item mb-lg-0 mb-1">
                                    <Dropdown drop="up">
                                        <Dropdown.Toggle variant="light" size="sm">{t('more')}</Dropdown.Toggle>
                                        <Dropdown.Menu className="mh-456 overflow-auto"
                                            aria-labelledby="dropdownMenuButton1">
                                            {
                                                showDelete &&
                                                <Dropdown.Item href={"#"} onClick={deletePropertyExpenses}>{t('button.delete')}</Dropdown.Item>
                                            }
                                            <Dropdown.Item href="#" onClick={(e) => handleSettleReverseReceipt(e, "receipt")}>{t('expenditure.receiptPayable')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        }
                        {/*  <!-- /Nav listing  end -->*/}
                    </Card.Body>
                </Card>
            </main>
            {/* <!-- /Main content start end --> */}
            <ChargeService />
            <InspectionService />
            <MaintenanceService />
            <EnvelopeService />
            <PropertyExpensesService state={state} noneHandleSelectAll={noneHandleSelectAll} />
            <PropertyElectronicEnvelopeService electronicEnvelopeListingCall={electronicEnvelopeListingCall} />
            <ExtraChargeService
                extraAndDiscount={extraAndDiscount}
                setExtraAndDiscount={setExtraAndDiscount}
                state={state}
                typestring="property_details"
                contractdata={contractData}
                noneHandleSelectAll={noneHandleSelectAll}
            />
            <Modal show={newLiquidateShow} onHide={liquidateClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("expenditure.liquidate")}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmitliquidate)}>
                    <Modal.Body>
                        <div className="d-flex align-items-center">
                            <Form.Group className="me-2 me-lg-3">
                                <Form.Check type="checkbox" {...register('settleDueDate')} label={t("expenditure.settleontheduedate")} />
                            </Form.Group>
                            <Form.Group className="me-2 me-lg-3">
                                <Form.Check type="checkbox" {...register('printReceipt')} label={t("expenditure.printreceipt")} />
                            </Form.Group>
                            <Form.Group >
                                <Form.Check type="checkbox" {...register('launchExpiration')} label={t("expenditure.launchontheexpirationday")} />
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={liquidateClose}>
                                        {t("close")}
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        {t("expenditure.liquidate")}
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>)
}
export default Property_detail;