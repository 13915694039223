import { config } from "../../../../../services/apiConfig";
import { getLocalStorageItem } from "../../../shared/utils/getLocalStorageItem";

export class ChargeService {
  private readonly token: string = getLocalStorageItem("tokenV2");
  private readonly userData: string = getLocalStorageItem("userData");
  private readonly customerApiUrl = config.CUSTOMER_API;
  private readonly transferApiUrl = config.TRANSFER_API;

  async getAllCharges(params: any) {
    const response = await fetch(
      `${this.transferApiUrl}/M1/income/charges/getToReceived`,
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.token,
          "connection-name": this.userData,
        },
      }
    );

    const data = await response.json();

    return data;
  }

  async invalidateCharges(body: {
    id_arr: number[];
    type: string;
    lease_period: string;
    FL_MOTIVOCANCELAR_RECB: string;
  }) {
    const response = await fetch(
      `${this.customerApiUrl}/aarin/invalidateCharge`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.token,
          "connection-name": this.userData,
        },
      }
    );

    const data = await response.json();

    return data;
  }
}
