import React, { useEffect, useState } from "react";

import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Offcanvas, Form, Button, Row, Col, FloatingLabel  } from "react-bootstrap";

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

import { AppDispatch } from "../../../../redux/store";
import { openErrorbox } from "../../Contracts/Common/actionPerform";
import { TranferSettingsForm } from "../../../../redux/slice/TransferSettings";
import { converter, convertToNumber, formatNumber } from "../../../constants/utils";
import { get_trasfer_settings, update_trasfer_settings } from "../../../../redux/slice/companyDataSlice";
import Loader from "../../../Profile/Company/Loader";

const SettingsDrawer = ({ show, onClose }) => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [loaderPage, setButtonPage] = useState(true);
    const [loaderButton, setButtonLoader] = useState(false);
    const [percentValues, setPercentValues] = useState({});
    
  const validationSchema = Yup.object().shape({});

    const { register, handleSubmit, setValue, watch, formState: { errors = {} } } = useForm<TranferSettingsForm>({
        resolver: yupResolver(validationSchema)
    });

    const isAdmCharge = watch('calc_adm_charge');

    useEffect(() => {
        (async () => {
            const { payload: { data = {} } } = await dispatch(get_trasfer_settings({})) ?? {};
            const percentFields: string[] = ['percent_fee', 'percent_fine'];
            const obj = {
                calc_adm_charge: Boolean(data?.IND_TX_ADM_JUROS_MULTA),
                percent_fee: data?.VL_JUROS !== undefined && data?.VL_JUROS !== null ? converter(data?.VL_JUROS) : undefined,
                percent_fine: data?.VL_MULTA !== undefined && data?.VL_MULTA !== null ? converter(data?.VL_MULTA) : undefined,
            }

            const fields: string[] = Object.keys(obj);
            fields.forEach((field: any) => setValue(field, obj[field]));
            percentFields.forEach((field: any) => {
                setPercentValues(values => ({ ...values, [field]: obj[field] || '' }));
            })

            setButtonPage(false)
        })()
    }, [dispatch, setValue])

    const handlePercentage = (e: any, key: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');

        if (value !== "") {
            const [intg, dec] = value.split(',')
            value = convertToNumber(intg)?.toString() + (dec !== undefined ? `,${dec}` : '')
            if (convertToNumber(value) > 100) value = '100,00'
        
            setValue(key, formatNumber(value))
            setPercentValues({ ...percentValues, [key]: value })
        } else {
            setValue(key, "")
            setPercentValues({ ...percentValues, [key]: '' })
        }
    };

    const onSubmit = async (data: any) => {
        setButtonLoader(true);

        const obj: any = {
            user_id: 1,
            calc_adm_charge: Boolean(data?.calc_adm_charge)
        };
    
        Object.entries(percentValues).forEach(([key, value]) => {
            obj[key] = value === '' ? null : convertToNumber(value)
        })

        const response = await dispatch(update_trasfer_settings(obj));
        if (response?.payload?.type?.toLowerCase() === "success") {
            Swal.fire({
                title: t("Sweetalert.settings"),
                text: t("Sweetalert.settingsSaved"),
                confirmButtonText: t("Sweetalert.ConfirmButtonText"),
            });
            onClose();
        } else {
            setButtonLoader(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: response?.payload?.data?.message,
                confirmButtonText: t("Sweetalert.ConfirmButtonText"),
            });
        }
    };

    return (
        <Offcanvas className="border-sm-none" placement="end" show={show} onHide={onClose}>
            {loaderPage && <Loader />}

            {!loaderPage && (
                <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column h-100" id="settingsDrawer">
                    <Offcanvas.Header className="border-bottom">
                        <Offcanvas.Title className="fw-bold d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center w-100">
                            {t('TransferSettingsDrawer.settings')}

                            <div className="text-primary ms-sm-auto d-flex justify-sm-end justify-content-between align-items-center">
                                <span onClick={onClose} className="cursor-pe">
                                    <img src="../assets/images/icons/cross.svg" alt="Close" className="h-16" />
                                </span>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <h6 className="d-sm-flex align-items-center justify-content-between mb-3 mb-lg-4 fw-bold">
                            {t('TransferSettingsDrawer.transfer')}
                        </h6>

                        <Form.Check type="checkbox" {...register('calc_adm_charge')} label={t('TransferSettingsDrawer.calcAdmFee')} />

                        <hr />

                        <h6 className="d-sm-flex align-items-center justify-content-between mb-3 mb-lg-4 fw-bold">
                            {t('TransferSettingsDrawer.transferCharges')}
                        </h6>

                        <Row className="gy-3 gy-lg-4">
                            <Col md={6} className="supplier_head">
                                <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t('fees')}>
                                    <Form.Control disabled={!!isAdmCharge} type="text" placeholder={t('fees')} className={`${errors.percent_fee ? 'is-invalid' : ''}`}  {...register('percent_fee')} onChange={e => handlePercentage(e, 'percent_fee')} />
                                    <Form.Control.Feedback type="invalid">{errors.percent_fee?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            <Col md={6} className="supplier_head">
                                <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t('trafficTicket')}>
                                    <Form.Control disabled={!!isAdmCharge} type="text" placeholder={t('trafficTicket')} className={`${errors.percent_fine ? 'is-invalid' : ''}`}  {...register('percent_fine')} onChange={e => handlePercentage(e, 'percent_fine')} />
                                    <Form.Control.Feedback type="invalid">{errors.percent_fine?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Offcanvas.Body>

                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={onClose}>{t("button.cancel")}</Button>

                        <Button type="submit" variant="primary" disabled={loaderButton} onClick={openErrorbox}>{t("button.save")}</Button>
                    </div>
                </Form>
            )}
        </Offcanvas>
    )
}

export default SettingsDrawer;