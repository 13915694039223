import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, FloatingLabel, Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm, } from "react-hook-form";
import { incomeService } from '../../../../services/incomeServices';
import Loader from '../../../Profile/Company/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NewSeries } from '../../../Interface';
import { RootState, AppDispatch } from '../../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { getBraches } from '../../../../redux/slice/branchSlice';
import Swal from 'sweetalert2';

const Series = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [items, setItems] = useState([])
    const [status, setStatus] = useState(true)
    const [double, setDouble] = useState(false);
    const [loader_on, setloader_on] = useState(false);
    const [show, setShow] = useState(false)
    const [state, setState] = useState<any>({
        status: "all",
        search_type: "",
        start: 0,
        end: 20
    })
    const { branchList } = useSelector((state: RootState) => state.branch);
    useEffect(() => {
        setStatus(true)
        incomeService.getSeries(state).then((res) => {
            if (res.type == "Success" || res.type == "success") {
                setItems(res?.data)
                setStatus(false)
            } else {
                setItems([])
                setStatus(false)
            }
        })
        dispatch(getBraches(state));
    }, [state, dispatch, loader_on])
    const Schema = yup.object().shape({
        ST_SERIE_SER: yup.string().required(t("requiredMmessage.fieldRequired")),
    })
    // The useForm hook is used to handle form registration, submission, and validation.
    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm<NewSeries>({
        resolver: yupResolver(Schema),
    });
    const onSubmit = (data: any) => {
        setloader_on(true)
        setDouble(true)
        incomeService.addSeries(data).then((res) => {
            if (res.type == "Success" || res.type == "success") {
                Swal.fire({
                    position: "center",
                    // icon: 'success',"success",
                    title: `${t("Sweetalert.Success")}`,
                    text: `${t("Sweetalert.insertRecord")}`,
                    showConfirmButton: true,
                    timer: 2000,
                });
                setShow(false)
                incomeService.getSeries(state)
                setloader_on(false)
                setDouble(false)
                reset()
            } else {
                Swal.fire({
                    position: "center",
                    // icon: 'success',"error",
                    title: `${t("Sweetalert.Error")}`,
                    text: `${t("Sweetalert.cannotInsert")}`,
                    showConfirmButton: true,
                    timer: 2000,
                });
                setloader_on(false)
                setDouble(false)
            }
        })

    }
    const handleClose = () => {
        setShow(false)
        setDouble(false);
        setloader_on(false);
        reset()
    }
    const showseries = (val) => {
        setShow(true)
    }
    const handleSeries = (id:number) =>{
        setShow(true)
        incomeService.Editseries({id:id}).then((res) =>{
            const fields: string[] = ['NM_ATUAL_SER', 'ST_SERIE_SER','ID_FILIAL_FIL'];  
            fields?.forEach((field: any) => setValue(field, res?.data.res[0]?.[field]));
            setValue("id",  res?.data.res[0]?.ID_SERIE_SER)
        })
    }
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t("Invoiceseries")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" aria-controls="collpase-filter-text">
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a className="fw-bold cursor-pe" onClick={() => showseries("new")} >
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("Newseries")}
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            {status ?
                <Loader />
                :
                <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                    {items && items.length > 0
                        ? items.map((item: any, index: Number) => {
                            return (
                                <Card className="mt-lg-4 mt-3" id="listOfSeries">
                                    <Card.Header>{item.ST_NOME_FIL}</Card.Header>
                                    <Card.Body className="pb-1">
                                        <Table
                                            responsive
                                            className="table table-custom table-custom-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{t("Invoices.series")}</th>
                                                    <th className='text-end'>{t("Invoices.Nextissue")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item?.data && item.data.length > 0 ?
                                                    item.data.map((CurElem: any, ind) => {
                                                        return (
                                                            <tr key={ind}>

                                                                <td className="d-flex align-items-center">
                                                                    <span className="cursor-pe icon" onClick={() =>handleSeries(CurElem.ID_SERIE_SER)}>
                                                                        <img src="assets/images/icons/edit-primary.svg" alt="Edit" className="h-20" />
                                                                    </span>
                                                                    {CurElem.FL_PRINCIPAL_SER == "1" && (
                                                                        <img src="assets/images/icons/oval-star.svg" alt="Star" className="h-14 cursor-pe" />
                                                                    )}
                                                                </td>
                                                                <td>{CurElem.ST_SERIE_SER}</td>
                                                                <td className='text-end'>{CurElem.NM_ATUAL_SER}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <p className='text-center'>{t("NoRecords")}</p>
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            )
                        })
                        : <p className='text-center'>{t("NoRecords")}</p>
                    }
                </main>
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {t("Newseries")} </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)} id="taxationFrm">
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3">
                            <input type='hidden' {...register("id")} />
                            <Col md={4}>
                                <FloatingLabel controlId="CNAE" label={t("Invoices.series") + " *"}>
                                    <Form.Control type="text" className={`${errors.ST_SERIE_SER ? 'is-invalid' : ''}`} placeholder="CNAE" {...register("ST_SERIE_SER")} />
                                    <Form.Control.Feedback type="invalid">{errors.ST_SERIE_SER?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="serviceCode" label={t("Invoices.Nextissue")}>
                                    <Form.Control type="text" placeholder={t("Invoices.Nextissue")} {...register("NM_ATUAL_SER")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel
                                    controlId="floatingSelect"
                                    label={t("Branch") + " *"}
                                >
                                    <Form.Select
                                        {...register("ID_FILIAL_FIL")}

                                        aria-label={t("Branch")}
                                    >
                                        {branchList &&
                                            branchList.length > 0 &&
                                            branchList.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer >
                        <div className='d-flex justify-content-end'>
                            <Button variant="outline-primary" className="me-lg-3 me-2" onClick={handleClose}>{t("button.close")}</Button>
                            {
                                loader_on ?
                                    <Button className="btn-width" disabled={double}>
                                        <span className="spinner-border spinner-border-sm"></span>
                                        <span className="px-1">{t("button.toSave")}</span>
                                    </Button>
                                    :
                                    <Button variant="primary" disabled={double} type='submit'>{t("button.toSave")} </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}

export default Series