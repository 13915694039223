import { useEffect, useRef, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useBankAccount } from "../../BankAccountProvider";
import { BankAccountFormType } from "../../schema";
import { getBanksList } from "../../services/getBanksList";
import { getBranchesList } from "../../services/getBranchesList";
import { AccountType, EnabledBanksToHomologate } from "../../types";

type Banks = Array<{
  bank_code: string;
  bank_name: string;
}>;

type Branches = Array<{
  id: string;
  name: string;
}>;

export function useDefaultFields() {
  const {
    accountVariation,
    setAccountVariation,
    accountType,
    setAccountType,
    shouldHomologate,
    setShouldHomologate,
    selectedBank,
    setSelectedBank,
  } = useBankAccount();
  const [banks, setBanks] = useState<Banks>([]);
  const [branches, setBranches] = useState<Branches>([]);
  const [isBankSearchFocused, setIsBankSearchFocused] = useState(false);
  const [searchedBank, setSearchedBank] = useState("");
  const bankSearchRef = useRef<HTMLDivElement>(null);
  const { errors, defaultValues } = useFormState<BankAccountFormType>();
  const { register, setValue } = useFormContext<BankAccountFormType>();
  const [initialBalance, setInitialBalance] = useState(() =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(Number(defaultValues?.moneyaccount ?? 0))
  );
  const [enableEditDate, setEnableEditDate] = useState(false);

  useEffect(() => {
    getBanksList().then((banks) => setBanks(banks));
    getBranchesList().then((branches) => {
      const reversedBranches = branches.reverse()

      setBranches(reversedBranches);
      setValue("branchtype", defaultValues?.branchtype ?? reversedBranches[0].id.toString());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues?.bankname) {
      const selectedBankToEdit = banks.find(
        (bank) => bank.bank_code === defaultValues?.bankname
      );

      if (!selectedBankToEdit) return;

      setValue(
        "bankname",
        `${selectedBankToEdit.bank_code} - ${selectedBankToEdit.bank_name}`
      );
      setSearchedBank(
        `${selectedBankToEdit.bank_code} - ${selectedBankToEdit.bank_name}`
      );
    }
  }, [banks, defaultValues, setValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutsideClick =
        bankSearchRef.current &&
        !bankSearchRef.current.contains(event.target as Node);

      if (isOutsideClick) return setIsBankSearchFocused(false);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const enabledBanksToHomologate = Object.values(EnabledBanksToHomologate);

  function getBanksToShow() {
    if (searchedBank)
      return banks
        .filter((bank) =>
          `${bank.bank_code.toLowerCase()} - ${bank.bank_name.toLowerCase()}`.includes(
            searchedBank.toLowerCase()
          )
        )
        .sort((a, b) => Number(a.bank_code) - Number(b.bank_code))
        .splice(0, 10);

    return banks
      .filter((bank) =>
        enabledBanksToHomologate.find(
          (bankToShow) => bankToShow === bank.bank_code
        )
      )
      .sort((a, b) => Number(a.bank_code) - Number(b.bank_code));
  }

  function getIsBankAbleToHomologate(bank: string) {
    return enabledBanksToHomologate.find((enabledBank) => enabledBank === bank);
  }

  const isContaCorrente =
    accountVariation === AccountType.CORRENTE &&
    accountType === AccountType.CORRENTE;

  const showHomologateField =
    isContaCorrente && getIsBankAbleToHomologate(selectedBank);

  const isBankNotAbleToHomologate =
    isContaCorrente && selectedBank && !getIsBankAbleToHomologate(selectedBank);

  function handleChangeBank(bank: { bank_code: string; bank_name: string }) {
    const { bank_code, bank_name } = bank;

    if (!getIsBankAbleToHomologate(bank_code)) setShouldHomologate(false);

    setValue("bankname", `${bank_code} - ${bank_name}`);
    setSearchedBank(`${bank_code} - ${bank_name}`);
    setSelectedBank(bank_code);
    setIsBankSearchFocused(false);
  }

  return {
    branches,
    accountVariation,
    setAccountVariation,
    bankSearchRef,
    setIsBankSearchFocused,
    setSearchedBank,
    isBankSearchFocused,
    banksToShow: getBanksToShow(),
    setAccountType,
    showHomologateField,
    setShouldHomologate,
    shouldHomologate,
    isBankNotAbleToHomologate,
    initialBalance,
    setInitialBalance,
    errors,
    defaultValues,
    register,
    handleChangeBank,
    enableEditDate,
    setEnableEditDate,
  };
}
