import React, { useEffect, useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

const EmptyReason = () => {
    const [t] = useTranslation();
    const location = useLocation();
    const [reasontext, setreasontext] = useState(false);

    useEffect(() => {
        if (location.pathname == "/contract") {
            setreasontext(true)
        }
    }, [location, i18n.language])
    
    return (
        <section className="content-wrapper">
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3 text-center">
                <img src="./assets/images/temp/empty_state.png" alt="Empty state" className="mx-auto img-fluid d-block my-lg-5 my-4" />
                {
                    reasontext ?
                        <h6 className="fw-bold">{i18n.language == "en" ? "!!! Limit exceed" : "!!! Limite excedido"} </h6>
                        :
                        <h6 className="fw-bold">{i18n.language == "en" ? "Don't have access?" : "Não tem acesso?"} </h6>
                }
                <p className="text-secondary mb-4 pb-xl-2">
                    {i18n.language == "en" ? "Please upgrade your plan to access this page." : "Por favor, atualize seu plano para acessar esta página."}
                </p>
                <Link to={"/dashboard"} className="btn btn-primary"> {t("Links.dashboard")}</Link>
            </main>
            {/* <!-- /Main content start end --> */}
        </section>
    )
}

export default EmptyReason
