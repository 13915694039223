import React, { useMemo } from 'react';

import moment from "moment";
import { Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { converter } from '../../constants/utils';
import FinancialExpenseService from '../Contracts/Common/FinancialExpenseService';

const BankTransactionsListing = (props) => {
    let { bankTransList, isCheckId, setIsCheckId, handleAllCheck } = props;

    const [t] = useTranslation();

    const groupBy = (xs, key) => {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };

    const data: any = useMemo(() => {
        if ((bankTransList.data || [])?.length === 0) return []
        const values = bankTransList.data?.reduce((prev, item) => {
            const date = moment(item.DT_DATA_TRANS || item.DT_LANCAMENTO_MOV || item.DT_VENCIMENTO_RECB, 'YYYY-MM-DD');


            // Transferências entre contas.
            if (item.ID_TRANSFERENCIA_TRANS) {
                const curr = {
                    id: item.ID_TRANSFERENCIA_TRANS,
                    type: 'transfer-same-owner',
                    value: item.VL_VALOR,
                    title: item.DESCRICAO_CONTA,
                    liquidated: false,
                    observation: item.ST_OBSERVACAO_TRANS || '',
                    valueType: item.tipo_movimento === 'Saída' ? 'negative' : 'positive',
                    description: `${item.tipo_movimento}: ${item.DESCRICAO_CONTA}`,
                    date: date.format('DD/MM/YYYY'),
                    sort: date.format('YYYY-MM-DD'),
                    totalDay: null,
                };
                return [...prev, curr];
            }

            if (item.ID_RECEBIMENTO_RECB === "Previous balance") {
                const curr = {
                    variant: 'no-line',
                    balance: item.balance,
                    date: date.format('DD/MM/YYYY'),
                    sort: date.format('YYYY-MM-DD'),
                    title: t('bankTransaction.previousBalance'),
                }

                return [curr, ...prev]
            }

            if (!!item.grouped && item.grouped?.length > 0) {
                const type = item.grouped?.length === 1 ? 'received' : 'receiveds'
                const curr = {
                    type: 'grouped',
                    liquidated: true,
                    valueType: 'positive',
                    date: date.format('DD/MM/YYYY'),
                    sort: date.format('YYYY-MM-DD'),
                    totalDay: item.grouped[0]?.VL_BALANCE_DAY_TOTAL,
                    title: t(`bankTransaction.types.${type}`, { value: item?.grouped?.length }),
                    value: item.grouped?.reduce((prev, curr) => prev + (curr?.VL_VALOR_MOV || curr?.VL_TOTAL_RECB || 0), 0)
                }
                return [...prev, curr]
            }

            const value = item?.VL_VALOR_MOV || item?.VL_TOTAL_RECB || 0
            const sign = Math.sign(value)
            const liquidated = !!item?.FL_LIQUIDADO
            const id = item?.ST_IDENTIFICADOR_IMO ? `${item?.ST_IDENTIFICADOR_IMO}/${item.lease_count}` : (item?.ID_RECEBIMENTO_RECB || item?.id || item?.ID_CONTABANCO_MOV || '')
            
            const paymentMethods = {
                0: t("customerAndEmployees.boleto"),
                1: t("customerAndEmployees.Cheque"),
                2: t("customerAndEmployees.money"),
                3: t("customerAndEmployees.creditcard"),
                4: t("customerAndEmployees.Debitcard"),
                8: t("customerAndEmployees.Transferbanking"),
                10: t("customerAndEmployees.others"),
                12: t("customerAndEmployees.pix"),
                13: t("customerAndEmployees.Automatictransfer"),
            }
            const payment = paymentMethods[item?.paymentMethod] || item?.BILLING_TYPE || item?.ST_FORMA_PAG || ""
            let observation = ''
            let type = ''

            if (!!payment || !!item?.ST_NOMEBANCO_BAN || !!item?.ST_DESCRICAO_CB) {
                observation = `${!!payment ? `${t("customerAndEmployees.with")} ${payment} ` : ''}${(!!item?.ST_NOMEBANCO_BAN || !!item?.ST_DESCRICAO_CB) ? `${t("in")} ${item?.ST_NOMEBANCO_BAN || item?.ST_DESCRICAO_CB}` : ''}`
            }

            const curr = {
                type,
                value,
                title: '',
                liquidated,
                observation,
                valueType: '',
                description: '',
                date: date.format('DD/MM/YYYY'),
                sort: date.format('YYYY-MM-DD'),
                totalDay: item?.VL_BALANCE_DAY_TOTAL
            }

            if (sign <= 0) {
                const name = item?.ST_NOME_PES_OWN || item?.ST_NOME_PES || item?.ST_NOME_SAC
                curr.description = !!name ? t('bankTransaction.inName', { name }) : item?.ST_HISTORICO_MOV || ''
                type = item?.ST_NOME_PES_OWN ? 'transfer' : 'expense'
                curr.valueType = 'negative'

                if (sign === 0) curr.observation = t('bankTransaction.liquidated')
            }

            if (sign > 0) {
                const name = item?.ST_NOME_PES_INQ || item?.ST_NOME_PES || item?.ST_NOME_SAC

                type = 'charge'
                curr.valueType = 'positive'
                curr.description = !!name ? t('bankTransaction.inName', { name }) : ''
            }

            curr.type = type
            curr.title = `${t(`bankTransaction.types.${type}`)} ${id}`

            return [...prev, curr]
        }, [])

        const previous = values.filter(e => e.variant === 'no-line')
        const others: any = values.filter(e => e.variant !== 'no-line')
        const grouped = Object.entries(groupBy(others, 'sort'))

        const formatted: any = grouped
            .reduce((prev: any, [key, items]: any) => {
                const item = {
                    variant: 'no-line',
                    balance: items[0]?.totalDay,
                    title: t('bankTransaction.BalanceIn'),
                    date: moment(key, 'YYYY-MM-DD').format('DD/MM/YYYY')
                }

                return [...prev, ...items, ...[item]]
            }, [])

        return [...previous, ...formatted]
    }, [bankTransList.data, t])

    const selectRow = async (e) => {
        setTimeout(() => {
            if (!e.target.checked) {
                e.target.checked = false;
                eitherChecked(e.target.getAttribute('data-id'), false)
            } else {
                e.target.checked = true;
                eitherChecked(e.target.getAttribute('data-id'), true)
            }
        }, 10);
    }

    const eitherChecked = (id, type) => {
        let isAvailable = isCheckId.filter(item => Number(item) === Number(id));
        if (isAvailable.length > 0) {
            let newData: any = [];
            let indexValue = isCheckId.indexOf(id);
            isCheckId.splice(indexValue, 1);
            newData = isCheckId
            setIsCheckId(newData);
        } else {
            setIsCheckId([...isCheckId, id])
        };

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            setTimeout(() => {
                let checkedAllId: any = false;
                data.forEach((item, index) => {
                    if (!item["checked"]) {
                        checkedAllId = true;
                    }
                })

                let checkedAll: any = document.querySelector('.mainCheckbox');
                if (checkedAllId) {
                    checkedAll["checked"] = false;
                } else {
                    checkedAll["checked"] = true;
                }
            }, 10)
        }
    }

    return (
        <>
            <Table responsive id="BankTransactionsListingtsx" className="table table-custom table-custom-bordered mb-0">
                <thead>
                    <tr>
                        <th className="w-24"><input type="checkbox" className="form-check-input checkbox-sm mainCheckbox" onClick={(e) => handleAllCheck(e, "all")} /></th>
                        <th>{t("bankTransaction.date")}</th>
                        <th>{t("description")}</th>
                        <th className="text-end">{t("bankTransaction.Revenue")}</th>
                        <th className="text-end">{t("bankTransaction.Expense")}</th>
                        <th className="text-end">{t("bankTransaction.Balance")}</th>
                    </tr>
                </thead>

                <tbody>
                    {data.map((item: any, index) => {
                        if (item.variant === 'no-line') {
                            return (
                                <tr key={index}>
                                    <td colSpan={5} className="text-dark-70 border-0">
                                        <span className='fw-bold'>{item.title}</span> <span>{item.date}</span>
                                    </td>

                                    <td className={`text-end fw-bold border-0 text-dark-70 ${Number(item.balance) <= 0 && 'text-danger-40'}`}>
                                        R$ {converter(`${item.balance}`)}
                                    </td>
                                </tr>
                            )
                        }

                        return (
                            <tr key={index}>
                                <td className='check_Checkbox'>
                                    <Form.Check type="checkbox" data-id={item.ID_CONTABANCO_MOV} onChange={selectRow} checked={item.isChecked} value={item.ID_CONTABANCO_MOV} />
                                </td>

                                <td className="text-dark-70">
                                    {item?.date || ''}
                                </td>

                                <td>
                                    <span className="w-100 d-block rent-rent text-dark-70">{item.title}</span>
                                    
                                    {item.description && <span className="w-100 d-block rent-rent fs-12">{item.description}</span>}
                                    {item.observation && <span className="w-100 d-block rent-rent fs-12 text-capitalize">{item.observation}</span>}
                                </td>

                                <td className="text-end">
                                    <span className={`text-nowrap d-block ${item.valueType === 'positive' && "text-success-55"}`}>
                                        R$ {converter(item.valueType === 'positive' ? `${item.value}` : '0')}
                                    </span>
                                </td>

                                <td className="text-end">
                                    <span className={`text-nowrap d-block ${item.valueType === 'negative' && "text-danger-40"}`}>
                                    R$ {converter(item.valueType === 'negative' ? `${item.value}`: '0')}
                                    </span>
                                </td>

                                <td className="text-end">
                                    <span className={`text-nowrap d-block ${item?.liquidated && 'text-dark-70'}`}>
                                        {t(item?.liquidated ? 'bankTransaction.liquidated' : 'bankTransaction.notLiquidated')}
                                    </span>
                                </td>
                            </tr>
                        )
                    })}

                    {data.length === 0 && (
                        <tr>
                            <td colSpan={7} className="text-center">{t("ReceiveandReceived.NochargesFound")}.</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <FinancialExpenseService />
        </>
    )
}

export default BankTransactionsListing;