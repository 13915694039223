import { Form, Modal, Button, Row, Col, InputGroup, Accordion, FloatingLabel, Card, Dropdown, OverlayTrigger, Tooltip, FormControl, ListGroup, Alert } from "react-bootstrap";
import { reInsuranceTp, contractDatails, propertyOpen, managerOpen, tenantsOpen, getGuarantor, insuranceOpen, contractClose, getOwner, getInsurance, insertContract, getTenants, getProperty, contractsData, addContractCronRecords, getContractSetting, UpdateTenantInp, } from "../../../../redux/slice/contractDataSlice";
import { getAllAccounts, getAllIndexs, getAllChecklists, getAllBillMethodListing, fetch_allFinancialIndexing, } from "../../../../redux/slice/expenditureDataSlice";
import * as Yup from "yup";
import { set, useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { contractForm } from "../../../Interface";
import Swal from "sweetalert2";
// import { getBraches } from '../../../../redux/slice/branchSlice';
import { getManager, registerData } from "../../../../redux/slice/propertyDataSlice";
import InsuranceService from "../../Contracts/Common/InsuranceService";
import TenantsService from "./TenantsService";
import ManagerService from "../../Contracts/Common/ManagerService";
import PropertyService from "./PropertyService";
import "../../../../App.css";
import customerService from "../../../../services/customerService";
import { numericOnly, openErrorbox, } from "../../Contracts/Common/actionPerform";
import moment from "moment";
import { incomeService } from "../../../../services/incomeServices";
import { gettoken } from "../../../../Api/Contact";
import ContractCost from "./ContractCost";
import RemainingBalance from "./RemainingBalance";
import ContractRegistered from "./ContractRegistered";
import CosignerService from "./CosignerService";
import { getBraches } from "../../../../redux/slice/branchSlice";
import { checkIdExists, convertToNumber, converter, encrypt_Decrypt, formatNumber, formatNumberEnglish, formatNumberPortuges, getMaxAssuredValue, getTitles, handleGreenCheckLogic, } from "../../../constants/utils";
import i18n from "../../../../i18n";
import { ClllThis } from "../../Contracts/Readjustment/ReadjustmentIndexes";
import NewGuarantors from "../../Contracts/Customer_and_employees/NewGuarantors";
import EmptyReason from "../Documents/EmptyReason";
import { config } from "../../../../services/apiConfig";
import { useBankAccounts } from "../../../Profile/Company/FormsOfReceipt/BankAccounts/hooks/useBankAccounts";

let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");

interface Coverage {
	value: number;
	text: string;
	// Define properties of Coverage
}
interface InsuranceRecord {
	Id: number;
	Name: string;
	ShortName: string;
	Total: number;
	Installments: number;
	TotalCalculatedByInstallments: number;
	Coverages: Coverage[]; // Ensure Coverages property is defined

	// Add other properties if needed
}
const EditContractService = (contractForm) => {
	const { guarantorData, contractDatail, insuranceData, tenantsData, propertyData, status, ContractSettingData, ownerData } = useSelector((state: RootState) => state.contractData);
	const { indexData, checklistData, billingData, accountData } = useSelector((state: RootState) => state.expenditureData);
	const { branchList } = useSelector((state: RootState) => state.branch);
	const { managerData } = useSelector((state: RootState) => state.propertyData);
	const navigate = useNavigate();
	const [activeKey, setActiveKey] = useState("0");
	const [oldData, setOldData] = useState<any>({});
	const [selectedCheckbox, setSelectedCheckbox] = useState<any>(null);
	const [selectedDate, setSelectedDate] = useState("");
	const [properVal, setProperVal] = useState('slct')
	const [managerVal, setManagerVal] = useState('')
	const [amdVal, setAdmVal] = useState('')
	const [valueCon, setValueCon] = useState('')
	const [startDate, setStartDate] = useState('')
	const [selectName, setSelectName] = useState('')
	const [Errormessage, setErrormessage] = useState<any>('')
  	const [ownersHasSplitPayment, setOwnersHasSplitPayment] = useState(false)
  	const [selectedBankAccount, setSelectedBankAccount] = useState("")
  	const [selectedReceivingMethod, setSelectedReceivingMethod] = useState("")
  	const [selectedBranch, setSelectedBranch] = useState("")
	
	const [percentValues, setPercentValues] = useState({})
	const [changedate, setchangedate] = useState(false)
	const [insuranceMaxVal, setInsuranceMaxVal] = useState<any>("");
	const [selectedTenantId, setTenantId] = useState("");
	/*-------------Set the contract form step state-------------*/
	const dispatch = useDispatch<AppDispatch>();
	const [t] = useTranslation();
	const [double, setDouble] = useState<any>(false);
	const [hide1, setHide1] = useState(true);
	const [hide2, setHide2] = useState(false);
	const [hide3, setHide3] = useState(false);
	const [hide4, setHide4] = useState(false);
	const [hide5, setHide5] = useState(false);
	const [hide6, setHide6] = useState(false);
	const [hide7, setHide7] = useState(false);
	const [hide8, setHide8] = useState(false);
	const [insurance, setInsurance] = useState(false);
	const [agencyOwned, setAgencyOwned] = useState(false);
	const [propertyOwnerDocument, setPropertyOwnerDocument] = useState("");
	const [allStates, setAllStates] = useState([]);
	const [settingOn, setSettingOn] = useState(true)
	const [insuranceStatus, setInsuranceStatus] = useState(false)
	const [state, setState] = useState<any>({
		start: "0",
		end: "100",
	});
	/*-----------------Set the hide/show contract form filds-----------*/
	const [isLoading, setIsLoading] = useState(true);
	const [loader_on, setloader_on] = useState(false);

	const [mincover, setCoverValue] = useState<any>();
	const [adres, setAdres] = useState(true);
	const [guarant, setGuarant] = useState(0);
	const [duDate, setDuDate] = useState(true);
	const [issValue, setIssValue] = useState(1);
	const [gurnty, setGurnty] = useState(true);
	const [bankFees, setBankFees] = useState(true);
	const [renterFees, setRenterFees] = useState(true);
	const [isActive, setIsActive] = useState(0);
	const [pay, setPay] = useState(0);
	const [interest, setInterest] = useState(true);
	const [guarType, setGuarType] = useState(0);
	const [busType, setBusType] = useState(true);
	const [expTermination, setExpTermination] = useState(true);
	const [isssContract, setIsssContract] = useState(false);
	const [ShowPendingCharge, setShowPendingCharge] = useState(false);
	const [hideFireInsuranceCard, setHideFireInsuranceCard] = useState<any>(true);
	const [saveAmmendmantAction, setSaveAmmendmantAction] = useState<any>(false);
	const [firstRent, setFirstRent] = useState(false);
	const [contractUseImobConfig, setContractUseImobConfig] = useState(false);
	//const [insuranceRecord, setInsuranceData] = useState([]);
	const [insuranceRecord, setInsuranceData] = useState<InsuranceRecord[]>([]);
	const inputRef = useRef<any>(null);
	const [contractTypes, setcontractType] = useState<any>('')
	const [filteredData, setFilteredData] = useState<any>([]);
	const [bussnesVal, setBussnessVal] = useState<Number>()
	/*----------Open the new cosigner modal form------------*/
	const [showNewCosignerModal, setNewCosignerModal] = useState(false);
	const newCosignerModalClose = () => setNewCosignerModal(false);

	/*----------Open the contract registered modal form------------*/
	const [showContractRegisteredModal, setContractRegisteredModal] =
		useState(false);
	const contractRegisteredModalClose = () => setContractRegisteredModal(false);
	const contractRegisteredModalShow = () => setContractRegisteredModal(true);

	/*----------Open the contracl cost modal form------------*/
	const [showRemainingBalanceModal, setRemainingBalanceModal] = useState(false);
	const remainingBalanceModalClose = () => setRemainingBalanceModal(false);
	const remainingBalanceModalShow = () => setRemainingBalanceModal(true);

	/*----------Open the remaining balance modal form------------*/
	const [showContractCostModal, setContractCostModal] = useState(false);
	const contractCostModalClose = () => setContractCostModal(false);
	const contractCostModalShow = () => setContractCostModal(true);

	/*----------Contract Summary Variables-----------------------*/
	const [selectedProperty, setSelectedProperty] = useState<any>("");
	const [selectedOwner, setSelectedOwner] = useState<any>(null);
	const [selectedTenant, setSelectedTenant] = useState<any>(null);
	const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
	const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
	const [selectedRentValue, setSelectedRentValue] = useState<any>(null);
	const [selectedGuarantee, setSelectedGuarantee] = useState<any>(null);
	const [selectedFireInsurance, setSelectedFireInsurance] = useState<any>(null);
	const [selectedFormOfPayment, setSelectedFormOfPayment] =
		useState<any>("Pix");
	const [propertyTypes1, setPropertyTypes1] = useState<any>()
	/*---------Tick State For Accordion Items -------------------*/
	const [tick0, setTick0] = useState<any>("");
	const [tick1, setTick1] = useState<any>("");
	const [tick2, setTick2] = useState<any>("");
	const [tick3, setTick3] = useState<any>("");
	const [tick4, setTick4] = useState<any>("");
	const [tick5, setTick5] = useState<any>("");
	const [tick6, setTick6] = useState<any>("");
	const [insuranceToken, setInsuranceToken] = useState<any>("");
	const [isInputFocused, setIsInputFocused] = useState([{ focus: false }]);
	const [BillTypebanks, setBillTypebanks] = useState<any>([]);
	const [userJson, setUserJson] = useState<any>([]);

	/*----------Open the insurance modal form------------*/
	const [items, setItems] = useState<any>([]);
	const newInsuranceOpen = (evt) => {
		dispatch(reInsuranceTp(evt.target.title));
		dispatch(insuranceOpen(true));
		dispatch(contractClose(false));
	};

	useEffect(() => {
		let userResult = encrypt_Decrypt();
		if (!userResult || (userResult.indexOf(2031) === -1)) {
			navigate("/dashboard");
		}
		setUserJson(userResult);
	}, [])

	/*----------Open the tenants modal form------------*/

	const newTenantsOpen = () => {
		dispatch(tenantsOpen(true));
		dispatch(contractClose(false));
	};

	/*----------Open the manager modal form------------*/

	const newManagerOpen = () => {
		dispatch(managerOpen(true));
		dispatch(contractClose(false));
	};

	/*----------Open the property modal form------------*/

	const newPropertyOpen = () => {
		dispatch(propertyOpen(true));
		dispatch(contractClose(false));
	};

	/*----------Load the below specific data on page load------------*/
	const [billmethodOnOff, setBillMethodOnOff] = useState({});
	const { id } = useParams();

	const [readName, setReadName] = useState<any>([]);
	useEffect(() => {
		let IntervalTime;
		(async () => {
			if (!readName.length) {
				// IntervalTime = setInterval(async () => {
				let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
				if (dataOutPut["payload"]) {
					// clearInterval(IntervalTime)
					let indPayload = dataOutPut["payload"]["data"];
					if (indexData.data) {
						indPayload = indexData.data
					}
					let result = await ClllThis(indPayload, "mysql");
					setReadName(result);
				}
				// }, 3000)
			}
		})()
		const data = getTitles();
		setItems(data);
	}, [indexData])

	const [granterState, setGaranterstate] = useState(false);
	const [granterIndex, setGaranterIndex] = useState();
	const formShow = (index) => {
		setGaranterstate(true);
		setGaranterIndex(index)
	}
	const onChangeShow = (show) => {
		setGaranterstate(show)
		setGaranterIndex(undefined)
	}

	const [multipleTenant, setMultipleTenant] = useState([
		{
			value: "",
			label: "",
			main: 0,
			percentage: "",
		},
	]);

	const [multipleGarunter, setMultipleGarunter] = useState([
		{
			value: "",
		},
	]);

	useEffect(() => {
		setIsLoading(false);
		const generateTokenForInsurance = async () => {
			try {
				const response = await fetch(
					`${config.INSURANCEAPI}/legacy/auth/login`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"api_key": `${config.INSURANCEAPIKEY}`,
						},
						body: JSON.stringify({
							agency_license: `${localStorage?.getItem('agencyId')}`,
							loginvv: "lenilda.santos@ingaia.com.br",
							login: `${localStorage?.getItem('userEmailId')}`,
						}),
					}
				);
				const data = await response.json();
				if (data) {
					const getInsuranceValue = getValues('insuranceCompany')
					if (getInsuranceValue == '24') {
						//handleInsuranceQuote()
					}

				}
				setInsuranceToken(data.access_token);
			} catch (error) {
				console.error("Error:", error);
			}
		};
		generateTokenForInsurance();
	}, []);

	const handleInsuranceQuote = async () => {
		const selectElement: any = document?.getElementById("insuranceCompany");
		const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("data-name");
		if (selectedDataName == "Tokio Marine" || selectName == "Tokio Marine") {
			setIsLoading(true);
			try {
				const assuredValueInput: any = document?.getElementsByName("assuredValue")[0];
				const assuredValue = assuredValueInput?.value;
				const contractType = getValues("contract_type");
				let minCoverVal: any = 0
				const rentValueCalculation: any = mincover
				const normalAmount = convertToNumber(rentValueCalculation)
				let insuran = normalAmount * 100;
				const rentValueCalcu: any = assuredValue ? assuredValue : getValues("assuredValue")
				const AssuAmount = convertToNumber(rentValueCalcu)
				const maxAssuredValue = getMaxAssuredValue(contractTypes, AssuAmount ? AssuAmount : insuran);
				if (maxAssuredValue) {
					minCoverVal = maxAssuredValue
				}
				let issueDate: any = ""
				let insuranceEnd: any = ""
				let contractStart: any = getValues("start2")
				let InsuranceStart: any = getValues("start")
				let InsuranceEnd: any = getValues("end")
				const today = new Date();
				const comparisonDate: any = new Date(contractStart);
				if (changedate && InsuranceStart !== "" && InsuranceEnd !== "") {

					if (moment(today).format("YYYY-MM-DD") < moment(comparisonDate).format("YYYY-MM-DD")) {
						issueDate = contractStart
						comparisonDate.setFullYear(comparisonDate.getFullYear() + 1);
						let formattedDate = comparisonDate.toISOString().split('T')[0];
						insuranceEnd = formattedDate

					} else {
						issueDate = moment(today).format("YYYY-MM-DD")
						today.setFullYear(today.getFullYear() + 1);
						let formattedDate = today.toISOString().split('T')[0];
						insuranceEnd = moment(formattedDate).format("YYYY-MM-DD")
					}
				} else {
					issueDate = InsuranceStart;
					insuranceEnd = InsuranceEnd;
				}
				const property_id: any = getValues("immobile");
				const propertyOwner = getValues("owner");

				// const propertyDetails = await dispatch(
				// 	getProperty({ id: id, contract: "contract", start: 0, end: 10000 })
				// );

				// const propertyDetails = fetchPropertyDetails.payload.data.filter(
				// 	(property: { id: any }) => property.id === parseInt(property_id)
				// )[0];
				let fetchPropertyDetails = await dispatch(registerData(property_id))
				const propertyDetails = fetchPropertyDetails?.payload?.data[0];
				const ownerId = propertyDetails?.owner[0]?.value;
				setPropertyTypes1(propertyDetails?.property_type)
				let data = await dispatch(contractDatails(Number(id)));
				if (data?.payload?.data[0]?.insuranceCompanyName == "Tokio Marine") {
					setValue("insuranceCompany", data?.payload?.data[0]?.insuranceCompany)

				}
				if (data?.payload?.data[0]?.start == "" && data?.payload?.data[0]?.end == "") {
					setValue("start", issueDate)
					setValue("end", insuranceEnd)
				}
				// const ownerId = propertyDetails.ownerResult[0].ownerId;
				const fetchOwnerDetails = await dispatch(getOwner());
				const ownerDetails = fetchOwnerDetails.payload.data.filter(
					(owner: { id: any }) => owner.id === ownerId
				);
				const fetchTenantsDetails = await dispatch(getTenants());
				const tenantIdString = await getValues("datatenants.tenants.0.name");
				const tenantId = parseInt(tenantIdString);
				const tenantDetails = fetchTenantsDetails.payload.data.filter(
					(tenant: { id: any }) => {
						return tenant.id === tenantId;
					}
				);
				let tenantType = ""
				let ownerType = ""
				if (tenantDetails.length) {
					const cleanedStr = tenantDetails[0].cnpj.replace(/[.\-/]/g, '');
					tenantType = cleanedStr.length == 11 ? "Fisica" : "jurídica";
				}
				if (tenantDetails.length) {
					const cleanedStr = tenantDetails[0].cnpj.replace(/[.\-/]/g, '');
					ownerType = cleanedStr.length == 11 ? "Fisica" : "jurídica";
				}
				const result = checkIdExists(Number(contractTypes), Number(propertyTypes1));
				if (
					(contractTypes == 1 && result) ||
					(contractTypes == 3 && result && bussnesVal !== undefined)
				) {
					const response = await fetch(
						`${config.INSURANCEAPI}/legacy/properties/quote?apiKey=${config.INSURANCEAPIKEY}`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `${insuranceToken}`,
							},
							body: JSON.stringify({
								property: {
									state: propertyDetails?.state ? propertyDetails?.state : "",
									city: propertyDetails?.city ? propertyDetails?.city : "",
									address_complement:  propertyDetails?.complement && propertyDetails?.complement.trim() !== "" ? propertyDetails?.complement?.slice(0, 15) : "",
									address: propertyDetails?.address ? propertyDetails?.address : "",
									address_number: propertyDetails?.number ? propertyDetails?.number : "",
									address_neighbourhood: propertyDetails?.neighborhood ? propertyDetails?.neighborhood : "",
									cep: propertyDetails?.zipcode ? propertyDetails?.zipcode : ""
								},
								policy_duration: {
									effective_date_end: insuranceEnd,
									effective_date_start: issueDate
								},
								agency_license: `${localStorage?.getItem('agencyId')}`,
								CONTRATACAO_AUTOMATICA: 0,
								tenant: {
									phone: tenantDetails.length ? tenantDetails[0].mobile : '',
									birth_date: moment(tenantDetails[0].birth).format('YYYY-MM-DD'),
									name: tenantDetails.length ? tenantDetails[0].name : '',
									gender: "M",
									email: tenantDetails.length ? tenantDetails[0].email : '',
									foreign: "",
									document_data: {
										emission_date: issueDate,
										document_nature: "",
										document_number: tenantDetails.length ? tenantDetails[0].rg : '',
									},
									politically_exposed: "",
									document_number: tenantDetails.length ? tenantDetails[0].cnpj : '',
									person_type: tenantType
								},
								property_owner: {
									birth_date: ownerDetails.length ? ownerDetails[0]?.birth : "",
									email: ownerDetails.length ? ownerDetails[0]?.email : "",
									document_number: ownerDetails.length ? ownerDetails[0]?.cnpj : "",
									name: ownerDetails.length ? ownerDetails[0]?.name : "",
									phone: ownerDetails.length ? ownerDetails[0]?.mobile : "",
									person_type: ownerType
								},
								user_email: `${localStorage?.getItem('userEmailId')}`,
								insurance: {
									property_id: contractTypes == "3" ? "2" : String(contractTypes),
									renewal: "",
									main_coverage: Number(minCoverVal),
									zip_code: propertyDetails.zipcode,
									property_type: propertyDetails.propertyType,
									property_group_id: bussnesVal ? bussnesVal : "",
								},
							}),
						}
					);
					const quoteData = await response.json();
					setIsLoading(true)
					const selectElement: any = document?.getElementById("insuranceCompany");
					const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("data-name");
					if (selectedDataName == "Tokio Marine") {
						setIsLoading(false)
						setHideFireInsuranceCard(false);
						setInsuranceData(quoteData.Packages);

						setInsurance(true)
						if (quoteData?.error) {
							setErrormessage(quoteData?.error)
							setHideFireInsuranceCard(true);
						}
					} else {
						setInsuranceData([]);
						setHideFireInsuranceCard(true);
					}
					const getContractedPlan = getValues('contractedPlan')
					setAlreadyClickedIndex(getContractedPlan)
				} else {

				}
			} catch (error) {
				// setIsLoading(true);
				console.log(error)
			}
		} else {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(getAllAccounts({ start: 0, end: 1000 }));
		dispatch(getAllBillMethodListing({ start: 0, end: 1000 }));

		if (id == null) {
			dispatch(getBraches(state));
		}
		dispatch(getManager());
		dispatch(getTenants());
		dispatch(
			getProperty({ id: id, contract: "contract", start: 0, end: 1000 })
		);
		dispatch(getInsurance({ insurance: "", start: 0, end: 1000 }));
		dispatch(getOwner());
		dispatch(getGuarantor());
		dispatch(getAllChecklists({ isStatus: "", start: 0, end: 1000 }));
		dispatch(getAllIndexs({ status: "", start: 0, end: 10 }));
		getContractsData(id);
	}, [dispatch, i18n.language]);

	useEffect(() => {
		dispatch(getInsurance({ insurance: "", start: 0, end: 1000 }));
	}, [filteredData]);


	useEffect(() => {
		if (!allStates.length) {
			(async () => {
				let resp = await customerService.getAddressBasedOnZipcode(`states`);
				if (resp.data.status == "success" || resp.data.type == "Success") {
					let loop = resp.data.data;
					let stateData = loop.map((item) => ({
						value: item.state,
						label: item.state,
					}));
					// Sort the stateData alphabetically by label
					stateData.sort((a, b) => a.label.localeCompare(b.label));
					setAllStates(stateData);
				}
			})();
		}
	}, []);

	/*----------Set the contract form fields------------*/
	const [paymentMethod, setPaymentMethod] = useState<string>('')
	const [Emptycontract, setEmptycontract] = useState(false);
	const getContractsData = async (id) => {
		setAgencyOwned(false);
		const fields: string[] = [
			"id",
			"immobile",
			"paymentMethod",
			// "branch",
			"exemption_termination",
			"contract_type",
			"businessActivity",
			"invoice",
			"includeInDIMOB",
			"calculationDateBase",
			"income_tax",
			"deduct_administration",
			"is_generate",
			"billing_address",
			"zipcode",
			"address",
			"number",
			"complement",
			"neighborhood",
			"city",
			"state",
			"guarantee",
			"guaranteeType",
			"responsible",
			"description",
			"identifier",
			"in",
			"until",
			"observation2",
			"insurer",
			"numberInstallments",
			"isContract",
			"start",
			"end",
			"prizeAmount",
			"installmentValue",
			"insuranceCompany",
			"numberInstallments2",
			"policy2",
			// "responsible4",
			"observation4",
			"start2",
			"duration",
			"end2",
			"due_date",
			"graceDays",
			"occupation",
			"secondRent",
			"readjustment_index",
			"readjustment_date",
			"billing_method",
			"delivery_method",
			"fineExemption",
			"lease_period",
			"fees",
			"trafficTicket",
			"punctuality",
			"charge_renter",
			"manager",
			"additional_clauses",
			"observation3",
			"fixed_amount",
			"automatic_payment",
			"transfer_type",
			"transfer_day",
			"guarantee2",
			"months",
			"bank_fee",
			"is_active",
			"policy",
			"bank",
			"applicationNumber",
			"backgroundType",
			"surety",
			"different_interest",
			"checklistTemplate",
			'fistRent',
			'contract_occupancy_date',
			// "bank_account"
		];
		if (typeof id !== "undefined" && id) {
			let data = await dispatch(contractDatails(Number(id)));
			// let settings = await dispatch(getContractSetting());
			if (data.payload.data.length > 0) {
				setcontractType(data.payload.data[0]?.contract_type)
				setPropertyTypes1(data?.payload?.data[0]?.proprty_type)
				setCoverValue(data.payload.data[0]?.rent_value)
				setSelectName(data.payload.data[0]?.insuranceCompanyName)
				setValue("price", converter(String(data?.payload?.data[0]?.price)));
				if (
					ContractSettingData &&
					ContractSettingData.length > 0 &&
					ContractSettingData[0].bank_fee_amount
				) {
					setRenterFees(false);
					setValue("renterPrice", ContractSettingData[0].bank_fee_amount); // Set renterPrice if exists
				} else {
					setValue("renterPrice", converter(String(data?.payload?.data[0]?.renterPrice)));
				}

				setValue("rent_value", converter(String(data?.payload?.data[0]?.["rent_value"])));
				setValue("contractedPlan", data?.payload?.data[0]?.["contractedPlan"] ? String(`${data?.payload?.data[0]?.["contractedPlan"]}`) : "");
				setAlreadyClickedIndex(data?.payload?.data[0]?.["contractedPlan"] ? String(`${data?.payload?.data[0]?.["contractedPlan"]}`) : "")
				setValue("assuredValue", data?.payload?.data[0]?.["assuredValue"] ? converter(`${data?.payload?.data[0]?.["assuredValue"]}`) : "0,00");
				setValue('value', converter((data?.payload?.data[0]?.["value"])));
				setValue("responsible4", "2");
				setValueCon(data?.payload?.data[0]?.["value"])
				// old data
				setOldData(data.payload.data[0]);
				let tenentDataArr: any = [];
				setStartDate(data?.payload?.data[0]?.start2)
				if (data?.payload?.data[0]?.fistRent == "1") {
					setFirstRent(true)
				} else {
					setFirstRent(false)
				}
				setTimeout(() => {
					setValue("insuranceCompany", data?.payload?.data[0]?.insuranceCompany)
				}, 2000)

				let result = propertyData.filter(
					(item) => Number(item.id) === Number(id)
				);
				setValue("monthlyAdmin", converter(`${data.payload.data[0].monthlyAdmin}`))
				if (data.payload.data[0]["ownerResult"][0]?.ownerName !== "undefined") {
					setSelectedRentValue(converter(String(data.payload.data[0]["rent_value"])));
					let inputDate = data.payload.data[0]["start2"];
					setSelectedStartDate(inputDate);

					let endDate = data.payload.data[0]["end2"];
			
					if (contractDatail && contractDatail.length > 0 && contractDatail?.[0].endDateMatter) {
						setSelectedEndDate(`${t("Indefinite")}`);
					} else {
						setSelectedEndDate(endDate);
					}

					if (
						data.payload.data[0]["insuranceCompanyName"] ==
						"inGaia Corretora de Seguros Ltda"
					) {
						setHideFireInsuranceCard(false);
					}
					setSelectedFireInsurance(
						data.payload.data[0]["insuranceCompanyName"]
					);

          const ownersId = data.payload.data[0]?.ownerResult?.map((owner: any) => owner.ownerId)
          if (ownersId && ownersId.length > 0) {
            const isOwnerAllowedToSplit = ownerData
              .filter(owner => ownersId.includes(owner.id))
              .every((owner: any) => owner.hasSplitPaymentIntegrated)
            setOwnersHasSplitPayment(isOwnerAllowedToSplit)
            if (!isOwnerAllowedToSplit) {
              handleAutomaticPayment(0)
            }
          } else {
            handleAutomaticPayment(0)
          }
					setSelectedOwner(data.payload.data[0]["ownerResult"][0]?.ownerName);
					setValue("owner", data.payload.data[0]["ownerResult"][0]?.ownerName);
				}
				data.payload.data[0]?.datatenants?.tenates.forEach(
					(itemsingle, index) => {
						let pickData =
							tenantsData &&
							tenantsData.length > 0 &&
							tenantsData.filter(
								(item) => Number(item.id) === Number(itemsingle.ID_PESSOA_PES)
							);
						tenentDataArr.push({
							value: itemsingle.ID_PESSOA_PES,
							label: pickData[0]?.["name"],
							main: itemsingle.FL_PRINCIPAL_INQ,
							percentage: itemsingle.NM_FRACAO_INQ,
						});
						setSelectedTenant(itemsingle.name);
						setValue(
							`datatenants.tenants.${index}.name`,
							itemsingle.ID_PESSOA_PES
						);
						setValue(
							`datatenants.tenants.${index}.main_tenant`,
							itemsingle.FL_PRINCIPAL_INQ
						);
						setValue(
							`datatenants.tenants.${index}.percentage_on_receipt`,
							itemsingle.NM_FRACAO_INQ
						);
					}
				);
				setMultipleTenant(tenentDataArr);
				fields.forEach((field: any) => {
					if (field == "readjustment_index") {
						setTimeout(() => {
							setValue("readjustment_index", (data.payload.data[0][field] == 0 || data.payload.data[0][field] == "0" || data.payload.data[0][field] === false) ? "1" : String(data.payload.data[0][field]));
						}, 1500)
					} else if (field == "isContract") {
						if (data.payload.data[0][field] == "1" || data.payload.data[0][field] == 1) {
							setValue(field, 1)
						} else {
							setValue(field, 0)
						}
					} else if (field == "different_interest") {
						setValue(field, 0) // Not working in superlogica also
					} else {
						if (field == "start2" || field == "end2" || field == "occupation" || field == "start" || field == "end") {
							setValue(field, moment(data.payload.data[0][field], "DD/MM/YYYY").format("YYYY-MM-DD"))
						} else {
							setValue(field, data.payload.data[0][field])
						}
					}

					if (field === "branch") {
						setSelectedBranch(String(data.payload.data[0]["branch"]))
					}

					if (field === "billing_method") {
						setSelectedReceivingMethod(String(data.payload.data[0]["billing_method"]))
					}

					if (data.payload.data[0]["branch"] !== 1 || data.payload.data[0]["billing_method"] !== "0") {
						handleAutomaticPayment(0)
					}

					if (field === "paymentMethod") {
						setValue("paymentMethod", data.payload.data[0][field]);
						setPaymentMethod(String(data.payload.data[0][field]));
					}
					
					if (field == "readjustment_date") {
						setValue("readjustment_date", moment(data.payload.data[0][field], "DD-MM-YYYY").format("YYYY-MM-DD"));
					}
				});


				let allGurantorData: any = [];
				data.payload.data[0]["guarantor"] &&
					data.payload.data[0]["guarantor"].length > 0 &&
					data.payload.data[0]["guarantor"].forEach((item, index) => {
						allGurantorData.push({ value: item["ID_PESSOA_PES"] });
						setValue(`guarantor.${index}.data`, item["ST_NOME_PES"]);
						setIsInputFocused([
							...isInputFocused,
							{ focus: true }
						])
					});
				allGurantorData &&
					allGurantorData.length > 0 &&
					setMultipleGarunter(allGurantorData);

				if (data.payload.data[0]["billing_method"] == 0) {
					setSelectedFormOfPayment(`${t("customerAndEmployees.boleto")}`);
				}
				if (data.payload.data[0]["billing_method"] == 1) {
					setSelectedFormOfPayment(`${t("customerAndEmployees.Cheque")}`);
				}
				if (data.payload.data[0]["billing_method"] == 2) {
					setSelectedFormOfPayment(`${t("NonPayment.cash")}`);
				}
				if (data.payload.data[0]["billing_method"] == 12) {
					setSelectedFormOfPayment(`${t("customerAndEmployees.pix")}`);
				}
        		const bankAccountsFilteredByBillingMethod = await filterBankAccountByBillingMethod(data.payload.data[0]["billing_method"]);
        		setBillTypebanks(bankAccountsFilteredByBillingMethod);
				setTimeout(() => {
					dispatch(getBraches(state));
					dispatch(getAllAccounts({ start: 0, end: 1000 }));
					setTimeout(() => {
						// handlefilterBillType({ billing_method: data.payload.data[0]["billing_method"] });
						setValue("bank_account", data.payload.data[0]['bank_account']);
						setValue("branch", data.payload.data[0]['branch']);
					}, 1500);
				}, 500);

				if (data.payload.data[0]["isContract"] !== "undefined") {
					if (data?.payload?.data[0]["isContract"] == 1 || data?.payload?.data[0]["isContract"] == "1") {
						setIsssContract(true);
						setIssValue(data.payload.data[0]["isContract"]);
					} else {
						setIssValue(0);
						setIsssContract(false);
					}
				}
				if (data.payload.data[0]["billing_address"] !== "undefined") {
					if (data.payload.data[0]["billing_address"] == 3) {
						setAdres(false);
					}
				}
				if (data.payload.data[0]["contract_type"] !== "undefined") {
					setBusType(true);
					if (data.payload.data[0]["contract_type"] == 3) {
						setBusType(false);
						setMaxiumVal("2000000")
					} else {
						setMaxiumVal("5000000")
					}
				}
				if (data.payload.data[0]["guarantee"] !== "undefined") {
					setSelectedGuarantee(data.payload.data[0]["guaranteeLabel"]);
					setGuarant(data.payload.data[0]["guarantee"]);
				}
				if (data.payload.data[0]["due_date"] !== "undefined") {
					if (data.payload.data[0]["due_date"]) {
						setDuDate(false);
					}
				}
				if (data.payload.data[0]["exemption_termination"] !== "undefined") {
					if (data.payload.data[0]["exemption_termination"] == 2) {
						setExpTermination(false);
					}
				}
				if (data.payload.data[0]["guarantee2"] !== "undefined") {
					if (data.payload.data[0]["guarantee2"] == 1) {
						setGurnty(false);
					}
					if (
						data.payload.data[0]["guarantee2"] == 1 ||
						data.payload.data[0]["guarantee2"] == -1
					) {
						setGuaranty(true);
					}
				}
				if (data.payload.data[0]["bank_fee"] !== "undefined") {
					if (data.payload.data[0]["bank_fee"] == 1) {
						setBankFees(false);
					}
				}
				if (data.payload.data[0]["charge_renter"] !== "undefined") {
					if (data.payload.data[0]["charge_renter"] == 1) {
						setRenterFees(false);
					}
				}
				if (data.payload.data[0]["is_active"] !== "undefined") {
					setIsActive(data.payload.data[0]["is_active"]);
				}
				if (data.payload.data[0]["automatic_payment"] !== "undefined") {
					setPay(data.payload.data[0]["automatic_payment"]);
				}
				if (data.payload.data[0]["different_interest"] !== "undefined") {
					if (data.payload.data[0]["different_interest"] == 1) {
						setInterest(false);
						setValue("different_interest", data.payload.data[0]["different_interest"])
					}
				}
				if (data.payload.data[0]["guaranteeType"] !== "undefined") {
					if (data.payload.data[0]["guaranteeType"]) {
						setGuarType(data.payload.data[0]["guaranteeType"]);
					}
				}
				if (data?.payload?.data[0]?.endDateMatter) {
					setValue("duration", "");
					setValue("end2", "");
				}
				if (data?.payload?.data[0]?.contract_occupancy_date == "1") {
					setSettingOn(true)
				} else {
					setSettingOn(false)
				}
				if (typeof data.payload.data[0].ownerResult !== "undefined" && data.payload.data[0].ownerResult.length) {
					if (data.payload.data[0].ownerResult[0].agencyOwned === 1) {
						setAgencyOwned(true);
						setValue("retain_transfer", "1");
						setBankFees(true);
						setValue("bank_fee", false);
						setValue("price", "");
						setValue('transfer_type', "")
						setValue('transfer_day', "");
						setValue('guarantee2', "0");
						setValue('monthlyAdmin', "");
						setValue('fixed_amount', false)
						setValue('manual_payment', true);
						setValue('automatic_payment', false);
					}
				}
				if (ContractSettingData && ContractSettingData.length > 0) {
					if (ContractSettingData[0]?.use_contract_occupancy_date_to_calculate_1st_prorated_rent == "1") {
						setSettingOn(true)
						setValue('contract_occupancy_date', true)
					} else {
						setSettingOn(false)
					}
				}

				if (!data.payload.data[0]?.delivery_method || data.payload.data[0]?.delivery_method == "") {
					setValue("delivery_method", "slct")
				}

				if (!data.payload.data[0]?.calculationDateBase || data.payload.data[0]?.calculationDateBase === "") {
					setContractUseImobConfig(false);
					setValue("calculationDateBase", "slct")
				}
				else {
					setContractUseImobConfig(true);
					setValue("calculationDateBase", data.payload.data[0]?.calculationDateBase);
				}

				if (data.payload.data[0].businessActivity) {
					if (data.payload.data[0].businessActivity == 1) {
						setBussnessVal(1)
						// setValue("businessActivity", t("newContractDev.services"))
					}
					else if (data.payload.data[0].businessActivity == 2) {
						// setValue("businessActivity", t("newContractDev.Commerce"))
						setBussnessVal(4)
					}
					else if (data.payload.data[0].businessActivity == 3) {
						// setValue("businessActivity", t("newContractDev.Office"))
						setBussnessVal(5)
					}
					else if (data.payload.data[0].businessActivity == 4) {
						// setValue("businessActivity", t("newContractDev.consulting"))
						setBussnessVal(5)
					}
					else {
						setValue("businessActivity", data.payload.data[0].businessActivity)
					}
				}
				let insuran = data?.payload?.data[0]?.["rent_value"] * 200;
				const maxAssuredValue = getMaxAssuredValue(data.payload.data[0]["contract_type"], insuran);
				if (maxAssuredValue) {
					setInsuranceMaxVal(maxAssuredValue)
				} else {
					setInsuranceMaxVal("5000000")
				}

        if (data.payload.data[0]["bank_account"]) {
          const bankAccountId = String(data.payload.data[0]["bank_account"])
          const bankAccount = bankAccountsFilteredByBillingMethod.find((acc: any) => acc.id === Number(bankAccountId))
          setSelectedBankAccount(bankAccountId)
          if (!bankAccount || !bankAccount.hasSplitPaymentIntegrated) {
            handleAutomaticPayment(0)
          }
        }

				firstParportionRent(null, null);
			} else {
				setEmptycontract(true)
			}
		}
	};
	/*--------------Close the contract modal and reset the contract form-----------*/

	const newContractClose = () => {
		setMultipleTenant([
			{
				value: "",
				label: "",
				main: 0,
				percentage: "",
			},
		]);
		getContractsData(id);
		setIssValue(0);
		setIsActive(1);
		setExpTermination(true);
		setAdres(true);
		setBusType(true);
		setDuDate(true);
		setGuarant(0);
		setIsssContract(true);
		setGurnty(true);
		setBankFees(true);
		setRenterFees(true);
		setInterest(true);
		setGuarType(0);
		setPay(0);
		dispatch(contractClose(false));
		setDouble(false);
		setHide1(true);
		setHide2(false);
		setHide3(false);
		setHide4(false);
		setHide5(false);
		setHide6(false);
		setHide7(false);
		setHide8(false);
		reset();
	};

	const [newContractShow2, setnewContractShow2] = useState(false);
	const newContractClose2btn = () => {
		setnewContractShow2(false);
	};

	/*----------------All step of contract form-------------*/
	const validationTenants = () => {
		let vaid = true;
		multipleTenant.forEach((item, indexs) => {
			let name = `datatenants.tenants.${indexs}.name`;
			let percent = `datatenants.tenants.${indexs}.percentage_on_receipt`;
			let tenantSelect: any = document.querySelector(
				'.tenants [name="' + name + '"]'
			);
			let percentSelect: any = document.querySelector(
				'.percentage [name="' + percent + '"]'
			);
			if (!item.value && multipleTenant.length == 1 && tenantSelect != null) {
				tenantSelect.style.border = "1px solid red";
				vaid = false;
			} else if (
				!item.value &&
				multipleTenant.length > 1 &&
				tenantSelect != null
			) {
				tenantSelect.style.border = "1px solid red";
				vaid = false;
			} else if (
				!item.percentage &&
				multipleTenant.length > 1 &&
				percentSelect != null
			) {
				percentSelect.style.border = "1px solid red";
				vaid = false;
			} else {
				if (percentSelect != null) {
					percentSelect.style.border = "0px solid red";
				}
				if (tenantSelect != null) {
					tenantSelect.style.border = "0px solid red";
				}
			}
		});
		return vaid;
	};
	const step1 = () => {
		setHide1(true);
		setHide2(false);
		setHide3(false);
		setHide4(false);
		setHide5(false);
		setHide6(false);
		setHide7(false);
		setHide8(false);
	};
	const step2 = () => {
		setHide1(false);
		setHide2(true);
		setHide3(false);
		setHide4(false);
		setHide5(false);
		setHide6(false);
		setHide7(false);
		setHide8(false);
	};
	const step3 = () => {
		let vaild = validationTenants();
		if (vaild) {
			setHide3(true);
			setHide2(false);
		}
		setHide1(false);
		setHide4(false);
		setHide5(false);
		setHide6(false);
		setHide7(false);
		setHide8(false);
	};
	const step4 = () => {
		setHide1(false);
		setHide2(false);
		setHide3(false);
		setHide4(true);
		setHide5(false);
		setHide6(false);
		setHide7(false);
		setHide8(false);
	};
	const step5 = () => {
		setHide1(false);
		setHide2(false);
		setHide3(false);
		setHide4(false);
		setHide5(true);
		setHide6(false);
		setHide7(false);
		setHide8(false);
	};
	const step6 = () => {
		setHide1(false);
		setHide2(false);
		setHide3(false);
		setHide4(false);
		setHide5(false);
		setHide6(true);
		setHide7(false);
		setHide8(false);
	};
	const step7 = () => {
		setHide1(false);
		setHide2(false);
		setHide3(false);
		setHide4(false);
		setHide5(false);
		setHide6(false);
		setHide7(true);
		setHide8(false);
	};
	const step8 = () => {
		setHide1(false);
		setHide2(false);
		setHide3(false);
		setHide4(false);
		setHide5(false);
		setHide6(false);
		setHide7(false);
		setHide8(true);
	};

	/*--------------Form validation-------------*/

	let contractOne: any = {
		immobile: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		contract_type: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		owner: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		income_tax: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		deduct_administration: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		invoice: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		calculationDateBase: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => (contractUseImobConfig ? value !== "slct" : true)),
		start2: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		// end2: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
		// 	.test('not-select',t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		// duration: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
		// 	.test('not-select',t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		rent_value: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		// due_date:Yup.date()
		// .typeError(t('requiredMmessage.fieldRequired'))
		// .when(
		//     'start2',
		//     (start2, schema) => (moment(start2).isValid() ? schema.min(start2, t("formLabel.EnsureDueDateAfterStartDate")) : schema),
		// ),
		due_date: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		occupation: Yup.string().required(t("requiredMmessage.fieldRequired")),
		readjustment_index: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		readjustment_date: Yup.date()
			.typeError(t("requiredMmessage.fieldRequired")),
		billing_address: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		branch: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		bank_account: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		billing_method: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		exemption_termination: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		guarantee: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		guaranteeType: Yup.string().when("guarantee", {
			is: "2",
			then: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			otherwise: Yup.string().strip(), // Strip validation if 'guarantor' is not selected
		}),
	};

	if (pay == 0 && agencyOwned == false) {
		let transferValidation = {
			transfer_day: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			transfer_type: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		}
		contractOne = { ...contractOne, ...transferValidation }
	}
	if (agencyOwned === false) {
		let agencyrValidation = {
			monthlyAdmin: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			guarantee2: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		}
		contractOne = { ...contractOne, ...agencyrValidation }
	}

	if (busType == false) {
		let commercialActivity = {
			businessActivity: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),

		}
		contractOne = { ...contractOne, ...commercialActivity }
	}
	let second = {
		// tenants: Yup.string().trim()
		//     .required(t('requiredMmessage.fieldRequired')),
		invoice: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		income_tax: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		deduct_administration: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		billing_address: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
	};

	let second2 = {};
	if (!adres) {
		second2 = {
			zipcode: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			city: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			address: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			number: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			complement: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			neighborhood: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			state: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
	}
	let second3 = { ...contractOne, ...second, ...second2 };
	const contractValidation2 = Yup.object().shape(second3);
	let third = {
		guarantee: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
	};
	let third2 = {};
	if (guarant != 0) {
		if (guarant == 1) {
			third2 = {
				guarantor: Yup.array()
					.of(
						Yup.object().shape({
							data: Yup.string()
								.trim()
								.nullable()
								.required(t("requiredMmessage.fieldRequired"))
								.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
						})
					)
					.nullable()
					.required("Array is required"),
			};
		}
		if (guarant == 2) {
			let third21 = {
				guaranteeType: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// in: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// until: Yup.string()
				// 	.trim()
				// 	.required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				value: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			};
			let third22 = {};
			if (guarType == 1) {
				third22 = {
					responsible: Yup.string()
						.trim()
						.required(t("requiredMmessage.fieldRequired"))
						.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				};
			}
			if (guarType == 4 || guarType == 6) {
				third22 = {
					description: Yup.string()
						.trim()
						.required(t("requiredMmessage.fieldRequired"))
						.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
					identifier: Yup.string()
						.trim()
						.required(t("requiredMmessage.fieldRequired"))
						.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				};
			}
			third2 = { ...third21, ...third22 };
		}
		if (guarant == 3) {
			third2 = {
				insurer: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// policy: Yup.string().trim()
				//     .required(t('requiredMmessage.fieldRequired')),
				numberInstallments: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// in: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// until: Yup.string()
				// 	.trim()
				// 	.required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				value: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			};
		}
		if (guarant == 4) {
			third2 = {
				bank: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				policy: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// in: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// until: Yup.string()
				// 	.trim()
				// 	.required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				value: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			};
		}
		if (guarant == 5) {
			third2 = {
				surety: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			};
		}
		if (guarant == 6) {
			third2 = {
				backgroundType: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				applicationNumber: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// in: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				// until: Yup.string()
				// 	.trim()
				// 	.required(t("requiredMmessage.fieldRequired"))
				// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
				value: Yup.string()
					.trim()
					.required(t("requiredMmessage.fieldRequired"))
					.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			};
		}
	}
	let third3 = { ...third, ...third2 };

	// contractOne = { ...contractOne, ...third3 }

	const contractValidation3 = Yup.object().shape(third3);
	let fourth = {};
	if (!isssContract) {
		let fourthnew = {
			start: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			end: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			assuredValue: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test("not-select", t("requiredMmessage.fieldRequired"), (value) => value !== "slct").
				test(
					"minValue",
					t("requiredMmessage.fieldNotValid", { value: "150.000,00" }),
					(value: any) => {
						if (!hideFireInsuranceCard) {
							let numericValue = convertToNumber(String(value));
							if (String(numericValue) >= "150000") {
								return true
							} else {
								return false
							}
						} else {
							return true
						}
					}
				).test(
					"maxValue",
					t("requiredMmessage.fieldNotValid", { value: insuranceMaxVal }),
					(value: any) => {
						if (!hideFireInsuranceCard) {
							let numericValue = convertToNumber(String(value));
							if (String(numericValue) > String(insuranceMaxVal)) {
								return false
							} else {
								return true
							}
						} else {
							return true
						}
					}
				),
			// .test(
			// 	"maxValue",
			// 	t("requiredMmessage.fieldNotValid", { value: insuranceMaxVal }),
			// 	(value: any) => {
			// 		if (!hideFireInsuranceCard) {
			// 			if (value >= String(insuranceMaxVal)) {
			// 				return false
			// 			} else {
			// 				return true
			// 			}
			// 		} else {
			// 			return true
			// 		}
			// 	}
			// ),
			// prizeAmount: Yup.string()
			// 	.trim()
			// 	.required(t("requiredMmessage.fieldRequired"))
			// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			// installmentValue: Yup.string()
			// 	.trim()
			// 	.required(t("requiredMmessage.fieldRequired"))
			// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			// numberInstallments2: Yup.string()
			// 	.trim()
			// 	.required(t("requiredMmessage.fieldRequired"))
			// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			insuranceCompany: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			// policy2: Yup.string()
			// 	.trim()
			// 	.required(t("requiredMmessage.fieldRequired"))
			// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			// contractedPlan: Yup.string()
			// 	.trim()
			// 	.required(t("requiredMmessage.fieldRequired"))
			// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			// responsible4: Yup.string()
			// 	.trim()
			// 	.required(t("requiredMmessage.fieldRequired"))
			// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
		fourth = { ...contractOne, ...fourthnew }
	}
	const contractValidation4 = Yup.object().shape(fourth);

	let fifth = {
		start2: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		// end2: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
		// 	.test('not-select',t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		// duration: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
		// 	.test('not-select',t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		rent_value: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		due_date: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		occupation: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		readjustment_index: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		// readjustment_date: Yup.string()
		// 	.trim()
		// 	.required(t("requiredMmessage.fieldRequired")),

		// graceDays: Yup.string()
		// 	.trim()
		// 	.required(t("requiredMmessage.fieldRequired"))
		// 	.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		branch: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		bank_account: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		billing_method: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		delivery_method: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		exemption_termination: Yup.string()
			.trim()
			.required(t("requiredMmessage.fieldRequired"))
			.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),

	};
	let fifth2 = {};
	if (!duDate) {
		// fifth2 = {
		//     firstRent: Yup.string().trim()
		//         .required(t('requiredMmessage.fieldRequired')),
		//     firstComplement: Yup.string().trim()
		//         .required(t('requiredMmessage.fieldRequired')),
		// }
	}
	let fifth3 = {};
	if (!expTermination) {
		fifth3 = {
			fineExemption: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
	}
	let fifth4 = {};
	if (!interest) {
		fifth4 = {
			fees: Yup.string().trim().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			trafficTicket: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			punctuality: Yup.string()
				.trim()
				.required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
	}
	let fifth5 = { ...fifth, ...fifth2, ...fifth3, ...fifth4 };
	const contractValidation5 = Yup.object().shape(fifth5);

	let six: any = {};

	if (agencyOwned == false) {
		let six2: any = {
			monthlyAdmin: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			guarantee2: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
		six = { ...six2 }
	}
	if (pay == 0 && agencyOwned == false) {
		let transferValidation = {
			transfer_day: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
			transfer_type: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		}
		six = { ...six, ...transferValidation }
	}
	let six2 = {};
	if (!gurnty) {
		six2 = {
			months: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
	}
	let six3 = {};
	if (!bankFees) {
		six3 = {
			price: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
	}
	let six33 = {};
	if (!renterFees) {
		six33 = {
			renterPrice: Yup.string().required(t("requiredMmessage.fieldRequired"))
				.test('not-select', t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
		};
	}
	let six4 = { ...six, ...six2, ...six3, ...six33 };
	const contractValidation6 = Yup.object().shape(six4);

	const contractValidation7 = Yup.object().shape({
		checklistTemplate: Yup.string().required(
			t("requiredMmessage.fieldRequired")
		),
	});
	contractOne = { ...contractOne, ...six4 };
	const contractValidation1 = Yup.object().shape(contractOne);
	let frm = {
		resolver: yupResolver(contractValidation1),
	};
	openErrorbox();

	if (!adres) {
		let vaild = validationTenants();
		frm = {
			resolver: yupResolver(contractValidation2),
		};
		openErrorbox();
	}
	if (
		guarant == 2 ||
		guarant == 3 ||
		guarant == 4 ||
		guarant == 5 ||
		guarant == 6
	) {
		frm = {
			resolver: yupResolver(contractValidation3),
		};
		openErrorbox();
	}
	if (!isssContract) {
		frm = {
			resolver: yupResolver(contractValidation4),
		};
		openErrorbox();
	}
	if (!expTermination) {
		frm = {
			resolver: yupResolver(contractValidation5),
		};
		openErrorbox();
	}
	if (hide6) {
		frm = {
			resolver: yupResolver(contractValidation6),
		};
		openErrorbox();
	}
	if (hide7) {
		frm = {
			resolver: yupResolver(contractValidation7),
		};
		openErrorbox();
	}

	const handleAccordionToggle = (eventKey) => {
		setActiveKey("");
	};

	/*---------------Add/Edit the contract form---------------*/

	const {
		register,
		reset,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<contractForm>(frm);

	const formSubmit = async (data: any, e: any) => {
		setloader_on(true);
		let contractId = data.id;
		let newTenantData = data?.datatenants?.tenants;
		setShowPendingCharge(false);
		e.preventDefault();
		data.occupation = moment(data.occupation).format("YYYY-MM-DD");
		let AllGaruntor: any = multipleGarunter;
		data["guarantor"] = AllGaruntor;
		data["readjustment_date"] = data.readjustment_date ? data.readjustment_date : null;
		data["contracttype"] = "updateContract";
		let priceValue: any = `${data.price}`;
		let priceValue_Val: any = convertToNumber(priceValue);
		let RenterpriceValue: any = `${data.renterPrice}`;
		let Renterprice_Val: any = convertToNumber(RenterpriceValue);
		data["price"] = priceValue_Val ? Number(priceValue_Val) : ''
		data["renterPrice"] = Renterprice_Val ? Renterprice_Val : ''
		let contractedPlanVal: any = `${data.contractedPlan}`;
		// let contractedPlan: any = convertToNumber(contractedPlanVal);
		let contractedPlan: any = contractedPlanVal;
		let assuredValue: any = `${data.assuredValue}`;
		let assuredVal: any = convertToNumber(assuredValue);
		data["id"] = id;
		let rent_value1: any = `${data.rent_value}`;
		let rent_val: any = convertToNumber(rent_value1);
		let firstRent1: any = `${data.firstRent}`
		let rentVal: any = convertToNumber(firstRent1);
		data["rent_value"] = rent_val
		data["firstRent"] = rentVal
		if (data?.fixed_amount) {
			let chnageAdm = amdVal ? amdVal : data.monthlyAdmin
			let AdmValue: any = convertToNumber(chnageAdm);
			data["monthlyAdmin"] = AdmValue
		} else {
			let cleanNumber: any = (data && data?.monthlyAdmin) ? data["monthlyAdmin"] : 0;
			if (data && data?.monthlyAdmin && data?.monthlyAdmin.includes(",")) {
				cleanNumber = data?.monthlyAdmin.replace(',', '.');
			}
			let num = parseFloat(cleanNumber);
			let formattedNumber = num.toFixed(2);
			data["monthlyAdmin"] = formattedNumber
		}

		let Val1: any = convertToNumber(valueCon);
		data["contractedPlan"] = contractedPlan
		data["assuredValue"] = assuredVal
		data["lastInstallment"] = premiumAmount
		data["value"] = Val1;
		data["isContract"] = (data["isContract"] == 1 || data["isContract"] == true) ? 1 : null

		var vaid = validationTenants();
		let addClassName: any = document.querySelector("#tenantinput");
		
		Object.entries(percentValues).forEach(([key, value]) => {
			data[key] = value === '' ? null : convertToNumber(value)
		})

		let endDateValitdation = 1;
		if (data.end2 && data.start2) {
			if (moment(data.start2).format("YYYY-MM") >= moment(data.end2).format("YYYY-MM")) {
				endDateValitdation = 0
			}
		}

		if (!vaid) {
			setloader_on(false);
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: "Tenants are required.",
				confirmButtonText: "OK",
				timer: 3000,
			});
			addClassName["style"]["color"] = "red";
			addClassName.querySelector("button")["style"]["color"] = "red";
		} else if (data.start2 > data.occupation) {
			setloader_on(false);
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("formLabel.EnsureMoveDateAfterStartDate"),
				confirmButtonText: "OK",
				timer: 3000
			})
		}
		else if (endDateValitdation == 0) {
			setloader_on(false);
			Swal.fire({
				title: t("Sweetalert.Error"),
				text: t("formLabel.EnsureMoveDateAfterStartDate"),
				confirmButtonText: "OK",
				timer: 2000
			})
		}
		else {
			addClassName["style"]["color"] = "black";
			addClassName.querySelector("button")["style"]["color"] = "black";
			if (multipleTenant.length == 1) {
				multipleTenant[0]["main"] = 1;
				multipleTenant[0]["percentage"] = "100";
				setValue(`datatenants.tenants.0.main_tenant`, true);
				setValue(`datatenants.tenants.0.percentage_on_receipt`, 100);
			}
			data.datatenants.tenants = multipleTenant;
			if (saveAmmendmantAction === true) {
				let madrid = { oldData };
				let barca = { data };
				let final_new_data = {
					start2: data.start2,
					end2: data.end2,
					owner: data.owner,
					tenant: data.datatenants.tenants[0].label,
					rent_value: data.rent_value,
					due_date: data.due_date,
					assuredValue: data.assuredValue,
					prizeAmount: data.prizeAmount,
					installmentValue: data.installmentValue,
					numberInstallments2: data.numberInstallments2,
					businessActivity: data.businessActivity,
					invoice: data.invoice,
					includeInDIMOB: data.includeInDIMOB,
					calculationDateBase: data.includeInDIMOB ? data.calculationDateBase : null,
					income_tax: data.income_tax,
					deduct_administration: data.deduct_administration,
					billing_address: data.billing_address,
					zipcode: data.zipcode,
					address: data.address,
					insuranceCompany: data.insuranceCompany,
					start: data.start,
					end: data.end,
					guarantee: data.guarantee,
					guarantee2: data.guarantee2,
					contract_type: data.contract_type,
				};

				let final_old_data = {
					start2: oldData.start2,
					end2: oldData.end2,
					owner: oldData.ownerResult[0].ownerName,
					tenant: oldData.datatenants.tenates[0].name,
					rent_value: oldData.rent_value2,
					due_date: oldData.due_date,
					assuredValue: oldData.assuredValue,
					prizeAmount: oldData.prizeAmount,
					installmentValue: oldData.installmentValue,
					numberInstallments2: oldData.numberInstallments2,
					businessActivity: oldData.businessActivity,
					invoice: oldData.invoice,
					includeInDIMOB: oldData.includeInDIMOB,
					calculationDateBase: oldData.includeInDIMOB ? oldData.calculationDateBase : null,
					income_tax: oldData.income_tax,
					deduct_administration: oldData.deduct_administration,
					billing_address: oldData.billing_address,
					zipcode: oldData.zipcode,
					address: oldData.address,
					insuranceCompany: oldData.insuranceCompany,
					start: oldData.start,
					end: oldData.end,
					guarantee: oldData.guarantee,
					guarantee2: oldData.guarantee2,
					contract_type: oldData.contract_type,
				};

				final_new_data["id"] = id;
				final_old_data["id"] = id;

				let response: any = await customerService.createAmendmant(
					final_new_data,
					final_old_data,
					selectedDate
				);

				if (
					selectedCheckbox == "keepexisting" ||
					selectedCheckbox == "chargePending"
				) {
					let save_response = await dispatch(insertContract(data));
					setloader_on(false)
				}
				if (
					typeof response.data !== "undefined" &&
					(response.data.resCode === 201 ||
						response.data.resCode === 200 ||
						response.data.resCode === 202)
				) {
					setAgencyOwned(false);
					setValue("retain_transfer", "");
					let addContractCronData = await dispatch(addContractCronRecords(contractId));

					let insert_id = response.data.data;
					let data = {
						readjustId: insert_id,
						contract_id: id,
					};
					setloader_on(false);
					// navigate(`/folder_detail/${id}`, { state: data });
					Swal.fire({
						// icon: 'success',"success",
						title: t("Sweetalert.Success"),
						text: i18n.language == "en" ? "Contract saved successfully" : "Contrato salvo com sucesso",
						confirmButtonText: "OK",
						timer: 2000,
					})
					navigate(`/folder_detail`, { state: data });
					//navigate(`/contract_details/${id}`);

				} else {
					setloader_on(false);
					Swal.fire({
						title: t("Sweetalert.Error"),
						text: response.data.message,
						// icon: 'success',"error",
						confirmButtonText: "OK",
						timer: 2000,
					});
				}
				setSaveAmmendmantAction(false);
			} else {
				const totalPercentage = multipleTenant?.reduce((acc, tenant) => acc + parseFloat(tenant.percentage), 0);
				if (totalPercentage < 100) {
					Swal.fire({
						// icon: 'success', 
						title: t("Sweetalert.Success"),
						text: t("Sweetalert.lesstotalpercentage"),
						confirmButtonText: "OK",
						timer: 2000
					})
					setDouble(false)
					setloader_on(false);
				} else if (totalPercentage > 100) {
					Swal.fire({
						// icon: 'success', 
						title: t("Sweetalert.Success"),
						text: t("Sweetalert.moretotalpercentage"),
						confirmButtonText: "OK",
						timer: 2000
					})
					setDouble(false)
					setloader_on(false);
				} else {
					let response: any = await dispatch(insertContract(data));
					if (
						typeof response.payload.data !== "undefined" &&
						(response.payload.resCode === 201 ||
							response.payload.resCode === 200 ||
							response.payload.resCode === 202)
					) {
						try {
							const result: any = newTenantData?.map((tenant) => ({
								ID_PESSOA_PES: Number(tenant?.name) || 0, // Use a default value if name is not a number
							})) || []; // Default to an empty array if no tenants exist
							const response = await dispatch(UpdateTenantInp({
								tenantData: result,
								contractId: contractId
							}));
						} catch (error) {
							setloader_on(false);
						}
						setloader_on(false);
						setDouble(false);
						setAgencyOwned(false);
						setValue("retain_transfer", "");
						// let addCronResponse = await dispatch(addContractCronRecords(contractId));
						// setnewContractShow2(true)
						// contractRegisteredModalShow();
						contractRegisteredModalShow();
						// Swal.fire({
						// 	// icon: 'success',"success",
						// 	title: t("Sweetalert.Success"),
						// 	text: i18n.language == "en" ? "Contract saved successfully" : "Contrato salvo com sucesso",
						// 	confirmButtonText: "OK",
						// 	timer: 2000,
						// }).then((res) => {
						// 	if (res.isConfirmed) {
						// 		newContractClose();
						// 		navigate(`/contract_details/${id}`);
						// 	}
						// });

						let contData = {
							contract: "",
							start: 0,
							end: 20,
						};
						dispatch(contractsData(contData));
					} else {
						setloader_on(false);
						setDouble(false);
						Swal.fire({
							title: t("Sweetalert.Error"),
							text: t("Sweetalert.somethingWent"),
							// icon: 'success',"error",
							confirmButtonText: "OK",
							timer: 2000,
						});
					}
				}
			}
		}

	};

	/*------------Set the owner fied value----------*/

	const propertyOwner = (evt, type) => {
		setAgencyOwned(false);
		setValue("retain_transfer", "");
		let tick = handleGreenCheckLogic(evt, type);
		setTick0(tick);
		let id = evt.target.value;
		let result = propertyData.filter(item => Number(item.id) === Number(id));

		if (result.length > 0 && result[0]?.ownerResult?.[0]?.ownerId) {

			let _owner: any = ownerData.filter(
				(item) => Number(item?.id) === Number(result[0].ownerResult[0].ownerId)
			);

			if (_owner.length > 0) {
				setPropertyOwnerDocument(_owner[0].cnpjcpf);

				if (selectedTenantId)
					shouldAutoSelectDIMOBForContract(Number(selectedTenantId));
			} else {
				console.error("Owner not found in ownerData.");
			}
		} else {
			console.error("Property or owner information not found.");
		}
		if (result.length > 0) {
			if (result[0].guarantee2 == "2") {
				setValue('guarantee2', "0")
			} else {
				setValue('guarantee2', result[0].guarantee2)
			}
			setValue('transfer_type', result[0].transfer_type)
			setValue('transfer_day', result[0].transfer_day)
			if (result[0].guarantee2 == "1") {
				setValue('months', result[0].months)
				setGurnty(false)
			} else {
				setGurnty(true)
			}

			setValue('rent_value', formatNumber(String(result[0].rent_amount)));
			setValue("monthlyAdmin", result[0].monthly_admin_rate ? converter(result[0]?.monthly_admin_rate) : converter(`${10}`));
			setAdmVal(result[0].monthly_admin_rate ? result[0]?.monthly_admin_rate : 10)
			if (result.length > 0 && result[0].ownerResult.length > 0) {
				setSelectedOwner(result[0].ownerResult[0]?.ownerName);
        const ownersId = result[0]?.ownerResult?.map((owner: any) => owner.ownerId)
        if (ownersId && ownersId.length > 0) {
          const isOwnerAllowedToSplit = ownerData
              .filter(owner => ownersId.includes(owner.id))
              .every((owner: any) => owner.hasSplitPaymentIntegrated)
          setOwnersHasSplitPayment(isOwnerAllowedToSplit)
          if (!isOwnerAllowedToSplit) {
            handleAutomaticPayment(0)
          }
        } else {
          handleAutomaticPayment(0)
        }
				setPropertyTypes1(result[0]?.property_type)
				setSelectedProperty(`${result[0].propertyType ? result[0].propertyType + ' : ' : ''}  ${result[0].address} - ${result[0].number}, CEP: ${result[0].zipcode}`)
				setValue('owner', result[0].ownerResult[0]?.ownerName);

				if (typeof result[0].ownerResult[0]?.agencyOwned !== "undefined" && result[0].ownerResult[0]?.agencyOwned === 1) {
					setAgencyOwned(true);
					setValue("retain_transfer", "1");
					setBankFees(true);
					setValue("bank_fee", false);
					setValue("price", "");
					setValue('transfer_type', "")
					setValue('transfer_day', "");
					setValue('guarantee2', "0");
					setValue('monthlyAdmin', "");
					setValue('fixed_amount', false)
					setValue('manual_payment', true);
					setValue('automatic_payment', false);
				}

				/*
				setAarinConfig(result[0].ownerResult[0].AARIN_ID ? 1 : 0);
				if (result[0].ownerResult[0].AARIN_ID) {
					setDouble(false);
				}
				else {
					setDouble(true);
				}
				*/
			}
		}
	}

	/*------------Set the billing address fied value----------*/

	const billingAddress = (evt, type) => {
		const tick = handleGreenCheckLogic(evt, type);
		setTick1(tick);
		setAdres(true);
		setValue("zipcode", "");
		setValue("address", "");
		setValue("number", "");
		setValue("complement", "");
		setValue("neighborhood", "");
		setValue("city", "");
		setValue("state", "");
		if (evt.target.value == 3) {
			setAdres(false);
		}
	};

	/*------------Set the business activity fied value----------*/
	const [maxiumVal, setMaxiumVal] = useState<any>('')

	const contractType = (evt, type) => {
		const tick = handleGreenCheckLogic(evt, type);
		setTick0(tick);
		setBusType(true);
		setValue("businessActivity", "");
		if (evt.target.value == 3) {
			setBusType(false);
			setMaxiumVal("2500000")
		} else {
			setMaxiumVal("5000000")
		}
		setcontractType(evt.target.value)
	};

	/*------------Set the guarantee fied value----------*/

	const [cosignerHide, setcosignerHide] = useState<any>(false);
	const guarantee = (evt, type) => {
		const tick = handleGreenCheckLogic(evt, type);
		if (evt.target.value == "1") {
			setcosignerHide(true);
		} else {
			setcosignerHide(false);
		}

		setTick3(tick);
		// setValue('guarantor', '');
		setValue("guaranteeType", "");
		setValue("in", "");
		setValue("until", "");
		setValue("value", "");
		setValue("observation2", "");
		setValue("responsible", "");
		setValue("insurer", "");
		setValue("policy", "");
		setValue("numberInstallments", "");
		setValue("bank", "");
		setValue("policy", "");
		setValue("surety", "");
		setValue("backgroundType", "");
		setValue("applicationNumber", "");
		setGuarant(Number(evt.target.value));
	};

	/*------------Set the due date fied value----------*/

	const dueDate = (evt) => {
		let currentDy = getValues("start2") ? moment(getValues("start2")).endOf("month").format("DD") : moment(dateBrazil).endOf("month").format("DD");
		if (evt.target.value) {
			if (Number(evt.target.value) <= Number(currentDy)) {
				clearErrors("due_date");
				setDouble(false);
				firstParportionRent(evt.target.value, "dueDate");
			}
			else {
				setValue("firstRent", "");
				setValue("firstComplement", "");
				setError("due_date", {
					type: "manual",
					message: t("dueDaysErr"),
				});
				setDouble(true);
			}
		}
	};

	const leaseMonth = (evt) => {
		firstParportionRent(evt.target.checked, "leaseMonth");
	};

	const firstParportionRent = (vl, type) => {
		let rentValue: any = getValues("rent_value");
		let leaseMonth: any = getValues("lease_period");
		let dueDate: any = getValues("due_date");
		let start2 = settingOn ? getValues("occupation") : getValues("start2");
		if (type == "rentValue") {
			rentValue = vl;
			clearErrors("rent_value");
		}
		if (type == "leaseMonth") {
			leaseMonth = vl;
		}
		if (type == "dueDate") {
			dueDate = vl;
			clearErrors("due_date");
		}
		if (type == "start2Date") {
			start2 = vl;
		}
		if (type == "occupation") {
			start2 = vl;
		}
		if (type == "dueDate" || type == "start2Date") {
			let comapareStartDate = getValues("start2")
			let adate = new Date(comapareStartDate); // Create a Date object from the given date string
			if (adate.getDate() == dueDate) {
				setValue("fistRent", false)
				setFirstRent(false)
			} else {
				setValue("fistRent", true)
				setFirstRent(true)
			}
		}
		let currtDays = moment(start2).format("DD");
		let totlDays = moment(start2, "YYYY-MM-DD").daysInMonth()
		let diffDays = Number(totlDays) - Number(currtDays);
		let due_date: any = moment(moment(start2).endOf('month').format("YYYY-MM-DD")).add(Number(dueDate), "days").format("YYYY-MM-DD")
		if (Number(currtDays) < Number(dueDate) && Number(totlDays) >= Number(dueDate)) {
			let dt = Number(dueDate) - Number(currtDays)
			due_date = moment(start2).add(Number(dt), "days").format("YYYY-MM-DD")
		}
		else {
			let dt = Number(dueDate) + Number(diffDays)
			due_date = moment(start2).add(Number(dt), "days").format("YYYY-MM-DD")
		}

		if (leaseMonth || leaseMonth == true) {
			if (due_date && rentValue && start2) {
				setDuDate(false);
				let countDays = moment(start2, "YYYY-MM-DD").daysInMonth();
				let currentDay = moment(start2).format("DD");
				if (rentValue) {
					rentValue = convertToNumber(rentValue)
				}
				let oneDayRent = Number(rentValue) / countDays;
				let totalDays: any = Number(countDays) - Number(currentDay);
				totalDays = totalDays + 1;
				setValue("dueDays", totalDays);
				let firstMonthRent: any = oneDayRent * totalDays;
				if (firstMonthRent % 1 !== 0) {
					firstMonthRent = (firstMonthRent);
				}

				let dec =
					"Ref" +
					moment(start2).format("DD/MM/YYYY") +
					" a " +
					moment(getValues("start2")).endOf("month").format("DD/MM/YYYY") +
					"(" +
					totalDays +
					")";
				let amt2 = converter(String(firstMonthRent))
				setValue("firstRent", amt2);
				setValue("firstComplement", dec);
				setDuDate(false);
			} else {
				setDuDate(true);
				setValue("secondRent", false);
				setValue("firstRent", "");
				setValue("firstComplement", "");
			}
		} else {
			if (due_date && rentValue && start2 && due_date > start2) {
				setDuDate(false);
				if (rentValue) {
					rentValue = convertToNumber(rentValue)
				}
				let firstDate = new Date(start2); //new Date('2019-11-12');
				let secondDate = new Date(due_date); //new Date('2019-11-20');
				let milliSFirst = firstDate.getTime();
				let milliSSecond = secondDate.getTime();
				due_date = Number((milliSSecond - milliSFirst) / (1000 * 3600 * 24));
				let countDays = moment(start2, "YYYY-MM-DD").daysInMonth();
				let currentDay = moment(start2).format("DD");
				let oneDayRent = Number(rentValue) / countDays;
				let totalDays = Number(due_date) + Number(currentDay) - Number(currentDay);
				let firstMonthRent: any = oneDayRent * totalDays;
				if (firstMonthRent % 1 !== 0) {
					firstMonthRent = (firstMonthRent);
				}
				let amt2 = converter(String(firstMonthRent))
				setValue("dueDays", due_date);
				setValue("firstRent", amt2);
				let dec =
					"Ref" +
					moment(start2).format("DD/MM/YYYY") +
					" a " +
					moment(getValues("start2"))
						.add(Number(totalDays) - 1, "days")
						.format("DD/MM/YYYY") +
					"(" +
					totalDays +
					")";
				setValue("firstComplement", dec);
			} else {
				setDuDate(true);
				setValue("secondRent", false);
				setValue("firstRent", "");
				setValue("firstComplement", "");
			}
		}
	};

	/*------------Set the exemption termination fied value----------*/

	const exemptionTermination = (evt, type: string) => {
		const tick = handleGreenCheckLogic(evt, type);
		setTick2(tick);
		setExpTermination(true);
		setValue("fineExemption", "");
		if (evt.target.value == 2) {
			setExpTermination(false);
		}
	};

	/*------------Set the contract fied value----------*/

	const issContract = (evt) => {
		// const tick = handleGreenCheckLogic(evt, type);
		// setTick4(tick);
		setIssValue(Number(evt.target.value));
		setValue("start", "");
		setValue("end", "");
		setValue("assuredValue", "");
		setValue("prizeAmount", "");
		setValue("installmentValue", "");
		setValue("numberInstallments2", "");
		setValue("insuranceCompany", "");
		setValue("policy2", "");
		setValue("contractedPlan", "");
		setValue("responsible4", "");
		setValue("observation4", "");
		if (evt.target.checked == true) {
			setIsssContract(true);
		} else {
			setIsssContract(false);
		}
	};

	/*------------Set the month fied value----------*/

	const [guaranty, setGuaranty] = useState(false);
	const guarantee2 = (evt) => {
		setGurnty(true);
		setValue("months", "");
		if (evt.target.value == 1) {
			setGurnty(false);
		}
		if (evt.target.value == 1 || evt.target.value == -1) {
			setValue("transfer_type", "1");
			setGuaranty(true);
		} else {
			setGuaranty(false);
		}
	};

	/*------------Set the price fied value----------*/

	const bankFee = (evt) => {
		setBankFees(true);
		setValue("price", "");
		if (evt.target.checked === true) {
			setBankFees(false);
		}
	};

	const rentersFee = (evt) => {
		const isChecked = evt.target.checked;

		if (isChecked) {
			setRenterFees(false); // Show the input field

			if (
				ContractSettingData &&
				ContractSettingData.length > 0 &&
				ContractSettingData[0].bank_fee_amount
			) {
				setValue("renterPrice", ContractSettingData[0].bank_fee_amount); // Set renterPrice if exists
			} else {
				setValue("renterPrice", ""); // Keep it blank if no valid bank_fee_amount
			}
		} else {
			setRenterFees(true); // Hide the input field
		}
	};

	const issActive = (evt) => {
		setIsActive(Number(evt.target.value));
	};

  function handleAutomaticPayment(value: number) {
    if (value === 1) {
      setValue("automatic_payment", true)
      setValue("manual_payment", false)
    } else {
      setValue("automatic_payment", false)
      setValue("manual_payment", true)
    }
    setPay(value);
    if (value === 1) {
      setValue('guarantee2', "0");
      setValue('retain_transfer', "");
    }
  }

	const automaticPayment = (evt) => {
		const value = Number(evt.target.value)
		setPay(value);
		if (value === 1) {
			setValue('guarantee2', "0");
			setValue('retain_transfer', "");
		}
	};

	/*------------Set the differebt interest fied value----------*/

	const [extraAndDiscount, setExtraAndDiscount] = useState({});
	const differentInterest = (evt) => {
		setInterest(true);
		if (evt.target.checked === true) {
			setInterest(false);
		}
		if (evt.target.checked) {
			(async () => {
				let response = await incomeService.getDiscountData(gettoken.userId);
				setExtraAndDiscount(response.data);
			})();
		} else {
			setExtraAndDiscount({});
		}
	};
	useEffect(() => {
		(async () => {
			let response = await incomeService.getDiscountData(gettoken.userId);
			if (typeof response.data !== "undefined" && response.data) {
				setExtraAndDiscount(response.data);
			}
		})();
	}, [contractDatail])

	const [feePercantage, setFeePercentage] = useState(false)
	useEffect(() => {
		setFeePercentage(false);
		setTimeout(() => {
			const percentFields: string[] = ['fees', 'trafficTicket', 'punctuality'];
			if (extraAndDiscount?.["use_percentage"] == true) {
				setFeePercentage(true);
			}
			
			const obj = {
				fees: '',
				punctuality: '',
				trafficTicket: '',
			}

			if (contractDatail.length) {
				obj.fees = contractDatail[0].fees !== undefined && contractDatail[0].fees !== null ? converter(contractDatail[0].fees) : undefined;
				obj.punctuality = contractDatail[0].punctuality !== undefined && contractDatail[0].punctuality !== null ? converter(contractDatail[0].punctuality) : undefined;
				obj.trafficTicket = contractDatail[0].trafficTicket !== undefined && contractDatail[0].trafficTicket !== null ? converter(contractDatail[0].trafficTicket) : undefined;
			} else if (Object.keys(extraAndDiscount).length > 0) {
				obj.fees = extraAndDiscount["monthly_interest"] !== undefined && extraAndDiscount["monthly_interest"] !== null ? converter(extraAndDiscount["monthly_interest"]) : undefined;
				obj.punctuality = extraAndDiscount["discount_rate"] !== undefined && extraAndDiscount["discount_rate"] !== null ? converter(extraAndDiscount["discount_rate"]) : undefined;
				obj.trafficTicket = extraAndDiscount["fine_rate"] !== undefined && extraAndDiscount["fine_rate"] !== null ? converter(extraAndDiscount["fine_rate"]) : undefined;
			}

			const fields: string[] = Object.keys(obj);
			fields.forEach((field: any) => setValue(field, obj[field]));
			percentFields.forEach((field: any) => {
			  setPercentValues(values => ({ ...values, [field]: obj[field] || '' }));
			})
		}, 100);
	}, [extraAndDiscount, contractDatail]);
	  
	const handlePercentage = (e: any, key: any) => {
		let { value } = e.target;
		value = value.replace(/[^\d,]/g, '');

		if (value !== "") {
			const [intg, dec] = value.split(',')
			value = convertToNumber(intg)?.toString() + (dec !== undefined ? `,${dec}` : '')
			if (convertToNumber(value) > 100) value = '100,00'

			setValue(key, formatNumber(value))
			setPercentValues({ ...percentValues, [key]: value })
		} else {
			setValue(key, "")
			setPercentValues({ ...percentValues, [key]: '' })
		}
	}
	
	const handleMoney = (e: any, key: any) => {
		let { value } = e.target;
		value = value.replace(/[^\d,]/g, '');

		if (value !== "") {
			const [intg, dec] = value.split(',')
			value = convertToNumber(intg)?.toString() + (dec !== undefined ? `,${dec}` : '')

			setValue(key, formatNumber(value))
			setPercentValues({ ...percentValues, [key]: value })
		} else {
			setValue(key, "")
			setPercentValues({ ...percentValues, [key]: '' })
		}
	}

	useEffect(() => {
		if (ShowPendingCharge) {
			setSelectedCheckbox("keepexisting")
		}
	}, [ShowPendingCharge])

	/*------------Set the guarantee type fied value----------*/

	const guaranteeType = (evt, type) => {
		const tick = handleGreenCheckLogic(evt, type);
		setTick3(tick);
		setValue("responsible", "");
		setValue("description", "");
		setValue("identifier", "");
		setGuarType(Number(evt.target.value));
	};
	const getLocation = async (e, inputType) => {
		let str = e.target.value
		let newStr = str.replace('-', ''); // Removes the hyphen
		// Input processing and formatting
		let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
		let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
		// Error validation
		// Retrieve address based on zipcode using customerService
		let resp = await customerService.getAddressBasedOnZipcode(
			`${inputType}/${newStr}`
		);
		let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
		let zipCodeCheck: any = document.querySelector(".zipCodeCheck");
		if (newValue !== "" || newValue !== null || newValue !== undefined) {
			if (
				resp?.["data"]["status"] == "success" ||
				resp?.["data"]["type"] == "Success"
			) {
				let res = resp["data"]["data"];
				querySel.style.border = "0px solid red";
				zipCodeCheck.removeAttribute("disabled");
				// Set the city value if available in the response
				if (res[0]["city"]) {
					setValue("city", res[0]["city"]);
				}
				if (res[0]["neigbohood"]) {
					setValue("neighborhood", res[0]["neigbohood"]);
				}
				if (res[0]["state"]) {
					setValue("state", res[0]["state"]);
				}
				if (res[0]["street"] || res[0]["streetType"]) {
					let data =
						(res[0]["streetType"] ? res[0]["streetType"] + " " : "") +
						(res[0]["street"] ?? "");
					setValue("address", data);
				}
				clearErrors("zipcode");
				clearErrors("city");
				clearErrors("neighborhood");
				clearErrors("address");
				clearErrors("state");
			} else {
				setError("zipcode", {
					type: "manual",
					message: t("invalidzipcode"),
				});
				querySel.style.border = "2px solid red";
				setValue("city", "");
				setValue("neighborhood", "");
				setValue("state", "");
				setValue("address", "");
				zipCodeCheck.setAttribute("disabled", "");
			}
		} else {
			querySel.style.border = "0px solid red";
			zipCodeCheck.removeAttribute("disabled");
		}
	};

	const handlefilterBillmethod = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick2(tick);
		let dataAccount = accountData.data.filter(
			(item, index) => Number(e.target.value) == Number(item.id)
		);
		setBillMethodOnOff(dataAccount[0]);
	};

	const handleChangeEndDate = (e, type, type1) => {
		const tick = handleGreenCheckLogic(e, type1);
		setTick2(tick);
		if (type == "start2Date") {
			setValue("occupation", e.target.value)
			setStartDate(e.target.value)
			setchangedate(true)
			// setValue("readjustment_date", e.target.value)
			setValue("due_date", "");
			setValue("firstRent", "");
		}
		if (type == "start2Date" && !settingOn) {
			setStartDate(e.target.value)
			// setValue("readjustment_date", e.target.value)
			setValue("occupation", e.target.value)
			setValue("due_date", "");
			if (e.target.value < getValues("due_date")) {
				firstParportionRent(e.target.value, "start2Date");

			} else {
				setDuDate(true);
				setValue("firstRent", "");
				setValue("firstComplement", "");
			}
			let inputDate = e.target.value;
			const parts = inputDate.split("-");
			const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
			setSelectedStartDate(formattedDate);
			// setValue("readjustment_date", moment(e.target.value).format("YYYY-MM-DD"))
		} else if (type == "occupation" && settingOn) {
			setValue("due_date", "");
			if (e.target.value < getValues("due_date")) {
				firstParportionRent(e.target.value, "occupation");
			} else {
				setDuDate(true);
				setValue("firstRent", "");
				setValue("firstComplement", "");
			}
			let inputDate = e.target.value;
			const parts = inputDate.split("-");
			const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
			setSelectedStartDate(formattedDate);
			// setValue("readjustment_date", moment(inputDate).format("YYYY-MM-DD"));
		}

		let currentDate: any = "";
		if (type == "duration") {
			if (getValues("start2") != "") {
				if (typeof Number(e.target.value) == "number" && e.target.value != "" && String(e.target.value) !== "0") {
					let Value: any = e.target.value;
					currentDate = moment(getValues("start2"))
						.add(Value, "months")
						.format("YYYY-MM-DD");
				} else {
					setValue("end2", "");
					setSelectedEndDate(`${t("Indefinite")}`);
					// setValue("readjustment_date", "");
				}
			} else {
				setValue("end2", "");
				setSelectedEndDate(`${t("Indefinite")}`);
				// setValue("readjustment_date", "");
			}
		} else if (type == "start2Date") {
			if (getValues("duration") != "") {
				currentDate = moment(e.target.value)
					.add(getValues("duration"), "months")
					.format("YYYY-MM-DD");
			} else {
				setValue("end2", "");
				setSelectedEndDate(`${t("Indefinite")}`);
				// setValue("readjustment_date", "");
			}
		}

		// if (type === "duration" && e.target.value < 1) {
		// 	setValue("duration", "1");
		// }

		if (currentDate !== "") {
			let futureMonthnew = new Date(currentDate);
			let yyyy: any = futureMonthnew.getFullYear();
			let mm: any = futureMonthnew.getMonth() + 1;
			let dd: any = futureMonthnew.getDate();
			if (dd < 10) dd = "0" + dd;
			if (mm < 10) mm = "0" + mm;
			let formattedToday = yyyy + "-" + mm + "-" + dd;
			let inputDate = formattedToday;
			const parts = inputDate.split("-");
			const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
			setSelectedEndDate(formattedDate);
			if (getValues("start2") < formattedToday) {
				setValue("end2", formattedToday);
			}
		} else {
			setSelectedEndDate(`${t("Indefinite")}`);
		}
	};

	const HandleReadjustmenDate = (e: any) => {
		// setValue("start2", e.target.value)
		// let getValueduration = getValues("duration");
		// setValue("end2", moment(e.target.value).add(getValueduration, "months").format("YYYY-MM-DD"))
	}
	const [adminRate, setAdminRate] = useState(true);
	const fixed_amountChange = (e: any, type: string, type1: string) => {
		let target: any = e.target;
		let inputValue: any = e.target.value;
		let Element = target.parentElement.parentElement.parentElement;
		if (type === "input") {
			let knowStatus = Element.querySelector("[name=fixed_amount]").checked;

			if (!knowStatus) {
				let filteredValue = inputValue.replace(/[^\d,.]/g, '');

				// Replace all commas with dots to maintain numeric value
				const normalizedValue = filteredValue.replace(/,/g, '.');
				// Check if the input is a valid number with up to two decimal places
				let isValid = /^\d*\.?\d{0,2}$/.test(normalizedValue);
				if (isValid) {
					// Ensure maximum value is 100.0
					if (parseFloat(normalizedValue) > 100) {
						filteredValue = '100,0';
					}
					inputValue = filteredValue
				}
			} else {
				// Handle cases where the checkbox is checked
				let value = inputValue.replace(/[^\d,]/g, '');
				if (value) {
					inputValue = formatNumber(value); // Assuming `formatNumber` is a function defined elsewhere
				}
			}
			setValue("monthlyAdmin", inputValue); // Assuming `setValue` is a state updater function
			setAdmVal(inputValue);

			const tick = handleGreenCheckLogic(e, type1);
			setTick4(tick);
		} else {
			let currentVal = target.checked;
			if (!currentVal) {
				let getElement = Element.parentElement;
				let getValue = getElement.querySelector("[name=monthlyAdmin]").value;
				if (parseFloat(getValue) > 100) {
					getElement.querySelector("[name=monthlyAdmin]").value = "100";
				}
			}
			setAdminRate(!currentVal);

		}
	};

	const onCompleteGuarantor = async (guarantor) => {
		const data = await getAllGuarantor({
			guarantor: "",
			person: "",
			status: "",
			start: 0,
			end: 20,
		});

		setTimeout(() => {
			const newGuarator = data.find((e) => e.cnpj === guarantor.cnpj)
			const fields = getValues("guarantor")

			if (fields[granterIndex || fields.length - 1]?.data === 'slct') {
				handleGuarantorInputChangeList({ target: { value: `${newGuarator.id}` } }, granterIndex || fields.length - 1, 'guaranteeinput')
			}
		}, 1000)
	}

	const shouldAutoSelectDIMOBForContract = (tenantId: number) => {

		setTenantId(String(tenantId));
		const selectedTenant: any = tenantsData.find((item: any) => Number(item?.id) === Number(tenantId));

		if (!selectedTenant) {
			console.error(`Tenant with ID ${tenantId} not found.`);
			return false;
		}

		const ownerDocument = propertyOwnerDocument.replace(/[.\-/]/g, '');
		const tenantDocument = selectedTenant.cnpj.replace(/[.\-/]/g, '');

		const ownerIsCompany = ownerDocument.length !== 11;
		const tenantIsCompany = tenantDocument.length !== 11;

		/* Task KLC 132 - If the owner is an individual and the tenant is a legal entity,  
		the DIMOB option must be selected.*/
		if (!ownerIsCompany && tenantIsCompany) {
			setContractUseImobConfig(true);
			setValue('includeInDIMOB', "1");
			setValue("calculationDateBase", "2");
		}
	};

	const handleTenantChange = (e, index, type) => {

		shouldAutoSelectDIMOBForContract(e?.target?.value);
		const tick = handleGreenCheckLogic(e, type);
		setTick1(tick);

		if (type == "tenent") {
			if (e.target.value) {
				let pickData =
					tenantsData &&
					tenantsData.length > 0 &&
					tenantsData.filter(
						(item) => Number(item.id) === Number(e.target.value)
					);
				multipleTenant[`${index}`]["value"] = `${pickData[0]["id"]}`;
				multipleTenant[`${index}`]["label"] = `${pickData[0]["name"]}`;
				setSelectedTenant(pickData[0]["name"]);
			}
		}

		if (type == "main") {
			document.querySelectorAll(".singleMaintenant input").forEach((item) => {
				item["checked"] = false;
			});
			multipleTenant.forEach((item, indexs) => {
				multipleTenant[`${indexs}`]["main"] = 0;
				setValue(`datatenants.tenants.${indexs}.main_tenant`, false);
			});
			e.target.checked = true;
			setValue(`datatenants.tenants.${index}.main_tenant`, true);
			multipleTenant[`${index}`]["main"] = e.target.checked ? `${1}` : `${0}`;
		}

		if (type == "percentage") {
			multipleTenant[`${index}`]["percentage"] = e?.target?.value;

			setValue(
				`datatenants.tenants.${index}.percentage_on_receipt`,
				multipleTenant[`${index}`]["percentage"]
			);

			let totalPercent: number = 0;
			multipleTenant.forEach((item, indexss) => {
				if (item.percentage !== "") {
					totalPercent += Number(item.percentage);
					if (indexss !== multipleTenant.length - 1) {
						let exceed = totalPercent + Number(e?.target?.value);
						multipleTenant[`${index}`]["percentage"] = "";
						// setValue(
						// 	`datatenants.tenants.${index}.percentage_on_receipt`,
						// 	"0"
						// );
						multipleTenant[`${index}`]["percentage"] = e?.target?.value;
						// setValue(
						// 	`datatenants.tenants.${index}.percentage_on_receipt`,
						// 	`${num}`
						// );
					}
				}
			});
			if (totalPercent > 100) {
				// multipleTenant[`${index}`]["percentage"] = 0;
				// setValue(`datatenants.tenants.${index}.percentage_on_receipt`, "0");
			}
		}

		let newData = multipleTenant;
		let allLabels = "";

		// Loop through the array and concatenate the 'label' values
		for (let i = 0; i < newData.length; i++) {
			if (newData.length > 1) {
				allLabels += newData[i].label + ",  ";
			} else {
				allLabels = newData[i].label;
			}
		}
		setSelectedTenant(allLabels);
		setMultipleTenant(newData);
	};

	const addAnother = (e) => {
		setMultipleTenant([
			...multipleTenant,
			{
				value: "",
				label: "",
				main: 0,
				percentage: "",
			},
		]);
	};

	const addAnotherGurantor = (e) => {
		setMultipleGarunter([
			...multipleGarunter,
			{
				value: "",
			},
		]);
		setIsInputFocused([
			...isInputFocused,
			{ focus: false }
		])
	};

	const handleDelete = (e, index) => {
		multipleTenant.splice(index, 1);
		setMultipleTenant([...multipleTenant]);
		reset({
			datatenants: {
				tenants: [{ value: "", main: "", percentage: "" }],
			},
		});
		multipleTenant.forEach((item, index) => {
			// setValue(`datatenants.tenants.${index}.id`, item.id);
			setValue(`datatenants.tenants.${index}.name`, item.value);
			setValue(`datatenants.tenants.${index}.main_tenant`, item.main);
			setValue(
				`datatenants.tenants.${index}.percentage_on_receipt`,
				item.percentage
			);
		});
	};

	const handleDeleteGarunter = (e, index) => {
		multipleGarunter.splice(index, 1);
		setMultipleGarunter([...multipleGarunter]);

		multipleGarunter.forEach((item, index) => {
			// setValue(`datatenants.tenants.${index}.id`, item.id);
			setValue(`guarantor.${index}.data`, item.value);
		});
		isInputFocused[index]["focus"] = true
	};

	const isNumber = (evt) => {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	};

	const handleOpen = (e, cmd) => {
		if (isActive === 3 || contractDatail[0].is_active == "3") {
			let disableElement: any =
				e.target.parentElement.parentElement.parentElement.parentElement
					.parentElement.parentElement;
			let submit: any = disableElement.querySelector(".submitContractForm");
			submit.click();
		} else {
			setShowPendingCharge(true);
		}
	};
	const handleClose = () => setShowPendingCharge(false);

	const handleAction = async (e, cmd) => {
		setShowPendingCharge(false);
		let disableElement: any =
			e.target.parentElement.parentElement.parentElement.parentElement
				.parentElement.parentElement;
		if (cmd == "update") {
			let submit: any = disableElement.querySelector(".submitContractForm");
			submit.click();
		}
	};

	const handleNavigate = async (e, cmd) => {
		await setSaveAmmendmantAction(true);
		setShowPendingCharge(false);
		let disableElement: any =
			e.target.parentElement.parentElement.parentElement.parentElement
				.parentElement.parentElement;
		if (cmd == "update") {
			let submit: any = disableElement.querySelector(".submitContractForm");
			submit.click();
		}
	};

	const handleChangeRentValue = (e, type) => {
		const rentValue = parseFloat(e.target.value.replace(',', '.'));
		if (rentValue > 0) {
			const tick = handleGreenCheckLogic(e, type);
			setTick2(tick);
			// Remove all characters except digits and commas
			let rent_amount_value = e.target.value.replace(/[^\d,]/g, '');
			rent_amount_value = formatNumber(rent_amount_value);
			setValue("rent_value", rent_amount_value);
			setSelectedRentValue(rent_amount_value);
			firstParportionRent(rent_amount_value, 'rentValue');
			clearErrors("rent_value");
		} else {
			setError("rent_value", {
				type: "manual",
				message: "Rent value should be more than 0.",
			});
		}
		// Get the select element
		const selectElement: any = document.getElementById("insuranceCompany");
		const selectedDataName = selectElement.options[selectElement.selectedIndex].getAttribute("data-name");
		if (selectedDataName == "Tokio Marine") {
			const normalAmount = convertToNumber(e.target.value)
			let insuran = normalAmount * 100;
			const maxAssuredValue = getMaxAssuredValue(contractTypes, insuran);
			if (maxAssuredValue) {
				setValue("assuredValue", converter(String(maxAssuredValue)));
			} else {
				setValue("assuredValue", "0,00");
			}
		}
	};

	/*-These functions are defined for green tick animation-*/

	const handlePropertyInputChange = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick0(tick);
	};

	const handleTenantInputChange = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick1(tick);
	};

	const handleDuration = (e, type) => {
		e.preventDefault();
		let contStartDate = getValues("start2");
		let contEndDate = e.target.value;
		if (contStartDate && contStartDate <= contEndDate) {
			let duration: any = moment(new Date(contEndDate)).diff(new Date(contStartDate), 'months', true);
			duration = Math.round(duration);
			if (duration >= 1 && moment(contStartDate).format("YYYY-MM") < moment(contEndDate).format("YYYY-MM")) {
				setValue("duration", duration)
			}
			else {
				setValue("duration", "");
			}
		}
	}
	const handleContractDetailInputChange = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick2(tick);
	};

	const handleGuarantorInputChange = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick3(tick);
	};

	const handleGuarantorInputChangeList = (e: any, index, type) => {
		let pickData = guarantorData && guarantorData.length > 0 && guarantorData.filter(item => Number(item.id) == Number(e.target.value));
		const tick = handleGreenCheckLogic(e, type);
		let value: any = e.target.value;
		setValue(`guarantor.${index}.data`, pickData[0]["name"]);
		multipleGarunter[index]["value"] = pickData[0]["id"];;
		// multipleGarunter[]
		setTick3(tick);
		setDisplay(false)
	};

	const handleFireInsuranceInputChange = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick4(tick);
	};

	const handleRentTransferInputChange = (e, type, type1) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick5(tick);
		if (type1 == "assuredValue") {
			const selectElement: any = document?.getElementById("insuranceCompany");
			const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("data-name");
			let { value } = e.target;
			value = value.replace(/[^\d,]/g, "");
			if (selectedDataName == "Tokio Marine") {
				if (Number(value) < 150000 || Number(value?.split(",")[0]) > maxiumVal) {
					setError("assuredValue", {
						type: "manual",
						 message: `Manter o limite mínimo de R$ 150.000,00 e limite máximo de R$ ${converter(String(insuranceMaxVal ? insuranceMaxVal :maxiumVal))}`
					}
					)
				} else {
					if (selectedDataName == "Tokio Marine") {
						handleInsuranceQuote()
					}
					clearErrors("assuredValue");
				}
			}
			 
			setValue("assuredValue", formatNumber(value))
		}
		if (type1 == "insuranceCompany") {
			setValue("insuranceCompany", e.target.value)
			const selectedOption = e.target.selectedOptions[0];
			const dataName = selectedOption.getAttribute('data-name');
			if (dataName == "Tokio Marine") {
				handleInsuranceQuote()
				setIsLoading(true)
				setInsuranceStatus(true)
			} else {
				setInsurance(false)
				setInsuranceStatus(false)
				setIsLoading(false)
				setValue("start", "");
				setValue("end", "");
				setValue("installmentValue", "");
				setValue("prizeAmount", "");
				setValue("contractedPlan", "");
				setValue('numberInstallments2', "")
				setValue("assuredValue", "")
			}
		}
	};

	const handleContractSituationInputChange = (e, type) => {
		const tick = handleGreenCheckLogic(e, type);
		setTick6(tick);
	};

	/*--------End Green Tick Functions ---------------*/
	const handleCheckboxChange = (name) => {
		setSelectedCheckbox(name);
		/*
									keepexisting
							keepexistingrecord
							chargePending
			*/
	};

	const handleDateChange = (event) => {
		setSelectedDate(event.target.value);
	};
	const handlePrice = (e, type) => {
		let { value } = e.target;
		value = value.replace(/[^\d,]/g, "");
		if (type == "price" && value) {
			setValue("price", formatNumber(value));
		} else if (type == "renterPrice" && value) {
			setValue("renterPrice", formatNumber(value));
		}
	};
	const [clickedIndex, setClickedIndex] = useState(-1);
	const [alreadyClickedIndex, setAlreadyClickedIndex] = useState('');
	const [insuredAmount, setInsuredAmount] = useState("");
	const [premiumAmount, setPremiumAmount] = useState("");
	const [installmentAmount, setInstallmentAmount] = useState("");
	const [installmentNumber, setInstallmentNumber] = useState("");

	const handleInsurance = async (index, record) => {
		const rentValueCalculation: any = getValues("assuredValue");
		const normalAmount = convertToNumber(rentValueCalculation)
		const contractType = getValues("contract_type");
		setValue("contractedPlan", record.ShortName);
		setAlreadyClickedIndex(record.ShortName)
		setClickedIndex(index);
		setInsuredAmount(record.Total); // Set the clicked index
		// setValue("assuredValue", record.Total);
		// let insuran = normalAmount * 100;
		let insuran = normalAmount
		const maxAssuredValue = getMaxAssuredValue(contractType, insuran);
		if (maxAssuredValue) {
			setValue("assuredValue", converter(String(maxAssuredValue)));
		} else {
			setValue("assuredValue", "0,00");
		}
		const rentValueCalculations: any = getValues("assuredValue");
		const normalAmounts = convertToNumber(rentValueCalculations)

		setPremiumAmount(record.LastInstallment);
		setValue("prizeAmount", record.Total);
		setInstallmentAmount(record.FirstInstallment);
		setValue("installmentValue", record.FirstInstallment);
		setInstallmentNumber(record.Installments);
		setValue("numberInstallments2", record.Installments);
		const recordId = record.RequestId;
		if (recordId != "null") {
			const contractStartDateString = getValues("start2"); // Assuming contractStartDateString is a string representing the date
			const contractStartDate = new Date(contractStartDateString); // Convert string to Date object
			const contractEndDate = new Date(contractStartDate); // Create a copy of contractStartDate

			// Add 12 months to the contract end date
			contractEndDate.setMonth(contractEndDate.getMonth() + 12);

			const contractStartDateISOString = contractStartDate.toISOString().split('T')[0];
			const contractEndDateString = contractEndDate.toISOString().split('T')[0]
			const property_id: any = getValues("immobile");
			const propertyOwner = getValues("owner");


			// const fetchPropertyDetails = await dispatch(
			// 	getProperty({ id: 359, contract: "contract", start: 0, end: 1 })
			// );

			let fetchPropertyDetails = await dispatch(registerData(property_id))
			const propertyDetails = fetchPropertyDetails?.payload?.data[0];
			const ownerId = propertyDetails?.owner[0]?.value;

			// const ownerId = propertyDetails.ownerResult[0].ownerId;
			const fetchOwnerDetails = await dispatch(getOwner());
			const ownerDetails = fetchOwnerDetails?.payload?.data?.filter(
				(owner: { id: any }) => owner.id === ownerId
			);
			const fetchTenantsDetails = await dispatch(getTenants());
			const tenantIdString = await getValues("datatenants.tenants.0.name");
			const tenantId = parseInt(tenantIdString);
			const tenantDetails = fetchTenantsDetails.payload.data.filter(
				(tenant: { id: any }) => {

					return tenant.id === tenantId;
				}
			);
			let tenantType = ""
			let ownerType = ""
			if (tenantDetails.length) {
				const cleanedStr = tenantDetails[0]?.cnpj?.replace(/[.\-/]/g, '');
				tenantType = cleanedStr.length == 11 ? "Fisica" : "jurídica";
			}
			if (ownerDetails.length) {
				const cleanedStr = ownerDetails[0]?.cnpj?.replace(/[.\-/]/g, '');
				ownerType = cleanedStr.length == 11 ? "Fisica" : "jurídica";
			}
			const insuranceResponse = await fetch(
				`${config.INSURANCEAPI}/legacy/properties/contract?apiKey=${config.INSURANCEAPIKEY}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `${insuranceToken}`,
					},
					body: JSON.stringify({
						property: {
							state: propertyDetails.state ? propertyDetails.state : "",
							city: propertyDetails.city ? propertyDetails.city :"",
							address_complement:  propertyDetails?.complement && propertyDetails?.complement.trim() !== "" ? propertyDetails?.complement?.slice(0, 15) : "",
							address: propertyDetails.address ? propertyDetails.address : "",
							address_number: propertyDetails.number ? propertyDetails.number : "",
							address_neighbourhood: propertyDetails.neighborhood ? propertyDetails.neighborhood : "",
							cep: propertyDetails.zipcode ? propertyDetails.zipcode: "",
						},
						contract_id: id,
						policy_duration: {
							effective_date_end: contractEndDateString,
							effective_date_start: contractStartDateISOString,
						},
						agency_license: `${localStorage?.getItem('agencyId')}`,
						CONTRATACAO_AUTOMATICA: 0,
						tenant: {
							phone: tenantDetails[0].mobile,
							birth_date: moment(tenantDetails[0].birth).format('YYYY-MM-DD'),
							name: tenantDetails[0]?.name ? tenantDetails[0]?.name : "",
							gender: "M",
							email: tenantDetails[0]?.email ? tenantDetails[0]?.email : "",
							foreign: "",
							document_data: {
								emission_date: contractEndDateString,
								document_nature: "",
								document_number: tenantDetails[0].rg ? tenantDetails[0].rg : "",
							},
							politically_exposed: "",
							document_number: tenantDetails[0]?.cnpj ? tenantDetails[0]?.cnpj : "",
							person_type: tenantType
						},
						property_owner: {
							birth_date: ownerDetails.length ? ownerDetails[0]?.birth : "",
							email: ownerDetails.length ? ownerDetails[0]?.email : "",
							document_number: ownerDetails.length ? ownerDetails[0]?.cnpj : "",
							name: ownerDetails.length ? ownerDetails[0]?.name : "",
							phone: ownerDetails.length ? ownerDetails[0]?.mobile : "",
							person_type: ownerType
						},
						user_email: `${localStorage?.getItem('userEmailId')}`,
						insurance: {
							property_id: contractTypes == "3" ? "2" : String(contractTypes),
							renewal: "",
							main_coverage: Number(normalAmounts),
							zip_code: propertyDetails.zipcode,
							property_type: propertyDetails.propertyType,
							property_group_id: bussnesVal ? bussnesVal : "",
						},
						quote_id: recordId
					}),
				}
			);
			const insuranceData = await insuranceResponse.json();
			setHideFireInsuranceCard(false);
			if (insuranceData.insurance.id != null) {
				setValue("policy2", insuranceData.insurance.insurance_policy_number);
				setValue("policy2_id", insuranceData.insurance.id)
				// let contractStart: any = getValues("start2")
				// const today = new Date(dateBrazil);
				// const comparisonDate: any = new Date(contractStart);
				// if (moment(today).format("YYYY-MM-DD") < moment(comparisonDate).format("YYYY-MM-DD")) {
				// 	setValue("start", contractStartDateISOString);
				// 	setValue("end", contractEndDateString);
				// } else {

				// 	setValue("start", moment(today).format("YYYY-MM-DD"));
				// 	today.setFullYear(today.getFullYear() + 1);
				// 	let formattedDate = today.toISOString().split('T')[0];
				// 	setValue("end", formattedDate);
				// }

			}
		}
	};

	const handleValue = (e: any) => {
		let { value } = e.target;
		value = value.replace(/[^\d,]/g, '');
		if (value !== "") {
			setValue("value", formatNumber(value))
			setValueCon(value)
		}
	}
	const firstRent_Val = (e: any) => {
		let { value } = e.target;
		value = value.replace(/[^\d,]/g, '');
		if (value !== "") {
			setValue("firstRent", formatNumber(value))
		}
	}
	const assuredValueInput = (e, type1) => {
		let { value } = e.target;
		value = value.replace(/[^\d,]/g, "");
		if (type1 == "assuredValue") {
			setValue("assuredValue", formatNumber(value))
		}
		//  else {
		// 	setValue("contractedPlan", formatNumber(value))
		// }
	}

	const getAllGuarantor = async (guarantorh) => {
		let guarantor = {
			guarantor: "",
			person: "",
			status: "",
			start: 0,
			end: 200,
		}
		let guarantorData = { guarantor: guarantor.guarantor, personStatus: guarantor.person, activeContract: guarantor.status, start: guarantor.start, end: guarantor.end }
		const data = await customerService.getAllGuarantor(guarantorData).then(res => {
			dispatch(getGuarantor());

			return res?.data?.data
		}).catch(err => {
			console.log("err", err)
		});

		return data
	}
	const handleFirstRent = (e) => {
		let check = e.target.checked
		if (check) {
			setFirstRent(true)
		} else {
			setFirstRent(false)
		}
	}

	useEffect(() => {
		if (contractForm.includeInDIMOB) {
			setContractUseImobConfig(true);
		}
	}, [contractForm.includeInDIMOB, setValue]);

	const handleImobOption = (e) => {
		const check = e.target.checked
		if (check) {
			setContractUseImobConfig(true);
			setValue("calculationDateBase", "2");
		} else {
			setContractUseImobConfig(false);
			setValue("calculationDateBase", "slct");
		}
	}

	const handleCalculationDateBaseValue = (e: any) => {

		if (e.target.value === "1") {
			Swal.fire({
				title: t("Sweetalert.Warning"),
				html: `
				  <p style="text-align: justify; line-height: 1.5;">${t("Sweetalert.federalRevenueGuidelinesMessage")}</p>
				  <div style="margin-top: 30px; display: flex; align-items: center; gap: 10px;">
					<input type="checkbox" class="form-check-input" id="confirmCheckbox" />
					<label for="confirmCheckbox" class="form-check-label" style="font-size: 16px; line-height: 1.5; font-weight: 600;">
					  ${t("Sweetalert.federalRevenueTermsAcceptance")}
					</label>
				  </div>
				`,
				icon: 'warning',
				showCancelButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				confirmButtonText: "OK",
				preConfirm: () => {
					const checkbox = document.getElementById('confirmCheckbox') as HTMLInputElement;
					if (!checkbox || !checkbox.checked) {
						Swal.showValidationMessage(`${t("Sweetalert.federalRevenueTermsRequired")}`);
						return false;
					}
					return true;
				},
			});
		}
	};

	const handleOccaptionDate = async (e) => {
		let check = e.target.checked
		if (check) {
			setSettingOn(true)
			let value = getValues("occupation")
			firstParportionRent(value, "occupation");
		} else {
			setSettingOn(false)
			let value: any = getValues("start2")
			firstParportionRent(value, "start2Date");
		}
	}
	const [searchResults, setSearchResults] = useState<any>();

	const [display, setDisplay] = useState(false);
	useEffect(() => {
		if (!display) {
			isInputFocused.forEach((item: any, index: any) => {
				isInputFocused[index]["focus"] = false
			})
			setIsInputFocused(isInputFocused);
		}
	}, [display])
	const handleInputFocus = (indexs: any) => {
		isInputFocused.forEach((item: any, index: any) => {
			if (indexs == index) {
				isInputFocused[index]["focus"] = true
			} else {
				isInputFocused[index]["focus"] = false
			}
		})
		setDisplay(true);

		setIsInputFocused(isInputFocused);
		if (guarantorData && guarantorData.length > 0) {
			setSearchResults(guarantorData)
		}
	};

	const handlefilterSearch = async (e) => {
		let name = e.target.value;
		if (name.trim() !== "" && e.target.value !== "") {
			const lowerSearchTerm = name.toLowerCase();
			const results = guarantorData.filter((itemName: any) => {
				const ownerName = (itemName["name"]).toLowerCase();
				let list = ownerName.includes(lowerSearchTerm);
				return list
			});
			setSearchResults(results)
		} else {
			if (guarantorData && guarantorData.length > 0) {
				setSearchResults(guarantorData)
			}
		}
	}

	const filterBankAccountByBillingMethod = async (billing_method: string) => {
    	const response = await dispatch(getAllAccounts({ start: 0, end: 100 }))

		let neBks: any = [];
		if (response.payload.data && response.payload.data.length > 0) {
        response.payload.data.forEach((element: any) => {
          if ((billing_method === "0" || billing_method === "12") && element.isActiveinFormofReceipt == 0) {
            if (element.accountType != 2 && element.accountType != null) {
              neBks.push(element);
            }
          } else {
            // CASH BANK
            if ((element.accountType == 2 || element.accountType == 13 || element.accountType == 14) && element.accountType != null && element.isActiveinFormofReceipt == 0) {
              neBks.push(element);
            }
          }
			});
		}

    	return neBks
	}
	useEffect(() => {
		if (branchList.length) {
			console.log('Branch List ....');
		}
	}, [branchList]);

	useEffect(() => {
		if (accountData && accountData.data && accountData.data.length) {
			let neBks: any = [];
			let billMethd: any = parseInt(getValues('billing_method'));
			accountData.data.forEach(element => {
				if ((billMethd == 0 || billMethd == 12 || billMethd == 28) && element.isActiveinFormofReceipt == 0) {
					if (element.accountType != 2 && element.accountType != null) {
						neBks.push(element);
					}
				} else {
					// CASH BANK
					if ((element.accountType == 2 || element.accountType == 13 || element.accountType == 14) && element.accountType != null && element.isActiveinFormofReceipt == 0) {
						neBks.push(element);
					}
				}
			});
			setBillTypebanks(neBks);
		}
	}, [accountData])

	const handlefilterBillType = (e) => {
		let billMethd: any = '';
		if (e.billing_method) {
			billMethd = e.billing_method
		} else {
			billMethd = e.target.value
		}
		let neBks: any = [];
		if (accountData && accountData.data && accountData.data.length) {
			accountData.data.forEach(element => {
				if ((billMethd == 0 || billMethd == 12 || billMethd == 28) && element.isActiveinFormofReceipt == 0) {
					if (element.accountType != 2 && element.accountType != null) {
						neBks.push(element);
					}
				} else {
					// CASH BANK
					if ((element.accountType == 2 || element.accountType == 13 || element.accountType == 14) && element.accountType != null && element.isActiveinFormofReceipt == 0) {
						neBks.push(element);
					}
				}
			});
			setBillTypebanks(neBks);
		}
	}

	useEffect(() => {
		const result = checkIdExists(Number(contractTypes), Number(propertyTypes1));
		const filteredInsuranceData = insuranceData?.filter(item => {
			if (contractTypes == 1 && result || (contractTypes == 3 && result && bussnesVal !== undefined)) {
				return true; // Show all data
			} else {
				return item.name !== "Tokio Marine"; // Exclude "Tokio Marine"
			}
		});
		setFilteredData(filteredInsuranceData)
		// Set the insurance company value only if there is data to set
		if (filteredInsuranceData && filteredInsuranceData.length > 0) {
			setTimeout(() => {
				const selectElement: any = document?.getElementById("insuranceCompany");
				const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("data-name");
				if (selectedDataName == "Tokio Marine") {
					const selectElement: any = document?.getElementById("insuranceCompany");
					const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("value");
					setInsuranceStatus(true)
					setValue("insuranceCompany", selectedDataName)
				} else {
					setInsuranceStatus(false)
				}
			}, 1000)

		}
	}, [contractTypes, propertyTypes1, bussnesVal])
	const businessActivity = (e) => {
		if (e.target.value == "1") {
			setBussnessVal(1)

		} else if (e.target.value == "2") {
			setBussnessVal(4)
		} else {
			setBussnessVal(5)
		}
	}
	const tooltip = (
		<Tooltip id="tooltip">
			Manter o limite mínimo de R$ 150.000,00 e limite máximo de R$ ${converter(String(insuranceMaxVal ? insuranceMaxVal :maxiumVal))}`
		</Tooltip>
	  );
    const isAllowedToSelectSplitPayment = useMemo(() => {
      const bankAccount = BillTypebanks?.find((acc: any) => acc.id === Number(selectedBankAccount))
      if (!bankAccount) {
        return false
      }

      const isSplitReceivingMethod = selectedReceivingMethod === "0"
      const isMainBranchSelected = selectedBranch === "1"

      return ownersHasSplitPayment &&
        bankAccount.hasSplitPaymentIntegrated &&
        isSplitReceivingMethod &&
        isMainBranchSelected
    }, [ownersHasSplitPayment, selectedBankAccount, BillTypebanks, selectedReceivingMethod, selectedBranch])

	const { bankAccounts } = useBankAccounts();
	const filteredBankAccounts = useMemo(() => {
		if (selectedFormOfPayment !== "Boleto - Conta Homologada")
			return BillTypebanks;

		return BillTypebanks.filter((bank_account) =>
			bankAccounts.find(
				(account) => account.id === bank_account.id && account.isHomologated
			)
		);
	}, [BillTypebanks, bankAccounts, selectedFormOfPayment]);

	if (Emptycontract) {
		return <EmptyReason />
	} else {
		return (
			<section className="content-wrapper">
				{/* <!-- Breadcrumb section start from here --> */}
				<div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
					<div className="w-100">
						<Row className="gx-2 justify-content-between align-items-center">
							<Col
								xs={{ order: 12 }}
								xl={{ span: 2, order: 0 }}
								className="mb-xl-0 mb-3"
							>
								<div className="d-flex align-items-center justify-content-start">
									<Link to={"/contracts"}>
										<img
											src="../assets/images/icons/arrow-left.svg"
											className="h-14"
											alt="Arrow left"
										/>
									</Link>
									<div className="w-100 overflow-hidden ms-2 ms-xl-3">
										<h1 className="mb-0 h4 lh-base text-truncate">
											{t("button.edit")} {t("contract")}
										</h1>
									</div>
								</div>
							</Col>
							<Col
								xs={{ order: 1 }}
								xl={{ span: 7, order: 3 }}
								className="text-xl-end mb-xl-0 mb-2"
							>
								<ul className="list-inline mb-0">
									{/* <li className="list-inline-item me-3 me-xl-2 me-xxl-4 mb-md-0 mb-1">
                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('newContractDev.savedConnectedInternet')}</Tooltip>}>
                                            <img src="../assets/images/icons/cloud-arrow.svg" alt="cloud icon" className="h-20" />
                                        </OverlayTrigger>
                                    </li> */}
									<li className="list-inline-item me-3 me-xl-2 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary">
										<Button
											variant="link"
											className="p-0 fw-bold"
											onClick={handleAccordionToggle}
										>
											<img
												src="../assets/images/icons/arrow-double.svg"
												alt="cloud icon"
												className="h-14 me-2"
											/>{" "}
											{t("newContractDev.collapseAll")}
										</Button>
										{/* <img src="../assets/images/icons/arrow-double.svg" alt="cloud icon" className="h-14 me-2" /> {t('newContractDev.collapseAll')} */}
									</li>
								</ul>
							</Col>
						</Row>
					</div>
				</div>
				{/* <!-- /Breadcrumb section end --> */}
				{/* <!-- Main content start from here --> */}
				<main className="container-fluid px-xl-5 px-md-4 px-3 pt-4 pb-lg-6 pb-md-4 pb-3 mb-lg-6">
					<Form onSubmit={handleSubmit(formSubmit)}>
						<Row className="gy-lg-4 gy-3 mb-lg-0 mb-5">
							<input type="hidden" {...register("policy2_id")} />
							<Col lg={4}>
								<Card className="overflow-hidden position-sticky top-0">
									<Card.Header>{t("newContractDev.contractSummary")}</Card.Header>
									<Card.Body className="px-0 pb-0">
										<div className="px-lg-4 px-3 pb-lg-4 pb-3">
											<p>{t("newContractDev.contractSummaryContent")}</p>
											<Row className="g-3">
												<Col md={12}>
													<p className="fs-12 mb-1 text-secondary">
														{t("Property")}
													</p>
													<h6 className="mb-0">{selectedProperty}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("newContractDev.PropertyOwner")}
													</p>
													<h6 className="mb-0">{selectedOwner}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("newContractDev.TenantSection")}
													</p>
													<h6 className="mb-0">{selectedTenant}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("TransferPage.startDate")}
													</p>
													<h6 className="mb-0"> {selectedStartDate}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("TransferPage.endDate")}
													</p>
													<h6 className="mb-0">{selectedEndDate}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("property.rentAmount")}
													</p>
													<h6 className="mb-0">R$ {selectedRentValue}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("newContractDev.WarrantySection")}
													</p>
													<h6 className="mb-0">{selectedGuarantee}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("formOfPayment")}
													</p>
													<h6 className="mb-0">{selectedFormOfPayment}</h6>
												</Col>
												<Col md={6}>
													<p className="fs-12 mb-1 text-secondary">
														{t("FireInsurance")}
													</p>
													<h6 className="mb-0">{selectedFireInsurance}</h6>
												</Col>
											</Row>
										</div>
										{/* <div className="bg-success-10 p-lg-4 p-3">
                                        <div className="d-flex justify-content-between mb-lg-3 mb-2">
                                            <p className="mb-0">
                                                {t('newContractDev.costThisContract')} <span className="ms-2 cursor-pe" onClick={contractCostModalShow}><img src="../assets/images/icons/info-dark.svg" alt="info" className="h-20" /></span>
                                            </p>
                                            <h6 className="mb-0"><span className="fw-bold">R$ 0,00</span><span className="fs-12 text-lowercase">/{t('TransferPage.month')}</span></h6>
                                        </div>
                                        {t('newContractDev.costThisContractContent')}
                                    </div> */}
										<div className="bg-warning-5 p-lg-4 p-3">
											<div className="d-flex justify-content-between">
												<p className="mb-0">
													{t("newContractDev.remainingPlanBalance")}{" "}
													<span
														className="ms-2 cursor-pe"
														onClick={remainingBalanceModalShow}
													>
														<img
															src="../assets/images/icons/info-dark.svg"
															alt="info"
															className="h-20"
														/>
													</span>
												</p>
												<h6 className="mb-0">
													<span className="fw-bold">150</span>{" "}
													<span className="fs-12 text-lowercase">
														{t("contracts")}
													</span>
												</h6>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
							<Col lg={8} id="editContratServietsx">
								<input type="hidden" {...register("id")} />
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0" className="mb-3">
										<Accordion.Header id="propertyinput">
											<div className="d-flex align-items-center justify-content-start w-100">
												<img
													src="../assets/images/icons/home-reqular.svg"
													alt="Property icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.PropertySection")}
												{tick0 === "" || tick0 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick0 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick0 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{/* <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" /> */}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<Row className="g-3">
												<Col md={6}>
													<InputGroup>
														<FloatingLabel
															controlId="floatingInput"
															label={t("newContractDev.PropertyName")}
														>
															<Form.Select
																className={`${errors.immobile ? "is-invalid" : ""}`}
																{...register("immobile")}
																onChange={(e) =>
																	propertyOwner(e, "propertyinput")
																}
															>
																<option value={properVal}>
																	{t("dropdownItem.select")}..
																</option>
																{propertyData.map((item, index) => (
																	<option key={index} value={item.id}>
																		{" "}
																		{item.property_identifier}{" "}
																		{item.propertyType
																			? item.propertyType + " : "
																			: ""}{" "}
																		{item.address} - {item.number}, CEP:{" "}
																		{item.zipcode}
																	</option>
																	// <option key={index} value={item.id + '----' + item.ownerName + '----' + item.rent_amount + '----' + item.monthly_admin_rate + '----' + item.aarin_id } >{item.number + ' ' + item.neighborhood + ' ' + item.address + ' ' + item.complement + ' CEP: ' + item.zipcode}</option>
																))}
															</Form.Select>
															<Form.Control.Feedback type="invalid">
																{errors.immobile?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
														{
															userJson && userJson.length > 0 &&
															(userJson.indexOf(2071) !== -1) &&
															<InputGroup.Text onClick={newPropertyOpen}>
																<img
																	src={
																		process.env.PUBLIC_URL +
																		"/assets/images/icons/documents-add-light.svg"
																	}
																	alt="Documents add"
																	className="h-20 cursor-pe"
																/>
															</InputGroup.Text>
														}
													</InputGroup>
												</Col>
												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label="Proprietário *"
													>
														<Form.Control
															type="text"
															disabled
															placeholder="Proprietário"
															className={`${errors.owner ? "is-invalid" : ""}`}
															{...register("owner")}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.owner?.message}
														</Form.Control.Feedback>
                            {selectedOwner && !ownersHasSplitPayment &&
                              <span className="ps-3 fs-12 text-wraning-45 fw-medium">
                                Repasse via split não está ativo para este proprietário
                              </span>
                            }
													</FloatingLabel>
												</Col>
												<Col>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.ContractType") + " *"}
													>
														<Form.Select
															{...register("contract_type")}
															onChange={(e) => contractType(e, "propertyinput")}
															className={`${errors.contract_type ? "is-invalid" : ""
																}`}
															aria-label={t("newContractDev.ContractType")}
														>
															<option value="slct">{t("select")}</option>
															<option value="1">{t("residential")}</option>
															<option value="2">{t("nonResidential")}</option>
															<option value="3">{t("commercial")}</option>
															<option value="4">{t("industry")}</option>
															<option value="5">{t("season")}</option>
															<option value="6">{t("byOrder")}</option>
															<option value="7">{t("mixed")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.contract_type?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col style={{ display: busType ? "none" : "block" }}>
													{/* <FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.CommercialActivity")}
													>
														<Form.Control
															type="text"
															placeholder={t("newContractDev.CommercialActivity")}
															{...register("businessActivity")}
														/>
													</FloatingLabel> */}
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.CommercialActivity") + " *"}
													>
														<Form.Select
															{...register("businessActivity")}
															aria-label={t("newContractDev.CommercialActivity")}
															onChange={(e) => businessActivity(e)}
															className={`propertyinput ${errors.businessActivity ? "is-invalid" : ""
																}`}
														>
															<option value="slct">{t("select")}</option>
															<option value="1">{t("newContractDev.services")}</option>
															<option value="2">{t("newContractDev.Commerce")}</option>
															<option value="3">{t("newContractDev.Office")}</option>
															<option value="4">{t("newContractDev.consulting")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.businessActivity?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1" className="mb-3">
										<Accordion.Header id="tenantinput">
											<div className="d-flex align-items-center justify-content-start w-100">
												<img
													src="../assets/images/icons/user-light.svg"
													alt="Tenant icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.TenantSection")}
												{tick1 === "" || tick1 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick1 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick1 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{/* <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" /> */}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											{/* //Working here */}
											{multipleTenant &&
												multipleTenant.length > 0 &&
												multipleTenant.map((item, index) => (
													<React.Fragment key={index}>
														<Row className="g-3 mb-3 align-items-center">
															<Col md xs={12}>
																<InputGroup>
																	<FloatingLabel
																		className="tenants"
																		controlId="floatingInput"
																		label={t("newContractDev.TenantName")}
																	>
																		<Form.Select
																			className={`${errors.tenants ? "is-invalid" : ""
																				}`}
																			{...register(
																				`datatenants.tenants.${index}.name`
																			)}
																			onChange={(e) =>
																				handleTenantChange(e, index, "tenent")
																			}
																		>
																			<option value="slct">
																				{t("dropdownItem.select")}
																			</option>
																			{tenantsData &&
																				tenantsData.length > 0 &&
																				tenantsData.map((item, index) => (
																					<option key={index} value={item.id}>
																						{item.name}
																					</option>
																				))
																			}
																		</Form.Select>
																		<Form.Control.Feedback type="invalid">
																			{errors?.tenants?.message}
																		</Form.Control.Feedback>
																	</FloatingLabel>
																	{userJson &&
																		userJson.length > 0 &&
																		userJson.indexOf(2041) !== -1 &&
																		<InputGroup.Text onClick={newTenantsOpen}>
																			<img
																				src={
																					process.env.PUBLIC_URL +
																					"/assets/images/icons/user-edit.svg"
																				}
																				alt="user add"
																				className="h-20 cursor-pe"
																			/>
																		</InputGroup.Text>
																	}
																</InputGroup>
															</Col>
															{multipleTenant.length > 1 && (
																<>
																	<Col xs={12} md={2}>
																		<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
																			<Form.Check
																				className="singleMaintenant"
																				type="checkbox"
																				{...register(
																					`datatenants.tenants.${index}.main_tenant`
																				)}
																				label={t("Report-Property.Main")}
																				onChange={(e) =>
																					handleTenantChange(e, index, "main")
																				}
																			/>
																		</Form.Group>
																	</Col>
																	<Col xs md={3}>
																		<FloatingLabel
																			className="percentage"
																			controlId="floatingSelect "
																			label={t("contractlistother.percentage") + "%"}
																		>
																			<Form.Control
																				type="text"
																				placeholder={t("percentage on receipt")}
																				{...register(
																					`datatenants.tenants.${index}.percentage_on_receipt`
																				)}
																				onChange={(e) =>
																					handleTenantChange(
																						e,
																						index,
																						"percentage"
																					)
																				}
																				onKeyPress={isNumber}
																			/>
																		</FloatingLabel>
																	</Col>
																	{/* {
                                                                        !id &&
                                                                        <Col md={2}>
                                                                        <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" onClick={(e) => handleDelete(e, index)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                                                        </Col>
                                                                        }*/}
																	<Col xs="auto" md="auto">
																		<Button
																			className="btn btn-link bg-light min-w-auto border-light rounded-4"
																			onClick={(e) => handleDelete(e, index)}
																		>
																			<img
																				src={
																					process.env.PUBLIC_URL +
																					"/assets/images/icons/delete-light.svg"
																				}
																				alt="Delete icon"
																				className="h-16 cursor-pe"
																			/>
																		</Button>
																	</Col>
																</>
															)}
														</Row>
													</React.Fragment>
												))}
											{multipleTenant.length < 6 && (
												<div className="text-center">
													<Button
														variant="link"
														className="fw-bold p-0"
														onClick={(e) => addAnother(e)}
													>
														<img
															src={
																process.env.PUBLIC_URL +
																"/assets/images/icons/plus-light.svg"
															}
															alt="add icon"
															className="h-18 cursor-pe me-1"
														/>
														{t("newContractDev.AddAnotherTenant")}
													</Button>
												</div>
											)}

											{/*<a className="text-primary fw-bold d-block">Another</a>*/}
											<h6 className="mb-3 mt-4 fw-bold">{t("taxations")}</h6>
											<Row className="g-3 mb-3">
												<Col md={4}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.TaxInvoice")}
													>
														<Form.Select
															{...register("invoice")}
															className={`${errors.invoice ? "is-invalid" : ""}`}
															onChange={(e) =>
																handleTenantInputChange(e, "tenantinput")
															}
															aria-label={t("newContractDev.TaxInvoice")}
														>
															<option value="slct">{t("select")}</option>
															<option value="0">{t("doSystem")}</option>
															<option value="1">{t("issueBySystem")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.invoice?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={4}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("incomeTax")}
													>
														<Form.Select
															{...register("income_tax")}
															className={`${errors.income_tax ? "is-invalid" : ""
																}`}
															onChange={(e) =>
																handleTenantInputChange(e, "tenantinput")
															}
															aria-label={t("incomeTax")}
														>
															<option value="slct">{t("select")}</option>
															<option value="-1">{t("doesTax")}</option>
															<option value="0">{t("withholdsSystem")}</option>
															<option value="1">{t("withholdTaxes")}</option>
															<option value="2">{t("withholdCollects")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.income_tax?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col me={4}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.DeductFee")}
													>
														<Form.Select
															{...register("deduct_administration")}
															className={`${errors.deduct_administration ? "is-invalid" : ""
																}`}
															onChange={(e) =>
																handleTenantInputChange(e, "tenantinput")
															}
															aria-label={t("newContractDev.DeductFee")}
														>
															<option value="slct">{t("select")}</option>
															<option value="0">{t("followGeneralSetup")}</option>
															<option value="1">{t("yesContract")}</option>
															<option value="2">{t("noJustContract")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.deduct_administration?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											<Row className="g-3 mb-3">
												<Col md={4}>
													<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
														<Form.Check
															type="checkbox"
															{...register("includeInDIMOB")}
															checked={contractUseImobConfig}
															label={t("newContractDev.IncludeContractInDIMOB")}
															onChange={(e) => handleImobOption(e)}
														/>
													</Form.Group>
												</Col>
												<Col md={8}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.CalculationBaseDate")}
													>
														<Form.Select
															{...register("calculationDateBase")}
															disabled={!contractUseImobConfig}
															className={`${errors.calculationDateBase ? "is-invalid" : ""}`}
															onChange={(e) =>
																handleCalculationDateBaseValue(e)
															}
															aria-label={t("newContractDev.CalculationBaseDate")}
														>
															{!contractUseImobConfig && <option value="slct">{t("dropdownItem.select")}</option>}
															{!agencyOwned && <option value="1">{t("payoutDate")}</option>}
															<option value="2">{t("debtSettlement")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.calculationDateBase?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											{/* <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-4">
                                            <Form.Check type="checkbox" {...register('is_generate')} label={t('newContractDev.GenerateDIMOB')} />
                                        </Form.Group> */}
											<h6 className="mb-3 fw-bold">{t("billingAddress")}</h6>
											<FloatingLabel
												controlId="floatingSelect"
												label={t("billingAddress")}
											>
												<Form.Select
													{...register("billing_address")}
													className={`${errors.billing_address ? "is-invalid" : ""
														}`}
													onChange={(e) => billingAddress(e, "tenantinput")}
													aria-label={t("billingAddress")}
												>
													<option value="slct">{t("dropdownItem.select")}</option>
													<option value="1">{t("useRentalPtyAddress")}</option>
													<option value="2">{t("useTenantAddress")}</option>
													<option value="3">{t("setAnotherAddress")}</option>
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													{errors.billing_address?.message}
												</Form.Control.Feedback>
											</FloatingLabel>
											<div style={{ display: adres ? "none" : "block" }}>
												<Row className="g-3 mt-0">
													<Col md={4}>
														<InputGroup>
															<FloatingLabel
																className="getzipcode"
																controlId="floatingSelect "
																label={t("zipCode") + " *"}
															>
																<Form.Control
																	type="text"
																	placeholder={t("zipCode")}
																	className={`${errors.zipcode ? "is-invalid" : ""
																		}`}
																	{...register("zipcode")}
																	onChange={(e) =>
																		getLocation(e, "zipcode")
																	}
																	maxLength={9}
																	minLength={9}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.zipcode?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
															<InputGroup.Text>
																<img
																	src={
																		process.env.PUBLIC_URL +
																		"/assets/images/icons/search.svg"
																	}
																	alt="Search"
																	className="h-18 cursor-pe"
																/>
															</InputGroup.Text>
														</InputGroup>
													</Col>
													<Col md={4}>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("address") + " *"}
														>
															<Form.Control
																type="text"
																placeholder={t("address")}
																className={`${errors.address ? "is-invalid" : ""
																	}`}
																{...register("address")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.address?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={4}>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("number") + " *"}
														>
															<Form.Control
																type="text"
																placeholder={t("number")}
																className={`${errors.number ? "is-invalid" : ""}`}
																{...register("number")}
																onChange={numericOnly}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.number?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("complement") + " *"}
														>
															<Form.Control
																type="text"
																placeholder={t("complement")}
																className={`${errors.complement ? "is-invalid" : ""
																	}`}
																{...register("complement")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.complement?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("neighborhood") + " *"}
														>
															<Form.Control
																type="text"
																placeholder={t("neighborhood")}
																className={`${errors.neighborhood ? "is-invalid" : ""
																	}`}
																{...register("neighborhood")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.neighborhood?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("city") + " *"}
														>
															<Form.Control
																type="text"
																className={`${errors.city ? "is-invalid" : ""}`}
																placeholder={t("city")}
																{...register("city")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.city?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("state") + " *"}
														>
															<Form.Select
																className={`${errors.state ? "is-invalid" : ""}`}
																{...register("state")}
																aria-label={t("state")}
															>
																<option value="slct">
																	{t("dropdownItem.select")}
																</option>
																{allStates.map((item, index) => {
																	return (
																		<option key={index} value={item?.["label"]}>
																			{item?.["label"]}
																		</option>
																	);
																})}
															</Form.Select>
															<Form.Control.Feedback type="invalid">
																{errors.state?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2" className="mb-3">
										<Accordion.Header>
											<div className="d-flex align-items-center justify-content-start w-100">
												<img
													src="../assets/images/icons/contract-detail.svg"
													alt="Contract details icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.ContractDetailsSection")}
												{tick2 === "" || tick2 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick2 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick2 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{/* <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" /> */}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<Row className="g-3 mb-4">
												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.Start")}
													>
														<Form.Control
															type="date"
															placeholder={t("newContractDev.Start")}
															className={`${errors.start2 ? "is-invalid" : ""}`}
															{...register("start2")}
															onChange={(e) =>
																handleChangeEndDate(
																	e,
																	"start2Date",
																	"contractdetailinput"
																)
															}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.start2?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("duration")}
													>
														<Form.Control
															type="number"
															placeholder={t("duration")}
															className={`${errors.duration ? "is-invalid" : ""}`}
															{...register("duration")}
															onChange={(e) =>
																handleChangeEndDate(
																	e,
																	"duration",
																	"contractdetailinput"
																)
															}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.duration?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.End")}
													>
														<Form.Control
															type="date"
															placeholder={t("newContractDev.End")}
															className={`${errors.end2 ? "is-invalid" : ""}`}
															{...register("end2")}
															onKeyUp={(e) => handleDuration(e, "end2")}
															onChange={(e) => {
																handleDuration(e, "end2");
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																)
															}}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.end2?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>

												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.MoveDate") + " *"}
													>
														<Form.Control
															type="date"
															placeholder={t("newContractDev.MoveDate")}
															className={`${errors.occupation ? "is-invalid" : ""
																}`}
															{...register("occupation")}
															// min={startDate}
															onChange={(e) =>
																handleChangeEndDate(
																	e,
																	"occupation",
																	"contractdetailinput"
																)
															}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.occupation?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={4}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.RentAmount")}
													>
														<Form.Control
															type="text"
															placeholder={t("newContractDev.RentAmount")}
															className={`${errors.rent_value ? "is-invalid" : ""
																}`}
															{...register("rent_value")}
															onChange={(e) =>
																handleChangeRentValue(e, "contractdetailinput")
															}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.rent_value?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={4}>
													{/* <FloatingLabel controlId="floatingInput" label={t('newContractDev.DueDate')}>
                                                        <Form.Control type="text" placeholder={t('newContractDev.DueDate')} className={`${errors.due_date ? 'is-invalid' : ''}`} {...register('due_date')} onChange={dueDate} onKeyUp={numericOnly} />
                                                        <Form.Control.Feedback type="invalid">{errors.due_date?.message}</Form.Control.Feedback >
                                                    </FloatingLabel> */}
													<FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.DueDate")}
													>
														<input type="hidden" {...register("dueDays")} />
														<Form.Control
															type="text"
															placeholder={t("newContractDev.DueDate")}
															className={`form-control ${errors.due_date ? "is-invalid" : ""
																}`}
															{...register("due_date")}
															onChange={(e) => dueDate(e)}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.due_date?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={4}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("graceDays")}
													>
														<Form.Control
															type="text"
															placeholder={t("details")}
															className={`${errors.graceDays ? "is-invalid" : ""
																}`}
															{...register("graceDays")}
															onChange={(e) => {
																numericOnly(e);
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																);
															}}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.graceDays?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											<Col md={12}>
												<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-2">
													<Form.Check type="checkbox" id="cb-9" {...register("contract_occupancy_date")} label={t("contractsettings.CalculateFirstProportional")} onChange={(e) => handleOccaptionDate(e)} />
												</Form.Group>
											</Col>
											<Col md={12}>
												<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-2">
													<Form.Check
														type="checkbox"
														{...register("fistRent")}
														label={t("checkboxLabel.Setup1strental")}
														onChange={(e) => handleFirstRent(e)}
													/>
												</Form.Group>
											</Col>
											{firstRent ?
												<div >

													<Row className="g-3">
														<Col md={6}>
															<FloatingLabel
																controlId="floatingInput"
																label={t("newContractDev.istRentAmount")}
															>
																<Form.Control
																	type="text"
																	placeholder={t("newContractDev.istRentAmount")}
																	className={`${errors.firstRent ? "is-invalid" : ""}`}
																	{...register("firstRent")}
																	onChange={firstRent_Val}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.firstRent?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
														</Col>
														<Col md={6}>
															<FloatingLabel
																controlId="floatingInput"
																label={t("complement")}
															>
																<Form.Control
																	type="text"
																	placeholder={t("complement")}
																	className={`${errors.firstComplement ? "is-invalid" : ""
																		}`}
																	{...register("firstComplement")}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.firstComplement?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
														</Col>
														<Col md={12}>
															<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
																<Form.Check
																	type="checkbox"
																	{...register("secondRent")}
																	label={t("newContractDev.ChargeTogtherRent")}
																/>
															</Form.Group>
														</Col>
													</Row>
												</div> : ""}
											<h6 className="fw-bold mt-4 mb-3">{t("readjustment")}</h6>
											<Row className="g-3">
												<Col md={6} className="readjustmet_index">
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.AdjustmentIndex")}>
														<Form.Select
															className={`${errors.readjustment_index ? "is-invalid" : ""
																}`}
															{...register("readjustment_index")}
															onChange={(e) =>
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																)
															}
															aria-label={t("newContractDev.AdjustmentIndex")}>
															<option value="slct">{t("formLabel.select")}</option>
															{readName && readName.length > 0
																? readName.map((item: any, index: number) => (
																	<option key={index} value={(parseInt(item.id))}>
																		{item.name}
																	</option>
																))
																: ""}
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.readjustment_index?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={6}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("readjustment_date") + " *"}
													>
														<Form.Control
															type="date"
															placeholder={t("complement")}
															className={` ${errors.readjustment_date ? "is-invalid" : ""}`}
															{...register("readjustment_date")}
															onChange={HandleReadjustmenDate}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.readjustment_date?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											<h6 className="fw-bold mt-4 mb-3">
												{t("newContractDev.ChargeTitle")}
											</h6>
											{/* <Alert className="bg-success-10 text-success-60 fw-medium p-2 mb-3">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/circle-check-success.svg"} alt="Documents add" className="h-20 cursor-pe me-2" />
                                            {t('newContractDev.zeroCostContract')} <img src={process.env.PUBLIC_URL + "/assets/images/icons/info-dark.svg"} alt="Documents add" className="h-20 cursor-pe ms-2" />
                                        </Alert> */}
											<Row className="g-3 mb-3">
												<Col md={6}>
													<FloatingLabel
														controlId="floatingSelect"
														label="Filial *"
                          >
														<Form.Select
															className={`form-control ${errors.branch ? "is-invalid" : ""
																}`}
															{...register("branch")}
															onChange={(e) => {
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																)
                                if (String(e.target.value) !== "1") {
                                  handleAutomaticPayment(0)
                                }
                                setSelectedBranch(e.target.value)
                              }}
															aria-label="Filial"
														>
															<option value="slct">{t("dropdownItem.select")}</option>
															{branchList &&
																branchList.length > 0 &&
																branchList.map((item, index) => (
																	<option key={index} value={item.id}>
																		{item.name}
																	</option>
																))}
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.branch?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>

												<Col md={6}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("newContractDev.FormPayment")}
													>
														<Form.Select
															className={`${errors.billing_method ? "is-invalid" : ""
																}`}
															{...register("billing_method")}
															onChange={(e) => {
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																);
																const selectedIndex = e.target.selectedIndex;
																setSelectedFormOfPayment(
																	e.target.options[selectedIndex].innerText
																);
																handlefilterBillType(e);
                                if (String(e.target.value) !== "0") {
                                  handleAutomaticPayment(0)
                                }
                                setSelectedReceivingMethod(e.target.value)
															}}
															aria-label={t("newContractDev.FormPayment")}
														>
															<option value="slct">{t("dropdownItem.select")}</option>
															{billingData.data && billingData.data.length > 0
																? billingData.data.map((item, index) => (
																	<option key={index} value={Number(item.id)}>
																		{item.name}
																	</option>
																))
																: ""}
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.billing_method?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>



												<Col md={6}>
													<FloatingLabel
														controlId="floatingSelect"
														label="Conta bancária *"
													>
														<Form.Select
                              {...register("bank_account")}
															className={`${errors.bank_account ? "is-invalid" : ""}`}
															aria-label={t("bankAccount")}
                              value={selectedBankAccount}
                              onChange={(e) => {
                                const bankAccount = accountData?.data?.find((acc: any) => acc.id === Number(e.target.value))
                                if (!bankAccount || !bankAccount.hasSplitPaymentIntegrated) {
                                  handleAutomaticPayment(0)
                                }

                                setSelectedBankAccount(e.target.value)
                              }}
														>
															<option value="slct">
																{t("dropdownItem.select")}..
															</option>
															{filteredBankAccounts.length > 0
																? filteredBankAccounts.map((item, index) => (
																	<option key={index} value={Number(item.id)}>
																		{item.account} -- {item.accountRef}
																	</option>
																))
																: ""}
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.bank_account?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>

												<Col md={6}>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("deliveryMethod")}
													>
														<Form.Select
															className={`${errors.delivery_method ? "is-invalid" : ""
																}`}
															{...register("delivery_method")}
															onChange={(e) =>
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																)
															}
															aria-label={t("deliveryMethod")}
														>
															<option value="slct">Correio e digital</option>
															<option value="1">Somente correio</option>
															<option value="2">Somente digital</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.delivery_method?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={6}>
													<OverlayTrigger
														placement="bottom"
														overlay={<Tooltip id="tooltip-bottom">{t(paymentMethod === '0' ? "billingMethods.expired" : "billingMethods.due")}</Tooltip>}
													>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("billingMethod")}
														>
															<Form.Select
																{...register("paymentMethod")}
																aria-label={t("billingMethod")}
																onChange={e => {
																	setValue('paymentMethod', e.target.value)
																	setPaymentMethod(e.target.value)
																}}
															>
																<option value="0">{t("expiredMonth")}</option>
																<option value="1">{t("monthDue")}</option>
															</Form.Select>
														</FloatingLabel>
													</OverlayTrigger>
												</Col>
												<Col md={6}>
													<OverlayTrigger
														placement="bottom"
														overlay={<Tooltip id="tooltip-bottom">{t('exemptionFineTooltip')}</Tooltip>}
													>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("exemptionFine")}
														>
															<Form.Select
																className={`${errors.exemption_termination ? "is-invalid" : ""
																	}`}
																{...register("exemption_termination")}
																onChange={(e) =>
																	exemptionTermination(e, "contractdetailinput")
																}
																aria-label={t("exemptionFine")}
															>
																<option value="slct">{t("select")}..</option>
																<option value="0">{t("noExemption")}</option>
																<option value="-1">{t("free")}</option>
																<option value="2">{t("exemptionMonths")}</option>
															</Form.Select>
															<Form.Control.Feedback type="invalid">
																{errors.exemption_termination?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</OverlayTrigger>
												</Col>
												<Col
													md
													style={{ display: expTermination ? "none" : "block" }}
												>
													<FloatingLabel
														controlId="floatingInput"
														label={t("monthsExemption") + " *"}
													>
														<Form.Control
															type="text"
															placeholder={t("monthsExemption")}
															className={`${errors.fineExemption ? "is-invalid" : ""
																}`}
															{...register("fineExemption")}
															onChange={(e) => {
																numericOnly(e);
																handleContractDetailInputChange(
																	e,
																	"contractdetailinput"
																);
															}}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.fineExemption?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
												<Form.Check
													type="checkbox"
													{...register("lease_period")}
													onChange={leaseMonth}
													label={t("leaseMonth")}
												/>
											</Form.Group>
											<Row className="g-3 mb-3">
												<Col md={6}>
													<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
														<Form.Check
															type="checkbox"
															{...register("charge_renter")}
															label={t("formLabel.charge_renter")}
															onChange={rentersFee}
														/>
													</Form.Group>
												</Col>
												<Col
													md={6}
													style={{ display: renterFees ? "none" : "block" }}
												>
													<FloatingLabel
														controlId="floatingInput"
														label={t("formLabel.chargeValue")}
													>
														<Form.Control
															type="text"
															className={`${errors.renterPrice ? "is-invalid" : ""
																}`}
															placeholder={t("formLabel.chargeValue")}
															{...register("renterPrice")}
															onChange={(e) => handlePrice(e, "renterPrice")}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.renterPrice?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											<InputGroup className="mb-3">
												<FloatingLabel
													controlId="floatingInput"
													label={t("commercialManager")}
												>
													<Form.Select
														aria-label={t("commercialManager")}
														{...register("manager")}
														onChange={(e) =>
															handleContractDetailInputChange(
																e,
																"contractdetailinput"
															)
														}
													>
														<option value={managerVal}>{t("select")}..</option>
														{managerData.map((item, index) => (
															<option key={index} value={item.id}>
																{item.name}
															</option>
														))}
													</Form.Select>
													<Form.Control.Feedback type="invalid">
													</Form.Control.Feedback>
												</FloatingLabel>
												<InputGroup.Text onClick={newManagerOpen}>
													<img
														src={
															process.env.PUBLIC_URL +
															"/assets/images/icons/user-edit.svg"
														}
														alt="Documents add"
														className="h-20 cursor-pe"
													/>
												</InputGroup.Text>
											</InputGroup>
											<FloatingLabel
												className="mb-3"
												controlId="floatingTextarea2"
												label={t("additionalClauses")}
											>
												<Form.Control
													as="textarea"
													placeholder={t("additionalClauses")}
													{...register("additional_clauses")}
													style={{ height: "100px" }}
												/>
											</FloatingLabel>
											<FloatingLabel
												controlId="floatingTextarea2"
												label={t("observation")}
											>
												<Form.Control
													as="textarea"
													placeholder={t("observation")}
													{...register("observation3")}
													style={{ height: "100px" }}
												/>
											</FloatingLabel>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3" className="mb-3">
										<Accordion.Header id="guaranteeinput">
											<div className="d-flex align-items-center justify-content-start w-100">
												<img
													src="../assets/images/icons/warranty.svg"
													alt="Warranty icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.WarrantySection")}
												{tick3 === "" || tick3 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick3 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick3 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{/* <img src="assets/images/icons/check-circle-success.svg" alt="complete" className="h-26 ms-auto me-xl-4 me-md-3 me-2" /> */}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<FloatingLabel
												controlId="floatingSelect"
												label={t("guarantee")}
											>
												<Form.Select
													className={`mb-2 ${errors.guarantee ? "is-invalid" : ""}`}
													aria-label="Guarantee"
													{...register("guarantee")}
													onChange={(e) => {
														guarantee(e, "guaranteeinput");
														const selectedIndex = e.target.selectedIndex;
														setSelectedGuarantee(
															e.target.options[selectedIndex].innerText
														);
													}}
												>
													<option value="slct">{t("select")}</option>
													<option value="1">{t("guarantor")}</option>
													<option value="2">{t("securityDeposit")}</option>
													<option value="3">{t("suretyBond")}</option>
													<option value="4">{t("capitalizationBond")}</option>
													<option value="5">{t("surety")}</option>
													<option value="6">{t("fiduciaryAssignment")}</option>
													<option value="0">{t("hasNoWarranty")}</option>
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													{errors.guarantee?.message}
												</Form.Control.Feedback>
											</FloatingLabel>
											{/* <div style={{ display: guarant == 1 ? "block" : "none" }}>
                                            <InputGroup className="mt-3">
                                                <FloatingLabel controlId="floatingInput" label={t('guarantor')}>
                                                    <Form.Select className={`${errors.guarantor ? 'is-invalid' : ''}`} {...register('guarantor')} >
                                                        <option value="slct">{t('dropdownItem.select')}..</option>
                                                        {guarantorData.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.guarantor?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </InputGroup>
                                        </div> */}
											{guarant == 1 ? (
												<div style={{ display: guarant == 1 ? "block" : "none" }}>
													{multipleGarunter &&
														multipleGarunter.map((item: any, index: number) => (
															<React.Fragment key={index}>
																<Row className="gy-lg-4 gy-3 mb-lg-4 ">
																	<Col md={multipleGarunter.length > 1 ? 10 : 12}>
																		<InputGroup>

																			<Form.Floating className="mb-0" ref={inputRef}>
																				{/* className={`form-control ${errors?.objdata?.[index]?.service ? 'is-invalid' : ''}`} */}
																				<FormControl
																					type="text"
																					placeholder="Type to search..."
																					{...register(`guarantor.${index}.data`)}
																					onChange={(e) => handlefilterSearch(e)}
																					onFocus={() => handleInputFocus(index)}
																					id="bank"
																					autoComplete="off" />
																				{isInputFocused && isInputFocused.length > 0 && isInputFocused?.[index]?.["focus"] && display && (
																					<ListGroup className="shadow  z-index-5 overflow-auto end-0 start-0" style={{ maxHeight: "260px", display: (isInputFocused[index]["focus"] && display) ? "block" : "none" }}>
																						{searchResults && searchResults.length > 0 &&
																							searchResults?.slice(0, 3).reverse().map((item: any, indexs: number) => {
																								return (
																									<ListGroup.Item className="px-3 cursor-pe" key={indexs}
																										onClick={() => handleGuarantorInputChangeList({
																											target: {
																												value: item.id,
																												name: item.name
																											}
																										}, index,
																											"guaranteeinput")}>
																										{item.name}
																									</ListGroup.Item>
																								)
																							})}
																					</ListGroup>
																				)}
																				<Form.Label htmlFor="floatingInputValue">{t("guarantor")}</Form.Label >
																			</Form.Floating>
																			<InputGroup.Text onClick={() => formShow(index)} title={t('customerAndEmployees.newGuarantor')}>
																				<img src="assets/images/icons/user-edit.svg" alt="add owner" className="h-20 cursor-pe" />
																			</InputGroup.Text>
																		</InputGroup>
																	</Col>
																	{multipleGarunter &&
																		multipleGarunter.length > 1 && (
																			<Col md="auto" className="px-1">
																				<Button
																					className="btn btn-link bg-light min-w-auto border-light rounded-4"
																					onClick={(e) =>
																						handleDeleteGarunter(e, index)
																					}
																				>
																					<img
																						src={
																							process.env.PUBLIC_URL +
																							"/assets/images/icons/delete-light.svg"
																						}
																						alt="Delete icon"
																						className="h-16 cursor-pe"
																					/>
																				</Button>
																			</Col>
																		)}
																</Row>
															</React.Fragment>

														))}
													{multipleGarunter && multipleGarunter.length < 5 && (
														<div className="text-center mt-2">
															<Button
																variant="link"
																className="fw-bold p-0"
																onClick={(e) => addAnotherGurantor(e)}
															>
																<img
																	src={
																		process.env.PUBLIC_URL +
																		"/assets/images/icons/plus-light.svg"
																	}
																	alt="add icon"
																	className="h-18 cursor-pe me-1"
																/>
																{t("propertyDev.addGarunter")}
															</Button>
														</div>
													)}
												</div>) : (
												""
											)
											}
											<div style={{ display: guarant == 2 ? "block" : "none" }}>
												<h6 className="fw-bold mb-3 mt-4">{t("details")}</h6>
												<Row className="g-3 mb-3">
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("type")}
														>
															<Form.Select
																{...register("guaranteeType")}
																onChange={(e) => {
																	guaranteeType(e, "guaranteeinput");
																}}
																className={`${errors.guaranteeType ? "is-invalid" : ""
																	}`}
																aria-label={t("type")}
															>
																<option value="slct">{t("select")}..</option>
																<option value="1">{t("value")}</option>
																<option value="3">{t("vehicle")}</option>
																<option value="4">{t("letterGuarantee")}</option>
																<option value="5">{t("immobile")}</option>
																<option value="6">{t("others")}</option>
															</Form.Select>
															<Form.Control.Feedback type="invalid">
																{errors.guaranteeType?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col
														md={6}
														style={{
															display:
																guarType == 1 || guarType == 0 ? "block" : "none",
														}}
													>
														<FloatingLabel
															controlId="floatingSelect"
															label={t("responsible")}
														>
															<Form.Select
																{...register("responsible")}
																className={`${errors.responsible ? "is-invalid" : ""
																	}`}
																aria-label={t("responsible")}
															>
																<option value="slct">{t("select")}..</option>
																<option value="0">{t("realEstate")}</option>
																<option value="1">{t("owner")}</option>
																<option value="2">{t("tenant")}</option>
															</Form.Select>
															<Form.Control.Feedback type="invalid">
																{errors.responsible?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
												<div
													style={{
														display:
															guarType == 1 || guarType == 3 || guarType == 5
																? "none"
																: "block",
													}}
												>
													<Row className="g-3 mb-3">
														<Col md={6}>
															<FloatingLabel
																controlId="floatingSelect"
																label={t("description")}
															>
																<Form.Control
																	type="text"
																	placeholder={t("description")}
																	className={`${errors.description ? "is-invalid" : ""
																		}`}
																	{...register("description")}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.description?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
														</Col>
														<Col md={6}>
															<FloatingLabel
																controlId="floatingSelect"
																label={t("identifier")}
															>
																<Form.Control
																	type="text"
																	placeholder={t("identifier")}
																	className={`${errors.identifier ? "is-invalid" : ""
																		}`}
																	{...register("identifier")}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.identifier?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
														</Col>
													</Row>
												</div>
											</div>
											<div style={{ display: guarant == 3 ? "block" : "none" }}>
												<h6 className="fw-bold mb-3 mt-4">{t("details")}</h6>
												<Row className="g-3 mb-3">
													<Col md={6}>
														<InputGroup>
															<FloatingLabel
																controlId="floatingInput"
																label={t("insurers")}
															>
																<Form.Select
																	aria-label={t("insurers")}
																	className={`${errors.insurer ? "is-invalid" : ""
																		}`}
																	{...register("insurer")}
																>
																	<option value="slct">
																		{t("dropdownItem.select")}..
																	</option>
																	{insuranceData &&
																		insuranceData.length > 0
																		? insuranceData.map((item, index) => (
																			<option key={index} value={item.id}>
																				{item.name}
																			</option>
																		))
																		: ""}
																</Form.Select>
																<Form.Control.Feedback type="invalid">
																	{errors.insurer?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
															<InputGroup.Text onClick={newInsuranceOpen}>
																<img
																	title="contractInsurance"
																	src={
																		process.env.PUBLIC_URL +
																		"/assets/images/icons/documents-add-light.svg"
																	}
																	alt="Documents add"
																	className="h-20 cursor-pe"
																/>
															</InputGroup.Text>
														</InputGroup>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("policy")}
														>
															<Form.Control
																type="text"
																placeholder={t("policy")}
																className={`${errors.policy ? "is-invalid" : ""}`}
																{...register("policy")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.policy?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md>
														<FloatingLabel
															controlId="floatingInput"
															label={t("numberInstallments")}
														>
															<Form.Control
																type="text"
																placeholder={t("numberInstallments")}
																className={`${errors.numberInstallments ? "is-invalid" : ""
																	}`}
																{...register("numberInstallments")}
																onChange={numericOnly}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.numberInstallments?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
											</div>
											<div style={{ display: guarant == 4 ? "block" : "none" }}>
												<h6 className="fw-bold mb-3 mt-4">{t("details")}</h6>
												<Row className="g-3 mb-3">
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("bank")}
														>
															<Form.Control
																type="text"
																placeholder={t("bank")}
																className={`${errors.bank ? "is-invalid" : ""}`}
																{...register("bank")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.bank?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("policy")}
														>
															<Form.Control
																type="text"
																placeholder={t("policy")}
																className={`${errors.policy ? "is-invalid" : ""}`}
																{...register("policy")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.policy?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
											</div>
											<div style={{ display: guarant == 6 ? "block" : "none" }}>
												<h6 className="fw-bold mb-3 mt-4">{t("details")}</h6>
												<Row className="g-3 mb-3">
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("backgroundType")}
														>
															<Form.Control
																type="text"
																placeholder={t("backgroundType")}
																className={`${errors.backgroundType ? "is-invalid" : ""
																	}`}
																{...register("backgroundType")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.backgroundType?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("applicationNumber")}
														>
															<Form.Control
																type="text"
																placeholder={t("applicationNumber")}
																className={`${errors.applicationNumber ? "is-invalid" : ""
																	}`}
																{...register("applicationNumber")}
																onChange={numericOnly}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.applicationNumber?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
											</div>
											<div
												style={{
													display:
														guarant == 2 ||
															guarant == 6 ||
															guarant == 4 ||
															guarant == 3
															? "block"
															: "none",
												}}
											>
												<Row className="g-3 mb-3">
													<Col md={4}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("in")}
														>
															<Form.Control
																type="date"
																placeholder={t("in")}
																className={`${errors.in ? "is-invalid" : ""}`}
																{...register("in")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.in?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={4}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("until")}
														>
															<Form.Control
																type="date"
																placeholder={t("until")}
																className={`${errors.until ? "is-invalid" : ""}`}
																{...register("until")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.until?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={4}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("value")}
														>
															<Form.Control
																type="text"
																placeholder={t("value")}
																className={`${errors.value ? "is-invalid" : ""}`}
																{...register("value")}
																onChange={(e) => handleValue(e)}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.value?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
												<FloatingLabel
													controlId="floatingTextarea2"
													label={t("formLabel.observation")}
												>
													<Form.Control
														as="textarea"
														placeholder="Observation"
														{...register("observation2")}
														style={{ height: "100px" }}
													/>
												</FloatingLabel>
											</div>
											<div style={{ display: guarant == 5 ? "block" : "none" }}>
												<InputGroup className="mt-3">
													<FloatingLabel
														controlId="floatingInput"
														label={t("surety")}
													>
														<Form.Select
															aria-label={t("surety")}
															className={`${errors.surety ? "is-invalid" : ""}`}
															{...register("surety")}
														>
															<option value="slct">
																{t("dropdownItem.select")}..
															</option>
															{guarantorData.map((item) => (
																<option key={item.id} value={item.id}>
																	{item.name}
																</option>
															))}
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.surety?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
													{/*<InputGroup.Text >
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                    </InputGroup.Text>*/}
												</InputGroup>
											</div>
											{/* {
                                            cosignerHide &&
                                            <>
                                                <Row className="g-3 mt-0">
                                                    <Col xs>
                                                        <InputGroup>
                                                            <FloatingLabel controlId="floatingInput" label={t('newContractDev.Cosigner')}>
                                                                <Form.Control type="text" placeholder={t('newContractDev.Cosigner')} />
                                                            </FloatingLabel>
                                                            <InputGroup.Text>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="user add" className="h-20 cursor-pe" />
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs="auto" md="auto">
                                                        <Button className="btn btn-link bg-light min-w-auto border-light rounded-4" ><img src={process.env.PUBLIC_URL + "/assets/images/icons/delete-light.svg"} alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                                    </Col>
                                                </Row>
                                                <div className="text-center mt-3">
                                                    <Button variant='link' className="fw-bold p-0" onClick={newCosignerModalShow}>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.AddAnotherCosigner')}
                                                    </Button>
                                                </div>
                                            </>
                                        } */}
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4" className="mb-3">
										<Accordion.Header id="fireinsuranceinput" onClick={() => handleInsuranceQuote()}>
											<div className="d-flex align-items-center justify-content-start w-100">

												<img
													src="../assets/images/icons/fire-insurance.svg"
													alt="Fire insurance icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.FireInsurance")}
												{tick5 === "" || tick5 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick5 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick5 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-lg-4 mb-3">
												{t("newContractDev.insuranceMandatoryTenancyLaw")}
											</p>
											{/* <Alert className="bg-success-10 text-success-60 fw-medium p-2 mb-3 mb-lg-4">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/circle-check-success.svg"} alt="Documents add" className="h-20 cursor-pe me-2" />
                                            {t('newContractDev.zeroCostContractInsurance')}
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/info-dark.svg"} alt="Documents add" className="h-20 cursor-pe ms-2" />
                                        </Alert> */}
											{/* <Alert className="bg-warning-5 text-warning-60 fw-medium p-2 mb-3 mb-lg-4">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/circle-check-warning.svg"} alt="Check icon" className="h-20 cursor-pe me-2" />{t('newContractDev.costContractKenloInsurance')}
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/info-dark.svg"} alt="Documents add" className="h-20 cursor-pe ms-2" />
                                        </Alert> */}
											<div style={{ display: isssContract ? "none" : "block" }}>
												<InputGroup className="mb-4">
													<FloatingLabel
														controlId="insuranceCompany"
														label={t("insuranceCompany") + "*"}
													>
														<Form.Select
															aria-label={t("insuranceCompany")}
															className={`${errors.insuranceCompany ? "is-invalid" : ""
																}`}
															{...register("insuranceCompany")}
															onChange={(e) => {
																handleRentTransferInputChange(
																	e,
																	"fireinsuranceinput", "insuranceCompany"
																);

																const selectedIndex = e.target.selectedIndex;
																setSelectedFireInsurance(e.target.options[selectedIndex].innerText);

																if (e.target.options[selectedIndex].innerText == "inGaia Corretora de Seguros Ltda") {
																	setHideFireInsuranceCard(false);
																	handleInsuranceQuote();
																} else {
																	setHideFireInsuranceCard(true);
																	setInsuredAmount("");
																	setPremiumAmount("");
																	setInstallmentAmount("");
																	setInstallmentNumber("");
																}
															}}
														>
															<option value="slct">
																{t("dropdownItem.select")}..
															</option>
															{filteredData &&
																filteredData.length > 0 ? (
																filteredData.map((item, index) => (
																	<option key={index} value={item.id} data-name={item.name}>
																		{item.name}
																	</option>
																))
															) : (
																<option value="">No records</option>
															)}
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.insuranceCompany?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
													<InputGroup.Text onClick={newInsuranceOpen}>
														<img
															title="contractInsurance"
															src={
																process.env.PUBLIC_URL +
																"/assets/images/icons/file-edit.svg"
															}
															alt="Add"
															className="h-20 cursor-pe"
														/>
													</InputGroup.Text>
												</InputGroup>
												{/* Insurance Card */}
												{
													isLoading ? (
														// Show loader while loading

														<div
															className="package-loading"
															style={{
																border: "1px solid #f82e52",
																borderRadius: "5px",
																background: "#f82e52",
																color: "#fff",
																padding: "2%",
																textAlign: "center",
															}}
														>Fetching Insurance Quotes... </div>

													) : (
														<>
															{insuranceData?.length > 1 ?
																<Row className="g-lg-4 g-3 mb-4">
																	{insuranceRecord &&
																		insuranceRecord.map((record, index) => (
																			<>
																				<Col
																					md={6}
																					key={index}
																					style={{ display: hideFireInsuranceCard ? "none" : "block", }}
																				>
																					<Card
																						className={`border border-2 text-center ${alreadyClickedIndex == record.ShortName
																							? "border-primary"
																							: ""
																							}`}
																						onClick={() =>
																							handleInsurance(index, record)
																						}
																						style={{ cursor: "pointer" }}
																					>
																						<Card.Body className="p-lg-4">
																							<h4 className="mb-4">
																								{t(record.Name)}
																							</h4>
																							<img
																								src={
																									process.env.PUBLIC_URL +
																									"/assets/images/temp/tokio_icon.png"
																								}
																								alt="tokio icon"
																								className="img-fluid mx-auto d-block"
																							/>
																							<span className="d-block text-secondary fs-12">
																								{t(
																									"newContractDev.inCollaborationKenloInsurance"
																								)}
																							</span>
																							<div className="fw-bold my-4">
																								{record.Installments}x{" "}
																								<span className="h3 lh-1">
																									R${" "}
																									{
																									   converter(String(record.TotalCalculatedByInstallments))	
																									}
																								</span>{" "}
																								<span className="fs-12 text-secondary d-block mt-1 ms-2">
																									R$ {converter(String(record.Total))}{" "}
																									{t("newContractDev.inTotal")}
																								</span>
																							</div>
																							<div className="d-flex justify-content-center text-start">
																								<ul className="list-unstyled">
																									{/* {record.Coverages.map(
                                                    (coverage, idx) => (
                                                      <li className="mb-3" key={idx}>
                                                        {coverage.value === 0 ? (
                                                          <img
                                                            title="contractInsurance"
                                                            src={
                                                              process.env.PUBLIC_URL +
                                                              "/assets/images/icons/cross-w.svg"
                                                            }
                                                            alt="cross icon"
                                                            className="h-16 cursor-pe me-lg-2 me-1"
                                                          />
                                                        ) : (
                                                          <img
                                                            title="contractInsurance"
                                                            src={
                                                              process.env.PUBLIC_URL +
                                                              "/assets/images/icons/check.svg"
                                                            }
                                                            alt="cross icon"
                                                            className="h-16 cursor-pe me-lg-2 me-1"
                                                          />
                                                        )}
                                                        {t(coverage.text)}
                                                      </li>
                                                    )
                                                  )} */}
																									{items
																										?.filter(item => item.contractType == contractTypes)
																										.map((item, idx) => (
																											<li key={idx} className="list-group-item border-0 p-0 d-flex  mb-2">
																												{item.labels[index] ? (
																													<>
																														{item.val == index ?
																															<img
																																title="contractInsurance"
																																src={
																																	process.env.PUBLIC_URL +
																																	"/assets/images/icons/cross-w.svg"
																																}
																																alt="cross icon"
																																className="h-16 cursor-pe me-lg-2 me-1"
																															/>
																															:
																															<img
																																title="contractInsurance"
																																src={`${process.env.PUBLIC_URL}/assets/images/icons/check.svg`}
																																alt="check icon"
																																className="h-16 cursor-pe me-lg-2 me-1"
																															/>
																														}
																														{t(item.labels[index]).charAt(0).toUpperCase() + t(item.labels[index]).slice(1)}
																													</>
																												) : null}
																											</li>
																										))}
																								</ul>
																							</div>
																							<span className="d-block text-dark-70 fs-12">
																								{t(
																									"newContractDev.insuranceAutomaticallyContractedActivated"
																								)}
																							</span>
																						</Card.Body>
																					</Card>
																				</Col>
																				{/* Render additional card based on quoteData */}
																				{!busType ?
																					<Alert
																						variant="warning"
																						className="d-flex align-items-center mb-0"
																					>
																						<img
																							src={
																								process.env.PUBLIC_URL +
																								"/assets/images/icons/triangle-exclamation.svg"
																							}
																							alt="Exclamation"
																							className="flex-shrink-0 me-2 h-16"
																						/>
																						{t("newContractDev.insuranceacceptance")} {" "}
																						<a
																							href="https://storage.cloud.google.com/download/storage/v1/b/bucket-kenlo-wegile/o/images%2FEmpresarial_Quadro-de-Analogias_1735803432919.pdf?generation=1735803435255415&alt=media"
																							rel="noopener noreferrer"
																							target="_blank"
																							className="cursor-pointer text-primary text-end"
																						>
																							&nbsp; {t("ReceiveandReceived.Clickhere")} &nbsp;
																						</a>
																					</Alert>
																					: ""}
																			</>
																		))}
																</Row>
																: ""}


														</>
													)}
												<p className="text-danger">
													{Errormessage?.message ? t(`InsuranceErro.${Errormessage?.message}`) : Errormessage?.message}
													{Errormessage?.error && Errormessage?.error?.map((err, index) => (
														<span key={index}>
															{Errormessage?.message}
															<br />
															{err.path.join(" > ")} -  {err.message}
															<br />
														</span>
													))}
												</p>
												<Row className="g-3 mb-3">
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("start")}
														>
															<Form.Control
																type="date"
																placeholder={t("start")}
																className={`${errors.start ? "is-invalid" : ""
																	}`}
																{...register("start")}
																disabled={insurance}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.start?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("end")}
														>
															<Form.Control
																type="date"
																placeholder={t("end")}
																className={`${errors.end ? "is-invalid" : ""
																	}`}
																{...register("end")}
																disabled={insurance}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.end?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													{selectedFireInsurance == "Tokio Marine" ?
														<Col md={6}>
															<FloatingLabel
																controlId="floatingInput"
																label={t("newContractDev.InsuredAmount")}
															>
																<OverlayTrigger placement="top" overlay={tooltip}>
																	<Form.Control
																		type="text"
																		//   value={insuredAmount}
																		placeholder={t(
																			"newContractDev.InsuredAmount"
																		)}
																		className={`${errors.assuredValue ? "is-invalid" : ""
																			}`}
																		{...register("assuredValue")}
																		onChange={(e) => {
																			handleRentTransferInputChange(
																				e,
																				"fireinsuranceinput", "assuredValue"
																			)
																		}}
																	/>
																</OverlayTrigger>
																<Form.Control.Feedback type="invalid">
																	{errors.assuredValue?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
														</Col> :
														<Col md={6}>
															<FloatingLabel
																controlId="floatingInput"
																label={t("newContractDev.InsuredAmount")}
															>
																<Form.Control
																	type="text"
																	//   value={insuredAmount}
																	placeholder={t(
																		"newContractDev.InsuredAmount"
																	)}
																	className={`${errors.assuredValue ? "is-invalid" : ""
																		}`}
																	{...register("assuredValue")}
																	onChange={(e) => {
																		handleRentTransferInputChange(
																			e,
																			"fireinsuranceinput", "assuredValue"
																		)
																	}}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.assuredValue?.message}
																</Form.Control.Feedback>
															</FloatingLabel>
														</Col>}
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("newContractDev.InsuredAmount")}
														>
															<OverlayTrigger placement="top" overlay={tooltip}>
																<Form.Control
																	type="text"
																	//   value={insuredAmount}
																	placeholder={t(
																		"newContractDev.InsuredAmount"
																	)}
																	className={`${errors.assuredValue ? "is-invalid" : ""
																		}`}
																	{...register("assuredValue")}
																	onChange={(e) => {
																		handleRentTransferInputChange(
																			e,
																			"fireinsuranceinput", "assuredValue"
																		)
																	}}
																/>
															</OverlayTrigger>
															<Form.Control.Feedback type="invalid">
																{errors.assuredValue?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6} style={{ 'display': 'none' }}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("newContractDev.PremiumAmount")}
														>
															<Form.Control
																type="text"
																// value={premiumAmount}
																placeholder={t(
																	"newContractDev.PremiumAmount"
																)}
																className={`${errors.prizeAmount ? "is-invalid" : ""
																	}`}
																{...register("prizeAmount")}
																onChange={numericOnly}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.prizeAmount?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6} style={{ 'display': 'none' }}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("newContractDev.InstallmentAmount")}
														>
															<Form.Control
																type="text"
																//value={installmentAmount}
																placeholder={t(
																	"newContractDev.InstallmentAmount"
																)}
																className={`${errors.installmentValue ? "is-invalid" : ""
																	}`}
																{...register("installmentValue")}
																onChange={numericOnly}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.installmentValue?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6} style={{ 'display': 'none' }}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("numberInstallments")}
														>
															<Form.Control
																type="text"
																//value={installmentNumber}
																placeholder={t("numberInstallments")}
																className={`${errors.numberInstallments2
																	? "is-invalid"
																	: ""
																	}`}
																{...register("numberInstallments2")}
																onChange={numericOnly}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.numberInstallments2?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6} style={{ 'display': 'none' }}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("policy")}
														>
															<Form.Control
																type="text"
																placeholder={t("policy")}
																className={`${errors.policy2 ? "is-invalid" : ""
																	}`}
																{...register("policy2")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.policy2?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={6}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("newContractDev.SelectedPlan")}
														>
															<Form.Control
																type="text"
																placeholder={t("newContractDev.SelectedPlan")}
																className={`${errors.contractedPlan ? "is-invalid" : ""
																	}`}
																{...register("contractedPlan")}
																disabled={insurance}
															// onChange={(e) => assuredValueInput(e, "contractedPlan")}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.contractedPlan?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={12}>
														<InputGroup>
															<FloatingLabel
																controlId="floatingSelect"
																label={t("newContractDev.ResponsibleParty")}
															>
																<Form.Select
																	{...register("responsible4")}

																	aria-label={t(
																		"newContractDev.ResponsibleParty"
																	)}
																	disabled={true}
																>
																	<option value="slct">{t("details")}..</option>
																	<option value="3">{t("realEstate")}</option>
																	<option value="2">{t("tenant")}</option>
																	<option value="1">{t("owner")}</option>
																</Form.Select>

															</FloatingLabel>
															<InputGroup.Text>
																<img
																	title="contractInsurance"
																	src={
																		process.env.PUBLIC_URL +
																		"/assets/images/icons/user-edit.svg"
																	}
																	alt="Add"
																	className="h-20 cursor-pe"
																/>
															</InputGroup.Text>
														</InputGroup>
													</Col>

													<Col md={12}>
														<FloatingLabel
															className="mb-3 mb-lg-4"
															controlId="floatingTextarea2"
															label={t("observation")}
														>
															<Form.Control
																as="textarea"
																placeholder={t("observation")}
																{...register("observation4")}
																style={{ height: "100px" }}
															/>
														</FloatingLabel>
													</Col>
												</Row>
											</div>
											<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
												<Form.Check
													type="checkbox"
													{...register("isContract")}
													onClick={(e) => issContract(e)}
													label={t("newContractDev.NoFireInsurance")}
												/>
											</Form.Group>
											{/* <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Check checked={issValue === 1} type="radio" {...register('isContract')} value="1" onClick={issContract} label={t('yes')} />
                                            </Form.Group> */}
										</Accordion.Body>
									</Accordion.Item>

									<Accordion.Item eventKey="5" className="mb-3">
										<Accordion.Header id="renttransferinput">
											<div className="d-flex align-items-center justify-content-start w-100">
												<img
													src="../assets/images/icons/rent.svg"
													alt="Rent transfer icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.RentTransferSection")}
												{tick4 === "" || tick4 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick4 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick4 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<Row className="g-3 mb-3">
												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label={`${t("monthlyAdminRate") + " *"} ${adminRate ? "(%)" : "(R$)"
															}`}
													>
														<Form.Control
															type="tel"
															placeholder={t("monthlyAdminRate")}
															className={`${errors.monthlyAdmin ? "is-invalid" : ""
																}`}
															{...register("monthlyAdmin")}
															onChange={(e) =>
																fixed_amountChange(
																	e,
																	"input",
																	"renttransferinput"
																)
															}
															disabled={agencyOwned ? true : false}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.monthlyAdmin?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={6}>
													<Form.Group
														className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center"
														onChange={(e) =>
															fixed_amountChange(
																e,
																"checked",
																"renttransferinput1"
															)
														}
													>
														<Form.Check
															type="checkbox"
															{...register("fixed_amount")}
															label={t("doFixedAmount")}
															disabled={agencyOwned ? true : false}
														/>
													</Form.Group>
												</Col>
											</Row>

											<Row className="g-3">
												<Col md>
													<FloatingLabel
														controlId="floatingSelect"
														label={t("guaranteedTransfer")}
													>
														<Form.Select
															className={`${errors.guarantee2 ? "is-invalid" : ""
																}`}
															{...register("guarantee2")}
															onChange={guarantee2}
															aria-label={t("guaranteedTransfer")}
															disabled={pay === 1 || (agencyOwned ? true : false)}
														>
															<option value="slct">{t("select")}..</option>
															<option value="0">{t("doesNotHave")}</option>
															<option value="1">{t("guaranteeFewMonths")}</option>
															<option value="-1">{t("guaranteeContract")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.guarantee2?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md style={{ display: gurnty ? "none" : "block" }}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("newContractDev.GuaranteedMonths")}
													>
														<Form.Control
															type="text"
															maxLength={12}
															className={`${errors.months ? "is-invalid" : ""}`}
															placeholder={t("newContractDev.GuaranteedMonths")}
															{...register("months")}
															onChange={(e) => {
																numericOnly(e);
															}}
															disabled={pay === 1 || (agencyOwned ? true : false)}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.months?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>

											<Row className="g-3 align-items-center mt-0">
												<Col md>
													<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
														<Form.Check
															type="checkbox"
															{...register("bank_fee")}
															onChange={bankFee}
															label={t("formLabel.Chargebankfee")}
															disabled={agencyOwned ? true : false}
														/>
													</Form.Group>
												</Col>
												<Col md style={{ display: bankFees ? "none" : "block" }}>
													<FloatingLabel
														controlId="floatingInput"
														label={t("formLabel.chargeValue")}
													>
														<Form.Control
															type="text"
															className={`${errors.price ? "is-invalid" : ""}`}
															placeholder={t("formLabel.chargeValue")}
															{...register("price")}
															onChange={(e) => handlePrice(e, "price")}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.price?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>

											<h6 className="fw-bold mt-4 mb-3">{t("transfer")}</h6>
											{typeof config.mode !== "undefined" ?
													<Form.Group className="mb-lg-4 mb-3 d-flex align-items-center gap-1">
														<Form.Check
                              checked={pay === 1}
                              disabled={!isAllowedToSelectSplitPayment}
                              type="radio"
                              {...register('automatic_payment')}
                              onChange={automaticPayment}
                              value="1"
                              label="Repasse automático via Split"
                            />
                            {!isAllowedToSelectSplitPayment &&
                              <span className="fs-12 text-wraning-45 fw-medium">
                                Contrato não esta apto para split automático
                              </span>
                            }
													</Form.Group>
													: ''
											}
											<Form.Group className="mb-lg-4 mb-3">
												<Form.Check
                          checked={pay === 0}
                          disabled={agencyOwned ? true : false}
                          type="radio"
                          {...register('automatic_payment')}
                          onChange={automaticPayment}
                          value="0"
                          label="Repasse manual"
                        />
											</Form.Group>

											<Row className="g-3 mb-3">
												<Col md={6}>
													<FloatingLabel
														controlId="floatingSelect"
														label={pay == 0 ? t("newContractDev.TypeTransfer") + "*" : t("newContractDev.TypeTransfer")}
													>
														<Form.Select
															className={`${errors.transfer_type ? "is-invalid" : ""
																}`}
															{...register("transfer_type")}
															disabled={pay === 1 || (guaranty || agencyOwned ? true : false)}
														>
															<option value="slct">{t("select")}..</option>
															<option value="0">{t("businessPayment")}</option>
															<option value="1">{t("fixedDay")}</option>
															<option value="2">{t("calendarPayment")}</option>
														</Form.Select>
														<Form.Control.Feedback type="invalid">
															{errors.transfer_type?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
												<Col md={6}>
													<FloatingLabel
														controlId="floatingInput"
														label={pay == 0 ? t("newContractDev.DaysPaymentTransfer") + "*" : t("newContractDev.DaysPaymentTransfer")}
													>
														<Form.Control
															type="text"
															maxLength={30}
															placeholder={t(
																"newContractDev.DaysPaymentTransfer"
															)}
															className={`${errors.transfer_day ? "is-invalid" : ""
																}`}
															{...register("transfer_day")}
															onChange={(e) => {
																numericOnly(e);
															}}
															disabled={pay === 1 || (agencyOwned ? true : false)}
														/>
														<Form.Control.Feedback type="invalid">
															{errors.transfer_day?.message}
														</Form.Control.Feedback>
													</FloatingLabel>
												</Col>
											</Row>
											
											<Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-3">
												<OverlayTrigger
													placement="bottom"
													overlay={<Tooltip id="tooltip-bottom">{t("differentContractTooltip")}</Tooltip>}
												>
													<Form.Check
														type="checkbox"
														{...register("different_interest")}
														onChange={differentInterest}
														label={t(pay === 0 ? "differentContract" : "differentContractRent")}
													/>
												</OverlayTrigger>
											</Form.Group>
											<div style={{ display: interest ? "none" : "block" }}>
												<Row className="g-3 mb-3">
													<Col md={3}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("fees")}
														>
															<Form.Control
																type="text"
																placeholder={t("fees")}
																className={`${errors.fees ? "is-invalid" : ""}`}
																{...register("fees")}
																onChange={e => handlePercentage(e, 'fees')}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.fees?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={4}>
														<FloatingLabel
															controlId="floatingInput"
															label={t("trafficTicket")}
														>
															<Form.Control
																type="text"
																placeholder={t("trafficTicket")}
																className={`${errors.trafficTicket ? "is-invalid" : ""
																	}`}
																{...register("trafficTicket")}
																onChange={e => handlePercentage(e, 'trafficTicket')}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.trafficTicket?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
													<Col md={5}>
														<FloatingLabel
															controlId="floatingInput"
															label={feePercantage ? t("punctualityDiscount1") : t("punctualityDiscount")}
														>
															<Form.Control
																type="text"
																placeholder={t("punctualityDiscount")}
																className={`${errors.punctuality ? "is-invalid" : ""
																	}`}
																{...register("punctuality")}
																onChange={e => feePercantage ? handleMoney(e, 'punctuality') : handlePercentage(e, 'punctuality')}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.punctuality?.message}
															</Form.Control.Feedback>
														</FloatingLabel>
													</Col>
												</Row>
											</div>

						  					<Row className="g-3 mb-3 ml-2">
												<Col md={12}>
													<Form.Group
														className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center"
													>
														<Form.Check
															type="checkbox"
															{...register("retain_transfer")}
															label={t("checkboxLabel.Retaintransfer")}
															disabled={pay === 1 || (agencyOwned ? true : false)}
														/>
													</Form.Group>
												</Col>
											</Row>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6" className="mb-3">
										<Accordion.Header id="contractsituationinput">
											<div className="d-flex align-items-center justify-content-start w-100">
												<img
													src="../assets/images/icons/contract-situation.svg"
													alt="Contract situation icon"
													className="me-xl-4 me-md-3 me-2 h-26"
												/>
												{t("newContractDev.ContractSituationSection")}
												{tick6 === "" || tick6 === "empty" ? (
													<img
														src="../assets/images/icons/circle1.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
												{tick6 === "all" ? (
													<img
														src="../assets/images/icons/check-circle-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : tick6 === "some" ? (
													<img
														src="../assets/images/icons/progress-success.svg"
														alt="complete"
														className="h-26 ms-auto me-xl-4 me-md-3 me-2"
													/>
												) : (
													""
												)}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<Row className="g-3 mb-4 mb-3">
												<Col md={6}>
													<Form.Group>
														<Form.Check
															checked={isActive === 1}
															type="radio"
															{...register("is_active")}
															onChange={issActive}
															value="1"
															label={t("active")}
														/>
													</Form.Group>
													<span className="fs-12 d-block text-secondary ms-4 ps-1 mt-1">
														{t("newContractDev.ActiveText")}
													</span>
												</Col>
												<Col md={6}>
													<Form.Group>
														<Form.Check
															checked={isActive === 3}
															type="radio"
															{...register("is_active")}
															onChange={issActive}
															value="3"
															label={t("pending")}
														/>
													</Form.Group>
													<span className="fs-12 d-block text-secondary ms-4 ps-1 mt-1">
														{t("newContractDev.PendingText")}
													</span>
												</Col>
											</Row>
											<FloatingLabel
												controlId="floatingInput"
												label={t("newContractDev.ChecklistTemplate")}
											>
												<Form.Select
													className={`${errors.checklistTemplate ? "is-invalid" : ""
														}`}
													{...register("checklistTemplate")}
													aria-label={t("newContractDev.ChecklistTemplate")}
												>
													<option value="slct">{t("dropdownItem.select")}..</option>
													{checklistData.data && checklistData.data.length > 0
														? checklistData.data.map((item, index) => (
															<option key={index} value={item.id}>
																{item.name}
															</option>
														))
														: ""}
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													{errors.checklistTemplate?.message}
												</Form.Control.Feedback>
											</FloatingLabel>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Col>
						</Row>
						<footer className="footer-fixed-lg bg-white border-top py-2 px-xl-5 px-md-4 px-3 mx-lg-0 mx-md-n4 mx-n3 mb-n3 mb-md-n4 mb-xl-0 mt-auto d-flex flex-wrap justify-content-lg-end">
							{/* {aarinConfig == 1 &&
                            <Button variant="outline-primary" style={{ display: hide1 ? "none" : "block" }} onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : hide4 ? step3 : hide5 ? step4 : hide6 ? step5 : step6)} className="js-btn-step me-2">
                            {t('previous')}
                            </Button>
                            }
                            {aarinConfig == 1 &&
                            <Button variant="primary zipCodeCheck" style={{ display: hide7 ? "none" : "block" }} onClick={handleSubmit(hide1 ? step2 : hide2 ? step3 : hide3 ? step4 : hide4 ? step5 : hide5 ? step6 : step7)} className=" min-w-auto js-btn-step" >
                            {t('next')}
                            </Button>
                            } */}
							{/* <Button variant="outline-primary" className="me-lg-3 me-2">{t('button.cancel')}</Button> */}
							{
								loader_on ?
									<Button className="btn-width">
										<span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
									</Button>
									:
									<>
										<Button
											variant="outline-primary"
											className="me-lg-3 me-2"
											onClick={() => navigate(`/contract_details/${id}`)}
										>
											{t("button.cancel")}
										</Button>
										{/* <Button disabled={double} variant="primary" type="submit" onClick={openErrorbox}>
											{t('save')}
										</Button> */}
										<Button
											variant="primary"
											type="submit"
											// disabled={double}
											className="submitContractForm zipCodeCheck"
											hidden
										>
											{t("newContractDev.registerContract")}
										</Button>
										<Button
											variant="primary"
											// disabled={double}
											className="zipCodeCheck"
											onClick={(e) => handleOpen(e, "add")}
										>
											{i18n.language == "en" ? "Next" : "Próximo"}
										</Button>
									</>
							}
						</footer>
					</Form>
					{/* </Modal> */}
				</main>
				{/* <!-- /Main content start end --> */}
				{/* Successfully modal start from here */}
				<Modal show={newContractShow2} onHide={newContractClose2btn}>
					<Modal.Header closeButton>
						<Modal.Title>
							<span className="fs-12 d-block">Successfully Complete</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="text-center">
								<img
									src={
										process.env.PUBLIC_URL +
										"/assets/images/icons/thumb-up-light.svg"
									}
									alt="Documents add"
									className="h-40 cursor-pe"
								/>
								<h3 className="mt-3 mb-2">{t("contractGenerated")}</h3>
								<ul className="list-inline d-sm-flex align-items-center justify-content-center mb-lg-4 mb-3">
									<li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
										<Dropdown>
											<Dropdown.Toggle
												variant="light"
												size="sm"
												id="dropdown-basic"
											>
												{t("printOut")}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item href="#/action-1">
													{t("contractMirror")}
												</Dropdown.Item>
												<Dropdown.Item href="#/action-2">
													{t("contract")}
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</li>
									<li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
										<Button variant="light" size="sm">
											{t("launchRentalFee")}
										</Button>
									</li>
									<li className="list-inline-item mb-sm-0 mb-1">
										<Button variant="light" size="sm">
											{t("accessContract")}
										</Button>
									</li>
								</ul>
								<a className="text-secondary">{t("registerAthContract")}</a>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="outline-primary" onClick={newContractClose2btn}>
							{t("close")}
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={ShowPendingCharge} onHide={handleClose} className="chooseFomContractal">
					<Modal.Header closeButton>
						<Modal.Title>
							{t("newContractDev.pendingChargesAndTransfer")}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>{t("newContractDev.chooseFromContractual")}</h5>
						<br />
						<Row className="mt-4">
							<Col md={12}>
								<Form.Group className="mb-lg-4 mb-3">
									<Form.Check
										type="checkbox"
										id="keepexisting"
										name="keepexisting"
										checked={selectedCheckbox === "keepexisting"}
										onChange={() => handleCheckboxChange("keepexisting")}
										label={t("newContractDev.changeFromToday")}
									/>
								</Form.Group>
							</Col>

							<Col md={12}>
								<Form.Group className="mb-lg-4 mb-3">
									<Form.Check
										type="checkbox"
										id="keepexistingrecord"
										name="keepexistingrecord"
										checked={selectedCheckbox === "keepexistingrecord"}
										onChange={() => handleCheckboxChange("keepexistingrecord")}
										label={t("newContractDev.changeFromSelectedDate")}
									/>
									{selectedCheckbox === "keepexistingrecord" && (
										<Form.Control
											type="date"
											value={selectedDate}
											onChange={handleDateChange}
										/>
									)}
								</Form.Group>
							</Col>
							<Col md={12}>
								<Form.Group className="mb-lg-4 mb-3">
									<Form.Check
										type="checkbox"
										id="chargePending"
										name="chargePending"
										checked={selectedCheckbox === "chargePending"}
										onChange={() => handleCheckboxChange("chargePending")}
										label={t("newContractDev.lastPaymentDate")}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-primary"
							onClick={(e) => handleAction(e, "update")}
						>
							{t("button.save")}
						</Button>
						<Button
							variant="primary"
							onClick={(e) => handleNavigate(e, "update")}
						>
							{t("button.saveAndGenerateContractAmmendment")}
						</Button>
					</Modal.Footer>
				</Modal>
				{/* Successfully modal end here */}
				{/* other modal including */}
				<InsuranceService />
				<TenantsService />
				<ManagerService type={"contract"} />
				<PropertyService />
				<ContractCost
					showContractCost={showContractCostModal}
					contractCostClose={contractCostModalClose}
				/>
				<RemainingBalance
					showRemainingBalance={showRemainingBalanceModal}
					remainingBalanceClose={remainingBalanceModalClose}
				/>
				<ContractRegistered
					showContractRegistered={showContractRegisteredModal}
					contractRegisteredClose={contractRegisteredModalClose}
					nagivatefile={id}
				/>
				<CosignerService
					showNewCosigner={showNewCosignerModal}
					newCosignerClose={newCosignerModalClose}
				/>
				<NewGuarantors onComplete={onCompleteGuarantor} show={granterState} setShow={onChangeShow} getAllGuarantor={getAllGuarantor} />
			</section>
		);
	}
};
export default EditContractService;
