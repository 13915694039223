import { config, gettoken } from "../../../../services/apiConfig";

export async function getAccountsList(): Promise<
  Array<{
    id: number;
    nameReference: string;
    bankname: string;
    agency: string;
    account: string;
    accountType: number;
    status: number;
  }>> {

  let obj = {
    start: "0",
    end: "100",
    all: "",
    user_id: gettoken.userId,
  };

  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(`${config.companyV2_URL}/bank/user_bankList`, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
  });

  const data = await response.json();

  return data.data;
}
