import { useCallback, useState } from "react";
import { config } from "../../../../../../services/apiConfig";

type HomologatedBankAccount = {
  id: number;
  agencia: string;
  agencia_dv: string;
  ativo: boolean;
  codigo_banco: string;
  conta: string;
  conta_dv: string;
  tipo_conta: string;
  convenios: Array<{
    numero_convenio: string;
  }>
  titulo_variacao_carteira: string;
};

export function useHomologatedBankAccounts() {
  const [homologatedBankAccounts, setHomologatedBankAccounts] = useState<
    HomologatedBankAccount[]
  >([]);

  const getHomologatedBankAccounts = useCallback(async () => {
    const token = localStorage.getItem("tokenV2") ?? "";
    const connectionName = localStorage.getItem("userData") ?? "";

    const response = await fetch(
      `${config.TRANSFER_API}/homologate/bankslip/bank`,
      {
        method: "GET",
        headers: {
          "x-auth-token": token,
          "Content-type": "application/json",
          "connection-name": connectionName,
        },
      }
    );

    const { data } = await response.json();
    setHomologatedBankAccounts(data.bank);

    return data.bank;
  }, []);

  return {
    homologatedBankAccounts,
    getHomologatedBankAccounts,
  };
}
