import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useForm } from "react-hook-form";
import { incomeService } from "../../../../services/incomeServices";
import { gettoken } from "../../../../Api/Contact";
import moment from "moment-timezone";

import { converter } from "../../../constants/utils";

export function useChargeLiquidate(props) {
  let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");
  const { taxadata } = useSelector((state: RootState) => state.taxa);
  const { setValue } = useForm();
  const [extraAndDiscount, setExtraAndDiscount] = useState({});

  useEffect(() => {
    (async () => {
      let response = await incomeService.getDiscountData(gettoken.userId);
      setExtraAndDiscount(response.data);
    })();
  }, [taxadata]);

  async function getLiquidityInfos(liquidityId) {
    const response = await incomeService.getEditCharge(liquidityId);

    if (response.type.toLowerCase() !== "success") return null;

    const singleLiquidity = response.data;

    let liquidityData = singleLiquidity["data"];
    let discountAll = singleLiquidity["discounts"].apply_discount_all;
    let interestApply = singleLiquidity["discounts"].use_percentage;
    let discountService = singleLiquidity["discounts"].discountedService;
    let discountResult: [] | any = discountService.find(
      (item) => item.ID_PRODUTO_PRD === liquidityData["ID_PRODUTO_PRD"]
    );

    let totalAmt: any = parseFloat(liquidityData["vl_emitido_recb"]);
    let currentDate = moment.utc(dateBrazil).format("YYYY-MM-DD");
    let maturityDate = moment
      .utc(liquidityData["DT_VENCIMENTO_RECB"])
      .format("YYYY-MM-DD");

    let monthlyInterest =
      String(Number(liquidityData["VL_TXJUROS_RECB"])) !== "NaN" &&
      Number(liquidityData["VL_TXJUROS_RECB"]) != 0 &&
      liquidityData["VL_TXJUROS_RECB"]
        ? Number(liquidityData["VL_TXJUROS_RECB"])
        : Number(extraAndDiscount["monthly_interest"])
        ? Number(extraAndDiscount["monthly_interest"])
        : 1;
    let fineRate =
      String(Number(liquidityData["VL_TXMULTA_RECB"])) !== "NaN" &&
      Number(liquidityData["VL_TXMULTA_RECB"]) != 0 &&
      liquidityData["VL_TXMULTA_RECB"]
        ? Number(liquidityData["VL_TXMULTA_RECB"])
        : Number(extraAndDiscount["fine_rate"])
        ? Number(extraAndDiscount["fine_rate"])
        : 10;
    let discount =
      String(Number(liquidityData["VL_TXDESCONTO_RECB"])) !== "NaN" &&
      Number(liquidityData["VL_TXDESCONTO_RECB"]) != 0 &&
      liquidityData["VL_TXDESCONTO_RECB"]
        ? Number(liquidityData["VL_TXDESCONTO_RECB"])
        : Number(extraAndDiscount["discount_rate"])
        ? Number(extraAndDiscount["discount_rate"])
        : 0;

    if (
      liquidityData["NM_TXJUROS_CON"] &&
      liquidityData["NM_TXMULTA_CON"] &&
      liquidityData["NM_TXDESCONTO_CON"]
    ) {
      monthlyInterest =
        String(Number(liquidityData["NM_TXJUROS_CON"])) !== "NaN" &&
        Number(liquidityData["NM_TXJUROS_CON"]) != 0 &&
        liquidityData["NM_TXJUROS_CON"]
          ? Number(liquidityData["NM_TXJUROS_CON"])
          : Number(extraAndDiscount["monthly_interest"])
          ? Number(extraAndDiscount["monthly_interest"])
          : 1;
      fineRate =
        String(Number(liquidityData["NM_TXMULTA_CON"])) !== "NaN" &&
        Number(liquidityData["NM_TXMULTA_CON"]) != 0 &&
        liquidityData["NM_TXMULTA_CON"]
          ? Number(liquidityData["NM_TXMULTA_CON"])
          : Number(extraAndDiscount["fine_rate"])
          ? Number(extraAndDiscount["fine_rate"])
          : 10;
      discount =
        String(Number(liquidityData["NM_TXDESCONTO_CON"])) !== "NaN" &&
        Number(liquidityData["NM_TXDESCONTO_CON"]) != 0 &&
        liquidityData["NM_TXDESCONTO_CON"]
          ? Number(liquidityData["NM_TXDESCONTO_CON"])
          : Number(extraAndDiscount["discount_rate"])
          ? Number(extraAndDiscount["discount_rate"])
          : 0;
    }
    let calMonthlyInterest: any = 0;
    let calFineRate: any = 0;
    let calMonetary: any = 0;
    let calDiscount: any = 0;

    let disApplied = 0;
    if (discountResult !== undefined || discountAll == 1) {
      disApplied = 1;
    }

    let discountDate = maturityDate;
    if (extraAndDiscount["give_discount"]) {
      let dys = extraAndDiscount["give_discount"]
        ? Number(extraAndDiscount["give_discount"])
        : 0;
      discountDate = moment
        .utc(maturityDate)
        .subtract(dys, "days")
        .format("YYYY-MM-DD");
    }

    let grDys = liquidityData["NM_CARENCIA_CON"]
      ? Number(liquidityData["NM_CARENCIA_CON"])
      : 0;
    let graceFineDate = moment
      .utc(maturityDate)
      .add(grDys, "days")
      .format("YYYY-MM-DD");

    if (currentDate > graceFineDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      const fstDate = new Date(maturityDate);
      const scdDate = new Date(currentDate);
      const differenceMs = Math.abs(Number(fstDate) - Number(scdDate));
      const numDays = Math.round(differenceMs / oneDay);
      calMonthlyInterest =
        interestApply === true
          ? monthlyInterest
          : (totalAmt * monthlyInterest) /
            100 /
            Number(
              moment
                .utc(liquidityData["DT_VENCIMENTO_RECB"])
                .endOf("month")
                .format("DD")
            );
      calMonthlyInterest = calMonthlyInterest * numDays;
      calFineRate =
        interestApply === true ? fineRate : (totalAmt * fineRate) / 100;
      calMonetary = 0;
      totalAmt = totalAmt + calMonthlyInterest + calFineRate + calMonetary;
    }
    if (disApplied == 1 && currentDate < discountDate) {
      calDiscount =
        interestApply === true ? discount : (totalAmt * discount) / 100;
      totalAmt = totalAmt - calDiscount;
    }
    if (
      disApplied == 1 &&
      currentDate == discountDate &&
      Number(extraAndDiscount["monthly_interest"]) == 0
    ) {
      calDiscount =
        interestApply === true ? discount : (totalAmt * discount) / 100;
      totalAmt = totalAmt - calDiscount;
    }

    if (!Number.isInteger(totalAmt)) {
      totalAmt = totalAmt.toFixed(2);
    }
    if (!Number.isInteger(calMonthlyInterest)) {
      calMonthlyInterest = calMonthlyInterest.toFixed(2);
    }
    if (!Number.isInteger(calFineRate)) {
      calFineRate = calFineRate.toFixed(2);
    }
    if (!Number.isInteger(calDiscount)) {
      calDiscount = calDiscount.toFixed(2);
    }
    let paidAmount = totalAmt;
    let data = {
      action: "update",
      DT_GERACAO_RECB: graceFineDate,
      DT_COMPETENCIA_RECB: maturityDate,
      ID_RECEBIMENTO_RECB: liquidityData["ID_RECEBIMENTO_RECB"],
      DT_LIQUIDACAO_RECB: currentDate, //sale off
      DT_RECEBIMENTO_RECB: currentDate, // moment(currentDate["DT_VENCIMENTO_RECB"]).add(0, "days").format('YYYY-MM-DD'), //credit
      VL_TOTAL_RECB: converter(`${paidAmount}`), //amount
      settleAmount: 0,
      origionalAmount: totalAmt,
      VL_DESCONTOCALCULADO_RECB: calDiscount, //converter(`${calDiscount}`), //discount ----- notGetting
      ID_FORMAPAGAMENTO_RECB: liquidityData["ID_FORMAPAGAMENTO_RECB"], //Form of Payment
      print_receipt: false, //print_receipt
      debit_bank_fee: false, //debit_bank_fee
      VL_TXJUROS_RECB: converter(String(calMonthlyInterest)), //VL_TXJUROS_RECB
      VL_TXMULTA_RECB: converter(String(calFineRate)), //legal_trafic_ticket VL_TXMULTA_RECB
      vl_taxacobranca_recb: converter(String(calMonetary)), //legal_monetary vl_descontocalculado_recb ----- notGetting
      ID_CHEQUE_PRE: liquidityData["ID_CHEQUE_PRE"] ?? null,
      NM_CHEQUE_PRE: liquidityData["NM_CHEQUE_PRE"] ?? "", //check_number ----- notGetting
      ST_BANCO_PRE: liquidityData["ST_BANCO_PRE"] ?? "", //check_bank_number ----- notGetting
      ST_AGENCIA_PRE: liquidityData["ST_AGENCIA_PRE"] ?? "", //check_agency ----- notGetting
      ST_CONTA_PRE: liquidityData["ST_CONTA_PRE"] ?? "", //check_account ----- notGetting
      DT_VENCIMENTO_PRE: liquidityData["DT_VENCIMENTO_PRE"] ?? "", //check_good ----- notGetting
      ST_OBSERVACAOINTERNA_RECB:
        liquidityData["ST_OBSERVACAOINTERNA_RECB"] !== null
          ? liquidityData["ST_OBSERVACAOINTERNA_RECB"]
          : "", //internal_observation
      ID_CONTA_CB: liquidityData["ID_CONTA_CB"],
      ID_CONTRATO_CON: liquidityData["ID_CONTRATO_CON"]
        ? liquidityData["ID_CONTRATO_CON"]
        : "",
    };

    let fields = Object.keys(data);
    fields.forEach((field: any) => setValue(field, data?.[field]));

    return data;
  }

  return {
    getLiquidityInfos,
  };
}
