import React from "react";

import { iconLibrary } from "./iconLibrary";

type DynamicIconProps = {
  name: keyof typeof iconLibrary;
  color?: string;
  size?: number;
};

export function Icon({
  name,
  color = "currentColor",
  size = 24,
}: DynamicIconProps) {
  const IconSvg = iconLibrary[name];

  if (!IconSvg) {
    console.warn(`Icon '${name}' not found in the icon library`);
    return null;
  }

  return React.cloneElement(IconSvg, {
    fill: color,
    width: size,
    height: size,
  });
}
