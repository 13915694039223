import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { get_BankAccountList, Newbankaccount, setEdit } from '../../../../redux/slice/bankSlice';
import { getBankList, updateSingle_Bank, handleEdit, Single_Bank, Main_Account } from "../../../../redux/slice/bankSlice"
import { Modal, Form, Button, Row, Col, Table, Accordion, Card, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from "moment-timezone";
import { getBraches } from '../../../../redux/slice/branchSlice';
import { openErrorbox } from '../../../Pages/Contracts/Common/actionPerform';
import { convertToNumber, converter, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import { integrateBankAccountWithSplitPayment } from '../FormsOfReceipt/BankAccounts/services';
// used it to show validation error on form
interface AccountType {
    value: number;
    label: string;
}

const EditBankAccount = ({ bankid, setId }) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    // Getting data from redux toolkit and post is used dispatch
    const dispatch = useDispatch<AppDispatch>()
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation();

    const [actType, setActType] = useState(true);
    const regexNotPattern = /^[0-9-]+$/;
    let first = {
        nameReference: yup.string().required(t("requiredMmessage.fieldRequired")),
        accountType: yup.string().required(t("requiredMmessage.fieldRequired")),
    }
    let second = {
        // nameinBank: yup.string().required(t("requiredMmessage.fieldRequired")),
        bankname: yup.string().required(t("requiredMmessage.fieldRequired")),
        moneyaccount: yup.string().required(t("requiredMmessage.fieldRequired")),
        account: yup.string().trim().nullable()
            .matches(regexNotPattern, { message: t('requiredMmessage.Thevaluemustbeanumber') })
            .required(t("requiredMmessage.fieldRequired")),
        agency: yup.string().trim().nullable()
            .matches(regexNotPattern, { message: t('requiredMmessage.Thevaluemustbeanumber') })
            .required(t("requiredMmessage.fieldRequired")).min(4, t("requiredMmessage.Agencyrequire")),
        branchtype: yup.string().required(t("requiredMmessage.fieldRequired")),
        date: yup.string().required(t("requiredMmessage.fieldRequired")),
    }
    if (actType) {
        first = { ...first, ...second }
    }

    let Schema = yup.object().shape(first)
    var frm = {
        resolver: yupResolver(Schema)
    }
    const handleAccountType = (vl) => {
        setActType(true);
        if (vl == 2) {
            setActType(false)
        }
    }
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<Newbankaccount>(frm);

    // usestate is created to change the data
    const myRef: any = useRef(null);
    const [currShow, setCurrShow] = useState<number>(10);
    const [allShow, setAllShow] = useState<string>('')
    const [minDate, setMinDate] = useState("01/01/2022")
    // Retrieve branchlist, show1, and status from the Redux state
    const { show, status, banklist } = useSelector((state: RootState) => state.bank)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const [loader_on, setloader_on] = useState(false);
    const [valueCon, setValueCon] = useState('')
    const maxDate = () => {
        const today = new Date(dateBrazil).toISOString().split('T')[0];
        return today;
    };
    // Create an object to store the pagination details
    let obj = {
        start: "0",
        end: currShow,
        all: allShow
    }
    const [accounType, setAccountType] = useState<AccountType[]>([
        { value: 1, label: `${t("banks.checkingAccount")}` },
        { value: 2, label: `${t("banks.box")}` },
        { value: 3, label: `${t("banks.investment")}` }
    ])
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    useEffect(() => {
        dispatch(getBraches(branchState))
    }, [dispatch])

    useEffect(() => {
        // Dispatch actions to get bank list and main account
        // dispatch(getBankList(obj));
        // dispatch(Main_Account());
        // Asynchronous function to fetch single bank data
        (async () => {
            let data = await dispatch(Single_Bank(bankid))
            // 'nameinBank'
            const fields = ['nameReference', 'bankname', 'agency', 'account', 'accountType', 'date', 'closingDate', 'branchtype'] as const;
            fields.forEach((field) => {
              if (field === "account" && data.payload.data[0]?.["account"]) {
                const [bankAccount, bankAccountDigit] = data.payload.data[0]?.["account"].split("-")
                setValue("account", bankAccount)
                setValue("bankAccountDigit", bankAccountDigit)
                return
              }
              setValue(field, data.payload.data[0]?.[field])
            });
            let moneyaccounts: any = String();
            setValue("moneyaccount", data?.payload?.data[0]?.moneyaccount ? converter(data?.payload?.data[0]?.moneyaccount) : "0,00");
            setValueCon(data?.payload?.data[0]?.moneyaccount)
            setMinDate(data.payload.data[0]?.['date']);
            handleAccountType(data.payload.data[0]?.['accountType']);
        })();
    }, [dispatch, currShow, allShow, bankid])
    // Function to handle closing the edit mode
    const handleClose = () => {
        if (!loader_on) {
            dispatch(handleEdit(false));
            setActType(true);
            setId("");
            reset();
        }
    }
  
    const onSubmit = async (data: Newbankaccount) => {
        // Set the bank ID
        data['id'] = bankid
        let moneyaccounts: any = convertToNumber(valueCon);
        data["moneyaccount"] = moneyaccounts;
        // Dispatch the updateSingle_Bank action and wait for the response
        setloader_on(true);
        data.account = `${data.account}-${data.bankAccountDigit ? data.bankAccountDigit : "0"}`
        let res = await dispatch(updateSingle_Bank(data))
        if (res && res?.payload && res.payload.type == 'Success') {
            integrateBankAccountWithSplitPayment(Number(bankid))
                .catch(err => console.log(err))

            Swal.fire({
                position: 'center',
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.RecordUpdated')}`,
                showConfirmButton: false,
            })
            // Reset the form and ID
            dispatch(Main_Account())
            dispatch(handleEdit(false))
            dispatch(get_BankAccountList({
                start: "0",
                end: "1000"
            }))
            dispatch(getBankList({
                start: "0",
                end: "1000"
            }))
            setloader_on(false);
            reset()
            dispatch(get_BankAccountList(obj))
            setId("");
        } else if (res?.payload?.data?.resCode == 211) {
            // Show an error message if the account number is not unique
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: t("Sweetalert.NameReferencealreadyexists"),
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            })
        } else if (res?.payload?.data?.resCode == 210) {
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: t("Sweetalert.accountnumberalreadyexists"),
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            })
        } else {
            if (res?.payload?.resCode == 400) {
                res.payload.message = res.payload.data.message;
            }

            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: res?.payload?.message,
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
            })
        }
        setloader_on(false);
        dispatch(setEdit(data));
    }

    const AddAmount = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("moneyaccount", formatNumber(value))
            setValueCon(value)
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} id="EditBankAccounttsx">
                <Modal.Header closeButton>
                    <Modal.Title>{t("banks.bankAccounts")}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body className='py-2'>
                        {/* Basic info for account add start from here */}
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={12}>
                                <Form.Floating>
                                    {/* onChange={(e) => handleAccountType(e.target.value)} */}
                                    <Form.Select className={`${errors.accountType ? 'is-invalid' : ''}`} disabled {...register('accountType')} aria-label="conta corrente" >
                                        <option value="0">{t("formLabel.select")}</option>
                                        <option value="1">{t("banks.checkingAccount")}</option>
                                        <option value="2">{t("banks.box")}</option>
                                        <option value="3">{t("banks.investment")}</option>
                                    </Form.Select >
                                    <Form.Control.Feedback type="invalid">{errors.accountType?.message}</Form.Control.Feedback >
                                    <Form.Label htmlFor="accountType">{t("banks.accountType")+ " *"}</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={12}>
                                <Form.Floating>
                                    <Form.Control type="text" className={`${errors.nameReference ? 'is-invalid' : ''}`} id="nameReference"
                                        placeholder="Name Reference" {...register("nameReference")} />
                                    <Form.Control.Feedback type="invalid" >{errors.nameReference?.message}</Form.Control.Feedback >
                                    <Form.Label htmlFor="nameReference">{t("formLabel.nameReference")+ " *"}</Form.Label>
                                </Form.Floating>
                            </Col>  
                            {actType ?
                                <>
                                    <Col md={12} className={`${errors.bankname ? 'is-invalid' : ''}`}>
                                        <InputGroup>
                                            <Form.Floating>
                                                <Form.Select className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"  {...register("bankname")}>
                                                    <option value=''>{t("formLabel.select")}</option>
                                                    {banklist && banklist.length > 0 &&
                                                        banklist.map((curElem: any, index: number) =>
                                                            <option key={index} value={curElem.bank_code}>{curElem.bank_name}</option>
                                                        )}
                                                </Form.Select >
                                                <Form.Label htmlFor="floatingInputValue">{t("banks.name")+ " *"}</Form.Label >
                                            </Form.Floating>
                                        </InputGroup> 
                                        <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback >
                                    </Col>
                                    <Col md={6}>
                                      <Form.Floating>
                                        <Form.Control
                                          className={`${errors.account ? 'is-invalid' : ''}`}
                                          id="account"
                                          placeholder="Conta"
                                          {...register("account")}
                                          onChange={(e: any) => {
                                            const newValue = e.target.value;
                                            setValue("account", newValue.replace(/\D/, ""))
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.account?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">Conta *</Form.Label>
                                      </Form.Floating>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Floating>
                                        <Form.Control
                                          id="bankAccountDigit"
                                          placeholder="Dígito"
                                          maxLength={1}
                                          {...register("bankAccountDigit")}
                                        />
                                        <Form.Label htmlFor="floatingInputValue">Dígito</Form.Label>
                                      </Form.Floating>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Floating>
                                        <Form.Control
                                          className={`${errors.agency ? 'is-invalid' : ''}`}
                                          id="agency"
                                          placeholder="Agência"
                                          {...register("agency")}
                                          maxLength={4}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.agency?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">Agência *</Form.Label>
                                      </Form.Floating>
                                    </Col>
                                    {/* <Col md={12}>
                                        <Form.Floating>
                                            <Form.Control type="text" className={`${errors.nameinBank ? 'is-invalid' : ''}`} id="nameinBank"
                                                placeholder="Name In Bank Account" {...register("nameinBank")} />
                                            <Form.Control.Feedback type="invalid" >{errors.nameinBank?.message}</Form.Control.Feedback >
                                            <Form.Label htmlFor="nameinBank">{t("formLabel.nameinBank")}</Form.Label>
                                        </Form.Floating>
                                    </Col> */}
                                    </>
                                : ''
                            }
                                    <Col md={12}>
                                        <Form.Floating>
                                            <Form.Control type="text" className={`${errors.moneyaccount ? 'is-invalid' : ''}`} id="moneyAccount"
                                                placeholder="Money Acount"  {...register("moneyaccount")} onChange={(e) => AddAmount(e)} />
                                            <Form.Label htmlFor="moneyAccount">{t("banks.howMuchMoney")+ " *"}</Form.Label>
                                            <Form.Control.Feedback type="invalid">{errors.moneyaccount?.message}</Form.Control.Feedback >
                                        </Form.Floating>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Floating>
                                            <Form.Control type="date" className={`${errors.date ? 'is-invalid' : ''}`} id="value"
                                                placeholder="When did you see that it had that value?" max={maxDate()}  {...register("date")} readOnly disabled/>
                                            <Form.Label htmlFor="value">{t("banks.whenDidYou")+ " *"}</Form.Label>
                                            <Form.Control.Feedback type="invalid">{errors.date?.message}</Form.Control.Feedback >
                                        </Form.Floating>
                                    </Col>
                               
                        </Row>
                        {/* /Basic info for account add end */}
        
                                <Accordion defaultActiveKey="0" className="accordion-custom">
                                    {/* More options for bank account add section start from here */}
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header >{t("banks.moreOptions")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className="gy-3">
                                                <Col md={12}>
                                                    <Form.Floating>
                                                        <Form.Select className={`${errors.branchtype ? 'is-invalid' : ''}`} aria-label="branch" {...register("branchtype")}>
                                                            <option value="">{t("formLabel.select")}</option>
                                                            {branchList && branchList.length > 0 &&
                                                                branchList.map((curElem: any, index: number) => {
                                                                    // if (curElem.id !== 0) {
                                                                        return (<option key={index} value={curElem.id}>{curElem.name}</option>)
                                                                    // }
                                                                })}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{errors.branchtype?.message}</Form.Control.Feedback >
                                                        <Form.Label htmlFor="branch">{t("formLabel.branch")+ " *"}</Form.Label>
                                                    </Form.Floating>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* /More options for bank account add section end */}
                                </Accordion> 
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                     <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={handleClose}>{t("button.close")}</Button>
                                    <Button variant="primary" id="editSubmit" type="submit" onClick={openErrorbox}>{t("button.toSave")}</Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <!-- /Bank account edit modal end --> */}
        </>
    )
}

export default EditBankAccount
