import React, { useEffect, useMemo, useState } from 'react';
import { ComProps, } from '../../../Interface';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NewTaxCode from './NewTaxCode';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { ServiceRecord, Taxationofservices, Newtaxopn, delete_Taxation, deleteTaxation, getTaxation, EditTaxation, updateServiceRecord, getCertificate, registerCompnay, updateCompnay, getNfService, GetregisterCompnay, GetCityCode } from '../../../../redux/slice/taxationSlice';
import { Modal, Form, Button, Row, Col, Accordion, FloatingLabel, Table, Alert, } from 'react-bootstrap';
import NFEservice from './NFEservice';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import Swal from 'sweetalert2';
import Pagination from '../../../Pagination';
import { CNJP_CPFValidation, encrypt_Decrypt, checkDuplicateCNJP_CPF, converter, formatNumber, convertToNumber } from '../../../constants/utils';
import { useNavigate } from 'react-router-dom';
import { registerData } from '../../../../redux/slice/companyDataSlice';
import { numericOnly } from '../../../Pages/Contracts/Common/actionPerform';
import { useVerifyApprovedCity } from '../../../../services/hooks';
import { config } from "../../../../services/apiConfig";

const Taxation = ({ submenu }: ComProps) => {
  const { isCityApproved, verifyCity } = useVerifyApprovedCity()
  const [t] = useTranslation()
  const navigate = useNavigate();
  const [userJson, setUserJson] = useState<any>([]);
  const [certificate_id, setCertificate_id] = useState("")
  const [cityCodes, setCityCode] = useState<any>('')
  const [cityName, setCityName] = useState('')
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(2010) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
  }, [])
  // used it to show validation error on form
  const validFormat = /^[a-zA-Z0-9]+$/;
  let mandatory: any = {
    municipal_registration: yup
      .string()
      .required(t("requiredMmessage.fieldRequired"))
      .trim()
      .matches(validFormat, { message: t("requiredMmessage.Specialcharacters") })
      .nullable(),
    state_registration: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    approximate_amount_of_taxes: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    tax_regime: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    Taxregime1: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    typeofpatio: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
  }
  // used it to show validation error on form
  let other: any = {
    Withholding_IRRF: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    Withhold_income_tax: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    PISWithholding: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    cofins_retention: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    social_contribution_tax: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    withhold_tac: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    INSS_Withholding: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),

  }
  // usestate is created to change the data
  const [check, setCheck] = useState<number>(1);
  const [picture, setPicture] = useState('')
  // Initialize alert & modal
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);
  const [showCity, setShowCity] = useState(true);
  const [showTaxation, setShowTaxation] = useState(false)
  const [taxationid, SetTaxationId] = useState()
  const [taxData, settaxData] = useState<any>([])
  const [isvisible, setIsvisible] = useState(true)
  let Schema = (check) ? yup.object().shape({ ...mandatory }) : yup.object().shape({ ...mandatory, ...other });
  // This line initializes the useDispatch hook and assigns it to the dispatch variable
  const dispatch = useDispatch<AppDispatch>()
  // The useForm hook is used to handle form registration, submission, and validation.
  const { handleSubmit, register, setValue, getValues, clearErrors, setError, formState: { errors } } = useForm<Taxationofservices>({
    resolver: yupResolver(Schema),
  });
  // Used Redux-Toolkit, Got the data and Destructors the data from taxation inside the redux folder
  const { taxadata, status1, taxalist, certificate, NFEservice1 } = useSelector((state: RootState) => state.taxa)

  const serviceData: any = useSelector((state: RootState) => state.service);
  const { companydata } = useSelector((state: RootState) => state.companyData);
  const [taxregime, setTaxRegime] = useState([
    { value: "0", label: "Microempresário e Empresa de Pequeno Porte (ME EPP)" },
    { value: "1", label: "Microempresa Municipal" },
    { value: "2", label: "Estimativa" },
    { value: "3", label: "Sociedade de Profissionais" },
    { value: "4", label: "Cooperativa" },
    { value: "5", label: "Microempresário Individual (MEI)" },
    { value: "6", label: "ISS/Fixo Autônomo" },
    { value: "7", label: "Sociedade Limitada" },
    { value: "8", label: "Sociedade Anônima" },
    { value: "9", label: "Empresa Individual de Resp.Limitada (EIRELI)" },
    { value: "10", label: "Outros" },
  ])

  const [percentValues, setPercentValues] = useState({})

  // Pop up show of new taxa service code on click
  const handleShow = () => {
    dispatch(Newtaxopn())
    dispatch(getNfService());
    setShowTaxation(true)
  }
  // Pop up show of Edit taxa service code on click
  const EditShowTaxa = (id) => {
    SetTaxationId(id)
    // dispatch(EditTaxation())
    setShowTaxation(false)
    dispatch(Newtaxopn())
  }
  //Getting the data shown when the page is rendered for the first time --registerData and getbranches
  useEffect(() => {
    dispatch(ServiceRecord())
    dispatch(getCertificate());
    dispatch(registerData());
  }, [dispatch,])

  useEffect(() => {
    const { city, state } = companydata[0] ?? {}

    if (city && state) verifyCity(city, state)
    if (companydata.length) {
      setCityName(companydata[0]?.city)
      cityCode()
    }
  }, [companydata])

  const cityCode = async () => {
    let res = await dispatch(GetCityCode({zipCode:taxData[0]?.Zipcode ? taxData[0]?.Zipcode : companydata[0].zipcode}))
    if (res.payload) {
      // const filteredData = res.payload.filter(item => item?.nome == companydata[0]?.city && item?.uf == companydata[0]?.state);
      // setCityCode(filteredData[0]?.id)
      setCityCode(res?.payload?.ibge)
    }
  }
  //When we first have to show the data in the input field
  const percentFields: string[] = ['approximate_amount_of_taxes', 'municipal', 'state', 'federal', 'Withholding_IRRF', 'Withhold_income_tax', 'PISWithholding', 'cofins_retention', 'social_contribution_tax', 'withhold_tac', 'INSS_Withholding',]
  useEffect(() => {
    const fields: string[] = ['CNPJ', 'municipal_registration', 'state_registration', 'approximate_amount_of_taxes', 'tax_regime', 'cultural_encouraging', 'opt_for_simple_nacional', 'Withholding_IRRF', 'PISWithholding', 'cofins_retention', 'social_contribution_tax', 'withhold_tac', 'TransmissãoNacional', 'Withhold_income_tax', 'INSS_Withholding', 'municipal', 'state', 'federal', 'Taxregime1', 'typeofpatio'];
    if (taxadata && taxadata.length > 0) {
      settaxData(taxadata)
      fields.forEach((field: any) => setValue(field, taxadata?.[0]?.[`${field}`]));
      percentFields.forEach((field: any) => {
        const value = String(taxadata?.[0]?.[`${field}`]);
        const cleanedValue = value?.replace(/[^\d,]/g, ''); // clean value

        setPercentValues(values => ({ ...values, [field]: cleanedValue }));
        const approximateTaxes = taxadata?.[0]?.approximate_amount_of_taxes || "0,00";
        setValue('approximate_amount_of_taxes', converter(approximateTaxes));
        const Withholding_IRRF = taxadata?.[0]?.Withholding_IRRF || "0,00";
        setValue('Withholding_IRRF', converter(Withholding_IRRF));
        const Withhold_income_tax = taxadata?.[0]?.Withhold_income_tax || "0,00";
        setValue('Withhold_income_tax', converter(Withhold_income_tax));
        const PISWithholding = taxadata?.[0]?.PISWithholding || "0,00";
        setValue('PISWithholding', converter(PISWithholding));
        const cofins_retention = taxadata?.[0]?.cofins_retention || "0,00";
        setValue('cofins_retention', converter(cofins_retention));
        const social_contribution_tax = taxadata?.[0]?.social_contribution_tax || "0,00";
        setValue('social_contribution_tax', converter(social_contribution_tax));
        const withhold_tac = taxadata?.[0]?.withhold_tac || "0,00";
        setValue('withhold_tac', converter(withhold_tac));
        const INSS_Withholding = taxadata?.[0]?.INSS_Withholding || "0,00";
        setValue('INSS_Withholding', converter(INSS_Withholding));




      });

      setCheck(taxadata?.[0]?.['opt_for_simple_nacional'])
    }
    // Data ko retrieve karne ke liye (agar zarurat ho)
    // const retrievedData: any = localStorage?.getItem('tipoLogradouro');
    // if (retrievedData) {
    //   const parsedData = JSON.parse(retrievedData);
    //   setValue('typeofpatio', parsedData?.typeofpatio)
    //   setValue('Taxregime1', parsedData?.Taxregime1)
    // } else {
    //   setValue('typeofpatio', "")
    //   setValue('Taxregime1', "")
    // }

  }, [taxadata?.[0]])
  // Used to Edit taxation servicedata and show data input field
  const HandleEdit = (e: any, cmd: string) => {
    e.preventDefault();
    let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement.parentElement;
    if (cmd == "edit") {
      if (thisDivform.querySelectorAll("#editTaxa")?.length) {
        thisDivform.querySelectorAll("#editTaxa input").forEach((item: any, index: number) => {
          item.removeAttribute('disabled');
        })
        thisDivform.querySelectorAll("#editTaxa select").forEach((item: any, index: number) => {
          item.removeAttribute('disabled');
        })
      }
      if (thisButton.querySelectorAll('.BtnAction.Update, .BtnAction.Edit')?.length > 0) {
        thisButton.querySelector('.BtnAction.Update')?.removeAttribute('hidden');
        thisButton.querySelector('.BtnAction.Edit')?.setAttribute('hidden', '');
      }
      setIsvisible(false)
    }
  }

  const handlePercentage = (e: any, key: any) => {
    let { value } = e.target;
    value = value.replace(/[^\d,]/g, '');
    if (value !== "") {
      setValue(key, formatNumber(value))
      setPercentValues({ ...percentValues, [key]: value })
    } else {
      setValue(key, "")
    }
  }

  // Used to edit Taxation of services rendered  data and submit
  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    if (certificate_id !== "" && certificate_id !== null && certificate_id !== undefined) {
      let cnpjDigitsOnly1 = data.CNPJ.replace(/\D/g, '');

      let obj = {
        "cpfCnpj": cnpjDigitsOnly1,
        'CNPJ': data.CNPJ,
        'municipal_registration': data.municipal_registration,
        'state_registration': data.state_registration,
        'approximate_amount_of_taxes': data.approximate_amount_of_taxes,
        'tax_regime': data.tax_regime,
        'cultural_encouraging': data.cultural_encouraging,
        'opt_for_simple_nacional': check,
        'Withholding_IRRF': data.Withholding_IRRF,
        'Withhold_income_tax': data.Withhold_income_tax,
        'PISWithholding': data.PISWithholding,
        'cofins_retention': data.cofins_retention,
        'social_contribution_tax': data.social_contribution_tax,
        'withhold_tac': data.withhold_tac,
        'INSS_Withholding': data.INSS_Withholding,
        'municipal': data.municipal,
        'state': data.state,
        'federal': data.federal,
        "Taxregime1": data.Taxregime1,
        "typeofpatio" :data.typeofpatio
      }

      Object.entries(percentValues).forEach(([key, value]) => {
        obj[key] = convertToNumber(value)
      })
      let cnpjDigitsOnly = data.CNPJ.replace(/\D/g, '');
      let registerCompany = {
        "cpfCnpj": cnpjDigitsOnly,
        'CNPJ': data.CNPJ ? data.CNPJ : companydata[0].cnpj,
        "inscricaoMunicipal": `${data?.municipal_registration}`,
        "inscricaoEstadual": `${data?.state_registration}`,
        "razaoSocial": `${companydata[0]?.company_name}`,
        "nomeFantasia": `${companydata[0]?.company_name}`,
        "certificado": certificate_id,
        "simplesNacional":  data.opt_for_simple_nacional == 0 ? false : true,
        "regimeTributario": Number(data.Taxregime1),
        "incentivoFiscal": true,
        "incentivadorCultural": data.cultural_encouraging,
        "regimeTributarioEspecial": Number(data.tax_regime),
        "endereco": {
          "bairro": `${taxData[0]?.neighborhood ? taxData[0]?.neighborhood : companydata[0].neighborhood}`,
          "cep": `${taxData[0]?.Zipcode ? taxData[0]?.Zipcode : companydata[0].zipcode}`,
          "codigoCidade": `${cityCodes}`,
          "descricaoCidade": `${companydata[0]?.city}`,
          "estado": `${taxData[0]?.state1 ? taxData[0]?.state1 : companydata[0]?.state}`,
          "logradouro": `${companydata[0]?.address}`,
          "numero": `${companydata[0]?.number}`,
          "tipoLogradouro": `${data.typeofpatio}`,
          "complemento": `${companydata[0]?.complement ? companydata[0]?.complement : ""}`,
        },
        "email": `${companydata[0]?.email}`,
        "nfse": {
          "ativo": true,
          "tipoContrato": 0,
          "config": {
            "producao": config.Compnayproducao,
            "nfseNacional": true,
            "consultaNfseNacional": true,
          }
        },
        "nfe": {
          "ativo": false,
          "tipoContrato": 0,

        },
        "nfce": {
          "ativo": false,
          "tipoContrato": 0,

        },
        "mdfe": {
          "ativo": false,
          "tipoContrato": 0,

        }
      }
      let updateCompany = {
        "cpfCnpj": cnpjDigitsOnly,
        'CNPJ': data.CNPJ ? data.CNPJ : companydata[0].cnpj,
        "inscricaoMunicipal": `${data?.municipal_registration}`,
        "inscricaoEstadual": `${data?.state_registration}`,
        "razaoSocial": `${companydata[0]?.company_name}`,
        "nomeFantasia": `${companydata[0]?.company_name}`,
        "certificado": certificate_id,
        "simplesNacional": data.opt_for_simple_nacional == 0 ? false : true,
        "regimeTributario": Number(data.Taxregime1),
        "incentivoFiscal": true,
        "incentivadorCultural": data.cultural_encouraging,
        "regimeTributarioEspecial": Number(data.tax_regime),
        "endereco": {
          "bairro": `${taxData[0]?.neighborhood ? taxData[0]?.neighborhood : companydata[0].neighborhood}`,
          "cep": `${taxData[0]?.Zipcode ? taxData[0]?.Zipcode : companydata[0].zipcode}`,
          "codigoCidade": `${cityCodes}`,
          "descricaoCidade": `${companydata[0]?.city}`,
          "estado": `${taxData[0]?.state1 ? taxData[0]?.state1 : companydata[0]?.state}`,
          "logradouro": `${companydata[0]?.address}`,
          "numero": `${companydata[0]?.number}`,
          "tipoLogradouro": `${data.typeofpatio}`,
          "complemento": `${companydata[0]?.complement ? companydata[0]?.complement : ""}`,
        },
        "email": `${companydata[0]?.email}`,
        "nfse": {
          "ativo": true,
          "tipoContrato": 0,
          "config": {
            "producao": config.Compnayproducao,
            "nfseNacional": true,
            "consultaNfseNacional": true,

          }
        },
        "nfe": {
          "ativo": false,
          "tipoContrato": 0,

        },
        "nfce": {
          "ativo": false,
          "tipoContrato": 0,

        },
        "mdfe": {
          "ativo": false,
          "tipoContrato": 0,

        }
      }
      let res = await dispatch(updateServiceRecord(obj))
      if(res?.payload?.type ==  "Success" || res?.payload?.type ==  "success"){
      let resp1 = await dispatch(GetregisterCompnay(registerCompany))
      if (resp1?.payload?.error) {
        let resp = await dispatch(registerCompnay(registerCompany))
        if (resp?.payload.message == "Cadastro efetuado com sucesso") {
          Swal.fire({
            icon: 'success',
            title: `${t("Sweetalert.Success")}`,
            text: `${t("Sweetalert.Recordupdated")} <br/> ${resp?.payload?.message}`,
            showConfirmButton: false,
            timer: 1000
          })
          // dispatch(editTaxaClose())
        } else if (resp?.payload?.error?.message == "Falha na validação do JSON de Empresa") {
          // Extract and format the error messages
          let errorMessages = Object.entries(resp?.payload?.error?.data.fields).map(
            ([key, value]) => `${key.split('.').pop()} : ${value}`
          ).join(', '); // Join messages with commas
          Swal.fire({
            icon: res?.payload?.type ==  "Success" || res?.payload?.type ==  "success" ? 'success' :"error",
            title: res?.payload?.type ==  "Success" || res?.payload?.type ==  "success" ? `${t("Sweetalert.Success")}` : `${t("Sweetalert.Error")}`,
            html: `${t("Sweetalert.Recordupdated")} <br/> <br/> ${t("Sweetalert.plunnotaerror")} <br/> <br/> ${resp?.payload?.error?.message}
             ${errorMessages}`,
            showConfirmButton: true,
          })
        }else{
          Swal.fire({
            icon: res?.payload?.type ==  "Success" || res?.payload?.type ==  "success" ? 'success' :"error",
            title: res?.payload?.type ==  "Success" || res?.payload?.type ==  "success" ? `${t("Sweetalert.Success")}` : `${t("Sweetalert.Error")}`,
            html: `${t("Sweetalert.Recordupdated")} <br/><br/> ${t("Sweetalert.plunnotaerror")} <br/><br/> ${resp?.payload?.error?.message}`,
            showConfirmButton: true,
            
          })
        }
      } else {
        let resp = await dispatch(updateCompnay(updateCompany))
        if (resp?.payload?.message == "Operação realizada com sucesso") {
          Swal.fire({
            icon: 'success',
            title: `${t("Sweetalert.Success")}`,
            text: `${t("Sweetalert.Recordupdated")} <br/>${resp?.payload?.message}`,
            showConfirmButton: false,
            timer: 1000
          })
        } else {
          // Extract and format the error messages
          let errorMessages;
          if (resp?.payload?.error?.data?.fields) {
            errorMessages = Object.entries(resp?.payload?.error?.data?.fields)?.map(
              ([key, value]) => `${key?.split('.')?.pop()} : ${value}`
            ).join(', '); // Join messages with commas
          }
          Swal.fire({
            icon: res?.payload?.type ==  "Success" || res?.payload?.type ==  "success" ? 'success' :"error",
            title: res?.payload?.type ==  "Success" || res?.payload?.type ==  "success" ? `${t("Sweetalert.Success")}` : `${t("Sweetalert.Error")}`,
            html: `${t("Sweetalert.Recordupdated")} <br/> <br/> ${t("Sweetalert.plunnotaerror")} <br/> <br/> ${resp?.payload?.error?.message} <br/> ${errorMessages ? errorMessages : ""}`,
            showConfirmButton: true,
          })
        }
      }
    }else{
      Swal.fire({
        // icon: 'success','error',
        title: `${t("Sweetalert.Error")}`,
        text: `${t("Sweetalert.somethingWent")}`,
        showConfirmButton: true,
      })
    }
    } else {
      Swal.fire({
        // icon: 'success','error',
        title: `${t("Sweetalert.Error")}`,
        text: `${t("Sweetalert.updateCertificate")}`,
        showConfirmButton: true,
      })
    }
    let thisDivform: any = e.target.parentElement.parentElement.parentElement.parentElement;
    let thisButton: any = e.target.parentElement.parentElement.parentElement;
    if (e.type == "submit") {
      if (thisDivform && thisDivform.querySelectorAll("#editTaxa")?.length) {
        thisDivform.querySelectorAll("#editTaxa input").forEach((item: any, index: number) => {
          item.setAttribute('disabled', '');
        });
      }
      if (thisDivform && thisDivform.querySelectorAll("#editTaxa")?.length) {
        thisDivform.querySelectorAll("#editTaxa select").forEach((item: any, index: number) => {
          item.setAttribute('disabled', '');
        });
      }
      if (thisButton && thisButton.querySelectorAll('.BtnAction.Update, .BtnAction.Edit, .BtnAction.Cancel')?.length > 0) {
        thisButton.querySelector('.BtnAction.Edit')?.removeAttribute('hidden');
        thisButton.querySelector('.BtnAction.Update')?.setAttribute('hidden', '');
      }
    }

  }
  // Show in image gate and input field on onchange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheck(1)
    } else {
      setCheck(0)
    }
  }
  //Getting the data shown when the page is rendered for the first time  
  useEffect(() => {
    if (check == 1) {
      document.querySelector(".Show_simple_nacional")?.setAttribute('hidden', '')
    } else {
      document.querySelector(".Show_simple_nacional")?.removeAttribute('hidden')
    }
  }, [check])

  useEffect(() => {
    dispatch(getTaxation())
  }, [dispatch])

  useEffect(() => {
    if (certificate.type == "Success") {
      let id = certificate?.data[0]?.ST_TOKEN_CERT
      setCertificate_id(id)
    }
  }, [certificate])
  // Pagination start
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const currentTableData: any = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    let newarr = taxalist && taxalist.length > 0 && taxalist.slice(firstPageIndex, lastPageIndex);
    return newarr;
  }, [currentPage, taxalist]);
  // Pagination End

  // To delete one by one taxation list one by one
  const DeleteTaxation = (cmd: string, id: number) => {
    if (cmd == "delete") {
      Swal.fire({
        title: `${t("Sweetalert.Warning")}`,
        text: `${t("Sweetalert.deleteTaxation")}`,
        // icon: 'success',"question",
        showCancelButton: true,
        confirmButtonText: `${t("Sweetalert.yesDeleteIt")}`,
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          let res = await dispatch(deleteTaxation(id));
          if (res.payload.type == "Success") {
            // Show a success message using Swal
            Swal.fire({
              // icon: 'success','success',
              title: `${t("Sweetalert.Success")}`,
              text: `${t("Sweetalert.deleteRecord")}`,
              showConfirmButton: false,
              timer: 1000
            })
            dispatch(delete_Taxation(id))
          } else {
            // Show an error message
            Swal.fire({
              // icon: 'success','error',
              title: `${t("Sweetalert.Error")}`,
              text: `${t("Sweetalert.somethingWent")}`,
              showConfirmButton: true,
            })
          }
        } else {
          console.log("no")
        };
      })
    }
  }
  const [showinform, setShowInform] = useState(false)
  const ShowInform = () => {
    setShowInform(true)
  }
  const handleCancel = () => {
    dispatch(getTaxation())
  }


  const handlemanicipal = (e: any) => {
    let { value } = e.target;
    const specialCharPattern = /[^a-zA-Z0-9]/;
    if (value.length > 15) {
      setError("municipal_registration", {
        type: "manual",
        message: t("requiredMmessage.7characters"),
      });
    } else if (specialCharPattern.test(value)) {
      setError("municipal_registration", {
        type: "manual",
        message: t("requiredMmessage.Specialcharacters"),
      });
    } else {
      clearErrors("municipal_registration");
    }
  };


  return (
    <section className="content-wrapper">
      {/* <!-- Breadcrumb section start from here --> */}
      {submenu}
      {/* <!-- /Breadcrumb section end -->
<!-- Main content start from here --> */}
      <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
        {/* {isCityApproved === false && (
            <Alert variant="danger" className="mb-lg-4 mb-3" show={showCity} onClose={() => setShowCity(false)} dismissible>
              {t("taxation.danger")}
            </Alert>
          )} */}
        <Row className="gy-3 gy-lg-4">
          <Col lg={8}>
            <Accordion defaultActiveKey="0">
              {/* Taxation of services rendered section start from here */}
              <Accordion.Item className="position-relative" eventKey="0">
                <Form onSubmit={handleSubmit(onSubmit)} id="editTaxa" className='texationTemplate'>
                  <div className="position-absolute end-0 top-0 mt-2 pt-1 me-5 me-sm-6 pe-2 z-index-5">
                    {
                      userJson && userJson.length > 0 && (userJson.indexOf(2011) !== -1) &&
                      <>
                        <div className='BtnAction Edit'>
                          <Button variant="link" size="sm" className="fw-bold lh-1 text-primary p-0" onClick={(e) => HandleEdit(e, "edit")}>{t("button.edit")}</Button>
                        </div>
                        <div className='BtnAction Cancel' hidden>
                          <Button variant="link" className="fw-bold lh-1 text-primary p-0 me-3" onClick={(e) => handleCancel()} type="submit" >
                            {"Cancel"}
                          </Button>
                        </div>
                        <div className='BtnAction Update' hidden>
                          <Button variant="link" size="sm" type='submit' className='zipCodeCheck fw-bold lh-1 text-primary p-0'>{t("button.update")}</Button>
                        </div>
                      </>
                    }
                  </div>
                  <Accordion.Header>{t("taxation.taxationOfServicesRendered")}</Accordion.Header>
                  <Accordion.Body>
                    <span className="d-block fw-bold"> {t("taxation.general")}</span>

                    <Alert bsPrefix="" variant="warning" className="mb-lg-4 mb-3" show={show} onClose={() => setShow(false)} dismissible>
                      {t("taxation.warning")}
                    </Alert>

                    <Alert variant="warning" className="my-lg-4 my-3" show={show2} onClose={() => setShow2(false)} dismissible>
                      <span className="d-block mb-2"> {t("taxation.important")}:</span>
                      <ul className="d-block ps-3 mb-0">
                        <li><span>{t("taxation.yourCompanyMust")}</span></li>
                        <li><span>{t("taxation.registerTheTax")}</span></li>
                        <li><span>{t("taxation.youAlsoNeedAn")} <span className="text-primary">{t("taxation.A1")}</span></span></li>
                      </ul>
                    </Alert>
                    {/* Taxation services from start from here */}
                    <Row className="mt-lg-4 mt-3">
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="CNPJ" label={t("formLabel.cnpj") + " *"}>
                          <Form.Control type="text"  {...register("CNPJ")} placeholder="" disabled  readOnly/>
                        </FloatingLabel>
                      </Col>
                      <div className="w-100"></div>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="municipalRegistration" label={t("formLabel.municipalregistration") + " *"}>
                          <Form.Control type="text" className={`${errors.municipal_registration ? 'is-invalid' : ''}`}
                            placeholder="Municipal registration" {...register("municipal_registration")} maxLength={15} onChange={(e) => handlemanicipal(e)} />
                          <Form.Control.Feedback className="invalid-feedback">{errors.municipal_registration?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="state" label={t("formLabel.stateregistration") + " *"}>
                          <Form.Control type="text" className={`${errors.state_registration ? 'is-invalid' : ''}`} placeholder="State registration" {...register("state_registration")} />
                          <Form.Control.Feedback type="invalid">{errors.state_registration?.message}</Form.Control.Feedback >
                        </FloatingLabel>
                      </Col>
                      <div className="w-100"></div>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="approximate_amount_of_taxes" label={t("formLabel.approximate") + " *"}>
                          <Form.Control type="text" className={`${errors.approximate_amount_of_taxes ? 'is-invalid' : ''}`}
                            placeholder="Approximate amount of taxes (%)" {...register("approximate_amount_of_taxes")} disabled onChange={e => handlePercentage(e, 'approximate_amount_of_taxes')} />
                          <Form.Control.Feedback type="invalid">{errors.approximate_amount_of_taxes?.message}</Form.Control.Feedback>
                          <span className="d-block fs-12 text-secondary mt-1 ms-3">{t("formLabel.IBPT")}</span>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="cultural_encouraging" label={t("formLabel.typeofpatio") + " *"}>
                          <Form.Select className={`${errors.typeofpatio ? 'is-invalid' : ''}`} aria-label="Tax regime" {...register("typeofpatio")} disabled>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <option value="Alameda">Alameda</option>
                            <option value="Avenida">Avenida</option>
                            <option value="Chácara">Chácara</option>
                            <option value="Colônia">Colônia</option>
                            <option value="Condomínio">Condomínio</option>
                            <option value="Eqnp">Eqnp</option>
                            <option value="Estância">Estância</option>
                            <option value="Estrada">Estrada</option>
                            <option value="Fazenda">Fazenda</option>
                            <option value="Praça">Praça</option>
                            <option value="Prolongamento">Prolongamento</option>
                            <option value="Rodovia">Rodovia</option>
                            <option value="Rua">Rua</option>
                            <option value="Sítio">Sítio</option>
                            <option value="Travessa">Travessa</option>
                            <option value="Vicinal">Vicinal</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.typeofpatio?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <a className="fw-bold cursor-pe" onClick={ShowInform}>{t("taxation.informTaxesByType")}</a>
                    {showinform &&
                      <Row className="mt-lg-4 mt-3">
                        <Col lg={5} md={6}>
                          <FloatingLabel className="mb-lg-4 mb-3" controlId="municipal" label={t("formLabel.municipal")}>
                            <Form.Control type="text" className={`${errors.municipal ? 'is-invalid' : ''}`}
                              placeholder="municipal" {...register("municipal")} disabled={isvisible} onChange={e => handlePercentage(e, 'municipal')} />
                            <Form.Control.Feedback className="invalid-feedback">{errors.municipal?.message}</Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                        <Col lg={5} md={6}>
                          <FloatingLabel className="mb-lg-4 mb-3" controlId="state registration" label={`${t("formLabel.state")} ${"(%)"}`}>
                            <Form.Control type="text" className={`${errors.state_registration ? 'is-invalid' : ''}`} placeholder="State registration" {...register("state")} disabled={isvisible} onChange={e => handlePercentage(e, 'state')} />
                            <Form.Control.Feedback type="invalid">{errors.state_registration?.message}</Form.Control.Feedback >
                          </FloatingLabel>
                        </Col>
                        <div className="w-100"></div>
                        <Col lg={5} md={6}>
                          <FloatingLabel controlId="federal" label={t("formLabel.federal")}>
                            <Form.Control type="text" className={`${errors.federal ? 'is-invalid' : ''}`}
                              placeholder="federal (%)" {...register("federal")} disabled={isvisible} onChange={e => handlePercentage(e, 'federal')} />
                            <Form.Control.Feedback type="invalid">{errors.federal?.message}</Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      </Row>}

                    <Row className="mt-lg-4 mt-3">
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="cultural_encouraging" label={t("formLabel.SpecialTaxRegime") + " *"}>
                          <Form.Select className={`${errors.tax_regime ? 'is-invalid' : ''}`} aria-label="Tax regime" {...register("tax_regime")} disabled>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <option value="1">Micro Empresa Municipal</option>
                            <option value="2">Estimativa</option>
                            <option value="3">Sociedade de Profissionais</option>
                            <option value="4">Cooperativa</option>
                            <option value="5">Microempresário Individual - MEI</option>
                            <option value="6">Microempresa ou Pequeno Porte - ME EPP</option>
                            <option value="7">Lucro Real</option>
                            <option value="8">Lucro Presumido</option>
                            <option value="9">Tributação Normal</option>
                            <option value="10">Simples nacional com excesso do sublimite</option>
                            <option value="11">Empresa de Responsabilidade Limitada</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.tax_regime?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="cultural_encouraging" label={t("formLabel.taxregime") + " *"}>
                          <Form.Select className={`${errors.Taxregime1 ? 'is-invalid' : ''}`} aria-label="Tax regime" {...register("Taxregime1")} disabled>
                            <option value="">{t('dropdownItem.select')}..</option>
                            <option value="0">Nenhum</option>
                            <option value="1">Simple national</option>
                            <option value="2">Simples Nacional - Excesso</option>
                            <option value="3">Regime Normal - Lucro Presumido</option>
                            <option value="4">Normal - Lucro Real</option>
                            <option value="5"> MEI (Microempreendedor individual)</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.Taxregime1?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    {/* <Form.Check type="checkbox" className={`mb - lg - 4 mb - 3`}  {...register("TransmissãoNacional")} id={`TransmissãoNacional`} label={t("formLabel.TransmissãoNacional")} /> */}
                    <Form.Check type="checkbox" className={`mb - lg - 4 mb - 3`}  {...register("cultural_encouraging")} id={`culturalEncouraging`} label={t("formLabel.culturalEncouraging")} />
                    <Form.Check className="mb-lg-4 mb-3" type="checkbox" {...register('opt_for_simple_nacional')} onChange={(e) => handleChange(e)} label={t("formLabel.optforsimple")} disabled onKeyDown={numericOnly} />
                    <Row className="Show_simple_nacional">
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="Withholding_IRRF" label={t("formLabel.withholding") + " *"}>
                          <Form.Control type="text" className={`${errors.Withholding_IRRF ? 'is-invalid' : ''}`} placeholder="Withholding IRRF (%)" {...register("Withholding_IRRF")} disabled onChange={e => handlePercentage(e, 'Withholding_IRRF')} />
                          <Form.Control.Feedback type="invalid">{errors.Withholding_IRRF?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="withholdingincome" label={t("formLabel.withholdingincome") + " *"}>
                          <Form.Control type="text" className={`${errors.Withhold_income_tax ? 'is-invalid' : ''}`} placeholder="Withholding income tax if amount greater than"
                            {...register("Withhold_income_tax")} disabled onChange={e => handlePercentage(e, 'Withhold_income_tax')} />
                          <Form.Control.Feedback type="invalid">{errors.Withhold_income_tax?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="PISWithholding" label={t("formLabel.pISWithholding") + " *"}>
                          <Form.Control type="text" className={`${errors.PISWithholding ? 'is-invalid' : ''}`} placeholder="PIS Withholding (%)" {...register("PISWithholding")} disabled onChange={e => handlePercentage(e, 'PISWithholding')} />
                          <Form.Control.Feedback type="invalid">{errors.PISWithholding?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="cofins_retention" label={t("formLabel.cofinsRetention") + " *"}>
                          <Form.Control type="text" className={`${errors.cofins_retention ? 'is-invalid' : ''}`} placeholder="Cofins Retention (%)"  {...register("cofins_retention")} disabled onChange={e => handlePercentage(e, 'cofins_retention')} />
                          <Form.Control.Feedback type="invalid">{errors.cofins_retention?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="social_contribution_tax" label={t("formLabel.CSocial") + " *"}>
                          <Form.Control type="text" className={`${errors.social_contribution_tax ? 'is-invalid' : ''}`}
                            placeholder="C. Social (%)"  {...register("social_contribution_tax")} disabled onChange={e => handlePercentage(e, 'social_contribution_tax')} />
                          <Form.Control.Feedback type="invalid">{errors.social_contribution_tax?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="withhold_tac" label={t("formLabel.Withholdtac") + " *"}>
                          <Form.Control type="text" className={`${errors.withhold_tac ? 'is-invalid' : ''}`} placeholder="Withhold tac if amount greater than" {...register("withhold_tac")} disabled onChange={e => handlePercentage(e, 'withhold_tac')} />
                          <Form.Control.Feedback type="invalid">{errors.withhold_tac?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col lg={5} md={6}>
                        <FloatingLabel controlId="INSS_Withholding" label={t("formLabel.INSSWithholding") + " *"}>
                          <Form.Control type="text" className={`${errors.INSS_Withholding ? 'is-invalid' : ''}`} placeholder="INSS Withholding (%)" {...register("INSS_Withholding")} disabled onChange={e => handlePercentage(e, 'INSS_Withholding')} />
                          <Form.Control.Feedback type="invalid">{errors.INSS_Withholding?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    {/* /Taxation services from end */}
                  </Accordion.Body>
                </Form>
              </Accordion.Item>
              {/* /Taxation of services rendered section end */}
              <Accordion.Item eventKey="1">
                <Accordion.Header >{t("taxation.serviceCodes1")}</Accordion.Header>
                <Accordion.Body>
                  {
                    userJson && userJson.length > 0 && (userJson.indexOf(2011) !== -1) &&
                    <>
                      <Button variant="light" size="sm" onClick={handleShow} className="mb-lg-4">{t("taxation.newTaxCode")}</Button>
                    </>
                  }
                  {/* <!-- Table start from here --> */}
                  <Table responsive className="table table-custom table-custom-bordered mb-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("taxation.CNAE")}</th>
                        <th>{t("taxation.serviceDescription")}</th>
                        <th>{t("taxation.services")}</th>
                        <th>{t("taxation.ISSQN")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxalist && taxalist.length > 0 ?
                        taxalist.map((curelm: any, index) => (
                          <tr key={index}>
                            <td>
                              {curelm.taxaCount === serviceData.length ?
                                <img src="assets/images/icons/oval-star.svg" alt="star" className="h-14 cursor-pe" />
                                : ""
                              }
                            </td>
                            <td className="text-dark-70">
                              <span className="d-block">{curelm.CNAE}</span>
                            </td>
                            <td className="text-dark-70">
                              {curelm.description}
                            </td>
                            <td className="text-dark-70">
                              {curelm.taxaCount}
                            </td>
                            <td className="text-dark-70" style={{ width: '10%' }}>
                              {converter(String(curelm.ISSQNrate)) + " " + "%"}
                            </td>
                            {/* <td className="text-end">s
                             <img onClick={(e) => EditShowTaxa(curelm.id)} src="assets/images/icons/edit-light.svg" alt="Edit" className="h-14 cursor-pe" />
                             <img onClick={(e) => DeleteTaxation("delete", curelm.id)} src="assets/images/icons/delete-light.svg" alt="Edit" className="h-14 cursor-pe" />
                            </td> */}
                            <td className="text-end">
                              {
                                userJson && userJson.length > 0 && (userJson.indexOf(2011) !== -1) &&
                                <>
                                  <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                    <li className="list-inline-item me-0">
                                      <Button variant="link" className="p-0" onClick={(e) => EditShowTaxa(curelm.id)}>
                                        <span className="cursor-pe icon"><img src="assets/images/icons/edit-primary.svg" alt="Edit" className="h-20" /></span>
                                      </Button>
                                    </li>
                                    <li className="list-inline-item">
                                      {curelm.taxaCount === 0 ?
                                        <Button variant="link" className="p-0 ms-2 ms-xxl-4 ms-lg-3" onClick={(e) => DeleteTaxation("delete", curelm.id)}>
                                          <span className="cursor-pe icon"><img className="h-20" src="./assets/images/icons/delete-danger.svg" alt="Delete" /></span>
                                        </Button> : ""}

                                    </li>
                                  </ul>
                                </>
                              }
                            </td>
                          </tr>)
                        )
                        : <tr><td colSpan={6} className="text-center">Data Not found</td></tr>}

                    </tbody>
                  </Table>
                  <p className="d-block mt-2 mb-0">{t("company.listing")} {taxalist?.length || 0} {t("taxation.ServiceCodes")}</p>
                </Accordion.Body>
              </Accordion.Item >
              {/* /Service codes and rates section end */}
            </Accordion>
          </Col>

          <Col lg={4}>

            <NFEservice cityName={cityName} />
          </Col>
        </Row>
      </main>
      {/* <!-- /Main content start end --> */}
      {/* New tax code modal start here  */}
      <NewTaxCode editId={taxationid} showTaxation={showTaxation} />
      {/* <!-- New tax code modal start here --> */}
    </section>
  )
}

export default Taxation