import React from "react";
import { Nav } from "react-bootstrap";

export const PANES = {
  charges: {
    key: "cobrancas",
    title: "Cobranças"
  },
  homologatedAccount: {
    key: "conta-homologada",
    title: "Conta homologada"
  },
} as const

type SwitchChargesTabPanesProps = {
  onSelectKey: (key: string) => void;
}

export function SwitchChargesTabPanes({ onSelectKey }: SwitchChargesTabPanesProps) {
  return (
    <Nav
      variant="pills"
      className="flex-row nav-pills-border border rounded-pill justify-content-between"
      defaultActiveKey="cobrancas"
      onSelect={(key) => {
        if (!key) {
          return
        }
        onSelectKey(key)
      }}
    >
      <Nav.Item className="text-center flex-grow-1">
        <Nav.Link eventKey={PANES.charges.key} title={PANES.charges.title}>
          Cobranças
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className="text-center flex-grow-1">
        <Nav.Link
          eventKey={PANES.homologatedAccount.key}
          title={PANES.homologatedAccount.title}
        >
          Conta homologada
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}
