import React from "react";

import { ChargesToReceiveProvider } from "./ChargesToReceiveProvider";
import { HomologatedAccount } from "./components";

export function ChargesToReceive() {
  return (
    <ChargesToReceiveProvider>
      <HomologatedAccount />
    </ChargesToReceiveProvider>
  );
}