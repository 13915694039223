import {
  Form,
  Accordion,
  Modal,
  Alert,
  Button,
  Row,
  Col,
  FloatingLabel,
  InputGroup,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  reInsuranceTp,
  insertRenewInsurance,
  contractDatails,
  insuranceOpen,
  getInsurance,
  renewInsuranceClose,
  getTenants,
  getProperty,
  getOwner,
  updateContractInsurancePolicy,
  getAllExpense
} from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { contractForm } from "../../../Interface";
import Swal from "sweetalert2";
import "../../../../App.css";
import moment from "moment";
import { getValue } from "@testing-library/user-event/dist/utils";
import InsuranceService from "./InsuranceService";
import { converter, convertToNumber, formatNumber, getMaxAssuredValue } from "../../../constants/utils";
import { registerData } from "../../../../redux/slice/propertyDataSlice";
import { config } from "../../../../services/apiConfig";
import Loader from "../../../Profile/Company/Loader";
interface Coverage {
  value: number;
  text: string;
  // Define properties of Coverage
}
interface tenantes {
  ID_PESSOA_PES: string;
  // Define properties of Coverage
}
interface InsuranceRecord {
  Id: number;
  Name: string;
  Total: number;
  Installments: number;
  TotalCalculatedByInstallments: number;
  Coverages: Coverage[]; // Ensure Coverages property is defined
  end2: string;
  start2: string;
  insEndLbl: string;
  immobile: string;
  rent_value: number;
  datatenants: tenantes[];
  insuranceCompanyName: string;
  insuranceCompany: string
  contract_type: any,
  assuredValue: any,
  suspended: any,
  is_active: any

  // Add other properties if needed
}

const RenewInsuranceService = (contractForm: any) => {
  const { newRenewInsuranceShow, insuranceData, status } = useSelector(
    (state: RootState) => state.contractData
  );
  // addInsurance
  let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");
  let { addIns, setMinCover, Errormessage, insuranceMaxVal, setInsuranceMaxVal } = contractForm;
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [t] = useTranslation();
  const [issValue, setIssValue] = useState(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState("");
  const [contractDetails, setContractDetails] = useState<InsuranceRecord[]>([]);
  const [quoteId, setQuoteId] = useState(0);
  const [contractStartData, setContractStartDate] = useState<any>('');
  const [contractEndDate, setContractEndDate] = useState<any>('');
  const [show, setShow] = useState(false)
  const [filteredData, setFilteredData] = useState<any>([]);
  const [Loading, setLoading] = useState(false)
  const [insurance, setInsurance] = useState(false);
  const [maxiumVal, setMaxiumVal] = useState<any>('')
  const [Responsible4, setResponsible4] = useState(false)
  // const [insuranceMaxVal, setInsuranceMaxVal] = useState<any>("");
  const [selectedFireInsurance, setSelectedFireInsurance] = useState<any>(null);
  const [premiumAmount, setPremiumAmount] = useState("");
  const items = [
    {
      contractType: 1,
      labels: ["Fire", "Fire", ""]
    },
    {
      contractType: 1,
      labels: ["lightning", "lightning", ""]
    },
    {
      contractType: 1,
      labels: ["explosion", "explosion", ""]
    },
    {
      contractType: 1,
      labels: ["smoke", "smoke", ""]
    },
    {
      contractType: 1,
      labels: ["aircraftcrash", "aircraftcrash", ""]
    },
    {
      contractType: 1,
      labels: ["Gale", "Gale", ""]
    },
    {
      contractType: 1,
      labels: ["hurricane", "hurricane", ""]
    },
    {
      contractType: 1,
      labels: ["cyclone", "cyclone", ""]
    },
    {
      contractType: 1,
      labels: ["tornado", "tornado", ""]
    },
    {
      contractType: 1,
      labels: ["hail", "hail", ""]
    },
    {
      contractType: 1,
      labels: ["electricaldamage", "electricaldamage", ""],
      val: 0
    },
    {
      contractType: 1,
      labels: ["PackageWithAssistanceContent4", "PackageWithAssistanceContent4", ""],
      val: 0
    },
    {
      contractType: 1,
      labels: ["Locksmith", "Locksmith", ""],
      val: 0
    },
    {
      contractType: 1,
      labels: ["unclogging", "unclogging", ""],
      val: 0
    },

    {
      contractType: 1,
      labels: ["electrician", "electrician", ""],
      val: 0
    },
    {
      contractType: 1,
      labels: ["plumber", "plumber", ""],
      val: 0
    }, {
      contractType: 1,
      labels: ["removalAndFurnitureStorage", "removalAndFurnitureStorage", ""],
      val: 0
    },
    {
      contractType: 1,
      labels: ["glazier", "glazier", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["Fire", "Fire", ""]
    },
    {
      contractType: 3,
      labels: ["lightning", "lightning", ""]
    },
    {
      contractType: 3,
      labels: ["explosion", "explosion", ""]
    },
    {
      contractType: 3,
      labels: ["smoke", "smoke", ""]
    },
    {
      contractType: 3,
      labels: ["aircraftcrash", "aircraftcrash", ""]
    },
    {
      contractType: 3,
      labels: ["Gale", "ResponsabilidadeCivil", ""]
    },
    {
      contractType: 3,
      labels: ["hurricane", "PackageWithAssistanceContent4", ""]
    },
    {
      contractType: 3,
      labels: ["cyclone", "Glass", ""]
    },
    {
      contractType: 3,
      labels: ["tornado", "mirrors", ""]
    },
    {
      contractType: 3,
      labels: ["hail", "marble", ""]
    },
    {
      contractType: 3,
      labels: ["Rentalexpenses", "Rentalexpenses", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["Gale", "Gale", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["hurricane", "hurricane", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["cyclone", "cyclone", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["tornado", "tornado", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["hail", "hail", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["Recomposiçãodedocumentos", "Recomposiçãodedocumentos", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["Locksmith", "Locksmith", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["Hidráulica", "Hidráulica", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["CoberturaProvisória", "CoberturaProvisória", ""],
      val: 0
    },
    {
      contractType: 3,
      labels: ["ColocaçãodeFechadura", "ColocaçãodeFechadura", ""],
      val: 0
    }
  ];
  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).subtract(1, "M");
  let searchDt = moment(newDate);
  let endDt = moment(newDate).add(1, "M");
  let yearDt = moment(newDate);
  const [state, setState] = useState<any>({
    id: id,
    startDate: startDt,
    searchDate: searchDt,
    endDate: endDt,
    type: "",
    expense: "",
    yearlySearch: yearDt,
    start: 0,
    end: 1000,
  });
  useEffect(() => {
    if (newRenewInsuranceShow) {
      dispatch(getInsurance({ insurance: "", start: 0, end: 1000 }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (newRenewInsuranceShow) {
      getContractsData(id);
    }
  }, [newRenewInsuranceShow]);

  const getContractsData = async (id: any) => {
    const fields: string[] = [
      "id",
      "start",
      "end",
      "prizeAmount",
      "installmentValue",
      "numberInstallments2",
      "insuranceCompany",
      "policy2",
      "contractedPlan",
      "responsible4",
      "observation4",
    ];
    if (typeof id !== "undefined" && id) {
      let data = await dispatch(contractDatails(Number(id)));
      if (data.payload.data.length > 0) {
        setClickedIndex(data.payload.data[0]["contractedPlan"])
        setContractDetails(data.payload.data);
        setIssValue(data.payload.data[0]["isContract"]);
        fields.forEach((field: any) =>
          setValue(field, data.payload.data[0][field]),
          setValue("assuredValue", converter(String(data?.payload?.data[0]?.assuredValue)))
        );
      }

    }
  };
  const newRenewInsuranceShowClose = () => {
    dispatch(renewInsuranceClose(false));
    reset();
    getContractsData(id);
    setLoading(false)
  };
  const newInsuranceOpen = (evt) => {
    dispatch(reInsuranceTp(evt.target.title));
    dispatch(insuranceOpen(true));
    dispatch(renewInsuranceClose(false));
  };
  const renewInsuranceValidation = Yup.object().shape({
    start: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    end: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    assuredValue: Yup.string()
      .trim()
      .required(t("requiredMmessage.fieldRequired"))
      .test("not-select", t("requiredMmessage.fieldRequired"), (value) => value !== "slct"),
    insuranceCompany: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    // policy2: Yup.string()
    //   .trim()
    //   .nullable()
    //   .required(t("requiredMmessage.fieldRequired")),
    contractedPlan: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
  });
  const renewInsuranceValidation1 = Yup.object().shape({
    start: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    end: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    assuredValue: Yup.string()
      .trim()
      .required(t("requiredMmessage.fieldRequired"))
      .test("not-select", t("requiredMmessage.fieldRequired"), (value) => value !== "slct").
      test(
        "minValue",
        t("requiredMmessage.fieldNotValid", { value: "150.000,00" }),
        (value: any) => {
          if (!isCheckboxChecked) {
            let numericValue = convertToNumber(String(value));
            if (String(numericValue) >= "150000") {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      ).test(
        "maxValue",
        t("requiredMmessage.fieldNotValid", { value: insuranceMaxVal }),
        (value: any) => {
          if (!isCheckboxChecked) {
            let numericValue = convertToNumber(String(value));
            if (String(numericValue) > String(insuranceMaxVal)) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        }
      ),
    insuranceCompany: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),
    // policy2: Yup.string()
    //   .trim()
    //   .nullable()
    //   .required(t("requiredMmessage.fieldRequired")),
    contractedPlan: Yup.string()
      .trim()
      .nullable()
      .required(t("requiredMmessage.fieldRequired")),

  });
  let frm = {
    resolver: yupResolver(renewInsuranceValidation1),
  };
  if (isCheckboxChecked == true) {
    frm = {
      resolver: yupResolver(renewInsuranceValidation),
    };
  }
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<contractForm>(frm);
  const onSubmit = async (data: contractForm, e: any) => {
    Swal.fire({
      title: t("Confirmation"),
      text: `${t("Areyousurei")} ${data?.type1 === "addInsurance" ? t("add") : t("renew")}   ${t("Sweetalert.thisInsurance")}`,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yesproceed"),
      cancelButtonText: t("Nocancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let assuredValue: any = `${data.assuredValue}`;
        let assuredVal: any = convertToNumber(assuredValue);
        data["assuredValue"] = assuredVal ? assuredVal : ""
        data["lastInstallment"] = premiumAmount
        // Get the select element
        const selectElement: any = document?.getElementById("insuranceCompany");
        const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("data-name");
        if (selectedDataName == "Tokio Marine") {
          setLoading(true)
          const response = await fetch(
            `${config.INSURANCEAPI}/legacy/auth/login`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "api_key": `${config.INSURANCEAPIKEY}`,
              },
              body: JSON.stringify({
                agency_license: `${localStorage?.getItem('agencyId')}`,
                loginvv: "lenilda.santos@ingaia.com.br",
                login: `${localStorage?.getItem('userEmailId')}`,
              }),
            }
          );
          const accessData = await response.json();
          if (accessData.access_token != "") {
            let contractData = await dispatch(contractDatails(Number(id)));
            const property_id = contractData?.payload?.data[0]?.immobile;
            const fetchPropertyDetails = await dispatch(registerData(property_id))
            const propertyDetails = fetchPropertyDetails?.payload?.data[0];
            console.log(propertyDetails, "propertyDetails")
            const ownerId = propertyDetails?.owner[0]?.value;
            const fetchOwnerDetails = await dispatch(getOwner());
            const ownerDetails = fetchOwnerDetails?.payload?.data?.filter(
              (owner: { id: any }) => owner.id == ownerId
            );
            const fetchTenantsDetails = await dispatch(getTenants());
            const tenantIdString =
              contractData.payload.data[0].datatenants.tenates[0].ID_PESSOA_PES;
            const tenantId = parseInt(tenantIdString);
            const tenantDetails = fetchTenantsDetails.payload.data.filter(
              (tenant: { id: any }) => {
                return tenant.id == tenantId;
              }
            );
            let tenantType = ""
            let ownerType = ""
            if (tenantDetails.length) {
              const cleanedStr = tenantDetails[0].cnpj.replace(/[.\-/]/g, '');
              tenantType = cleanedStr.length == 11 ? "Fisica" : "jurídica";
            }
            if (ownerDetails.length) {
              const cleanedStr = tenantDetails[0].cnpj.replace(/[.\-/]/g, '');
              ownerType = cleanedStr.length == 11 ? "Fisica" : "jurídica";
            }
            const insuranceResponse = await fetch(
              `${config.INSURANCEAPI}/legacy/properties/contract?apiKey=${config.INSURANCEAPIKEY}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${accessData.access_token}`,
                },
                body: JSON.stringify({
                  property: {
                    state: propertyDetails.state ? propertyDetails.state : "",
                    city: propertyDetails.city ? propertyDetails.city : "",
                    address_complement:  propertyDetails?.complement && propertyDetails?.complement.trim() !== "" ? propertyDetails?.complement?.slice(0, 15) : "",
                    address: propertyDetails.address ? propertyDetails.address : "",
                    address_number: propertyDetails.number ? propertyDetails.number : "",
                    address_neighbourhood: propertyDetails.neighborhood ? propertyDetails.neighborhood : "",
                    cep: propertyDetails.zipcode ? propertyDetails.zipcode : "",
                  },
                  contract_id: String(contractData?.payload?.data[0]?.id),
                  policy_duration: {
                    effective_date_end: data.end ? data.end : "",
                    effective_date_start: data.start ? data.start : "",
                  },
                  agency_license: `${localStorage?.getItem('agencyId')}`,
                  CONTRATACAO_AUTOMATICA: 0,
                  tenant: {
                    phone: tenantDetails[0].mobile ? tenantDetails[0].mobile : "",
                    birth_date: moment(tenantDetails[0].birth).format('YYYY-MM-DD') ? moment(tenantDetails[0].birth).format('YYYY-MM-DD') : "",
                    name: tenantDetails[0].name ? tenantDetails[0].name : "",
                    gender: "M",
                    email: tenantDetails[0].email ? tenantDetails[0].email : "",
                    foreign: "",
                    document_data: {
                      emission_date: data.start ? data.start : "",
                      document_nature: "",
                      document_number: tenantDetails[0]?.rg ? tenantDetails[0]?.rg : "",
                    },
                    politically_exposed: "",
                    document_number: tenantDetails[0]?.cnpj ? tenantDetails[0]?.cnpj : "",
                    person_type: tenantType
                  },
                  property_owner: {
                    birth_date: ownerDetails.length ? ownerDetails[0]?.birth : "",
                    email: ownerDetails.length ? ownerDetails[0]?.email : "",
                    document_number: ownerDetails.length ? ownerDetails[0]?.cnpj : "",
                    name: ownerDetails.length ? ownerDetails[0]?.name : "",
                    phone: ownerDetails.length ? ownerDetails[0]?.mobile : "",
                    person_type: ownerType,
                  },
                  user_email: `${localStorage?.getItem('userEmailId')}`,
                  insurance: {
                    property_id: String(contractDetails[0]?.contract_type ? contractDetails[0]?.contract_type : ""),
                    renewal: "",
                    main_coverage: Number(assuredVal),
                    zip_code: propertyDetails.zipcode ? propertyDetails.zipcode : "",
                    property_type: propertyDetails.propertyType ? propertyDetails.propertyType : "",
                    property_group_id: contractData?.payload?.data[0]?.contract_type == "3" ? contractData?.payload?.data[0]?.businessActivity == 1 ? "1" : contractData?.payload?.data[0]?.businessActivity == 2 ? "4" : contractData?.payload?.data[0]?.businessActivity == 3 ? "5" : contractData?.payload?.data[0]?.businessActivity == 4 ? "5" : "" : ""
                  },
                  quote_id: quoteId,
                }),
              }
            );
            const insuranceData = await insuranceResponse.json();
            if (insuranceData.error) {
              setLoading(false)
              Swal.fire({
                title: t("Sweetalert.Error"),
                text: insuranceData.error.message,
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000,
              });
            } else {
              setValue('policy2', insuranceData?.insurance?.insurance_policy_number)
              setValue("policy2_id", insuranceData?.insurance?.id)
              setLoading(false)
              if (getValues('policy2') != '') {
                let contractId = data.id;
                let policyNumber = insuranceData?.insurance?.insurance_policy_number;
                let policyNumberId: any = insuranceData?.insurance?.id;
                e.preventDefault();
                setDouble(true);
                let response = await dispatch(insertRenewInsurance(data));

                if (
                  typeof response.payload !== "undefined" &&
                  (response.payload.resCode === 202 || response.payload.resCode === 200)
                ) {
                  let data: any = { contractId, policyNumber, policyNumberId }
                  await dispatch(updateContractInsurancePolicy(data));
                  setDouble(false);
                  Swal.fire({
                    // icon: 'success','success',
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    confirmButtonText: "OK",
                    timer: 2000,
                  });
                  setLoading(true)
                  newRenewInsuranceShowClose();
                  dispatch(getAllExpense(state));
                } else {
                  setDouble(false);
                  setLoading(false)
                  Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000,
                  });
                }
              }
            }
          }

        } else {
          setDouble(true);
          let response = await dispatch(insertRenewInsurance(data));
          if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
            setDouble(false);
            Swal.fire({
              // icon: 'success','success',
              title: t("Sweetalert.Success"),
              text: t("Sweetalert.successget"),
              confirmButtonText: "OK",
              timer: 2000,
            });
            newRenewInsuranceShowClose();
          } else {
            setDouble(false);
            Swal.fire({
              title: t("Sweetalert.Error"),
              text: t("Sweetalert.somethingWent"),
              // icon: 'success',"error",
              confirmButtonText: "OK",
              timer: 2000,
            });
          }
        }
        setLoading(false)
        // User clicked confirm button
        // You can put your code for the action you want to perform here
        //   let data = await dispatch(cancelInsuranceRequest(contractId));
        //   if(data.payload.resCode == '201'){
        //     window.location.reload();
        //   }else{
        //     Swal.fire({
        //         title: t("Sweetalert.Error"),
        //         text: t("Sweetalert.somethingWent"),
        //         // icon: 'success',"error",
        //         confirmButtonText: "OK",
        //         timer: 2000,
        //       });
        //   }
        //Swal.fire("Success", "Your action was successful!", "success");
      } else {
        // User clicked cancel button or closed the popup
        //Swal.fire("Cancelled", "Your action was cancelled", "error");
      }
    });
    return;
  };
  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    reset();
  };
  const handleInsurance = async (index: any, record: any) => {
    setClickedIndex(record.ShortName);
    const insuranceEndDate = moment(contractDetails[0].insEndLbl, 'DD/MM/YYYY').format('YYYY-MM-DD')
    if (contractDetails[0].insEndLbl != '') {
      var contractEndDateEarlier = insuranceEndDate;
    } else {
      var contractEndDateEarlier = contractDetails[0].start2;
    }
    // let assuredValue = getValues('assuredValue');
    // if (assuredValue == '') {
    //   let calculateRent = (contractDetails[0].rent_value * 100);
    //   setValue('assuredValue', calculateRent.toString())
    // }
    const rentValueCalculation: any = contractDetails[0]?.rent_value;
    let asuuVal
    if (getValues("assuredValue")) {
      asuuVal = convertToNumber(getValues("assuredValue"))
    }
    const normalAmount = convertToNumber(rentValueCalculation)
    let assVal1 = asuuVal ? asuuVal : normalAmount * 100;
    let insuran = contractDetails[0]?.assuredValue ? contractDetails[0]?.assuredValue : assVal1
    const maxAssuredValue = getMaxAssuredValue(contractDetails[0]?.contract_type, insuran);
    if (maxAssuredValue) {
      setValue("assuredValue", converter(String(maxAssuredValue)));
    } else {
      setValue("assuredValue", "0,00");
    }
    const normalAmount1 = convertToNumber(rentValueCalculation)
    let assVa = normalAmount1 * 200;
    let insuran1 = assVa
    const maxAssuredValue1 = getMaxAssuredValue(contractDetails[0]?.contract_type, insuran1);
    if (maxAssuredValue1) {
      setInsuranceMaxVal(maxAssuredValue1)
    } else {
      setInsuranceMaxVal("5000000")
    }

    // const contractStartDate = new Date(contractEndDateEarlier); // Convert string to Date object
    // contractStartDate.setDate(contractStartDate.getDate() + 1);

    // const contractEndDate = new Date(contractStartDate); // Create a copy of contractStartDate

    // // Add 12 months to the contract end date
    // contractEndDate.setMonth(contractEndDate.getMonth() + 12);

    // const contractStartDateISOString = contractStartDate
    //   .toISOString()
    //   .split("T")[0];
    // const contractEndDateString = contractEndDate.toISOString().split("T")[0];
    if (addIns == "addInsurance") {
      let contractStart: any = contractDetails[0]?.start2
      const today = new Date();
      let parts = contractStart.split("/");
      let formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
      let comparisonDate: any = new Date(formattedDate);
      if (moment(today).format("YYYY-MM-DD") < moment(comparisonDate).format("YYYY-MM-DD")) {
        let newDate1 = moment(contractStart, 'DD/MM/YYYY').format('YYYY-MM-DD');
        setValue("start", newDate1);
        setContractStartDate(newDate1)
        comparisonDate.setFullYear(comparisonDate.getFullYear() + 1);
        const year = comparisonDate.getFullYear();
        const month = String(comparisonDate.getMonth() + 1).padStart(2, '0');
        const day = String(comparisonDate.getDate()).padStart(2, '0');
        const newDate = `${year}-${month}-${day}`;
        setValue("end", newDate);
        setContractEndDate(newDate)

      } else {
        setValue("start", moment(today).format("YYYY-MM-DD"));
        setContractStartDate(moment(today).format("YYYY-MM-DD"))
        today.setFullYear(today.getFullYear() + 1);
        let formattedDate = today.toISOString().split('T')[0];
        setValue("end", moment(formattedDate).format("YYYY-MM-DD"));

        setContractEndDate(moment(formattedDate).format("YYYY-MM-DD"))
      }

    }

    setValue("installmentValue", record.FirstInstallment);
    setPremiumAmount(record.LastInstallment);
    setValue("prizeAmount", record.Total);
    setValue("contractedPlan", record.Name);
    setQuoteId(record?.RequestId);

    setValue('numberInstallments2', record.Installments)

  };

  const handleInsuran = async (e: any) => {
    let { value } = e.target
    const isMatchFound = insuranceData?.some(item => item.id == value && item.name == "Tokio Marine" && item.FL_PRORATADIA_EMP == 5);
    if (isMatchFound) {
      setSelectedFireInsurance("Tokio Marine")
      setIsCheckboxChecked(false)
      setValue("start", "");
      setValue("end", "");
      setValue("installmentValue", "");
      setValue("prizeAmount", "");
      setValue("contractedPlan", "");
      setValue('numberInstallments2', "")
      setValue("assuredValue", "")
    } else {
      setSelectedFireInsurance("")
      setValue("start", "");
      setValue("end", "");
      setValue("installmentValue", "");
      setValue("prizeAmount", "");
      setValue("contractedPlan", "");
      setValue('numberInstallments2', "")
      setValue("assuredValue", "")
      setIsCheckboxChecked(true)
      setInsurance(false)
    }
  }
  function compareDates(targetDate) {
    // Get the current date
    const currentDate = new Date(dateBrazil);
    // Parse the target date
    const dateToCompare = new Date(targetDate);
    // Compare the two dates
    if (currentDate < dateToCompare) {
      return "-1";
    } else if (currentDate > dateToCompare) {
      return "1";
    } else {
      return "0";
    }
  }
  useEffect(() => {

    if (addIns == "addInsurance") {
      const filteredData = insuranceData.filter(item => item.name == "Tokio Marine" && item.FL_PRORATADIA_EMP == 5);
      setTimeout(() => {
        setValue("insuranceCompany", filteredData[0]?.id)
      }, 1500)
      setValue("observation4", "")
      if (filteredData?.length > 0 &&
        contractDetails[0]?.contract_type == 1 || contractDetails[0]?.contract_type == 3) {
        setIsCheckboxChecked(false);
        setInsurance(true)
        setSelectedFireInsurance("Tokio Marine")
      } else {
        setIsCheckboxChecked(true);
        setInsurance(false)
      }
      setValue("type1", "addInsurance")
      if (contractDetails[0]?.contract_type == 3) {
        setMaxiumVal("2500000")
      } else {
        setMaxiumVal("5000000")
      }
    } else {
      setTimeout(() => {
        const insuranceCompany = getValues('insuranceCompany');
        const isMatchFound = insuranceData?.some(
          (item) => item?.id == insuranceCompany && item?.name == 'Tokio Marine' && item?.FL_PRORATADIA_EMP == 5 && (contractDetails[0]?.contract_type == 1 || contractDetails[0]?.contract_type == 3)
        );
        if (isMatchFound && contractDetails[0]?.contract_type == 1 || contractDetails[0]?.contract_type == 3) {
          setIsCheckboxChecked(false);
          setInsurance(true)
          setSelectedFireInsurance("Tokio Marine")
        } else {
          setIsCheckboxChecked(true);
          setInsurance(false)
        }
      }, 1000);
      if (contractDetails[0]?.contract_type == 3) {
        setMaxiumVal("2500000")
      } else {
        setMaxiumVal("5000000")
      }
      setValue("type1", "renewInsurance")
    }

    setValue("responsible4", "2")

  }, [newRenewInsuranceShow, insuranceData, addIns, contractDetails])
  useEffect(() => {
    if (contractDetails) {
      const filteredInsuranceData = insuranceData?.filter(item => {
        if (contractDetails[0]?.contract_type == 1 || contractDetails[0]?.contract_type == 3
        ) {
          return true; // Show all data
        } else {
          return item.name !== "Tokio Marine"; // Exclude "Tokio Marine"
        }
      });

      setFilteredData(filteredInsuranceData); // Update filtered data

      // Set the insurance company value only if there is data to set
      if (filteredInsuranceData && filteredInsuranceData.length > 0) {
        setTimeout(() => {
          setValue("insuranceCompany", contractDetails[0]?.insuranceCompany);
        }, 1000)

      }
    }

  }, [newRenewInsuranceShow, contractDetails, insuranceData]);
  const [load, setLoad] = useState("")
  useEffect(() => {
    if (contractForm?.insuranceQuotes?.length > 0) {
      setLoad("sucesss")
    }
  }, [load, contractForm])

  const handlAssVal = (e: any) => {
    let { value } = e.target;
    value = value.replace(/[^\d,]/g, "");
    const selectElement: any = document?.getElementById("insuranceCompany");
    const selectedDataName = selectElement?.options[selectElement?.selectedIndex]?.getAttribute("data-name");
    if (selectedDataName == "Tokio Marine") {
      setInsurance(true)
      if (Number(value) < 150000 || Number(value?.split(",")[0]) > maxiumVal) {
        setError("assuredValue", {
          type: "manual",
          message: `Manter o limite mínimo de R$ 150.000,00 e limite máximo de R$ ${converter(String(maxiumVal))}`
        }
        )
      } else {
        setLoad("loading")
        setMinCover(value)
        clearErrors("assuredValue");
      }
    } else {
      setInsurance(false)
    }
    if (Number(value) > maxiumVal) {
      setValue("assuredValue", converter(String(maxiumVal)));
      clearErrors("assuredValue");
      return
    }

    setValue("assuredValue", formatNumber(value))
  }
  const tooltip = (
    <Tooltip id="tooltip">
      Manter o limite mínimo de R$ 150.000,00 e limite máximo de R$ {converter(String(insuranceMaxVal ? convertToNumber(String(insuranceMaxVal)) : convertToNumber(String(maxiumVal))))}
    </Tooltip>
  );
  return (
    <>
      {/* <!-- New renew insurance modal start from here --> */}
      <Modal show={newRenewInsuranceShow} onHide={newRenewInsuranceShowClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title> {addIns == "addInsurance" ? t("addInsurance") : t("renewInsurance")}  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Check className="mb-lg-4 mb-3" checked={isCheckboxChecked} type="checkbox"  {...register('isContract')} label={t("Doesnothaveinsurance")} onChange={handleCheckboxChange} /> */}
            <input type="hidden" {...register("id")} />
            <input type="hidden" {...register("type1")} />
            <Row
              className={`gy-3 gy-lg-4 mb-lg-4 mb-3 ${isCheckboxChecked ? "d-none" : ""
                }`}
            >
              {
                contractForm?.insuranceQuotes &&
                contractForm?.insuranceQuotes.map((record, index) => (
                  <Col md={6} key={index}>
                    <Card
                      className={`border border-2 text-center ${clickedIndex == record.ShortName ? "border-primary" : ""
                        }`}
                      onClick={() => handleInsurance(index, record)}
                      style={{ cursor: "pointer" }}
                    >
                      <Card.Body className="p-lg-4">
                        <h4 className="mb-4">{t(record.Name)}</h4>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/temp/tokio_icon.png"
                          }
                          alt="tokio icon"
                          className="img-fluid mx-auto d-block"
                        />
                        <span className="d-block text-secondary fs-12">
                          {t("newContractDev.inCollaborationKenloInsurance")}
                        </span>
                        <div className="fw-bold my-4">
                          {record.Installments}x{" "}
                          <span className="h3 lh-1">
                            R$ {converter(String(record.TotalCalculatedByInstallments))}
                          </span>{" "}
                          <span className="fs-12 text-secondary d-block mt-1 ms-2">
                            R$ {converter(String(record.Total))} {t("newContractDev.inTotal")}
                          </span>
                        </div>
                        <div className="d-flex justify-content-center text-start">
                          <ul className="list-unstyled">
                            {/* {record.Coverages.map(
                                                    (coverage, idx) => (
                                                      <li className="mb-3" key={idx}>
                                                        {coverage.value === 0 ? (
                                                          <img
                                                            title="contractInsurance"
                                                            src={
                                                              process.env.PUBLIC_URL +
                                                              "/assets/images/icons/cross-w.svg"
                                                            }
                                                            alt="cross icon"
                                                            className="h-16 cursor-pe me-lg-2 me-1"
                                                          />
                                                        ) : (
                                                          <img
                                                            title="contractInsurance"
                                                            src={
                                                              process.env.PUBLIC_URL +
                                                              "/assets/images/icons/check.svg"
                                                            }
                                                            alt="cross icon"
                                                            className="h-16 cursor-pe me-lg-2 me-1"
                                                          />
                                                        )}
                                                        {t(coverage.text)}
                                                      </li>
                                                    )
                                                  )} */}
                            {items
                              ?.filter(item => item.contractType == contractDetails[0]?.contract_type)
                              .map((item, idx) => (
                                <li key={idx} className="list-group-item border-0 p-0 d-flex align-items-center mb-2">
                                  {item.labels[index] ? (
                                    <>
                                      {/* <img
                                        title="contractInsurance"
                                        src={`${process.env.PUBLIC_URL}/assets/images/icons/check.svg`}
                                        alt="check icon"
                                        className="h-16 cursor-pe me-lg-2 me-1"
                                      /> */}
                                      {item.val == index ?
                                        <img
                                          title="contractInsurance"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/cross-w.svg"
                                          }
                                          alt="cross icon"
                                          className="h-16 cursor-pe me-lg-2 me-1"
                                        />
                                        :
                                        <img
                                          title="contractInsurance"
                                          src={`${process.env.PUBLIC_URL}/assets/images/icons/check.svg`}
                                          alt="check icon"
                                          className="h-16 cursor-pe me-lg-2 me-1"
                                        />
                                      }
                                      {t(item.labels[index]).charAt(0).toUpperCase() + t(item.labels[index]).slice(1)}
                                    </>
                                  ) : null}
                                </li>
                              ))}
                          </ul>
                        </div>
                        <span className="d-block text-dark-70 fs-12">
                          {t(
                            "newContractDev.insuranceAutomaticallyContractedActivated"
                          )}
                        </span>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              {contractDetails[0]?.contract_type == "3" && contractForm?.insuranceQuotes?.length > 0 ?
                <Alert
                  variant="warning"
                  className="d-flex align-items-center mb-0"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/triangle-exclamation.svg"
                    }
                    alt="Exclamation"
                    className="flex-shrink-0 me-2 h-16"
                  />
                  {t("newContractDev.insuranceacceptance")} {" "}
                  <a
                    href="https://storage.cloud.google.com/download/storage/v1/b/bucket-kenlo-wegile/o/images%2FEmpresarial_Quadro-de-Analogias_1735803432919.pdf?generation=1735803435255415&alt=media"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="cursor-pointer text-primary text-end"
                  >
                    &nbsp; {t("ReceiveandReceived.Clickhere")} &nbsp;
                  </a>
                </Alert>
                : ""}
            </Row>
            {load == "loading" ?
              <div className="text-center">
                <div className="spinner-border text-danger" role="status">
                </div>
              </div>
              :
              <p className="text-danger">
                {contractForm?.insuranceQuotes == undefined ?
                  <>
                    {Errormessage?.message}
                    {Errormessage?.error && Errormessage?.error?.map((err, index) => (
                      <span key={index}>
                        {Errormessage?.message}
                        <br />
                        {err.path.join(" > ")} -  {err.message}
                        <br />
                      </span>
                    ))}
                  </>
                  : ""}
              </p>
            }


            <Row
              className={`gy-3 gy-lg-4 mb-lg-4 mb-3`}
            >
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("start") + " *"}>
                  <Form.Control
                    type="date"
                    placeholder={t("start")}
                    className={`form-control ${errors.start ? "is-invalid" : ""
                      }`}
                    {...register("start")}
                    disabled={insurance}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.start?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel controlId="floatingInput" label={t("end") + " *"}>
                  <Form.Control
                    type="date"
                    placeholder={t("end")}
                    className={`form-control ${errors.end ? "is-invalid" : ""}`}
                    {...register("end")}
                    disabled={insurance}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.end?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("installmentValue") + " *"}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("installmentValue")}
                    className={`form-control ${errors.installmentValue ? "is-invalid" : ""
                      }`}
                    {...register("installmentValue")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.installmentValue?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("numberInstallments")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("numberInstallments") + " *"}
                    className={`form-control ${errors.numberInstallments2 ? "is-invalid" : ""
                      }`}
                    {...register("numberInstallments2")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numberInstallments2?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              {selectedFireInsurance == "Tokio Marine" ?
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label={t("assuredValue") + " *"}
                  >
                    <OverlayTrigger placement="top" overlay={tooltip}>
                      <Form.Control
                        type="text"
                        placeholder={t("assuredValue")}
                        className={`form-control ${errors.assuredValue ? "is-invalid" : ""
                          }`}
                        {...register("assuredValue")}
                        onChange={(e) => handlAssVal(e)}
                      />
                    </OverlayTrigger>
                    <Form.Control.Feedback type="invalid">
                      {errors.assuredValue?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col> :
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label={t("assuredValue") + " *"}
                  >
                    <Form.Control
                      type="text"
                      placeholder={t("assuredValue")}
                      className={`form-control ${errors.assuredValue ? "is-invalid" : ""
                        }`}
                      {...register("assuredValue")}
                      onChange={(e) => handlAssVal(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.assuredValue?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              }

              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("prizeAmount")}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("prizeAmount") + " *"}
                    className={`form-control ${errors.prizeAmount ? "is-invalid" : ""
                      }`}
                    {...register("prizeAmount")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.prizeAmount?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <InputGroup>
                  <FloatingLabel
                    controlId="insuranceCompany"
                    label={t("insuranceCompany") + " *"}
                  >
                    <Form.Select
                      className={`form-control ${errors.insuranceCompany ? "is-invalid" : ""
                        }`}
                      {...register("insuranceCompany")}
                      onChange={(e) => handleInsuran(e)}
                    >
                      <option value="">{t("dropdownItem.select")}..</option>
                      {filteredData && filteredData.length > 0 ? (
                        filteredData.map((item, index) => (
                          <option key={index} value={item.id} data-name={item.name}>
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option value="">{t("reportSystem.NoRecords")}</option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.insuranceCompany?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <InputGroup.Text onClick={newInsuranceOpen}>
                    <img
                      title="reNewInsurance"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/documents-add-light.svg"
                      }
                      alt="Documents add"
                      className="h-20 cursor-pe"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel controlId="floatingInput" label={t("policy") + " *"}>
                  <Form.Control
                    type="text"
                    placeholder={t("policy") + " *"}

                    {...register("policy2_id")}
                  />
                  <Form.Control.Feedback type="invalid">
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6} style={{ display: "none" }}>
                <FloatingLabel controlId="floatingInput" label={t("policy") + " *"}>
                  <Form.Control
                    type="text"
                    placeholder={t("policy") + " *"}

                    {...register("policy2")}
                  />
                  <Form.Control.Feedback type="invalid">
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("contractedPlan") + " *"}
                >
                  <Form.Control
                    type="text"
                    placeholder={t("contractedPlan")}
                    className={`form-control ${errors.contractedPlan ? "is-invalid" : ""
                      }`}
                    {...register("contractedPlan")}
                    disabled={insurance}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contractedPlan?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label={t("responsible") + " *"}
                >
                  <Form.Select
                    {...register("responsible4")}
                    disabled={true}
                    aria-label="Responsible"
                  >
                    <option value="">{t("dropdownItem.select")}</option>
                    <option value="1">{t("realEstate")}</option>
                    <option value="2">{t("Dashboard.Tenant")}</option>
                    <option value="3">{t("Dashboard.Owner")}</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel
              controlId="floatingTextarea2"
              label={t("formLabel.observation")}
            >
              <Form.Control
                as="textarea"
                placeholder="Observation"
                {...register("observation4")}
                style={{ height: "100px" }}
              />
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={newRenewInsuranceShowClose}
            >
              {t("button.close")}
            </Button>
            {Loading ?
              <Button className="btn-width" disabled={double}>
                <span className="spinner-border spinner-border-sm"></span>
                <span className="px-1">{t("Processing")}</span>
              </Button> :
              <Button disabled={double} type="submit" variant="primary">
                {t("button.save")}
              </Button>}
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New renew insurance modal end --> */}
      <InsuranceService />
    </>
  );
};
export default RenewInsuranceService;
