import React, { useEffect, useRef, useState, useTransition } from 'react';
import { getBankList, Main_Account, Newbankaccount } from '../../../../redux/slice/bankSlice';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import AddNewBank from './AddNewBank';
import { addNewbankaccount, addBankAccount, handleCloseBank, handleOpnBank, innerbank, kobanaBankAccount, } from "../../../../redux/slice/bankSlice"
import { AppDispatch, RootState } from '../../../../redux/store';
import { getBraches } from '../../../../redux/slice/branchSlice';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Row, Col, Accordion, InputGroup, ListGroup } from 'react-bootstrap';
import { numericOnly, openErrorbox } from '../../../Pages/Contracts/Common/actionPerform';
import moment from "moment-timezone";
import { CNJP_CPFValidation, convertToNumber, formatNumber } from '../../../constants/utils';
import { registerDatacomapny } from '../../../../redux/slice/companyDataSlice';
const Newbankaccounts = ({ userbanklist }) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const [t] = useTranslation()
    const inputRef = useRef<any>(null);
    const [accountTypes, setAccountType] = useState(false)
    const [types, setTypes] = useState('')
    const [nameofBanks, setNameofBanks] = useState<any>('')
    // used it to show validation error on form
    // const regexNotPattern = /^[^a-z]*$/;
    const regexNotPattern = /^[0-9-]+$/;
    const [isAarinBankAdded, setisAarinBankAdded] = useState(false)
    let first = {
        nameReference: yup.string().required(t("requiredMmessage.fieldRequired")),
        accountType: yup.string().required(t("requiredMmessage.fieldRequired")),
        // termsAndConditions: yup.bool().oneOf([true], "You must accept the terms and conditions"),
    }
    let second = {
        nameReference: yup.string().required(t("requiredMmessage.fieldRequired")),
        // documentNo: yup.string().required(t("requiredMmessage.fieldRequired")),
        // documentType: yup.string().required(t("requiredMmessage.fieldRequired")),
        // nameinBank: yup.string().required(t("requiredMmessage.fieldRequired")),
        bankname: yup.string().required(t("requiredMmessage.fieldRequired")),
        moneyaccount: yup.string().required(t("requiredMmessage.fieldRequired")),
        account: yup.string().trim().nullable()
            // .matches(regexNotPattern, { message: t('requiredMmessage.Thevaluemustbeanumber') })
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t("requiredMmessage.fieldRequired")),
        agency: yup.string().trim().nullable()
            // .matches(regexNotPattern, { message: t('requiredMmessage.Thevaluemustbeanumber') })
            .test('is-valid', t('requiredMmessage.Pleaseenteronlynumbers'), (value: any) => {
                return regexNotPattern.test(value);
            })
            .required(t("requiredMmessage.fieldRequired")).min(4, t("requiredMmessage.Agencyrequire")),
        branchtype: yup.string().required(t("requiredMmessage.fieldRequired")),
        date: yup.string().required(t("requiredMmessage.fieldRequired")),
        // termsAndConditions: yup.bool().oneOf([true], "You must accept the terms and conditions"),
        accountType: yup.string().required(t("requiredMmessage.fieldRequired")),
    }
    let fist = {}
    if (accountTypes) {
        fist = first
    } else {
        fist = second
    }
    let Schema = yup.object().shape(fist)
    var frm = {
        resolver: yupResolver(Schema)
    }
    const handleAccountType = (e: any) => {
        let anyDta: any = "";
        setValue("bankname", anyDta);
        setValue("account", anyDta);
        setValue("agency", anyDta);
        if (e.target.value == 2) {
            setAccountType(true)
        } else {
            setAccountType(false)
        }
        if (e.target.value !== "") {
            clearErrors("accountType");
        } else {
            setError("accountType", {
                type: "manual",
                message: `${t("requiredMmessage.fieldRequired")}`,
            });
        }
    }
    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>();
    // Initialize the useTranslation hook for internationalization
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    const maxDate = () => {
        const today = new Date(dateBrazil).toISOString().split('T')[0];
        return today;
    };
    // The useForm hook is used to handle form registration, submission, and validation.
    const { register, handleSubmit, getValues, reset, setValue, setError, clearErrors, formState: { errors } } = useForm<Newbankaccount>(frm);
    // Retrieve banklist, show1, and status from the Redux state
    const { banklist, show1, status } = useSelector((state: RootState) => state.bank)
    const { branchList, } = useSelector((state: RootState) => state.branch)
    const [valueCon, setValueCon] = useState('')
    const [loader_on, setloader_on] = useState(false);

    // Pop up hide of new bank account on click
    const NewBankClose = () => {
        if (!loader_on) {
            dispatch(handleCloseBank(false));
            setAccountType(true);
            // Reset the form fields
            reset();
        }
    }
    // Pop up show and hide of inner add bank account
    const addInberBankOpen = () => {
        dispatch(handleOpnBank(false))
        dispatch(innerbank(true))
    }
    useEffect(() => {
        (async () => {
            let data: any = await dispatch(registerDatacomapny());
            if (data?.["payload"]?.["data"].length > 0) {
                if (data?.["payload"]?.type == "Success") {
                    let companyData1 = data["payload"]["data"][0];
                    let { email, cnpj, company_name } = companyData1;
                    setValue('documentNo', cnpj)
                    setValue('nameReference', company_name)
                }
            }
        })()
    }, [])
    // It fetches the branches data and updates the state using the 'dispatch' function
    useEffect(() => {
        let aarinBank = false;
        if (userbanklist && userbanklist.length) {
            userbanklist.forEach(element => {
                if (element.AARIN_ID && element.AARIN_ID != '') {
                    aarinBank = true;
                }
            });
        }
        setisAarinBankAdded(aarinBank);
        dispatch(getBraches(branchState))
        let maney = getValues("moneyaccount")
        if (maney) {
            clearErrors("moneyaccount")
        }
    }, [dispatch, show1, userbanklist])
    const onSubmit = async (data: any, e: any) => {
        let bankcode = '';
        banklist.forEach((curElem: any, index: number) => {
            if (curElem.bank_name == data.bankname) {
                bankcode = curElem.bank_code
            }
        })
        data["bankname"] = bankcode
        // Dispatch an action to add a new bank account
        let moneyaccounts: any = convertToNumber(valueCon);
        data["moneyaccount"] = moneyaccounts;
        data['nameinBank'] = nameofBanks
        setloader_on(true);
        data.documentNo = data.documentNo.replace(/[.-]/g, '');
        data.account = `${data.account}-${data.bankAccountDigit ? data.bankAccountDigit : "0"}`;
        let res = await dispatch(addNewbankaccount(data))
        if (res?.payload?.type == "Success") {
            Swal.fire({
                position: 'center',
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.insertRecord')}`,
            }).then((result) => {
                if (result.isConfirmed) {
                    // Dispatch actions to add bank account and close the bank form
                    dispatch(addBankAccount(data))
                    dispatch(handleOpnBank(false))
                    dispatch(Main_Account())
                }
                // Define an object for retrieving the bank list
                let obj = {
                    start: "0",
                    end: "10",
                    all: ""
                }
                dispatch(getBankList(obj))
            });
            reset();
            setloader_on(false);
        } else if (res?.payload?.data?.resCode == 211) {
            // Show an error message if the account number is not unique
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: t("Sweetalert.NameReferencealreadyexists"),
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 4000
            })
            setloader_on(false);
        } else if (res?.payload?.data?.resCode == 210) {
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: t("Sweetalert.accountnumberalreadyexists"),
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 4000
            })
            setloader_on(false);
        } else {
            if (res?.payload?.resCode == 400) {
                res.payload.message = res.payload.data;
            }
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: res?.payload?.message,
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 4000
            })
            setloader_on(false);
        }
    };

    useEffect(() => {
        const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
        const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
        setSearchResults(filteredBanks);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [banklist]);


    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };

    const HandleBankAccount = (e: any, item) => {
        setValue('bankname', item?.bank_name)
        setIsInputFocused(false);
        if (e.target.value !== "") {
            clearErrors("bankname");
            clearErrors("moneyaccount");
        } else {
            setError("bankname", {
                type: "manual",
                message: `${t("requiredMmessage.fieldRequired")}`,
            });
        }
    }
    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = banklist.filter((bank) => {
                // Convert the bank name to lowercase for case-insensitive comparison
                const bankName = bank.bank_name.toLowerCase();
                return bankName.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
            const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
            setSearchResults(filteredBanks);
        }
    }

    const AddAmount = (e: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (value !== "") {
            setValue("moneyaccount", formatNumber(value))
            setValueCon(value)
            clearErrors("moneyaccount");
        } else {
            setError("moneyaccount", {
                type: "manual",
                message: `${t("requiredMmessage.fieldRequired")}`,
            });
        }
    }

    const handleInputChange = (e: any) => {
        const value: any = e.target.value;
        const name: any = e.target.name;
        const regex = /^-?\d*$/; // Regular expression to allow numbers and a leading hyphen for negative numbers
        if (regex.test(value)) {
            setValue(name, value, { shouldValidate: true });
        } else {
            e.preventDefault();
        }
    };
    const handleCPF_CNJPVaue = async (e) => {
        // let name = e.target.name;
        // let newTypes = types.toLowerCase()
        // let checkCNJP = CNJP_CPFValidation(e.target.value, newTypes);
        // if (checkCNJP?.["status"] || e.target.value == "") {
        //     if(e.target.value !== ""){
        //         clearErrors(name)
        //         // clearErrors('documentType')
        //     }
        // } else {
        //     setError(name, {
        //         type: "manual",
        //         message: checkCNJP?.["message"]
        //     });
        // };
    }
    const HandlChange = (e: any) => {
        setTypes(e.target.value)
    }
    const NameofBank = (e: any) => {
        if (e.target.value !== "") {
            setNameofBanks(e.target.value)
            clearErrors('nameReference')
        } else {
            setError('nameReference', {
                type: "manual",
                message: t("requiredMmessage.fieldRequired")
            });
        }
    }
    return (
        <>
            <Modal show={show1} onHide={NewBankClose} className="Newbankaccounttstsx">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("banks.newBankAccount")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='py-2'>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={12}>
                                <Form.Floating>
                                    <Form.Select className={`${errors.accountType ? 'is-invalid' : ''}`}  {...register('accountType')} aria-label="conta corrente" onChange={(e) => handleAccountType(e)}>
                                        <option value="">{t("select")}</option>
                                        <option value="1">{t("banks.checkingAccount")}</option>
                                        <option value="2">{t("banks.box")}</option>
                                        <option value="3">{t("banks.investment")}</option>
                                    </Form.Select >
                                    <Form.Control.Feedback type="invalid">{errors.accountType?.message}</Form.Control.Feedback >
                                    <Form.Label htmlFor="accountType">{t("banks.accountType") + " *"}</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={12}>
                                <Form.Floating>
                                    <Form.Control type="text" className={`${errors.nameReference ? 'is-invalid' : ''}`} id="nameReference"
                                        placeholder="Name Reference" {...register("nameReference")} onChange={(e) => NameofBank(e)} />
                                    <Form.Control.Feedback type="invalid" >{errors.nameReference?.message}</Form.Control.Feedback >
                                    <Form.Label htmlFor="nameReference">{t("formLabel.nameReference") + " *"}</Form.Label>
                                </Form.Floating>
                            </Col>

                            {/* <Col md={12}>
                                <Form.Floating>
                                    <Form.Select className={`${errors.documentType ? 'is-invalid' : ''}`}  {...register('documentType')} aria-label="conta corrente" onChange={(e) =>HandlChange(e)}>
                                        <option value="">{t("select")}</option>
                                        <option value="Cnpj">Cnpj</option>
                                        <option value="Cpf">CPF</option> 
                                    </Form.Select >
                                    <Form.Control.Feedback type="invalid">{errors.documentType?.message}</Form.Control.Feedback >
                                    <Form.Label htmlFor="documentType">{t("banks.docmenttype")}</Form.Label>
                                </Form.Floating>
                            </Col> */}

                            <Col md={12} hidden>
                                <Form.Floating>
                                    <Form.Control type="text" className={`${errors.documentNo ? 'is-invalid' : ''}`} id="documentNo"
                                        placeholder={t("contractlistother.DocumentNo")} {...register("documentNo")} readOnly onChange={(e) => handleCPF_CNJPVaue(e)} />
                                    <Form.Control.Feedback type="invalid" >{errors.documentNo?.message}</Form.Control.Feedback >
                                    <Form.Label htmlFor="documentNo">{t("contractlistother.DocumentNo")}</Form.Label>
                                </Form.Floating>
                            </Col>
                            {accountTypes ? "" :
                                <>
                                    <Col md={12} className={`${errors.bankname ? 'is-invalid' : ''}`}>
                                        <InputGroup>
                                            <Form.Floating ref={inputRef} >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type to search..."
                                                    {...register('bankname')}
                                                    onChange={(e) => handlefilterBillmethod(e)}
                                                    onFocus={handleInputFocus}
                                                    className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"
                                                    autoComplete="off"
                                                />
                                                {isInputFocused && (
                                                    <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}   >
                                                        {searchResults?.slice(0, 10).reverse().map((item: any, index) => (
                                                            <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, item)}>
                                                              {item.bank_code} - {item.bank_name}
                                                            </ListGroup.Item>
                                                        ))}
                                                    </ListGroup>
                                                )}
                                                <Form.Label htmlFor="floatingInputValue">{t("banks.name") + " *"}</Form.Label >
                                                <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback >
                                            </Form.Floating>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Floating>
                                        <Form.Control
                                          type="text"
                                          className={`${errors.account ? 'is-invalid' : ''}`}
                                          id="account"
                                          placeholder="Conta"
                                          {...register("account")}
                                          onChange={(e: any) => {
                                            const newValue = e.target.value;
                                            setValue("account", newValue.replace(/\D/, ""))
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.account?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">Conta *</Form.Label>
                                      </Form.Floating>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Floating>
                                        <Form.Control
                                          className={`${errors.account ? 'is-invalid' : ''}`}
                                          id="bankAccountDigit"
                                          placeholder="Dígito"
                                          {...register("bankAccountDigit")}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.account?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">Dígito</Form.Label>
                                      </Form.Floating>
                                    </Col>
                                    <Col md={3}>
                                      <Form.Floating>
                                        <Form.Control
                                          className={`${errors.agency ? 'is-invalid' : ''}`}
                                          id="agency"
                                          placeholder="Agência"
                                          {...register("agency")}
                                          onChange={handleInputChange}
                                          maxLength={4}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.agency?.message}</Form.Control.Feedback>
                                        <Form.Label htmlFor="floatingInputValue">Agência *</Form.Label>
                                      </Form.Floating>
                                    </Col>
                                </>
                            }
                            <Col md={12}>
                                <Form.Floating>
                                    <Form.Control type="text" className={`${errors.moneyaccount ? 'is-invalid' : ''}`} id="moneyAccount"
                                        placeholder="Money Acount"  {...register("moneyaccount")} onChange={(e) => AddAmount(e)} />
                                    <Form.Label htmlFor="moneyAccount">{t("banks.howMuchMoney") + " *"}</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.moneyaccount?.message}</Form.Control.Feedback >
                                </Form.Floating>
                            </Col>
                            <Col md={12}>
                                <Form.Floating>
                                    <Form.Control type="date" className={`${errors.date ? 'is-invalid' : ''}`} id="value"
                                        placeholder="When did you see that it had that value?" max={maxDate()}  {...register("date")} />
                                    <Form.Label htmlFor="value">{t("banks.whenDidYou") + " *"}</Form.Label>
                                    <Form.Control.Feedback type="invalid">{errors.date?.message}</Form.Control.Feedback >
                                </Form.Floating>
                            </Col>

                        </Row>
                        {/* /Basic info for account add end */}
                        <Accordion defaultActiveKey="0" className="accordion-custom">
                            {/* More options for bank account add section start from here */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >{t("banks.moreOptions")}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-3">
                                        <Col md={12}>
                                            <Form.Floating>
                                                <Form.Select className={`${errors.branchtype ? 'is-invalid' : ''}`} aria-label="branch" {...register("branchtype")}>
                                                    <option value="">{t("formLabel.select")}</option>
                                                    {branchList && branchList.length > 0 &&
                                                        branchList.map((curElem: any, index: number) =>
                                                            <option key={index} value={index + 1}>{curElem.name}</option>
                                                        )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.branchtype?.message}</Form.Control.Feedback >
                                                <Form.Label htmlFor="branch">{t("formLabel.branch") + " *"}</Form.Label>
                                            </Form.Floating>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* /More options for bank account add section end */}
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <>
                                    <Button variant="outline-primary" onClick={NewBankClose}>{t("button.close")}</Button>
                                    <Button variant="primary" type="submit" id="addnewBank" onClick={openErrorbox}>{t("button.toSave")}</Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New bank account modal end */}
            {/*  New bank modal start here */}
            <AddNewBank />
            {/*  /New bank modal end */}
        </>
    )
}
export default Newbankaccounts
