import React from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";

import { Icon } from "../Icon";

type SuccessToastProps = {
  message: string;
  show: boolean;
  onClose: () => void;
};

export function SuccessToast(props: SuccessToastProps) {
  const { message, show, onClose } = props;

  return (
    <ToastContainer
      className="p-3 position-fixed"
      position="bottom-end"
      style={{ zIndex: 10 }}
    >
      <Toast
        className="rounded-4 text-white border-0"
        onClose={onClose}
        show={show}
        style={{ backgroundColor: "#404040" }}
        delay={3000}
        autohide
      >
        <Toast.Body className="d-flex align-items-center gap-1 p-2">
          <Icon name="checkmarkCircle" color="#A7D6C2" size={32} />
          <span className="me-2">{message}</span>
          <Button variant="link" className="ms-auto p-0" onClick={onClose}>
            <Icon name="dismiss" color="white" size={32} />
          </Button>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}