import React from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { zodResolver } from "@hookform/resolvers/zod";

import { chargeService } from "../../services";
import { useChargesToReceive } from "../../ChargesToReceiveProvider";
import {
  InvalidateChargeForm,
  InvalidateChargeFormSchema,
} from "./definitions";
import { invalidationReasonsOptions } from "./contants";
import { useBankSlips } from "../HomologatedChargesList/useBankSlips";
import { useHomologatedChargesList } from "../HomologatedChargesList";

type InvalidateChargesModalProps = {
  show: boolean;
  onClose: () => void;
  chargesId: number[];
};

export function InvalidateChargesModal(props: InvalidateChargesModalProps) {
  const { show, onClose, chargesId } = props;
  const { fetchAllCharges } = useChargesToReceive();
  const { deselectAllCharges } = useHomologatedChargesList();
  const { handleDiscardOrCancelBankSlips } = useBankSlips();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<InvalidateChargeForm>({
    resolver: zodResolver(InvalidateChargeFormSchema),
  });

  function handleCloseModal() {
    setValue("FL_MOTIVOCANCELAR_RECB", "");
    onClose();
  }

  async function onSubmit(data: InvalidateChargeForm, e: any) {
    e.preventDefault();

    try {
      const response = await chargeService.invalidateCharges({
        ...data,
        id_arr: chargesId,
      });

      if (response.resCode === 200 && response.type === "Success") {
        console.error("response", response);
        throw new Error("Invalidate charge failed");
      }

      deselectAllCharges();

      Swal.fire({
        title: "Sucesso",
        text: "Cobrança invalidada com sucesso.",
        confirmButtonText: "OK",
        timer: 2000,
      });

      await handleDiscardOrCancelBankSlips(chargesId);

      fetchAllCharges();

      handleCloseModal();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Falha ao invalidar cobrança.",
        confirmButtonText: "OK",
        timer: 2000,
      });
    }
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Invalidar cobrança</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            className="mb-lg-4 mb-3"
            controlId="reason_invalidation"
            label="Motivo da invalidação"
          >
            <Form.Select
              aria-label="Motivo da invalidação"
              className={`form-control ${
                errors.FL_MOTIVOCANCELAR_RECB ? "is-invalid" : ""
              }`}
              {...register("FL_MOTIVOCANCELAR_RECB")}
            >
              <>
                <option disabled value="" label="Selecione o motivo" />
                {invalidationReasonsOptions.map((item) => (
                  <option
                    key={item.value}
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </>
            </Form.Select>
          </FloatingLabel>
          <input
            defaultValue="CancelBoleto"
            type="hidden"
            {...register("lease_period")}
          />
          <input defaultValue="Boleto" type="hidden" {...register("type")} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="outline-primary"
            onClick={handleCloseModal}
          >
            Fechar
          </Button>
          <Button type="submit" variant="primary">
            {isSubmitting
              ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              : <span>Invalidar</span>
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
