import React from "react";

import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { DefaultFields } from "./components/DefaultFields/DefaultFields";
import { useTransferAccountForm } from "./useTransferAccountForm";

export function TransferAccountForm() {
  const { onSubmit } = useTransferAccountForm();
  const { handleSubmit } = useFormContext();
  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="transferAccountForm">
      <DefaultFields />
    </Form>
  );
}
