import React from "react";

import {
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";

import { useDefaultFields } from "./useDefaultFields";

export function DefaultFields() {
  const {
    initialBalance,
    setInitialBalance,
    errors,
    register,
    handleChangeAccount,
    originSearchRef,
    destinationSearchRef,
    isOriginAccountSearchFocused,
    setIsOriginAccountSearchFocused,
    isDestinationAccountSearchFocused,
    setIsDestinationAccountSearchFocused,
    getAccountsToShow,
    searchedOriginAccount,
    setSearchedOriginAccount,
    searchedDestinationAccount,
    setSearchedDestinationAccount,    
  } = useDefaultFields();

  return (
    <>
      <Row className="mb-3">
        <Col sm={6}>
          <FloatingLabel controlId="date" label="Data transf.">
            <Form.Control
              placeholder="Data transf."
              type="date"
              {...register("date")}
              className={`${errors.date && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.date?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
        <Col sm={6}>
          <FloatingLabel controlId="transferAmount" label="Valor transferência">
            <Form.Control
              placeholder="Valor transferência"
              {...register("transferAmount")}
              value={initialBalance}
              className={`${errors.transferAmount && "is-invalid"}`}
              onChange={(e) => {
                const value = e.target.value;

                const cleanedValue = value.replace(/\D/g, "");

                if (cleanedValue === "") return setInitialBalance("R$ 0,00");

                const numero = parseFloat(cleanedValue) / 100;

                const formattedValue = numero.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                });

                setInitialBalance(formattedValue);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.transferAmount?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      {(
        <>
          <Row className="mb-2">
            <Col sm={12}>
              <InputGroup>
                <FloatingLabel controlId="originAccount" label="Origem" ref={originSearchRef}>
                  <Form.Control
                    placeholder="Origem"
                    className={`mb-1 ${errors.originAccount && "is-invalid"}`}
                    onFocus={() => setIsOriginAccountSearchFocused(true)}
                    {...register("originAccount", { required: "A conta de origem é obrigatória." })}
                    value={searchedOriginAccount}
                    onChange={(e) => setSearchedOriginAccount(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && searchedOriginAccount) {
                        e.preventDefault();
                        setSearchedOriginAccount("");
                      }
                    }}
                  />
                  {isOriginAccountSearchFocused && (
                    <div className="shadow position-absolute z-index-5 overflow-auto" style={{ maxHeight: "350px" }}>
                      {getAccountsToShow("origin").length > 0 ? (
                        <ListGroup>
                          {getAccountsToShow("origin").map((account) => (
                            <ListGroup.Item
                              key={account.account}
                              className="p-2 cursor-pe"
                              onClick={() => {
                                handleChangeAccount(account, "origin");
                              }}
                            >
                              {account.nameReference} - Agência: {account.agency} - Conta: {account.account}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <ListGroup.Item className="text-danger text-center">
                        </ListGroup.Item>
                      )}
                    </div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.originAccount?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={12}>
              <InputGroup>
                <FloatingLabel controlId="destinationAccount" label="Destino" ref={destinationSearchRef}>
                  <Form.Control
                    placeholder="Destino"
                    className={`mb-1 ${errors.destinationAccount && "is-invalid"}`}
                    onFocus={() => setIsDestinationAccountSearchFocused(true)}
                    {...register("destinationAccount", { required: "A conta de destino é obrigatória." })}
                    value={searchedDestinationAccount}
                    onChange={(e) => setSearchedDestinationAccount(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && searchedOriginAccount) {
                        e.preventDefault();
                        setSearchedDestinationAccount("");
                      }
                    }}
                  />
                  {isDestinationAccountSearchFocused && (
                    <div className="shadow position-absolute z-index-5 overflow-auto" style={{ maxHeight: "350px" }}>
                      {getAccountsToShow("destination").length > 0 ? (
                        <ListGroup>
                          {getAccountsToShow("destination").map((account) => (
                            <ListGroup.Item
                              key={account.account}
                              className="p-2 cursor-pe"
                              onClick={() => handleChangeAccount(account, "destination")}>
                              {account.nameReference} - Agência: {account.agency} - Conta: {account.account}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <ListGroup.Item className="text-danger text-center">
                        </ListGroup.Item>
                      )}
                    </div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.destinationAccount?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </InputGroup>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col sm={12}>
          <FloatingLabel controlId="complement" label="Complemento">
            <Form.Control
              as="textarea"
              placeholder="Complemento"
              {...register("complement")}
              className={`${errors.complement && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.complement?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}