import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import { Icon } from "../../../../../components/Icon";
import { bankSlipService } from "../../../services";
// import { useFeedbackDrawer } from "../../FeedbackDrawer";
import { useHomologatedChargesList } from "../../HomologatedChargesList";
import { ImportRemittanceModal } from "./ImportRemittanceModal";
import Swal from "sweetalert2";

export function RemittanceCard() {
  const [showImportRemittanceModal, setShowImportRemittanceModal] =
    useState(false);
  // const { showFeedbackDrawer } = useFeedbackDrawer();
  const { countSelectedCharges, selectedCharges, homologatedCharges } =
    useHomologatedChargesList();

  async function saveFile(file: any) {
    try {
      const blobFile = new Blob([file.content], { type: "text/plain" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blobFile);
      link.download = file.name;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }

  async function handleExportToRemittanceFile() {
    try {
      const integrationIds = homologatedCharges
        .filter((charge) => selectedCharges.includes(charge.id))
        .map((charge) => charge.integrationId);

      const response = await bankSlipService.exportToRemittanceFile(
        integrationIds
      );

      const files = response.data._sucesso.map((file: any) => {
        return {
          name: file.arquivo,
          content: atob(file.remessa),
        };
      });

      files.forEach((file: any) => {
        saveFile(file);
      });
    } catch (error) {
      console.log("error", error);

      Swal.fire({
        title: "Erro",
        text: "Falha ao exportar arquivo. Tente novamente.",
        confirmButtonText: "Ok",
      });
    }
  }

  async function handleImportRemittanceFile() {
    setShowImportRemittanceModal(true);
  }

  function handleCloseImportRemittanceModal() {
    setShowImportRemittanceModal(false);
  }

  return (
    <Card className="mb-lg-4 mb-3">
      <Card.Header className="text-dark-80 fs-3">
        <Row className="justify-content-between align-items-center">
          <Col>Remessa</Col>
          {/* <Col>
            <Button
              variant="link"
              className="p-0 d-flex align-items-center"
              onClick={showFeedbackDrawer}
            >
              <Icon name="documentCatchUp" />
              <span className="ms-1">Feedback</span>
            </Button>
          </Col> */}
          <Col className="d-flex justify-content-center">
            <Button
              variant="link"
              className="p-0 d-flex align-items-center"
              onClick={handleImportRemittanceFile}
            >
              <Icon name="documentArrowUp" />
              <span className="ms-1">Importar</span>
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="text-dark-80">
        <Row className="gx-6">
          <Col>
            <strong className="fs-3">{countSelectedCharges}</strong>
            <p className="mt-1 mb-0">Marcado para remessa</p>
          </Col>
          <Col>
            <Button
              className="w-100 d-flex align-items-center justify-content-center"
              variant="outline-primary"
              onClick={handleExportToRemittanceFile}
            >
              <Icon name="documentArrowDown" />
              <span className="ms-1">Exportar</span>
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <ImportRemittanceModal
        show={showImportRemittanceModal}
        onClose={handleCloseImportRemittanceModal}
      />
    </Card>
  );
}
