import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { config, gettoken } from "../../Api/Contact";
import { AnyARecord } from "dns";
let connectionName : any = '';
if( localStorage.getItem("userData") ){
  connectionName = localStorage.getItem("userData");
}
export const STATUSES = Object.freeze({
  LOADING: "loading",
  SUCEESS: "success",
  ERROR: 'error'
});

export interface Newbankaccount {
  id: any
  nameReference: string,
  documentNo: string,
  documentType: string,
  nameinBank: string,
  bankname: number,
  agency: number,
  account: string,
  bankAccountDigit?: string,
  moneyaccount: AnyARecord
  date: Date,
  closingDate: Date,
  accountType: string[],
  branchtype: string,
  main_ac:number,
  day:string,
  das:string,
  until:string
  status:number
  titulo_variacao_carteira: string
  titulo_doc_especie: string
  titulo_aceite: string
  // termsAndConditions: string
}
//add new bank list  interface
interface newbanklist {
  bank_code: string,
  bank_name: string
}
interface AuthState {
  newbank: Newbankaccount[],
  newbanklist: newbanklist[]
  userbanklist: Newbankaccount[],
  bankAccount:Newbankaccount[],
  mainbank: Newbankaccount[],
  banklist: newbanklist[],
  status: any,
  show: boolean
  show1: boolean,
  show2: boolean
}

const initialState: AuthState = {
  newbank: [],
  newbanklist: [],
  userbanklist: [],
  bankAccount:[],
  mainbank: [],
  banklist: [],
  status: STATUSES.LOADING,
  show: false,
  show1: false,
  show2: false
};

let user_id = gettoken.userId
const bankSlice = createSlice({
  name: "brankSlice",
  initialState,
  reducers: {
    setEdit: (state, action) => {
     state.userbanklist = state.userbanklist.map((el) => 
        el.id == action.payload.id ? { ...action.payload } : el
      );
    },
    addBankAccount: (state, action) => {
      state.userbanklist = [action.payload, ...state.userbanklist];
      // state.status = STATUSES.SUCEESS;
    },
    addNewBank: (state, action) => {
      state.banklist = [action.payload, ...state.banklist]
    },
    mainBankAcc:(state, action)=>{
      state.userbanklist= state.userbanklist.map((el) => 
    el.main_ac == action.payload.main_ac ? {...action.payload } : el
    );
    },
    delete_user_Bank_Acccount: (state, action) => {
      state.userbanklist = state.userbanklist.filter((el) => el.id != action.payload);
      // state.mainbank = state.mainbank.filter((el) =>el.id != action.payload);
    },
    handleClo: (state, action) => {
      state.show = action.payload
    },
    handleOpn: (state, action) => {
      state.show = action.payload
    },
    handleOpnBank: (state, action) => {
      state.show1 = action.payload
    },
    handleCloseBank: (state, action) => {
      state.show1 = action.payload
    },
    innerbank: (state, action) => {
      state.show2 = action.payload
    },
    handleEdit: (state, action) => {
      state.show = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewbankaccount.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(addNewbankaccount.fulfilled, (state, action:PayloadAction<any>) => {
        // state.status = STATUSES.SUCEESS
      })
      .addCase(addNewbankaccount.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });
    builder
      .addCase(getBankList.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(getBankList.fulfilled, (state, action: PayloadAction<any>) => {
        state.userbanklist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(getBankList.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });

     builder
      .addCase(get_BankAccountList.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(get_BankAccountList.fulfilled, (state, action: PayloadAction<any>) => {
        state.bankAccount = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(get_BankAccountList.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });

    builder
      .addCase(add_Bank.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(add_Bank.fulfilled, (state, action: PayloadAction<any>) => {
        state.newbanklist = action.payload.data;
        state.banklist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(add_Bank.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(Filter_bank.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(Filter_bank.fulfilled, (state, action: PayloadAction<any>) => {
        state.userbanklist = action.payload.data
        state.bankAccount = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(Filter_bank.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(Single_Bank.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(Single_Bank.fulfilled, (state, action: PayloadAction<any>) => {
        // state.newbank = { ...action.payload.data, ...state.newbank }
        // state.status = STATUSES.SUCEESS
      })
      .addCase(Single_Bank.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });
    builder
      .addCase(updateSingle_Bank.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(updateSingle_Bank.fulfilled, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.SUCEESS
      })
      .addCase(updateSingle_Bank.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });
      builder
      .addCase(deleteSingle_Bank.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteSingle_Bank.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = STATUSES.SUCEESS
      })
      .addCase(deleteSingle_Bank.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
    builder
      .addCase(Main_Account.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(Main_Account.fulfilled, (state, action: PayloadAction<any>) => {
        state.mainbank = action.payload.data
        // state.status = STATUSES.SUCEESS
      })
      .addCase(Main_Account.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      });
      builder
      .addCase(Update_Main_Account.pending, (state) => {
        // state.status = STATUSES.LOADING;
      })
      .addCase(Update_Main_Account.fulfilled, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.SUCEESS
      })
      .addCase(Update_Main_Account.rejected, (state, action: PayloadAction<any>) => {
        // state.status = STATUSES.ERROR
      })
      builder
      .addCase(AccountChange.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(AccountChange.fulfilled, (state, action: PayloadAction<any>) => {
        state.userbanklist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(AccountChange.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(searching.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(searching.fulfilled, (state, action: PayloadAction<any>) => {
        state.userbanklist = action.payload.data
        state.status = STATUSES.SUCEESS
      })
      .addCase(searching.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      
       builder
      .addCase(updateStatus.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(updateStatus.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(updateStatus.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
      builder
      .addCase(kobanaBankAccount.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(kobanaBankAccount.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.SUCEESS
      })
      .addCase(kobanaBankAccount.rejected, (state, action: PayloadAction<any>) => {
        state.status = STATUSES.ERROR
      });
  },
});
export const { addBankAccount, addNewBank, delete_user_Bank_Acccount, setEdit, handleClo, handleOpn, handleCloseBank, handleOpnBank, innerbank, handleEdit ,mainBankAcc} = bankSlice.actions
export default bankSlice.reducer

//POST -- ADD_NEW_BANK ACCOUNT API 
export const addNewbankaccount = createAsyncThunk(
  'new_bank_account',
  async (postData: Newbankaccount) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    postData["user_id"] = user_id
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/bank_accountNew`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName,
      },
    });
    const data = await response.json();
    return data
  }
); 
// GET - BANKLIST DATA API

export const get_BankAccountList = createAsyncThunk(
  'get_BankAccountList',
  async (obj:any) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    obj["user_id"]= user_id
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/user_bankList`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName,
      },
    });
    const data = await response.json();
    let newData = data.data;
    let newarray:any = [];

    newData.forEach((item ) => {
      if (item.main_ac == 1) {
        newarray.push(item)
      }
    })

    newData.forEach((item) => {
      if (item.main_ac != 1) {
        newarray.push(item)
      }
    })

    data.data = newarray;
    return data
  }
);

export const getBankList = createAsyncThunk(
  'get_user_bankList',
  async (obj:any) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    obj["user_id"]= user_id
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/user_bankList`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName,
      },
    });
    const data = await response.json();
    let newData = data.data;
    let newarray:any = [];

    newData.forEach((item ) => {
      if (item.main_ac == 1) {
        newarray.push(item)
      }
    })

    newData.forEach((item) => {
      if (item.main_ac != 1) {
        newarray.push(item)
      }
    })

    data.data = newarray;
    return data
  }
);

// GET -- ADD BANK  LIST
export const add_Bank = createAsyncThunk(
  'addbank',
  async (_, thunk) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/bankList`, {
      method: "POST",
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName,
      }
    })
    const data = await response.json()
    return data
  }
);

// POST - GET SINGLE BANK DATA API
export const Single_Bank = createAsyncThunk(
  'get_single_bank',
  async (id: number, accountType) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    let obj = {
      user_id: user_id,
      id: id
    }
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/userBank`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// PUT UPDATE SINGLE BANK API
export const updateSingle_Bank = createAsyncThunk(
  'update_Single_Bank',
  async (postData: Newbankaccount) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    postData["user_id"] = user_id;
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/bank_accountOld/`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    })
    const data = await response.json();
    return data
  }
);

// DETELE USER BANK ACCOUNT
export const deleteSingle_Bank = createAsyncThunk(
  'delete_Single_Bank',
  async (id:number) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    let token:any = localStorage.getItem("tokenV2");
    let raw = JSON.stringify({
      "user_id": id
    });
    const response = await fetch(`${config.companyV2_URL}/bank/user_bankList`, {
      method: 'DELETE',
      body:raw,
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);

// GET MAIN BANK ACCOUNT 
export const Main_Account = createAsyncThunk(
  'get_main_bank_account',
  async (_, thunkAPI) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    let raw = JSON.stringify({
      "user_id": user_id
    });
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/user_mainBank`, {
      method: 'POST',
      body: raw,
      headers: {
        'x-auth-token': token,
        "Content-Type": "application/json",
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// PUT UPDATE MAIN BANK ACCOUNT 
export const Update_Main_Account = createAsyncThunk(
  'update_main_bank_account',
  async (postdata:Newbankaccount) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    postdata["user_id"]= user_id
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/user_mainBank`, {
      method: 'PUT',
      body: JSON.stringify(postdata),
      headers: {
        'x-auth-token': token,
        "Content-Type": "application/json",
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);


// CHNAGE BANK ACCOUNT PRIMARY ACCOUNT 

export const AccountChange = createAsyncThunk(
  'Change _main_bank_account',
  async (id:number) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    let raw = JSON.stringify({
      "bank_id": id,
      "user_id" :user_id
    });
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/bank_accounts/change_main_account`, {
      method: 'PUT',
      body: raw,
      headers: {
        'x-auth-token': token,
        "Content-Type": "application/json",
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);
// GET BRANCHA FILTER - API
export const Filter_bank = createAsyncThunk(
  'filter_branch',
  async (filterdata: string) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    var raw = JSON.stringify(filterdata);
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/all/filter`, {
      method: 'POST',
      body: raw,
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);


// API SEACHING DATA FROM BANKS
export const searching = createAsyncThunk(
  'Search_filter',
  async (searchData: string) => {  
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }   
     let bodyContent = JSON.stringify({
       search:searchData
     });
     let token:any = localStorage.getItem("tokenV2");
     let response = await fetch(`${config.companyV2_URL}/bank/bank_accounts/search`, { 
       method: "PUT",
       body: bodyContent,
       headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
     });
     
     let data = await response.json();
      return data
  }
);


export const updateStatus = createAsyncThunk(
  'update_Status',
  async (postData:any) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    let token:any = localStorage.getItem("tokenV2");
    const response = await fetch(`${config.companyV2_URL}/bank/user_bankList/status`, {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: {
        'x-auth-token': token,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    })
    const data = await response.json();
    return data
  }
);

// POST KOBANA BANK ACCOUNT --API POST

export const kobanaBankAccount = createAsyncThunk(
  'kobana_bank_account',
  async (postData: any) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    const response = await fetch(`${config.KOBANA_API}/createBankBilletAccount/`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Authorization': gettoken.BearerAuth,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });
    const data = await response.json();
    return data
  }
);

// GET  API GET KOBANA BANK ACCOUNT --DONE
export const GetkobanaBankAccount = createAsyncThunk(
  'get_kobana_bank_account',
  async (id:number) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    const response = await fetch(`${config.KOBANA_API}/getBankBilletAccounts/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': gettoken.BearerAuth,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);


export const  BulleteBankAccount = createAsyncThunk(
  'bullet_abank_account',
  async (postData: any) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    const response = await fetch(`${config.KOBANA_API}/createBankBillets/`, {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Authorization': gettoken.BearerAuth,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);
export const BilletsBankAccount = createAsyncThunk(
  'get_Bank_Billet',
  async (id:number) => {
    let connectionName : any = '';
    if( localStorage.getItem("userData") ){
      connectionName = localStorage.getItem("userData");
    }
    const response = await fetch(`${config.KOBANA_API}/getBankBillet/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': gettoken.BearerAuth,
        'Content-type': 'application/json',
        'connection-name' : connectionName
      },
    });

    const data = await response.json();
    return data
  }
);

