import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { AppDispatch, } from "../../../redux/store";
import { getBankList } from "../../../redux/slice/bankSlice";
import { useTransferAccount } from "./TransferAccountProvider";
import { createNewTransfer } from "./services/createNewTransfer";
import { useTransferAccountDrawer } from "../TransferAccountDrawer";

export function useTransferAccountForm() {
  const { reset } = useFormContext();
  const dispatch = useDispatch<AppDispatch>();
  const { resetContextValues } = useTransferAccount();
  const { setShowTransferAccountDrawer } = useTransferAccountDrawer();
  const { selectedOriginAccount, selectedDestinationAccount } = useTransferAccount();

  async function onSubmit(data: any) {
    const transferPayload = {
      originAccount: selectedOriginAccount ?? null,
      destinationAccount: selectedDestinationAccount ?? null,
      date: data.date ?? null,
      transferAmount: data.transferAmount,
      complement: data.complement,
    };

    try {
      await createNewTransfer({ ...transferPayload });

      dispatch(
        getBankList({
          start: "0",
          end: "100",
        })
      );

      Swal.fire({
        title: "Sucesso!",
        text: "Transferência realizada com sucesso!",
        icon: "success",
      }).then(() => {
        reset();
        resetContextValues();
        setShowTransferAccountDrawer({
          action: undefined,
        });
      });
    } catch (error) {

      dispatch(
        getBankList({
          start: "0",
          end: "100",
        })
      );

      Swal.fire({        
        text: "Ocorreu um erro ao registrar a transferência.",
        icon: "error",
      });

    }
  }

  return { onSubmit };
}
