import React from 'react'; 
import {
    createBrowserRouter,
    RouterProvider, 
} from "react-router-dom";
import './App.css';
import Login from './component/Auth/Login';
import CompanyData from './component/Profile/Company/CompanyData/CompanyData';
import Branches from './component/Profile/Company/Branches/Branches';
import BankAccounts from './component/Profile/Company/BankAccounts/BankAccounts';
import Formreceipt from './component/Profile/Company/FormsOfReceipt/Formreceipt';
import Taxation from './component/Profile/Company/taxation/Taxation';
import AccountsPlan from './component/Profile/Company/AccountPlan/AccountsPlan';
import Dashboard from './component/Pages/Dashboard';
import Navbar from './component/Navbar'; 
import Service from './component/Profile/Company/services/Service';
import Notifications from './component/Profile/Company/Notifications';
import SentEmail from './component/Profile/History/SentEmail/SentEmail';
import SentMessage from './component/Profile/History/SentMessage/SentMessage';
import ReadjustmentIndexes from './component/Pages/Contracts/Readjustment/ReadjustmentIndexes';
import ReadjustmentedIndexes from './component/Pages/Contracts/Readjustment/ReadjustmentedIndexes';
import Contracts from './component/Pages/Contracts/Contracts';
import ContractsSearch from "./component/Pages/Contracts/ContractsSearch";
import ReadjustContracts from './component/Pages/Contracts/ReadjustContracts';
import ReadjustSimulate from './component/Pages/Contracts/ReadjustSimulate';
import Expenditure from './component/Pages/Contracts/Contracts/Expenditure';
import Pending_checklists from './component/Pages/Contracts/Contracts/Pending_checklists';
import Insurers from './component/Pages/Contracts/Contracts/Insurers';
import AddInsurence from './component/Pages/Contracts/Contracts/AddInsurence';
import Contract_details from './component/Pages/Contracts/Contracts/ContractDetail/Contract_details';
import Checklists from './component/Pages/Contracts/Contracts/Checklist/Checklists';
import NewGenerateChargeSimulate from './component/Pages/Contracts/Contracts/ContractDetail/NewGenerateChargeSimulate';
import Property_detail from './component/Pages/Contracts/Properties/Property_detail'; 
import Property_history from './component/Pages/Contracts/Properties/Property_history';
import Administrators from './component/Pages/Contracts/Properties/Administrator/Administrators';
import Condos from './component/Pages/Contracts/Properties/Condos';
import Managers from './component/Pages/Contracts/Properties/Managers';
import Properties from './component/Pages/Contracts/Properties/Properties'; 
import Inspectors from './component/Pages/Contracts/Customer_and_employees/Inspectors';
import Suppliers from './component/Pages/Contracts/Customer_and_employees/Suppliers';
import Owners_and_beneficiaries from './component/Pages/Contracts/Customer_and_employees/Owners_and_beneficiaries';
import Tenants from './component/Pages/Contracts/Customer_and_employees/Tenants';
import Guarantors from './component/Pages/Contracts/Customer_and_employees/Guarantors';
import Owners_area from './component/Pages/Contracts/Customer_and_employees/Owners_area';
import Tenant_area from './component/Pages/Contracts/Customer_and_employees/Tenant_area';
import CustomersAndEmployees from './component/Pages/Contracts/Customer_and_employees/CustomersAndEmployees';
import Brokers from './component/Pages/Contracts/Customer_and_employees/Brokers';
import GenerateCharges from './component/Pages/Income/GenerateCharges/GenerateCharges';
import ContractsReadjust from './component/Pages/Income/GenerateCharges/ContractsReadjust';
import TableIndex from './component/Pages/Income/GenerateCharges/TableIndex';
import ReceivableCharges from './component/Pages/Income/ReceivableCharges/ReceivableCharges';
import ChargeInvoices from './component/Pages/Income/Invoices/Invoices'; 
import Received from './component/Pages/Income/Received/Received'; 
import BillingRule from './component/Pages/Income/BillingRule/BillingRule';
import Message from './component/Pages/Income/BillingRule/Message';
import BankTransactions from './component/Pages/Financial/BankTransactions'; 
import SubNavBar from './component/SubNavBar';
import User from './component/Profile/Users/User';
import Profile from "./component/Pages/Contracts/Customer_and_employees/Owner_details";
import Transfers from './component/Pages/expenses/transfers'; 
import ToBePaid from './component/Pages/expenses/ToBePaid';
import Paid from './component/Pages/expenses/paid'; 
import NewTransfers from './component/Pages/expenses/new_transfers/NewTransfers'; 
import ProfileDetails from './component/Profile/ProfileDetails';
import Administrators_details from './component/Pages/Contracts/Properties/Administrator/Administrators_details';
import Documents from './component/Pages/Contracts/Contracts/ContractDetail/Documents';
import Condos_details from './component/Pages/Contracts/Properties/Condos_details';
import Broker_details from './component/Pages/Contracts/Customer_and_employees/Broker_details';
import Mangers_details from './component/Pages/Contracts/Properties/Mangers_details';
import PropertyDocuments from './component/Pages/Contracts/Properties/PropertyDocuments'; 
import ElectronicEnvelope from './component/Pages/Contracts/Contracts/ContractDetail/ElectronicEnvelope';
import ContractService from './component/Pages/NewDesigns/Contracts/ContractService';
import NonPayment from './component/Pages/NewDesigns/NonPayment/NonPayment';
import FolderListing from './component/Pages/NewDesigns/Documents/Folder/FolderListing';
import DocumentsList from './component/Pages/NewDesigns/Documents/Documents';
import EmptyFolder from './component/Pages/NewDesigns/Documents/EmptyFolder';
import NotifyEmail from './component/Pages/NewDesigns/NonPayment/Contract/NotifyEmail';
import EditContractService from './component/Pages/NewDesigns/Contracts/EditContractService';
import NonPaymentAgreements from './component/Pages/NewDesigns/NonPayment/NonPaymentAgreements';
import NewEnvelope from './component/Pages/NewDesigns/NewEnvelope';
import FolderDetail from './component/Pages/NewDesigns/Documents/Folder/FolderDetail';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Impersonate } from './newComponents/views/Impersonate';
import TermofService  from "./component/Privacy_policy/TermofService"
import Privacy_policys from "./component/Privacy_policy/Privacy_policys"

import 'moment/min/locales'
import moment from "moment";
import momentTZ from "moment-timezone";
import FormReceiptV2 from './component/Profile/Company/FormsOfReceipt/FormReceiptV2';
import Series from './component/Pages/Income/Invoices/Series';

declare global {
    interface Window {
        fcWidget: any;
    }
}

const routes = [
    { path: "/", element: <Login /> },
        // Impersonate Route
    {  path: "/impersonate", element: (<GoogleOAuthProvider clientId={ "429889268995-6qj8l80ebbitsf73m1ta4hvmqu65n626.apps.googleusercontent.com"}><Impersonate />{" "}</GoogleOAuthProvider> )},
    { path: "/terms_of_service", element: <TermofService /> },
    { path: "/privacy_policy", element: <Privacy_policys /> },
    {
        element: <Navbar />,
        children: [
            // Dashboard Page
            { path: "/dashboard", element: <Dashboard submenu={<SubNavBar title={'Dashboard'} />} /> },
            // Contracts Routes
            { path: "/contracts", element: <Contracts submenu={<SubNavBar title={'Contracts'} />} /> },
            { path: "/contractsSearch/:id", element: <ContractsSearch submenu={<SubNavBar title={'ContractsSearch'} />} /> },
            { path: "/readjustContracts", element: <ReadjustContracts submenu={<SubNavBar title={'ReadjustContracts'} />} /> },
            { path: "/readjustSimulate", element: <ReadjustSimulate  /> },
            { path: "/customer_and_employees", element: <CustomersAndEmployees /> },
            { path: "/properties", element: <Properties /> },
            { path: "/readjustment_indexes", element: <ReadjustmentIndexes /> },
            { path: "/ReadjustmentedIndexes/:id", element: <ReadjustmentedIndexes /> },
            { path: "/generateChargeSimulate", element: <NewGenerateChargeSimulate /> },
            { path: "/expenditure", element: <Expenditure /> },
            { path: "/pending_checklists", element: <Pending_checklists /> },
            { path: "/insurers", element: <Insurers /> },
            { path: "/add_insurance", element: <AddInsurence /> },
            { path: "/checklists", element: <Checklists /> },
            { path: "/contract_details/:id", element: <Contract_details /> },
            { path: "/property_detail/:id", element: <Property_detail /> }, 
            { path: "/property_history/:id", element: <Property_history /> },
            { path: "/documents", element: <Documents /> },
            { path: "/property_documents/:id", element: <PropertyDocuments />},
            { path: "/Administrators_details/:id", element: <Administrators_details submenu={<SubNavBar title={'Administrators'} />} /> },
            { path: "/administrators", element: <Administrators /> },
            { path: "/condos", element: <Condos /> },
            { path: "/condos_details/:id", element: <Condos_details submenu={<SubNavBar title={'condominium'} />} /> },
            { path: "/brokers", element: <Brokers /> },
            { path: "/Broker_details/:id", element: <Broker_details /> },
            { path: "/managers", element: <Managers /> },
            { path: "/managers_details/:id", element: <Mangers_details/>},
            { path: "/inspectors", element: <Inspectors /> },
            { path: "/suppliers", element: <Suppliers /> },
            { path: "/owners_and_beneficiaries", element: <Owners_and_beneficiaries /> },
            { path: "/tenants", element: <Tenants /> },
            { path: "/guarantors", element: <Guarantors /> },
            { path: "/owners_area", element: <Owners_area /> },
            { path: "/tenant_area", element: <Tenant_area /> },
            { path: "/all_users", element: <User /> }, 
            { path: "/electronic_envelope/:id", element: <ElectronicEnvelope />},
            // Income
            { path: "/generate_charges", element: <GenerateCharges /> },
            { path: "/contracts_readjust", element: <ContractsReadjust /> },
            { path: "/table_index", element: <TableIndex /> },
            { path: "/receivable_charges", element: <ReceivableCharges /> },
            { path: "/receivable_charges/:id", element: <ReceivableCharges /> },
            { path: "/charge_invoices", element: <ChargeInvoices /> }, 
            { path: "/series", element: <Series /> }, 
            { path: "/received", element: <Received /> }, 
            { path: "/billing_rule", element: <BillingRule /> },
            { path: "/message", element: <Message /> }, 
            //Expenses
            { path: "/transfers", element: <Transfers /> }, 
            { path: "/toBePaid", element: <ToBePaid /> },
            { path: "/paid", element: <Paid /> },
            { path: "/new_transfers", element: <NewTransfers /> },
            // Financial Routes
            { path: "/bank_transactions", element: <BankTransactions /> }, 
            //SubMenu NavBar
            // Profile Company Routes
            { path: "/company", element: <CompanyData submenu={<SubNavBar title={'Company'} />} /> },
            { path: "/branches", element: <Branches submenu={<SubNavBar title={'Branches'} />} /> },
            { path: "/bank_accounts", element: <BankAccounts submenu={<SubNavBar title={'Bank Accounts'} />} /> },
            { path: "/form_receipt", element: <Formreceipt submenu={<SubNavBar title={'Forms Of Receipts'} />} /> },
            { path: "/taxation", element: <Taxation submenu={<SubNavBar title={'Taxation'} />} /> },
            { path: "/accounts_plan", element: <AccountsPlan submenu={<SubNavBar title={'Accounts Plan'} />} /> }, 
            { path: "/services", element: <Service submenu={<SubNavBar title={'Service'} />} /> },
            { path: "/notifications", element: <Notifications submenu={<SubNavBar title={'Notifications'} />} /> },
            { path: "/owner_details/:id", element: <Profile submenu={<SubNavBar title={'profile'} />} /> },
            { path: "/profile_details/:id", element: <ProfileDetails submenu={<SubNavBar title={'profile'} />} /> },
            //Hostory
            { path: "/sent_email", element: <SentEmail /> },
            { path: "/sent_message", element: <SentMessage /> },
            //Reports Component
            // New design
            // { path: "/new_transfers", element: <NewTransfers /> },
            { path: "/contract", element: <ContractService /> },
            { path: "/edit_contract/:id", element: <EditContractService />},
            { path: "/documents_list", element: <DocumentsList /> },
            { path: "/empty_folder", element: <EmptyFolder /> },
            { path: "/folder_listing/:id", element: <FolderListing /> },
            { path: "/folder_detail", element: <FolderDetail /> },
            { path: "/non_payment", element: <NonPayment /> },
            { path: "/notify_email", element: <NotifyEmail /> },
            { path: "/non_payment_agreements", element: <NonPaymentAgreements /> },
            { path: "/new_envelope", element: <NewEnvelope /> },
            { path: "/form_receipt_v2", element: <FormReceiptV2 submenu={<SubNavBar title={'Forms Of Receipts'} />} /> },
        ]
    },
]
const router = createBrowserRouter(routes);
function App() {
    let lang_code = localStorage.getItem("language") ? localStorage.getItem("language") : "pt";
    const locales = {
        en: 'en',
        pt: 'pt-br'
    }
    if (lang_code) {
        moment.locale(locales[lang_code]);
        momentTZ.defineLocale(locales[lang_code], (moment.localeData() as any)?._config);
        momentTZ.locale(locales[lang_code]);
    }
    return (
        <div className="wrapper">
            <RouterProvider router={router} />
        </div>
    )
}
export default App;
