import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getBankList } from "../../../../redux/slice/bankSlice";
import { getBraches } from "../../../../redux/slice/branchSlice";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import {
  showcancelData,
  showcanceledDate,
  showChargeData,
  showChargeDate,
  showcreditData,
  showCreditDate,
  showgeneratData,
  showgenerateDate,
  showLiqueDate,
  showliquidData,
} from "../../../../redux/slice/contractDataSlice";
import { fetchService } from "../../../../redux/slice/ServiceSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { encrypt_Decrypt, PrintPDF } from "../../../constants/utils";
import AnnualDebtStatment from "./AnnualDebtStatment";
import ChargeService from "./ChargeService";
import ChargesFilter from "./ChargesFilter";
import eventBus from "./EventBus";
import moment from "moment-timezone";
import { incomeService } from "../../../../services/incomeServices";
import { PANES, SwitchChargesTabPanes } from "../../../../newComponents/features/chargesToReceive/components/SwitchChargesTabPanes";
import axios from "axios";

const SubHeader = (props) => {
  const location = useLocation();
  const { id } = useParams();
  let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");
  let {
    getChargesSummery,
    setSearchValue,
    offFirstload,
    setOffFirstLoad,
    setsearch_type,
    setStateUpdate,
    stateChange,
    setStatechange,
    setCurrentPage,
    setStatus,
    setSelectedTab
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const branchData = useSelector((state: RootState) => state.branch);
  const bankData = useSelector((state: RootState) => state.bank);
  const serviceData = useSelector((state: RootState) => state.service);
  const {
    filterDatadate,
    filterliquiddate,
    filtercreditdate,
    filtercanceldate,
    filtergeneratdate,
  } = useSelector((state: RootState) => state.contractData);
  const { wayofreceiving } = useSelector((state: RootState) => state.forms);
  const { accountData } = useSelector((state: RootState) => state.expenditureData);

  const [buttonPrintLoading, setButtonPrintLoading] = useState(false);
  const [printDropdown, setPrintDropdown] = useState(false);
  const [userJson, setUserJson] = useState<any>([]);
  const [pathName, setPathName] = useState<any>("");
  const [branchState, setBranchState] = useState({
    start: "0",
    end: "100",
  });

  useEffect(() => {
    let userResult = encrypt_Decrypt();
    setUserJson(userResult);
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      let newString = location?.pathname.replace("/", "");
      setPathName(newString);
    }
  }, [location]);
  const reportKenloPayValidation = Yup.object().shape({
    bank_account: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    start_date: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    end_date: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired'))
  });
  let frm = {
    resolver: yupResolver(reportKenloPayValidation),
    defaultValues: {
      end_date: moment().format('YYYY-MM-DD'),
      start_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
    }
  }
  const { register, handleSubmit, getValues, setValue, clearErrors, setError, formState: { errors } } = useForm<{
    bank_account: string,
    start_date: string,
    end_date: string
  }>(frm);
  //New charges modal initialization
  const [showChargeModal, setChargeModal] = useState(false);
  const newChargeModalClose = () => {
    setChargeModal(false);
  };
  const newChargeModalShow = () => {
    document.querySelector("body")?.setAttribute("new_charge_action", "new");
    eventBus.dispatch("chargeIdis", "");
    setTimeout(() => {
      setChargeModal(true);
    }, 600);
  };

  //Annual debt discharge statement modal initialization
  const [showAnnualDischargeModal, setAnnualDischargeModal] = useState(false);
  const annualDischargeModalClose = () => setAnnualDischargeModal(false);
  const annualDischargeModalShow = () => setAnnualDischargeModal(true);

  //Desktop filters modal initialization
  const [showDesktopModal, setDesktopModal] = useState(false);
  const desktopModalClose = () => {
    let setvalue: any = document.querySelector("#status");
    if (setvalue) {
      setvalue.value = '{"value":"","label":"All"}';
    }

    let personValue: any = document.querySelector("#personvalue");
    if (personValue) {
      personValue.value = "";
    }
    dispatch(showChargeData([]));
    dispatch(showliquidData([]));
    dispatch(showcreditData([]));
    dispatch(showcancelData([]));
    dispatch(showgeneratData([]));
    setFilterData({
      filter_by: [],
      status: [],
      person: [],
      see_online: [],
      customer: [],
      service: [],
      bank_account: [],
      billing_payment: [],
      customer_payment: [],
      branch: [],
      marked_shipment: [],
      delivery_method: [],
      ground_invalid: [],
      with_split: [],
      credit_card_failure: [],
      credit_card_mistake: [],
    });
    setDesktopModal(false);
  };

  const desktopModalCloseDone = () => {
    setDesktopModal(false);
  };

  const desktopModalShow = () => {
    setDesktopModal(true);
    setFiltersOpen(false);
  };

  //Mark for shipment modal initialization
  const [showMarkShipmentModal, setMarkShipmentModal] = useState(false);
  const [markShip, setMarkShip] = useState(false);
  const [totalMarkShip, setTotalMarkShip] = useState(false);
  const [totalSelectedCharge, setTotalSelectedCharge] = useState(0);
  const [shipmarkId, setShipMarkId] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState<string>(PANES.charges.key)

  const markShipmentModalShow = (type) => {
    setMarkShipmentModal(true);
    if (type == "select") {
      let argument = {
        FL_STATUS_RECB: 0,
        type: "toReceive",
        Maturity_Date: "2023-06",
        filter: {},
        filteSearchDate: "",
        filterEndDate: "",
        search_type: "",
      };

      let TodayDate = new Date(dateBrazil);
      argument["filteSearchDate"] = moment(TodayDate).format("YYYY-MM-DD");
      argument["filterEndDate"] = moment(TodayDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      incomeService.getChargesSummery(argument).then((res) => {
        if (res.data) {
          let idpushdata: any = [];
          res.data.forEach((item) => {
            idpushdata.push(item["ID_RECEBIMENTO_RECB"]);
          });
          setShipMarkId(idpushdata);
          setTotalSelectedCharge(idpushdata.length);
        } else {
          setShipMarkId([]);
          setTotalSelectedCharge(0);
        }
      });
      setTotalMarkShip(true);
      setMarkShip(true);
    } else {
      setShipMarkId([]);
      setTotalMarkShip(false);
      setMarkShip(false);
      setTotalSelectedCharge(0);
    }
  };

  const markShipmentModalClose = () => {
    setMarkShipmentModal(false);
    setShipMarkId([]);
    setTotalSelectedCharge(0);
  };

  let obj = {
    start: "0",
    end: "",
    all: "",
  };

  useEffect(() => {
    dispatch(getBankList(branchState));
    dispatch(getBraches(branchState));
    dispatch(fetchService());
  }, []);

  const handleDataShow = () => {
    let argument = {
      FL_STATUS_RECB: 0,
      type: "toReceive",
      Maturity_Date: "2023-06",
      filter: {},
      filteSearchDate: "",
      filterEndDate: "",
      search_type: "",
    };

    let TodayDate = new Date(dateBrazil);
    argument["filteSearchDate"] = moment(TodayDate).format("YYYY-MM-DD");
    argument["filterEndDate"] = moment(TodayDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    setStateUpdate(argument);
    markShipmentModalClose();
  };

  const [allfilter, setAllFilter] = useState({
    filter_by: [
      { value: 1, label: "Due date" },
      { value: 2, label: "Settlement" },
      { value: 3, label: "Credit" },
      { value: 4, label: "Forecast" },
      { value: 5, label: "Competence" },
      { value: 6, label: "Cancellation" },
      { value: 7, label: "Generation" },
    ],

    status: [
      { value: "", label: "All" },
      // { value: 0, label: "pending" },
      // { value: 1, label: "Received" },
      // { value: 2, label: "Settled" },
      // { value: 3, label: "Suspended Charges" },
      // { value: 4, label: "Protested" },
      { value: 6, label: "Cancelled" },
      // { value: 7, label: "Sent to the registry" },
      // { value: 8, label: "Cards with issues" },
    ],
    person: [
      // { value: 1, label: "Physical and Legal" }, // both
      { value: 2, label: "Physical" }, // CPF
      { value: 3, label: "Legal" }, // CNPJ
    ],
    see_online: [
      { value: 0, label: "All" },
      { value: 1, label: "Viewed Online" },
      { value: 2, label: "Not Viewed" },
    ],
    customer: [
      { value: 1, label: "Customer 1" },
      { value: 2, label: "Customer 2" },
    ],
    bank_account: [{ value: 1, label: "Nicoly test box" }],
    billing_payment: [
      { value: 1, label: "All" },
      { value: 2, label: "Ticket" },
      { value: 3, label: "Check" },
    ],
    customer_payment: [
      { value: 1, label: "All" },
      { value: 2, label: "Ticket" },
      { value: 3, label: "Check" },
    ],
    branch: [{ value: 1, label: "Headquarters" }],
    service: [{ value: 1, label: "Service 1" }],
    marked_shipment: [
      { value: "", label: "All" },
      { value: "marcados", label: "Marked for shipment" },
      { value: "desmarcados", label: "Not Marked for shipment" },
      { value: "enviadas", label: "Shipments sent" },
      { value: "confirmadas", label: "Confirmed shipments" },
      { value: "erro", label: "Shipments with error" },
      { value: "baixa", label: "Settled shipments" },
    ],
    delivery_method: [
      { value: "", label: "All" },
      { value: "-1", label: "Mail and Digital" },
      { value: "correio", label: "Mail Only" },
      { value: "digital", label: "Digital Only" },
    ],
    ground_invalid: [
      { value: "-1", label: "All Reasons" },
      { value: "1", label: "Incorrect Billing" },
      { value: "2", label: "Substituted Billing" },
      { value: "3", label: "Cancellation" },
      { value: "4", label: "Bonus" },
      { value: "7", label: "Canceled Proposal" },
      { value: "8", label: "Provision for Doubtful Debt (PDD)" },
      { value: "5", label: "Suspension" },
      { value: "9", label: "Negotiation" },
      { value: "10", label: "Underpayment" },
      { value: "11", label: "Activate Subscription" },
      { value: "6", label: "Other" },
    ],
    with_split: [
      { value: "-1", label: "All" },
      { value: "0", label: "No split" },
      { value: "1", label: "With split" },
    ],
    credit_card_failure: [
      { value: "", label: "Todos" },
      { value: "temporaria", label: "Temporary failure" },
      { value: "permanente", label: "Permanent failure" },
    ],
    credit_card_mistake: [{ value: 1, label: "Mistake 1" }],
  });

  useEffect(() => {
    if (bankData["userbanklist"]) {
      let store: any = [];
      bankData["userbanklist"].forEach((item) => {
        const receivingMethods = wayofreceiving.filter(receivingMethod => receivingMethod["ID_CONTA_CB"] === item.id)
        if (activeTabKey === PANES.homologatedAccount.key &&
            !receivingMethods.some(receivingMethod => receivingMethod["FL_BOLETOSUPERLOGICA_FRECB"] === 1)
           )
         {
           return
         }

        store.push({ value: item.id, label: item.nameReference });
      });
      allfilter["bank_account"] = store;
    }

    if (branchData["branchList"]) {
      let store: any = [];
      branchData["branchList"].forEach((item) => {
        store.push({ value: item.id, label: item.branchname });
      });
      allfilter["branch"] = store;
    }

    if (serviceData["servicelist"]) {
      let store: any = [];
      serviceData["servicelist"].forEach((item) => {
        store.push({
          value: item.ID_PRODUTO_PRD,
          label: item.ST_DESCRICAO_PRD,
        });
      });
      allfilter["service"] = store;
    }
  }, [
    bankData["userbanklist"],
    serviceData["servicelist"],
    branchData["branchList"],
    activeTabKey,
  ]);

  useEffect(() => {
    if (i18n.language == "en") {
      setAllFilter({
        ...allfilter,
        filter_by: [
          { value: 1, label: "Due date" },
          { value: 2, label: "Settlement" },
          { value: 3, label: "Credit" },
          { value: 4, label: "Forecast" },
          { value: 5, label: "Competence" },
          { value: 6, label: "Cancellation" },
          { value: 7, label: "Generation" },
        ],
        status: [
          { value: "", label: "All" },
          // { value: 0, label: "Pending" },
          // { value: 1, label: "Received" },
          // { value: 2, label: "Settled" },
          // { value: 3, label: "Suspended Charges" },
          // { value: 4, label: "Protested" },
          { value: 6, label: "Cancelled" },
          // { value: 7, label: "Sent to the registry" },
          // { value: 8, label: "Cards with issues" },
        ],
        person: [
          // { value: 1, label: "Physical and Legal" }, // both
          { value: 2, label: "Physical" }, // CPF
          { value: 3, label: "Legal" }, // CNPJ
        ],
        see_online: [
          { value: 0, label: "All" },
          { value: 1, label: "Viewed Online" },
          { value: 2, label: "Not Viewed" },
        ],
        customer: [
          { value: 1, label: "Customer 1" },
          { value: 2, label: "Customer 2" },
        ],
        bank_account: [{ value: 1, label: "Nicoly test box" }],
        billing_payment: [
          { value: 1, label: "All" },
          { value: 2, label: "Ticket" },
          { value: 3, label: "Check" },
        ],
        customer_payment: [
          { value: 1, label: "All" },
          { value: 2, label: "Ticket" },
          { value: 3, label: "Check" },
        ],
        branch: [{ value: 1, label: "Headquarters" }],
        marked_shipment: [
          { value: "", label: "All" },
          { value: "marcados", label: "Marked for Shipment" },
          { value: "desmarcados", label: "Not Marked for Shipment" },
          { value: "enviadas", label: "Shipments Sent" },
          { value: "confirmadas", label: "Confirmed Shipments" },
          { value: "erro", label: "Shipments with Error" },
          { value: "baixa", label: "Settled Shipments" },
        ],
        delivery_method: [
          { value: "", label: "All" },
          { value: "-1", label: "Mail and Digital" },
          { value: "correio", label: "Mail Only" },
          { value: "digital", label: "Digital Only" },
        ],
        ground_invalid: [
          { value: "-1", label: "All Reasons" },
          { value: "1", label: "Incorrect Billing" },
          { value: "2", label: "Substituted Billing" },
          { value: "3", label: "Cancellation" },
          { value: "4", label: "Bonus" },
          { value: "7", label: "Canceled Proposal" },
          { value: "8", label: "Provision for Doubtful Debt (PDD)" },
          { value: "5", label: "Suspension" },
          { value: "9", label: "Negotiation" },
          { value: "10", label: "Underpayment" },
          { value: "11", label: "Activate Subscription" },
          { value: "6", label: "Other" },
        ],
        with_split: [
          { value: "-1", label: "All" },
          { value: "0", label: "No split" },
          { value: "1", label: "With split" },
        ],
        credit_card_failure: [
          { value: "", label: "Todos" },
          { value: "temporaria", label: "Temporary failure" },
          { value: "permanente", label: "Permanent failure" },
        ],
        credit_card_mistake: [{ value: 1, label: "Mistake 1" }],
      });
    } else {
      setAllFilter({
        ...allfilter,
        filter_by: [
          { value: 1, label: "Data de vencimento" },
          { value: 2, label: "Liquidação" },
          { value: 3, label: "Crédito" },
          { value: 4, label: "Previsão" },
          { value: 5, label: "Competência" },
          { value: 6, label: "Cancelamento" },
          { value: 7, label: "Geração" },
        ],
        status: [
          { value: 0, label: "Todos" },
          // { "value": 0, "label": "Pendente" },
          // { "value": 1, "label": "Recebido" },
          // { "value": 2, "label": "Liquidado" },
          // { "value": 3, "label": "Cobranças Suspensas" },
          // { "value": 4, "label": "Protestado" },
          { value: 6, label: "Cancelado" },
          // { "value": 7, "label": "Enviado ao Registro" },
          // { "value": 8, "label": "Cartões com Problemas" }
        ],
        person: [
          // { "value": 1, "label": "Físico e Jurídico" },
          { value: 2, label: "Físico" },
          { value: 3, label: "Jurídico" },
        ],
        see_online: [
          { value: 0, label: "Todos" },
          { value: 1, label: "Visualizado Online" },
          { value: 2, label: "Não Visualizado" },
        ],
        customer: [
          { value: 1, label: "Cliente 1" },
          { value: 2, label: "Cliente 2" },
        ],
        bank_account: [{ value: 1, label: "Caixa de teste Nicoly" }],
        billing_payment: [
          { value: 1, label: "Todos" },
          { value: 2, label: "Boleto" },
          { value: 3, label: "Cheque" },
        ],
        customer_payment: [
          { value: 1, label: "Todos" },
          { value: 2, label: "Boleto" },
          { value: 3, label: "Cheque" },
        ],
        branch: [{ value: 1, label: "Sede" }],
        marked_shipment: [
          { value: "", label: "Todos" },
          { value: "marcados", label: "Marcados para Envio" },
          { value: "desmarcados", label: "Não Marcados para Envio" },
          { value: "enviadas", label: "Envios Enviados" },
          { value: "confirmadas", label: "Envios Confirmados" },
          { value: "erro", label: "Envios com Erro" },
          { value: "baixa", label: "Envios Baixados" },
        ],
        delivery_method: [
          { value: "", label: "Todos" },
          { value: "-1", label: "Correio e Digital" },
          { value: "correio", label: "Somente Correio" },
          { value: "digital", label: "Somente Digital" },
        ],
        ground_invalid: [
          { value: "-1", label: "Todos os Motivos" },
          { value: "1", label: "Cobrança Incorreta" },
          { value: "2", label: "Cobrança Substituída" },
          { value: "3", label: "Cancelamento" },
          { value: "4", label: "Bônus" },
          { value: "7", label: "Proposta Cancelada" },
          { value: "8", label: "Provisão para Devedores Duvidosos (PDD)" },
          { value: "5", label: "Suspensão" },
          { value: "9", label: "Negociação" },
          { value: "10", label: "Pagamento Parcial" },
          { value: "11", label: "Ativar Assinatura" },
          { value: "6", label: "Outro" },
        ],
        with_split: [
          { value: "-1", label: "Todos" },
          { value: "0", label: "Sem divisão" },
          { value: "1", label: "Com divisão" },
        ],
        credit_card_failure: [
          { value: "", label: "Todos" },
          { value: "temporaria", label: "Falha Temporária" },
          { value: "permanente", label: "Falha Permanente" },
        ],
        credit_card_mistake: [{ value: 1, label: "Erro 1" }],
      });
    }
  }, [i18n.language]);

  const [filterData, setFilterData] = useState<any>({
    filter_by: [],
    status: [],
    person: [],
    see_online: [],
    customer: [],
    service: [],
    bank_account: [],
    billing_payment: [],
    customer_payment: [],
    branch: [],
    marked_shipment: [],
    delivery_method: [],
    ground_invalid: [],
    with_split: [],
    credit_card_failure: [],
    credit_card_mistake: [],
  });

  const allmultipleFilter = (item: any, type) => {
    setOffFirstLoad(true);
    if (item.length < 1) {
      setFilterData({
        ...filterData,
        [type]: [],
      });
    } else {
      if (typeof item == "string") {
        item = [JSON.parse(item)];
      }
      setFilterData({
        ...filterData,
        [type]: item,
      });
    }
  };

  useEffect(() => {
      let newObj = {};
      for (const [key, value] of Object.entries(filterData)) {
        if (value && value?.["length"] > 0) {
          newObj[`${key}`] = value;
        }
      }
      setSearchValue(newObj);
  }, [filterData]);

  const [openFilters, setFiltersOpen] = useState(false);
  const handleShow = () => {
    dispatch(showChargeDate(true));
  };

  const handleShow1 = () => {
    dispatch(showLiqueDate(true));
  };

  const handleShow2 = () => {
    dispatch(showCreditDate(true));
  };

  const handleShow3 = () => {
    dispatch(showcanceledDate(true));
  };

  const handleShow4 = () => {
    dispatch(showgenerateDate(true));
  };

  let typingTimer;
  const searchFilter = (searchValue) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setsearch_type(searchValue);
    }, 800);
  };

  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).subtract(1, "M").format("YYYY-MM-DD");
  let searchDt = moment(newDate).format("YYYY-MM-DD");
  let endDt = moment(newDate).add(1, "M").format("YYYY-MM-DD");

  const filterCharge = (dt: any, tp: string) => {
    if (typeof tp !== "undefined") {
      let currentDate = moment(dt);
      if (tp == "Subtract") {
        startDt = moment(currentDate).subtract(1, "M").format("YYYY-MM-DD");
        searchDt = moment(startDt).add(1, "M").format("YYYY-MM-DD");
        endDt = moment(currentDate).add(1, "M").format("YYYY-MM-DD");
        setStatus("loading");
      } else {
        startDt = moment(currentDate).subtract(1, "M").format("YYYY-MM-DD");
        searchDt = moment(startDt).add(1, "M").format("YYYY-MM-DD");
        endDt = moment(currentDate).add(1, "M").format("YYYY-MM-DD");
        setStatus("loading");
      }

      setStatechange({
        ...stateChange,
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        start: 0,
        ID_RECEBIMENTO_RECB: "",
        listType: "",
        filteSearchDate: "",
        filterEndDate: "",
      });
      setCurrentPage(1);
      setStatus("");
    }
  };

  const bankAccounts = useMemo(() => {
    if (accountData.data?.length) {
      return accountData.data.filter(e => e.isActiveinFormofReceipt === 0 && e.accountType !== 2 && e.accountType != null && !!e.hasSplitPaymentIntegrated);
    }

    return [];
  }, [accountData])

  useEffect(() => {
    const main = bankAccounts.find(e => e.main_ac === 1);
    if (main) setValue('bank_account', main.id);
  }, [bankAccounts, setValue])

  const openPrintFilters = (e) => {
    e.stopPropagation();
    setPrintDropdown(true);
  };

  const handleDate = (e, key) => {
    setValue(key, e.target.value);

    let { start_date: start, end_date: end }: any = getValues() ?? {};
    if (key === 'end_date') end = e.target.value;
    if (key === 'start_date') start = e.target.value;

    clearErrors(['end_date', 'start_date']);
    if (start && end) {
      end = new Date(end);
      start = new Date(start);

      if (end <= start) {
        setError(key, {
          type: "manual",
          message: `${t("requiredMmessage.periodInvalid")}`,
        });
      }
    }
  };

  const onReport = async (formData = {}) => {
    setButtonPrintLoading(true);

    try {
      const report = await incomeService.getSplitReport(formData);
      if (!report?.data?.link) {
        Swal.fire({
          timer: 2000,
          confirmButtonText: "OK",
          title: t("Sweetalert.Error"),
          text: t('Sweetalert.noReportData'),
        });
      } else {
        axios({
          method: 'GET',
          responseType: 'blob',
          url: report?.data?.link,
      }).then(response => {
          const href = URL.createObjectURL(response.data);
          window.open(href, '_blank');
      });
      }
    } catch (e) {
      Swal.fire({
        timer: 2000,
        confirmButtonText: "OK",
        title: t("Sweetalert.Error"),
        text: t('Sweetalert.somethingWent'),
      });
    }

    setButtonPrintLoading(false);
  };

  return (
    <>
      <div
        data-page="Charges due"
        className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3"
      >
        <div className="w-100 mb-xl-4">
          <Row className="gx-xl-5 justify-content-between align-items-center">
            <Col
              xs={{ order: 12 }}
              xl={{ span: "auto", order: 0 }}
              className="mb-xl-0 mb-3"
            >
              <div className="d-flex align-items-center justify-content-between me-xl-1">
                <h1 className="mb-0 lh-base text-truncate">
                  {pathName == "received"
                    ? t("ReceiveandReceived.Incomereceived")
                    : t("ReceiveandReceived.Chargesdue")}
                </h1>
                <div className="d-xl-none">
                  <Button
                    variant="light"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setFiltersOpen(!openFilters)}
                    aria-controls="collpase-filter-text"
                    aria-expanded={openFilters}
                  >
                    {t("dropdownItem.filters")}
                    {id && id !== null ? (
                      <img
                        src={"../assets/images/icons/filter-icon.svg"}
                        alt="Filter"
                        className="ms-1 h-12"
                      />
                    ) : (
                      <img
                        src={"./assets/images/icons/filter-icon.svg"}
                        alt="Filter"
                        className="ms-1 h-12"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
              {/* Search bar */}

              {/*  */}
              <InputGroup>
                <Form.Control
                  placeholder={t("formLabel.typeBasedThis")}
                  aria-label="Search"
                  aria-describedby="search_icon"
                  onChange={(e) => searchFilter(e.target.value)}
                />
                <InputGroup.Text id="search_icon">
                  {id && id !== null ? (
                    <img
                      src={"../assets/images/icons/search.svg"}
                      alt="Search icon"
                    />
                  ) : (
                    <img
                      src={"./assets/images/icons/search.svg"}
                      alt="Search icon"
                    />
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              xl={{ order: 3 }}
              className="text-xl-end mb-xl-0 mb-2 col-xl"
            >
              <ul className="list-inline mb-0">
                {userJson &&
                  userJson.length > 0 &&
                  userJson.indexOf(3031) !== -1 && (
                    <>
                      <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                        <a
                          className="fw-bold cursor-pe"
                          onClick={newChargeModalShow}
                        >
                          {id && id !== null ? (
                            <img
                              src={"../assets/images/icons/plus-light.svg"}
                              alt="Plus icon"
                              className="me-1 h-20"
                            />
                          ) : (
                            <img
                              src={"./assets/images/icons/plus-light.svg"}
                              alt="Plus icon"
                              className="me-1 h-20"
                            />
                          )}
                          {t("expenditure.newCharge")}
                        </a>
                      </li>
                      <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="link"
                            size="sm"
                            className="p-0 fw-bold dropdown-arrow-none"
                            id="shippingDropdown"
                          >
                            {t("ReceiveandReceived.Shipping")}
                            {id && id !== null ? (
                              <img
                                src="../assets/images/icons/arrow-bottom-primary.svg"
                                alt="Plus icon"
                                className="ms-1 h-8"
                              />
                            ) : (
                              <img
                                src="./assets/images/icons/arrow-bottom-primary.svg"
                                alt="Plus icon"
                                className="ms-1 h-8"
                              />
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => markShipmentModalShow("select")}
                            >
                              {t("ReceiveandReceived.Markallforshipment")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => markShipmentModalShow("deselect")}
                            >
                              {t("ReceiveandReceived.Deselectallfromshipment")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </>
                  )}
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      size="sm"
                      className="p-0 fw-bold dropdown-arrow-none"
                      id="helpDropdown"
                    >
                      {t("ReceiveandReceived.Help")}
                      {id && id !== null ? (
                        <img
                          src="../assets/images/icons/arrow-bottom-primary.svg"
                          alt="Plus icon"
                          className="ms-1 h-8"
                        />
                      ) : (
                        <img
                          src="./assets/images/icons/arrow-bottom-primary.svg"
                          alt="Plus icon"
                          className="ms-1 h-8"
                        />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/">
                        {t("ReceiveandReceived.Receivable")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/">
                        {t("ReceiveandReceived.Creditcardmistakes")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      size="sm"
                      className="p-0 fw-bold dropdown-arrow-none"
                      id="moreDropdown"
                    >
                      {t("button.more")}
                      {id && id !== null ? (
                        <img
                          src="../assets/images/icons/arrow-bottom-primary.svg"
                          alt="Plus icon"
                          className="ms-1 h-8"
                        />
                      ) : (
                        <img
                          src="./assets/images/icons/arrow-bottom-primary.svg"
                          alt="Plus icon"
                          className="ms-1 h-8"
                        />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Link className="dropdown-item" to={"/billing_rule"}>
                        {t("Links.billingRule")}
                      </Link>
                      <Dropdown.Item href="/">
                        {t("ReceiveandReceived.Waysofreceiving")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/">
                        {t("ReceiveandReceived.Postdatedchecks")}
                      </Dropdown.Item>
                      <Dropdown.Item href="/">
                        {t("ReceiveandReceived.Bankholidays")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={annualDischargeModalShow}>
                        {t("ReceiveandReceived.Annualdebtdischargestatement")}
                      </Dropdown.Item>
                      <AnnualDebtStatment
                        showAnnualModal={showAnnualDischargeModal}
                        annualModalClose={annualDischargeModalClose}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <Dropdown >
                    <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="printDropdown">
                      <img src={id && id !== null ? "../assets/images/icons/print-light.svg" : "./assets/images/icons/print-light.svg"} alt="Print" className="h-20 cursor-pe" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {!printDropdown && (
                        <>
                          <Dropdown.Item onClick={PrintPDF}>{t('ReceivablePrintDrawer.print')}</Dropdown.Item>
                          <Dropdown.Item onClick={openPrintFilters}>{t('ReceivablePrintDrawer.kenloPay')}</Dropdown.Item>
                        </>
                      )}

                      {printDropdown && (
                        <Form onSubmit={handleSubmit(onReport)} className="d-flex flex-column h-100" id="reportKenloPay">
                          <div className="p-xl-3 pt-xl-1 fw-bold border-bottom d-flex align-items-center justify-content-between">
                            <div className="text-primary me-xl-1 d-flex justify-sm-end justify-content-between align-items-center">
                              <span onClick={() => setPrintDropdown(false)} className="cursor-pe">
                                <img src="../assets/images/icons/arrow-left.svg" alt="Back" className="h-16" />
                              </span>
                            </div>

                            <span className="text-truncate">{t('ReceivablePrintDrawer.kenloPay')}</span>

                            <div className="text-primary ms-3 d-flex justify-sm-end justify-content-between align-items-center">
                              <Dropdown.Item onClick={() => setPrintDropdown(false)} className="p-0 cursor-pe" style={{ backgroundColor: 'transparent' }}>
                                <img src="../assets/images/icons/cross.svg" alt="Close" className="h-16" />
                              </Dropdown.Item>
                            </div>
                          </div>
                          
                          <div className="p-xl-3 pb-xl-1 d-flex flex-column">
                            <FloatingLabel label={t("bankAccount")}>
                              <Form.Select className={`form-control ${errors.bank_account ? 'is-invalid' : ''}`} {...register("bank_account")} aria-label={t("bankAccount")}>
                                <option value="">{t("formLabel.select")}</option>
                                
                                {bankAccounts && bankAccounts.length > 0
                                  ? bankAccounts.map((item, index) => (
                                    <option key={index} value={Number(item.id)}>
                                      {item.account} -- {item.accountRef}
                                    </option>
                                  ))
                                  : ""}
                              </Form.Select>
                              <Form.Control.Feedback className="invalid-feedback">{errors?.bank_account?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <h6 className="d-flex my-3 mb-2 fw-bold">
                              {t('expenditure.timeperiod')}
                            </h6>

                            <FloatingLabel className="mb-2" controlId="floatingInput" label={t("start")}>
                              <Form.Control
                                type="date"
                                placeholder="Start"
                                {...register("start_date")}
                                max={moment().format("YYYY-MM-DD")}
                                onChange={e => handleDate(e, "start_date")}
                                min={moment().add(-1, 'month').format("YYYY-MM-DD")}
                                className={`form-control ${errors.start_date ? 'is-invalid' : ''}`}
                              />
                              <Form.Control.Feedback className="invalid-feedback">{errors?.start_date?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <FloatingLabel label={t("end")} controlId="floatingInput">
                              <Form.Control
                                type="date"
                                placeholder="End"
                                {...register("end_date")}
                                max={moment().format("YYYY-MM-DD")}
                                onChange={e => handleDate(e, "end_date")}
                                min={moment().add(-1, 'month').format("YYYY-MM-DD")}
                                className={`form-control ${errors.end_date ? 'is-invalid' : ''}`}
                              />
                              <Form.Control.Feedback className="invalid-feedback">{errors?.end_date?.message}</Form.Control.Feedback>
                            </FloatingLabel>

                            <Button type="submit" disabled={buttonPrintLoading} className="mt-3 btn-width">{t('Generatereport')}</Button>
                          </div>
                        </Form>
                      )}
                    </Dropdown.Menu>
                </Dropdown>
                </li>
                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                  <a href="#">
                    {id && id !== null ? (
                      <img
                        src={"../assets/images/icons/settings-light.svg"}
                        alt="Settings"
                        className="h-20"
                      />
                    ) : (
                      <img
                        src={"./assets/images/icons/settings-light.svg"}
                        alt="Settings"
                        className="h-20"
                      />
                    )}
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        {/* Filters start here */}
        <Collapse className="d-xl-block collpase-filters" in={openFilters}>
          <Form id="collpase-filter-text" action="" method="">
            <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
              <h5 className="mb-0 fw-bold">Filters</h5>
              <span
                className="cursor-pe"
                onClick={() => setFiltersOpen(!openFilters)}
              >
                {id && id !== null ? (
                  <img
                    src={"../assets/images/icons/cross.svg"}
                    alt="Plus icon"
                    className="ms-xxl-1 ms-auto h-14"
                  />
                ) : (
                  <img
                    src={"./assets/images/icons/cross.svg"}
                    alt="Plus icon"
                    className="ms-xxl-1 ms-auto h-14"
                  />
                )}
              </span>
            </div>
            <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
              <Col xl="auto">
                <SwitchChargesTabPanes
                  onSelectKey={key => {
                    setActiveTabKey(key)
                    setSelectedTab && setSelectedTab(key)
                    setFilterData({
                      ...filterData,
                      'billing_payment': key === 'cobrancas' ? [] : [28]
                    })
                  }}
                />
              </Col>
              <Col xl="auto">
                <ButtonGroup
                  className="btn-date-filter"
                  size="sm"
                  aria-label="Date"
                >
                  <Button
                    variant="primary"
                    className="btn-arrow"
                    onClick={() =>
                      filterCharge(stateChange?.startDate, "Subtract")
                    }
                  >
                    {id && id !== null ? (
                      <img
                        src={"../assets/images/icons/arrow-white.svg"}
                        className="h-24"
                      />
                    ) : (
                      <img
                        src={"./assets/images/icons/arrow-white.svg"}
                        className="h-24"
                      />
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-date capitalize"
                    onClick={() =>
                      filterCharge(stateChange?.startDate, "Subtract")
                    }
                    disabled
                  >
                    {moment(stateChange?.searchDate).format("MMM/YYYY")}
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-arrow"
                    onClick={() => filterCharge(stateChange?.endDate, "Add")}
                  >
                    {id && id !== null ? (
                      <img
                        src={"../assets/images/icons/arrow-white-right.svg"}
                        className="h-24"
                      />
                    ) : (
                      <img
                        src={"./assets/images/icons/arrow-white-right.svg"}
                        className="h-24"
                      />
                    )}
                  </Button>
                </ButtonGroup>
              </Col>
              {pathName && pathName !== "received" && (
                <Col xl="auto">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={`${
                        filterData["status"].length > 0 ? "show" : ""
                      }`}
                    >
                      {filterData?.["status"]["0"]?.["label"]
                        ? filterData?.["status"]["0"]?.["label"]
                        : t("dropdownItem.status")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="px-4">
                      <FloatingLabel
                        controlId="status"
                        label={t("dropdownItem.status")}
                      >
                        <Form.Select
                          onChange={(e: any) =>
                            allmultipleFilter(e.target.value, "status")
                          }
                          aria-label="Status"
                          id="status"
                        >
                          <option value="">{t("formLabel.select")}</option>
                          {allfilter.status.map((item, index) => (
                            <option key={index} value={JSON.stringify(item)}>
                              {item.label}
                            </option>
                          ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}

              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle
                    variant="light"
                    size="sm"
                    className={`${
                      filterData["person"].length > 0 ? "show" : ""
                    }`}
                  >
                    {filterData?.["person"]["0"]?.["value"] == "1"
                      ? t("dropdownItem.physicalAndLegal")
                      : filterData?.["person"]["0"]?.["value"] == "2"
                      ? t("dropdownItem.physical")
                      : filterData?.["person"]["0"]?.["value"] == "3"
                      ? t("dropdownItem.legal")
                      : t("dropdownItem.person")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="px-4">
                    <FloatingLabel
                      controlId="person"
                      label={t("dropdownItem.person")}
                    >
                      <Form.Select
                        onChange={(e: any) =>
                          allmultipleFilter(e.target.value, "person")
                        }
                        aria-label="Person"
                        id="personvalue"
                      >
                        <option value="">{t("formLabel.select")}</option>
                        {allfilter.person.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle
                    variant="light"
                    size="sm"
                    className={`${
                      filterData["service"].length > 0 ? "show" : ""
                    }`}
                  >
                    {t("Links.services")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdwon-lg px-4">
                    <MultiSelect
                      options={allfilter.service}
                      value={filterData.service}
                      onChange={(item) => allmultipleFilter(item, "service")}
                      labelledBy={t("Links.services")}
                      overrideStrings={{
                        selectSomeItems: `${t("formLabel.select")}`,
                        selectAll: `${t("selectAll")}`,
                        search: `${t("formLabel.typeSearch")}`,
                        allItemsAreSelected: `${t("selectAll")}`,
                      }} // <- to override strings
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xl="auto">
                <Dropdown autoClose="outside">
                  <Dropdown.Toggle
                    variant="light"
                    size="sm"
                    className={filterDatadate.length == 0 ? "" : "show"}
                    onClick={handleShow}
                  >
                    {t("TransferPage.Duetotheduedatebetween")}
                  </Dropdown.Toggle>
                </Dropdown>
              </Col>

              {pathName == "received" ? (
                <>
                  <Col xl="auto">
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        className={filterliquiddate.length == 0 ? "" : "show"}
                        onClick={handleShow1}
                      >
                        {t("TransferPage.liquidatedatebetween")}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </Col>
                  <Col xl="auto">
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        className={filtercreditdate.length == 0 ? "" : "show"}
                        onClick={handleShow2}
                      >
                        {t("TransferPage.crediteddatebetween")}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </Col>
                </>
              ) : (
                ""
              )}

              {pathName !== "received" ? (
                <>
                  <Col xl="auto">
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        className={filtercanceldate.length == 0 ? "" : "show"}
                        onClick={handleShow3}
                      >
                        {t("TransferPage.canceldatebetween")}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </Col>
                  <Col xl="auto">
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        className={filtergeneratdate.length == 0 ? "" : "show"}
                        onClick={handleShow4}
                      >
                        {t("TransferPage.generatedatebetween")}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </Col>
                </>
              ) : (
                ""
              )}

              <Col xl="auto">
                <Dropdown autoClose="outside" className="moreFilter">
                  <Dropdown.Toggle
                    variant="light"
                    size="sm"
                    className={`d-flex align-items-center ${
                      filterData.bank_account.length > 0 ||
                      filterData.billing_payment.length > 0 ||
                      filterData.customer_payment.length > 0 ||
                      filterData.branch.length > 0 ||
                      filterData.marked_shipment.length > 0 ||
                      filterData.delivery_method.length > 0 ||
                      filterData.ground_invalid.length > 0 ||
                      filterData.with_split.length > 0 ||
                      filterData.credit_card_failure.length > 0 ||
                      filterData.credit_card_mistake.length > 0
                        ? "show"
                        : ""
                    }`}
                    onClick={desktopModalShow}
                  >
                    {t("moreFilters")}
                    {id && id !== null ? (
                      <img
                        src={`../assets/images/icons/${
                          filterData.bank_account.length > 0 ||
                          filterData.billing_payment.length > 0 ||
                          filterData.customer_payment.length > 0 ||
                          filterData.branch.length > 0 ||
                          filterData.marked_shipment.length > 0 ||
                          filterData.delivery_method.length > 0 ||
                          filterData.ground_invalid.length > 0 ||
                          filterData.with_split.length > 0 ||
                          filterData.credit_card_failure.length > 0 ||
                          filterData.credit_card_mistake.length > 0
                            ? "icons8-plus-24.png"
                            : "plus-light.svg"
                        }`}
                        alt="Plus icon"
                        className="ms-xl-1 ms-auto h-20"
                      />
                    ) : (
                      <img
                        src={`./assets/images/icons/${
                          filterData.bank_account.length > 0 ||
                          filterData.billing_payment.length > 0 ||
                          filterData.customer_payment.length > 0 ||
                          filterData.branch.length > 0 ||
                          filterData.marked_shipment.length > 0 ||
                          filterData.delivery_method.length > 0 ||
                          filterData.ground_invalid.length > 0 ||
                          filterData.with_split.length > 0 ||
                          filterData.credit_card_failure.length > 0 ||
                          filterData.credit_card_mistake.length > 0
                            ? "icons8-plus-24.png"
                            : "plus-light.svg"
                        }`}
                        alt="Plus icon"
                        className="ms-xl-1 ms-auto h-20"
                      />
                    )}
                  </Dropdown.Toggle>
                </Dropdown>

                <ChargesFilter
                  allmultipleFilter={allmultipleFilter}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  allfilter={allfilter}
                  setAllFilter={setAllFilter}
                  showDesktopModal={showDesktopModal}
                  desktopModalClose={desktopModalClose}
                  desktopModalCloseDone={desktopModalCloseDone}
                />
              </Col>
            </Row>
          </Form>
        </Collapse>
        {/* /Filters end */}
      </div>
      {/* Mark for shipment modal start from here */}
      <Modal show={showMarkShipmentModal} onHide={markShipmentModalClose}>
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>{t("ReceiveandReceived.Markforshipment")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert className="mb-0" variant="warning">
              {totalMarkShip ? (
                <>
                  {t("ReceiveandReceived.Thereare")}{" "}
                  <a
                    className="fw-bold cursor-pe"
                    onClick={(e) => handleDataShow()}
                  >
                    {totalSelectedCharge} {t("ReceiveandReceived.charges")}{" "}
                  </a>{" "}
                  {t("ReceiveandReceived.tobemarkedforshipment")}.
                </>
              ) : (
                <>
                  {t("ReceiveandReceived.Nobillettobeunmarkedfromtheshipment")}.
                </>
              )}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={markShipmentModalClose}>
              {t("button.close")}
            </Button>
            {markShip && (
              <Button variant="primary" type="submit">
                {t("ReceiveandReceived.Markforshipment")}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      {/* /Mark for shipment modal end */}
      <ChargeService
        show={showChargeModal}
        onShow={newChargeModalShow}
        onClose={newChargeModalClose}
        getChargesSummery={getChargesSummery}
      />
    </>
  );
};

export default SubHeader;
