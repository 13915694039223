import React, { useMemo } from "react";

import { Card, Col, Row } from "react-bootstrap";
import { converter } from "../../../../../component/constants/utils";
import type { ChargeSummary } from "../../schemas";

type SummaryCardProps = {
  title: string;
  summary?: ChargeSummary;
};

export function SummaryCard({ title, ...props }: SummaryCardProps) {
  const {
    amountReceivable = 0,
    amountReceived = 0,
    countReceivable = 0,
    countReceived = 0,
  } = props.summary ?? {};

  const amountReceivableText = useMemo(() => {
    return `R$ ${converter(amountReceivable)}`;
  }, [amountReceivable]);

  const amountReceivedText = useMemo(() => {
    return `R$ ${converter(amountReceived)}`;
  }, [amountReceived]);

  const countReceivedText = useMemo(() => {
    return `${countReceived} ${countReceived > 1 ? "pagos" : "pago"}`;
  }, [countReceived]);

  const countReceivableText = useMemo(() => {
    return `${countReceivable} à receber`;
  }, [countReceivable]);

  return (
    <Card className="mb-lg-4 mb-3">
      <Card.Header className="text-dark-80 fs-3">{title}</Card.Header>
      <Card.Body className="text-dark-80">
        <Row className="gy-3 gx-xxl-5 gx-md-4 ">
          <Col className="border-end border-light border-xs-none">
            <strong className="fs-3">{amountReceivedText}</strong>
            <p className="mt-1 mb-0">{countReceivedText}</p>
          </Col>
          <Col>
            <strong className="fs-3">{amountReceivableText}</strong>
            <p className="mt-1 mb-0">{countReceivableText}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
