import React, { useEffect, useRef, useState } from 'react';
import { ComProps, } from '../../../Interface'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from "react-router-dom";
import { set, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { handleClo, handleOpn } from "../../../../redux/slice/profileSlice"
import { Dropdown, InputGroup, Col, Row, Form, Button, Modal, Card, Table, FloatingLabel, Alert, ButtonGroup, Badge, DropdownButton, ListGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { getOwner } from '../../../../redux/slice/propertyDataSlice';
import { useTranslation } from 'react-i18next';
import NewOwner from './NewOwner';
import { openNewOwner, deleteOwner, addOwnerbank, updateOwnerMainAccount, disableMainAccount,getEmailListing } from '../../../../redux/slice/contractDataSlice';
import { CNJP_CPFValidation, Text_Decoder, checkDuplicateCNJP_CPF, encrypt_Decrypt } from '../../../constants/utils';
import customerService from '../../../../services/customerService';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';
import Checkbox from '../Common/Checkbox';
import { singleemailPost, singleemailfetchData } from '../../../../redux/slice/SendEmailSlice';
//import moment from 'moment';
import moment from "moment-timezone";
import EmailSeen from '../Common/EmailSeen';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ownerPropertyData,ownerTransferData,transferContracts, transferToBePaid, transferToBePaided } from '../../../../redux/slice/transferDataSlice';
import i18n from '../../../../i18n';
import Loader from "../../../Profile/Company/Loader";

export interface paymentMethods {
    payment: string,
    id: any
    nameReference: string,
    bankname: string[],
    agency: number,
    account: number,
    moneyaccount: number
    date: Date,
    closingDate: Date,
    accountType: string[],
    branchtype: string,
    main_ac: number,
    day: string,
    das: string,
    until: string,
    status: number,
    pixKey: string,
    pixKeyType: string,
    operation: string,
    ID_AARIN_CON: string
}

const Owner_details = ({ submenu }: ComProps) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const location = useLocation();
    let contract_id = null;
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const { show } = useSelector((state: RootState) => state.profile)
    const { emailStatus,emailData } = useSelector((state: RootState) => state.contractData)
    const { toBePaidedtransferData, transferContractData,ownTransferStatus,ownTransferData,ownPropertyStatus,ownPropertyData } = useSelector((state: RootState) => state.transferData)
    const { id } = useParams();
    const [owner, setOwner] = useState<any>({})
    const [ownerProperty, setOwnerProperty] = useState<any>([])
    const [ownerContracts, setOwnerContracts] = useState<any>([])
    const [ownerCharges, setOwnerCharges] = useState<any>([])
    // const [banklist, setbanklist] = useState<any>([])
    const [userpaymentMethods, setPaymentMethods] = useState<any>([])
    const [transferstate, setTransferState] = useState<any>([])
    const [ownerPayment, setOwnerPayment] = useState(false)
    const { banklist } = useSelector((state: RootState) => state.bank)
    const [userJson, setUserJson] = useState<any>([]);
    const [Item, setItem] = useState<any>("")
    const [NoMainAccount, setNoMainAccount] = useState<any>('abc');
    const [ArinBankId, setArinBankId] = useState<any>('');
    const [filtername, setFiltername] = useState<any>()
    const [searchResults, setSearchResults] = useState<any>();
    const inputRef = useRef<any>(null);

    const navigate = useNavigate();
    const regexNotPattern = /^[0-9-]+$/;

    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');
    const [pixTypeHide, setPixTypeHide] = useState(false)

    const [state, setState] = useState<any>({
        contract: "",
        owner: "",
        bankAccount: "",
        paidwith: "",
        supplier: "",
        bankAccountType: "",
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        start: 0,
        end: 40,
        filterby: "",
        typeofexpenses: "",
    })

    const [chargestate, setChargesState] = useState({
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        start: 0,
        end: 40,
        ownerId: id,
        type: "owner"
    });
    const [currectAcc, setCurrectAcc] = useState({
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        start: 0,
        end: 40,
    })
    let PageSize = state.end;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpage, setTotalPage] = useState(0);

    useEffect(() => {
        let userResult = encrypt_Decrypt();
        let getDocVal: any = document.querySelector("body");
        let checkvaluee = getDocVal?.getAttribute("linkforid");
        if (!userResult || userResult.indexOf(Number(checkvaluee)) === -1) {
            navigate("/customer_and_employees");
        }
        setUserJson(userResult);
    }, [])

    const regexNotPatternBank = /^[0-9-]+$/;
    const validationSchema = Yup.object().shape({
        // nameReference: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        payment: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        bankname: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        account: Yup.string().trim().nullable()
            .test('is-valid', 'Please enter only numbers and hyphens', (value: any) => {
                return regexNotPatternBank.test(value);
            })
            .required(t('requiredMmessage.fieldRequired')),
        agency: Yup.string().trim().nullable()
            .test('is-valid', 'Please enter only numbers and hyphens', (value: any) => {
                return regexNotPatternBank.test(value);
            }).required(t("requiredMmessage.fieldRequired")).min(4, t("requiredMmessage.Agencyrequire")),
        // until: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });

    const validationSchema2 = Yup.object().shape({
        payment: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        pixKeyType: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        pixKey: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });

    var frm = { resolver: yupResolver(validationSchema) }
    if (!pixTypeHide) {
        frm = { resolver: yupResolver(validationSchema2) }
    }

    const [showDisplayModal, setDisplayModal] = useState(false);
    const { register, handleSubmit, reset, setValue, setError, clearErrors, formState: { errors } } = useForm<paymentMethods>(frm);

    const handleClose = () => {
        dispatch(handleClo(false))
        setBankAccountDetail(false);
        setLocalDetail(false);
        setPixTypeHide(false);
        setOwnerPayment(false)
        setValue("payment", "");
    }

    const handleShow = () => {
        dispatch(openNewOwner(true))
    }

    const mailSubmit = (data: paymentMethods) => {
        data['ownerId'] = owner?.id;
        data['ArinBankId'] = ArinBankId ? ArinBankId : '';
        dispatch(addOwnerbank(data)).then(response => {
            let res = response.payload;
            if (res.resCode == 202) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.updatesuccess"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
                getOwner(owner?.id);
                setOwnerPayment(false);
            } else {
                if (res.resCode == 400) {
                    res.data = res.message;
                }
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: res.data ? res.data : t("Sweetalert.deletefailed"),
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(e => {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.deletefailed"),
                confirmButtonText: "OK",
                timer: 2000
            });
        })
    }

    useEffect(() => {

        getOwner(id);
        let ownerContract = { userId: id, status: "All" }
        getOwnerPropertListing({ userId: id });
        getOwnerContractListing(ownerContract);
    }, [i18n.language]);

    // transferContracts
    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(add_Bank())
        // if (toBePaidedtransferData.type == "success") {
        //     const filteredData = toBePaidedtransferData?.data?.filter(item => {
        //         return item.owner.some(owner => owner.id == id);
        //     });
        //     setTransferState(filteredData)
        // } else {
        //     setTransferState([])
        // }
    }, [dispatch, toBePaidedtransferData, transferContractData]);


    useEffect(() => {
        if (transferContractData && transferContractData["data"] && transferContractData["data"].length > 0) {
         
            const filteredData = transferContractData?.data?.filter((item: any) => {
                return item.owner.some((owner: any) => owner.id == id);
            });
            setTransferState(filteredData)
        } else {
            setTransferState([])
        }
    }, [transferContractData])

    useEffect(() => {
        dispatch(transferToBePaided({
            ...state,
            endDate: state.endDate?.format('MM/YYYY'),
            startDate: state.startDate?.format('MM/YYYY'),
        }));

        dispatch(transferContracts({
            ...state,
            owner: id,
            endDate: state.endDate?.format('MM/YYYY'),
            startDate: state.startDate?.format('MM/YYYY'),
            transferType: "completed"
        }));
    }, [dispatch, state]);

    let [ ownTransfer,setOwnTransfer ] = useState<any>([]);
    let [ ownProperty,setOwnProperty ] = useState<any>([]);
    useEffect(() => {
       dispatch(ownerTransferData({ id: id }))
       dispatch(ownerPropertyData({ id: id }))       
    }, [dispatch]);

    useEffect(() => {
        setOwnTransfer(ownTransferData)
    }, [ownTransferData]);

    useEffect(() => {
        setOwnProperty(ownPropertyData)
    }, [ownPropertyData]);


    const getOwner = (userId) => {
        const cat = localStorage.getItem("Suppliers");
        setItem(cat)
        if (cat !== "Suppliers") {
            let textFilter = ''
            let userData = { ownerId: userId, start: 0, end: 1, type: "" };
            customerService.getAllOwner(userData)
                .then((res) => {
                    if (res?.data?.data && (res.status == 200 || res.status == 201)) {
                        setOwner(res.data.data[0]);
                        let owner = res.data.data[0];
                        let paymentMethods: any = [];
                        if (owner?.paymentMethods && owner?.paymentMethods.length > 0) {
                            owner?.paymentMethods.map((curElem: any, index: number) => {
                                if (curElem.API_REQUEST != '' && curElem.API_REQUEST != 'Bank Type is PIX.') {
                                    let mth = JSON.parse(curElem.API_REQUEST);
                                    mth.ID_AARIN_CON = curElem.ID_AARIN_CON
                                    mth.MAIN_ACCOUNT = curElem.MAIN_ACCOUNT
                                    mth.AA_STATUS = curElem.AA_STATUS
                                    if (curElem.API_REQUEST && curElem.API_REQUEST != '' && (curElem.API_REQUEST).indexOf('BankTypeisPIX') >= 0) {
                                        mth.IsPix = true;
                                        mth.AA_CONTA_BANK_ID = curElem.AA_CONTA_BANK_ID;
                                        mth.PIX_KEY_TYPE = 'PIX'
                                    }
                                    paymentMethods.push(mth);
                                    if (mth.MAIN_ACCOUNT == 1) {
                                        setNoMainAccount('');
                                    }
                                }
                            })
                            setPaymentMethods(paymentMethods);
                        }
                        let lebel_callection = [t("owner"), t("tenant"), t("customerAndEmployees.guarantors"), t("Broker"), t("customerAndEmployees.inspectors"), t("customerAndEmployees.suppliers")];
                        if (res.data.data[0] && res.data.data[0].type_collection) {
                            for (let j = 0; j < res.data.data[0].type_collection.length; j++) {
                                if (res.data.data[0].type_collection[j] == 1) {
                                    textFilter += `${lebel_callection[j]} `;
                                }
                            }
                        }

                        setFiltername(textFilter)
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                });
        } else {
            let inspectorData = { supplier: "", start: 0, end: 20, activeContract: "All", personStatus: "", type: "SupplierList" };
            customerService.getAllSuppliers(inspectorData).then(res => {
                if (res.data.resCode === 201) {
                    res.data.data.forEach(element => {
                        if (element.id == id) {
                            setOwner(element)
                        }
                    });
                }
            })
        }

    }

    const [allEmailList, setAllEmailList] = useState([]);
    const [fetchEmailData, setfetchEmaildata] = useState([]);
    function checkIfEmailInString(text:any) { 
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(text);
    }
    
    useEffect(() => {
        if (typeof owner !== "undefined" && owner) {
            if (Object.keys(owner).length > 0) {
                (async () => {
                    let reqobj = owner["email"];                    
                    let email:any=[];
                    // if(owner.email){
                    //     if(checkIfEmailInString(owner.email)==true){
                    //         email.push(owner.email)
                    //     }                        
                    // }
                    // if(owner.observation){
                    //     if(checkIfEmailInString(owner.observation)==true){
                    //         email.push(owner.observation)
                    //     }                        
                    // }
                    email.push(owner["id"])
                    if(email.length>0){
                        let response  = await dispatch(getEmailListing({id:owner["id"],email,start:0,end:1000}))
                    }

                    let res = await dispatch(singleemailPost(id));
                    if (res && res.payload && res.payload.data && res["payload"]["data"].length > 0) {
                        let filteredData = res.payload.data.filter(entry => entry.DT_ENTRADA_FE !== "0000-00-00 00:00:00" && entry.DT_ENTRADA_FE !== null);
                        setAllEmailList(filteredData)
                    }
                })()
            }
        }
    }, [owner])

    useEffect(() => {
        customerService.allOwnerCharge(chargestate).then((res) => {
            if (res.data.type == "success" || res.data.type == "Success") {
                setOwnerCharges(res.data.data)
            }
        })
    }, [chargestate])

    const getOwnerPropertListing = (filter) => {
        let userData = { status: filter.status, owners: filter.userId, start: 0, end: 20 };
        customerService.getAllProperty(userData)
            .then((res) => {
                if (res.data.type === "success" || res.data.type === "Success") {
                    res.data.data.forEach(element => {
                        element.ownerResult.forEach(element => {
                            if (element.ownerId == id) {
                                setOwnerProperty(res.data.data);
                            }
                        });
                    });
                } else {
                    setOwnerProperty([]);
                }
            })
            .catch((err) => { });
    }

    const getOwnerContractListing = (filter) => {
        let userData = { status: filter.status, ownerId: filter.userId, start: 0, end: 20 };
        customerService.getAllOwnerContracts(userData)
            .then((res) => {
                if (res.data.type === "success" || res.data.type === "Success") {
                    setOwnerContracts(res.data.data);
                }
                else {
                    setOwnerContracts([])
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    // Filter COntracts
    const filterContracts = (evnt) => {
        const { value } = evnt.target;
        let filterCont = { userId: owner?.id, status: value }
        getOwnerContractListing(filterCont);
    };

    const NewPayment = () => {
        setArinBankId('');
        setLocalDetail(false);
        setOwnerPayment(true)
    }


    const [HidePixAlso, setHidePixAlso] = useState(false)
    const [bankAccountDetail, setBankAccountDetail] = useState(false);
    const [localDetail, setLocalDetail] = useState(false);
    const ownerPaymentHide = (evt) => {
        setHidePixAlso(false);
        let eTarget = evt.target.value;
        if (eTarget == 1 || eTarget == 2 || eTarget == 5 || eTarget == 6 || eTarget == 0) {
            setBankAccountDetail(true);
            setLocalDetail(true);
            setPixTypeHide(true);
        }
        if (eTarget == 3) {
            setBankAccountDetail(false);
            setLocalDetail(true);
            setPixTypeHide(true);
            setHidePixAlso(true)
        }
        if (evt.target.value == 12) {
            setPixTypeHide(false);
        }
        if (eTarget == 4 || eTarget == "") {
            setBankAccountDetail(false);
            setLocalDetail(false);
            setPixTypeHide(true); setHidePixAlso(true)
        }
        if (eTarget == 7 || eTarget == 12) {
            setBankAccountDetail(true);
            setLocalDetail(true);
            setPixTypeHide(false)
        }
    }

    const deletetheOwner = (e, type) => {
        let newId = owner?.id
        let typesupp: String = ''
        if (Item == "Suppliers") {
            newId = owner?.favorecidoId;
            typesupp = "supplier"
        }

        let data = { ownerStatus: 1, ownerId: newId, type: type, typesupplier: typesupp }
        Swal.fire({
            title: t("Sweetalert.Areyousure"),
            text: t("Sweetalert.Youwontbeabletorevert"),
            // icon: 'success','warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, it!'
        }).then(async (result) => {
            if (result.value) {
                dispatch(deleteOwner(data)).then(response => {
                    let res = response.payload;
                    if (res.resCode == 201 || res.resCode == 408) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.deletesuccess"),
                            // icon: 'success',"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        getOwner(id);
                        navigate("/owners_and_beneficiaries")
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: res?.message ? res.message : t("Sweetalert.deletefailed"),
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 3000
                        });
                    }
                }).catch(e => {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.deletefailed"),
                        // icon: 'success',"error",
                        confirmButtonText: "OK",
                        timer: 3000
                    });
                })
            }
        })
    }

    const handleClick = e => {
        const { id, checked } = e.target;
        let paymentMethods: any = [];
        userpaymentMethods.map((curElem: any, index: number) => {
            if (curElem.ID_AARIN_CON == id) {
                let mch = 0;
                if (checked) {
                    mch = 1;
                }
                curElem.MAIN_ACCOUNT = mch;
                let data = {
                    ID_AARNI_ID: id,
                    OWNER_ID: owner?.id,
                    MAIN_ACCOUNT: curElem.MAIN_ACCOUNT
                };
                dispatch(updateOwnerMainAccount(data)).then(response => {
                    let res = response.payload;
                    if (res.resCode == 200 && res?.data.length) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.insertRecord"),
                            confirmButtonText: "OK",
                            timer: 3000
                        });
                        setNoMainAccount('');
                        curElem.AA_STATUS = 1;
                    } else {
                        setNoMainAccount('Please select at least one Primary account!');
                    }
                });
            } else {
                curElem.MAIN_ACCOUNT = 0;
            }
            paymentMethods.push(curElem);
        })
        setPaymentMethods(paymentMethods);
    };

    const disableBankAccount = e => {
        const { id, checked } = e.target;
        let paymentMethods: any = [];
        userpaymentMethods.map((curElem: any, index: number) => {
            if (curElem.ID_AARIN_CON == id) {
                let mch = 1;
                if (checked) {
                    mch = 2;
                    curElem.MAIN_ACCOUNT = 0;
                }
                curElem.AA_STATUS = mch
                let data = {
                    ID_AARNI_ID: id,
                    OWNER_ID: owner?.id,
                    AA_STATUS: curElem.AA_STATUS
                };
                dispatch(disableMainAccount(data)).then(response => {
                    let res = response.payload;
                    if (res.resCode == 200 && res?.data.length) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.insertRecord"),
                            confirmButtonText: "OK",
                            timer: 3000
                        });
                        setNoMainAccount('');
                    } else {
                        // setNoMainAccount('Please select at least one Primary account!');
                    }
                });
            } else {
                curElem.AA_STATUS = curElem.AA_STATUS;
            }
            paymentMethods.push(curElem);
        })
        setPaymentMethods(paymentMethods);
    };

    const [firstLoad, setFirstLoad] = useState(false);
    const SeenEmailHandle = async (e, emailid, email) => {
        setFirstLoad(true)

        let reqobj: any = {
            id: emailid,
            email: email
        };

        let res1 = await dispatch(singleemailfetchData(reqobj));

        if (res1["payload"]["data"].length > 0) {
            setfetchEmaildata(res1["payload"]["data"]);
        }
        setDisplayModal(true);
    }

    useEffect(() => {
        if (firstLoad) {
            setTimeout(() => {
                let email: any = document?.querySelector('.emailclicked');
                email.click();
            }, 500)
        }
    }, [fetchEmailData])

    const filterCharge = (dt, tp, type) => {
        if (typeof tp !== "undefined") {
            let currentDate = moment(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }

            if (type == "transfer") {
                setState({
                    ...state,
                    ['startDate']: startDt,
                    ['searchDate']: searchDt,
                    ['endDate']: endDt,
                })
            } else if (type == "Currentaccount") {
                setCurrectAcc({
                    ...currectAcc,
                    ['startDate']: startDt,
                    ['searchDate']: searchDt,
                    ['endDate']: endDt,
                })
            } else {
                setChargesState({
                    ...chargestate,
                    ['startDate']: startDt,
                    ['endDate']: endDt,
                    ['searchDate']: searchDt,
                    ['start']: 0
                });
            }

            setCurrentPage(1);
        }
    }

    const handleNavigateDocument = async (e) => {
        let data = {
            person_id: id,
            person_type: "owner",
            person_email: owner?.email
        }
        navigate(`/documents`, { state: data });
    }

    const updateBankEvent = (item) => {
        setPixTypeHide(true);
        setOwnerPayment(true);
        setLocalDetail(true);
        setValue('payment', ((item.formOfPayment >= 0) ? item.formOfPayment : 0));
        setValue('ID_AARIN_CON', item.ID_AARIN_CON);
        if (item.IsPix && item.PIX_KEY_TYPE == 'PIX') {
            setPixTypeHide(false);
            setArinBankId(item.ID_AARIN_CON);
            setValue('pixKeyType', '2');
            setValue('pixKey', item.AA_CONTA_BANK_ID);
            setValue('payment', '12');
        } else {
            setBankAccountDetail(true);
            setArinBankId(item.ID_AARIN_CON);
            setValue('bankname', ((item.ST_BANCO_PES ? item.ST_BANCO_PES : 0)));
            setValue('account', item.conta);
            setValue('agency', item.agencia);
            setValue('nameReference', item.nome);
            setValue('until', item.cnpj ? item.cnpj : item.cpf);
            setValue('main_ac', item.MAIN_ACCOUNT)
        }
    }
    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, id ? id : "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });

                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)

            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    const [typedValue, setTypedValue] = useState('');  // Tracks the user’s input
    const [selectedBankName, setSelectedBankName] = useState('');  // Tracks the selected bank name
    const [isInputFocused, setIsInputFocused] = useState(false);
    const searchContainerRef: any = useRef(null);

    const handleInputChange = (e) => {
        setTypedValue(e.target.value); // Update the typed value
        handlefilterBillmethod(e);      // Trigger the search filter
    };


    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = banklist.filter((bank) => {
                // Convert the bank name to lowercase for case-insensitive comparison
                const bankName = bank.bank_name.toLowerCase();
                const bankCode = bank.bank_code.toLowerCase();
                return bankName.includes(lowerSearchTerm) || bankCode.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            // const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
            // let filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
            let filteredBanks: any = banklist
            setSearchResults(filteredBanks);
        }
    }

    useEffect(() => {
        // const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
        // let filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
        let filteredBanks: any = banklist
        setSearchResults(filteredBanks);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [banklist]);

    // Inside your component

    // Detect click outside to close the list
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setIsInputFocused(false);  // Close the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const HandleBankAccount = (e, item) => {
        e.preventDefault();
        setValue('bankname', item.bank_code); // Store bank_code in register
        setSelectedBankName(`${item.bank_code} - ${item.bank_name}`); // Set display name
        setTypedValue(`${item.bank_code} - ${item.bank_name}`);  // Update typed value to show selected
        setIsInputFocused(false); // Close dropdown
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    return (
        <section className="content-wrapper ownerDetails-template">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 7, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">

                                {
                                    (location && location?.state &&
                                        location?.state?.contract_id &&
                                        location?.state?.contract_id !== null &&
                                        location?.state?.contract_id !== undefined &&
                                        location?.state?.contract_id !== "") ?
                                        <Link to={`/contract_details/${location?.state?.contract_id}`}><img src="../assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                        :
                                        <Link to={"/owners_and_beneficiaries"}>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-16" alt="Arrow left" />
                                        </Link>
                                }

                                <h1 className="mb-0 h4 lh-base ms-2 ms-lg-3 ms-xl-4 text-nowrap">{Item !== "Suppliers" ? filtername : t("supplier")} {t('detail')}</h1>
                                <div className="w-100 ms-2 ms-lg-3 ms-xl-4 d-flex align-items-center">
                                    <h4 className="lh-base mb-0 text-secondary">{owner?.name}
                                        <Badge className="text-dark ms-1" bg="success-30">{owner?.["statusowner"] == "0" ? t("active") : ''}</Badge>
                                        <Badge className="text-white ms-1" bg="secondary">{owner?.["statusowner"] == "1" ? t("inActive") : ''}</Badge></h4>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                                    <>
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdown-basic">
                                                    {t("actions")}
                                                    <img src="../assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {owner?.["statusowner"] == "0" ? <Dropdown.Item href="#" onClick={(e) => deletetheOwner(e, "delete")}>{t("formReceipt.disable")}</Dropdown.Item> : ""}
                                                    {owner?.["statusowner"] == "1" ? <Dropdown.Item href="#" onClick={(e) => deletetheOwner(e, "")}>{t("notifications.enable")}</Dropdown.Item> : ""}
                                                    {/* <Dropdown.Item href="#">{t("historic")}</Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>

                                    </>
                                }
                                <li className="list-inline-item">
                                    <Button type="button" variant="link" size="sm" className="p-0 fw-bold" onClick={(e) => handleNavigateDocument(e)}>
                                        {t("documents")}
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* <!-- /Breadcrumb section end --> */}
            </div>
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <div>
                    <div className='hidden' hidden>
                        <PDFDownloadLink document={<EmailSeen emailData={fetchEmailData} />} fileName={`Email_${new Date(dateBrazil).getTime()}.pdf`}>
                            <Button className={"emailclicked"}>
                                email
                            </Button>
                        </PDFDownloadLink>
                    </div>
                    <Row className="h-100 gy-3 gy-lg-4">
                        <Col lg={6}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("property.data")}
                                    {
                                        userJson && userJson.length > 0 && (userJson.indexOf(2041) !== -1) &&
                                        <>
                                            {
                                                owner?.agencyStatus != 1 ?
                                                    <div> <Button variant="link" className="mb-lg-4 mb-3 p-0" onClick={handleShow}>{t("changeperson")}</Button></div>
                                                    : ''
                                            }
                                        </>
                                    }
                                </Card.Header>
                                <Card.Body>

                                    <span className="d-block">
                                        <img src="../assets/images/icons/user-light.svg" alt="User" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.cnpjCpf")} - {owner?.cnpj ? owner?.cnpj : owner?.cnpjcpf}</span>
                                    </span>
                                    <hr />
                                    <span className="d-block">
                                        <img src="../assets/images/icons/user-light.svg" alt="User" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.fantasyName")} - { owner?.fantasy ? owner?.fantasy : "" }</span>
                                    </span>
                                    <hr />
                                    {owner?.zipcode !== null && owner?.zipcode !== "" && owner?.zipcode !== undefined ?
                                        <div>
                                            <span className="d-block">
                                                <img src="../assets/images/icons/home_g.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1">{t("formLabel.address")}   - {" "} {owner.address} {owner.number ? owner.number : ""} {owner.complement ? owner.complement : ""} {owner.neighborhood} {owner.city} {owner.state} CEP: {owner.zipcode}</span>
                                            </span>
                                            <hr />
                                        </div>
                                        :
                                        ""
                                    }
                                    {owner?.rg !== null && owner?.rg !== "" && owner?.rg !== undefined ?
                                        <>
                                            <span className="d-block">
                                                <img src="../assets/images/icons/file-light.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1">RG {owner?.rg ? " -" + owner?.rg : ""}</span>
                                            </span>
                                            <hr />
                                        </>
                                        :
                                        ""}

                                    {owner?.observation !== null ?
                                        <>

                                            <span className="d-block">
                                                <img src="../assets/images/icons/file-light.svg" alt="file" className="ms-1 h-16" />
                                                <span className="ps-1">{t("formLabel.observation")}  - {owner?.observation ? owner?.observation : ""}</span>
                                            </span>
                                            <hr />
                                        </> :
                                        ""
                                    }
                                    <span className="d-block fw-bold">{t("formLabel.contact")}</span>
                                    <hr />
                                    <span className="d-block ms-2 ms-lg-3">
                                        <img src="../assets/images/icons/mobile-light.svg" alt="mobile" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.Cellphone")}  {owner?.mobile}</span>
                                    </span>
                                    <hr />
                                    <span className="d-block ms-2 ms-lg-3">
                                        <img src="../assets/images/icons/phone-light.svg" alt="phone" className="ms-1 h-18" />
                                        <span className="ps-1">{t("formLabel.telephone")} {owner?.telephone}</span>
                                    </span>
                                    <hr />
                                    <span className="d-block ms-2 ms-lg-3">
                                        <img src="../assets/images/icons/envelop.svg" alt="envelop" className="ms-1 h-16" />
                                        <span className="ps-1">{t("formLabel.email")} {owner?.email}</span>
                                    </span>
                                    <hr />
                                    <span className="d-block">
                                        <span className="fw-bold">$ {t("formLabel.payment")}</span>
                                        <span className="ps-4 text-secondary"><span className="me-1 fw-bold"></span>{owner?.payment == 1 ? t('customerAndEmployees.chequeDeposit') : owner?.payment == 2 ? t('customerAndEmployees.cashDeposit') : owner?.payment == 3 ? t('customerAndEmployees.pickUpCheque') : owner?.payment == 4 ? t('customerAndEmployees.withdrawMoney') : owner?.payment == 5 ? t('customerAndEmployees.bankTransfer') : owner?.payment == 6 ? t('customerAndEmployees.docTed') : owner?.payment == 12 ? t("defaultPaymentMethod1.pixboleto") : ""}</span>
                                        <hr />
                                        {owner?.bank_agency && owner?.bank_acocunt ?
                                            <span className="d-block ms-2 ms-lg-3">
                                                <span className="ps-1">{t("banks.agency")} {owner?.bank_agency} {t("banks.accountNo")} {owner?.bank_acocunt}</span>
                                                <hr />
                                            </span>
                                            : ""}

                                    </span>
                                    <span className="d-block">
                                        <span className="fw-bold">{t("formLabel.transferDay")} 5  </span>
                                        <span className="ps-4 text-secondary"> {t("formLabel.notransfer")}</span>
                                    </span>
                                    <hr />
                                    <span className="d-block">
                                        <span className="fw-bold">{t("formLabel.beneficiaries")} </span>
                                        <span className="ps-4 text-secondary"> <img src="../assets/images/icons/user-light.svg" alt="User" className="ms-1 h-16" /> {owner?.name}</span>
                                        <span className="ps-4 text-secondary">100.00%</span>
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("contractlistother.Latestcontracts")}
                                </Card.Header>
                                <Card.Body className="pb-1">
                                    {/* <!-- Table start from here --> */}
                                    <FloatingLabel label={t("formLabel.Contractsin")} className="mb-lg-4 mb-3">
                                        <Form.Select onChange={(evnt) => filterContracts(evnt)}>
                                            <option value="all">{t("All")}</option>
                                            <option value="active">{t("customerAndEmployees.active")}</option>
                                            <option value="expired">{t("expired")}</option>                                            
                                            <option value="pending">{t("pending")}</option>
                                            <option value="paused">{t("paused")}</option>
                                            <option value="terminated">{t("terminated")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <tbody>
                                            {ownerContracts && ownerContracts.length > 0 ?
                                                ownerContracts.map((curElem: any, index: number) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td className="text-dark-70"><Link className="text-dark-70" to={`/contract_details/${curElem.id}`}><img src="../assets/images/icons/folder.svg" alt="envelop" className="me-1 h-16" /> {t("contract")} {curElem?.property_identifier ? curElem?.property_identifier + "/" + curElem?.lease_count : curElem?.ID_IMOVEL_IMO + "/" + curElem?.lease_count }</Link>
                                                                <span className="text-secondary d-block">{t("tenant")} : {curElem?.tenant_name}</span>
                                                            </td>
                                                            <td className="text-end"><Badge className="text-dark ms-1" bg={`${curElem?.active == "Active" ? "success-10" : curElem?.active == "Paused" ? "light" : curElem?.active == "Terminated" || curElem?.active == "Expired" ? "warning-10" : "light"}`}>{curElem?.active == "Active" ? t("active") : curElem?.active == "Paused" ? t("paused") : curElem?.active == "Terminated" ? t("terminated") : curElem?.active == "Expired" ? t("expired") : t("pending")}</Badge></td>
                                                        </tr>
                                                    )
                                                }
                                                ) :
                                                <div className='text-center text-secondary border p-xl-4 p-3 rounded-4'>
                                                    {t("NoRecords")}
                                                </div>
                                            }
                                        </tbody>
                                    </Table>
                                    {/* <!-- /Table end --> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        {
                            userJson && userJson.length > 0 && (userJson.indexOf(2090) !== -1) &&
                            <Col lg={6} id="paymenttsxMethod">
                                <Card className="h-100">
                                    <Card.Header>{t("customerAndEmployees.Otherpaymentmethods")}</Card.Header>
                                    <Card.Body className="text-center">
                                        <span>{t("Registerother")}</span>
                                        {NoMainAccount &&
                                            <Alert role="alert" variant="warning" className="fade d-block mt-lg-4 mt-3 show">{t("oneprimaryaccount")}</Alert>
                                        }
                                        <div className="d-block w-100 text-center mt-lg-4 mt-3">
                                            <Table responsive className="table-custom table-custom-bordered mb-0" id="primaryBankDetails">
                                                <thead>
                                                    <tr><th>{t("Primary")}</th><th className="text-start text-capitalize">{t("formReceipt.disable")}</th><th className="text-start">{t("BankDetails")}</th><th></th></tr>
                                                </thead>
                                                <tbody>
                                                    {userpaymentMethods && userpaymentMethods.length > 0 &&
                                                        userpaymentMethods.map((curElem: any, index: number) =>
                                                            <tr key={index}>
                                                                <td className={"check_Checkbox"}>
                                                                    <Checkbox name="main_acc" key={curElem.ID_AARIN_CON} type="checkbox" id={curElem.ID_AARIN_CON} handleClick={handleClick} isChecked={curElem.MAIN_ACCOUNT == 1} />
                                                                </td>
                                                                <td>
                                                                    <Checkbox title="disable" name="main_acc" key={curElem.ID_AARIN_CON} type="checkbox" id={curElem.ID_AARIN_CON} handleClick={disableBankAccount} isChecked={curElem.AA_STATUS == 2} />
                                                                </td>

                                                                {curElem.IsPix ?
                                                                    <td className="text-dark-70 text-start">PIX<small className="d-block">{curElem.AA_CONTA_BANK_ID}</small></td>
                                                                    :
                                                                    <td className="text-dark-70 text-start">{curElem.bankNome} <small className="d-block">Agencia : {curElem.agencia},  Conta : {curElem.conta} , ISPB : {curElem.conta}</small></td>
                                                                }

                                                                <td>
                                                                    {userJson && userJson.length > 0 && (userJson.indexOf(2091) !== -1) && <Button variant="link" className="p-0" onClick={e => updateBankEvent(curElem)}><img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} className="h-20" alt="Edit" title="Edit" /></Button>}
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <Button variant="link" className="fw-bold p-0" type="button" onClick={NewPayment}>{t("NonPayment.newPaymentMethod")}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }
                        <Col lg={6}>
                            <Card className="h-100">
                                <Card.Header>{t("Transferstatement")} <span className="capitalize">{state.searchDate.format("MMM/YYYY")}</span></Card.Header>
                                <Card.Body className="pb-1">
                                    <ul className="list-inline fw-bold">
                                        <li className="list-inline-item mb-sm-0 mb-1 me-2 me-xl-3 me-xxl-4">
                                            <Link to={"/report"} role="button">{t("Groupedstatement")}</Link>
                                        </li>
                                        <li className="list-inline-item mb-sm-0 mb-1 me-2 me-xl-3 me-xxl-4">
                                            <Link to={"/transfers"} role="button">{t("Unrealizedtransfers")}</Link>
                                        </li>
                                        <li className="list-inline-item mb-sm-0 mb-1 me-2 me-xl-3 me-xxl-4">
                                            <Link to={"/transfers"} role="button">{t("Transfersmade")}</Link>
                                        </li>
                                    </ul>
                                    <div className="d-block w-100  mt-lg-4 mt-3">
                                        <div className="d-inline-flex mb-3">
                                            <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract', "transfer")}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                                    {/* {state.startDate} */}
                                                </Button>
                                                <Button variant="primary" className="btn-date capitalize" disabled>
                                                    {state.searchDate.format("MMM/YYYY")}
                                                </Button>
                                                <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add', "transfer")}>
                                                    {/* {state.endDate} */}
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        <Table responsive className="table-custom table-custom-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t("maturity")}</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th className="text-end">{t("value")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transferstate && transferstate.length > 0 ?
                                                    transferstate.map((item: any) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {item.recMaturity2}</td>
                                                                <td>
                                                                    <img src="../assets/images/icons/home_g.svg" />
                                                                    <span className="ps-1">
                                                                        {item.propertyType ?
                                                                            <Link className="text-dark-70" to={"/property_detail/" + item.propertyId} target="_blank">
                                                                                {item.propertyType + ' : '}
                                                                            </Link>
                                                                            : ''
                                                                        }
                                                                        {item.address ? item.address + ' - ' : ''}
                                                                        {item.number ? item.number + ', ' : ''}
                                                                        {item.zipcode ? 'CEP: ' + item.zipcode : ''}
                                                                        {
                                                                            item.owner.length > 0 ? item.owner.map((items, indx) => (
                                                                                <>
                                                                                    &nbsp;
                                                                                    <img src="../assets/images/icons/user_g.svg" />
                                                                                    <span className="ps-1" key={indx}>
                                                                                        {items.name}
                                                                                    </span>
                                                                                </>
                                                                            ))
                                                                                : ''
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td></td>
                                                                <td className="text-end fw-bold text-danger-40">
                                                                    <span className="d-block text-nowrap"> R$ {item.VL_AMOOUNT_PAID}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className="p-1 text-secondary text-center">{t('formLabel.noRecordsFounds')}</div>
                                                        </td>
                                                    </tr>

                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col lg={12}>
                            <Card>
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("formLabel.contact")}
                                    <div>
                                        {
                                            userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                            <>
                                                <Button variant="link" className="mb-lg-4 mb-3 p-0" data-bs-target="#toAdd"
                                                    data-bs-toggle="modal">{t("Toadd")}</Button>
                                            </>
                                        }
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {/* <!-- Table start from here --> */}
                        {/* <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="text-dark-70">
                                                    <img src="../assets/images/icons/envelop.svg" alt="envelop" className="h-14" />
                                                    <span className="ps-1">Email aline@navegantes.com.br</span>
                                                </td>
                                                <td className="text-end">
                                                    {
                                                        userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                                        <>
                                                            <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                                                <li className="list-inline-item me-3">
                                                                    <img src="../assets/images/icons/edit-light.svg" alt="edit" className="h-14" />
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <img src="../assets/images/icons/delete-light.svg" alt="edit" className="h-14" />
                                                                </li>
                                                            </ul>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table> */}
                        {/* <!-- /Table end --> */}
                        {/* </Card.Body>
                            </Card>
                        </Col>  */}

                        {/* <Col lg={12}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("Recenttickets")}
                                    <div>
                                        {
                                            userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                            <>
                                                <ButtonGroup>
                                                    <DropdownButton variant="light" size="sm" as={ButtonGroup} title="New ticket">
                                                        <Dropdown.Item>{t("Ticketfor")} {t("notifications.owner")}</Dropdown.Item>
                                                        <Dropdown.Item>{t("Ticketfor")}  {t("notifications.tenant")}</Dropdown.Item>
                                                        <Dropdown.Item>{t("Internalticket")} - {t("notifications.owner")}</Dropdown.Item>
                                                        <Dropdown.Item>{t("Internalticket")} - {t("notifications.tenant")}</Dropdown.Item>
                                                    </DropdownButton>
                                                    <Button variant="light" size="sm">{t("Seealltickets")}</Button>
                                                </ButtonGroup>
                                            </>
                                        }
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Alert variant="warning" className="d-block mt-lg-4 mt-3 text-center">{t("datanotfound")}</Alert>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        <Col lg={12}>
                            <Card className="h-100">
                                <Card.Header>{t("Emaillist")}</Card.Header>
                                <Card.Body>
                                    {/* <!-- table start grom here --> */}
                                {
                                    emailStatus == "loading" ? (
                                      <Loader />
                                ) : (
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                {/* <th></th> */}
                                                <th>{t("Message")}</th>
                                                <th></th>
                                                <th className="text-end">{t("reportSystem.Recipient")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/*{
                                                allEmailList && allEmailList.length > 0 &&
                                                allEmailList.map((item, index) => {
                                                    let datee = moment(item["DT_ENTRADA_FE"]).format("YYYY-MM-DD HH:mm:ss");
                                                    let dataMat: any = item["DT_ENTRADA_FE"];
                                                    let todayDate = new Date(dateBrazil);
                                                    let maturityDate = new Date(`${dataMat?.split("T")[0]}`);
                                                    let Difference_In_Time = maturityDate.getTime() - todayDate.getTime();
                                                    let Difference_In_Days: any = Difference_In_Time / (1000 * 3600 * 24);
                                                    let MathSign = Math.sign(Difference_In_Days);
                                                    let dayAgo = "";
                                                    if (MathSign == -1) {
                                                        dayAgo = String(parseInt(Difference_In_Days)).replace(
                                                            "-",
                                                            ""
                                                        );
                                                        if (Number(dayAgo) > 30) {
                                                            if (Math.floor(Number(dayAgo) / 30) >= 12) {
                                                                let YearAgo = `${String(
                                                                    Math.floor(Number(dayAgo) / 30 / 12)
                                                                )}`;
                                                                if (YearAgo === "1") {
                                                                    dayAgo = `${YearAgo} year`;
                                                                } else {
                                                                    dayAgo = `${YearAgo} years`;
                                                                }
                                                            } else {
                                                                let monthsAgo = `${String(
                                                                    Math.floor(Number(dayAgo) / 30)
                                                                )}`;
                                                                if (monthsAgo === "1") {
                                                                    dayAgo = `${monthsAgo} month`;
                                                                } else {
                                                                    dayAgo = `${monthsAgo} months`;
                                                                }
                                                            }
                                                        } else {
                                                            if (dayAgo == "0") {
                                                                dayAgo = "Today";
                                                            } else if (dayAgo == "1") {
                                                                dayAgo = "Yesterday";
                                                            } else {
                                                                dayAgo = `${dayAgo} days`;
                                                            }
                                                        }
                                                    }
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                {/* <td>
                                                                    {
                                                                        userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                                                        <>
                                                                            <img src="../assets/images/icons/edit-light.svg" alt="edit" className="h-14 cursor-pe" />
                                                                        </>
                                                                    }
                                                                </td> */}
                                                               {/*} <td>
                                                                    <span className="d-block text-dark-70">  {item["ST_ASSUNTO_FE"]} </span>
                                                                    {" -" + datee}
                                                                </td>
                                                                <td>
                                                                    {/* {
                                                                        item["FL_OCULTO_FE"] ?
                                                                            <>
                                                                                <img src="../assets/images/icons/envlop.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, item["ID_EMAIL_FE"], item["ST_PARA_FE"])} />has already been seen
                                                                            </>
                                                                            :
                                                                            <> */}
                                                                    {/*<img src="../assets/images/icons/envelop.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, item["ID_EMAIL_FE"], item["ST_PARA_FE"])} /> {t("EmailDownloads")}
                                                                    {/* </>
                                                                    } */}
                                                               {/*} </td>
                                                                <td className="text-dark-70 text-end">
                                                                    <span className="d-block">{item["ST_PARA_FE"]}</span>
                                                                    <Badge className="bg-success-10 text-dark cursor-pe"> {dayAgo} </Badge>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }*/}

                                             {
                                                emailData?.data && emailData.data.length > 0 &&
                                                emailData.data.map((item, index) => {                                                    
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>
                                                                    {/* {
                                                                        userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                                                        <>
                                                                            <img src="../assets/images/icons/edit-light.svg" alt="edit" className="h-14 cursor-pe" />
                                                                        </>
                                                                    } */}
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        { item.ST_ASSUNTO_FE }
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item["FL_OCULTO_FE"]==0 ?
                                                                            <>
                                                                                <img src="../assets/images/icons/eye.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, item["ID_EMAIL_FE"], item["ST_PARA_FE"])} />{ t("emailSeen") }
                                                                            </>
                                                                        :
                                                                            <>                                                                     
                                                                                <img src="../assets/images/icons/envelop.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, item["ID_EMAIL_FE"], item["ST_PARA_FE"])} />{ t("emailTemplate") }
                                                                            </>                                                                        
                                                                    } 
                                                                </td>
                                                                <td className="text-dark-70 text-end">
                                                                    <span className="d-block">{ item.ST_PARA_FE }</span>
                                                                     { item.DT_ENTRADA_FE }
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                )}
                                    {/*<p className="mb-0">{t("formLabel.listing")} {allEmailList && allEmailList.length} {t("emails")}, {allEmailList && allEmailList.length} {t("sentandwith")}.</p>*/}
                                <p className="mb-0">{t("formLabel.listing")} { emailData?.totalRow && emailData.totalRow ? emailData.totalRow : 0 } {t("emails")}, { emailData?.sendCount && emailData?.sendCount ? emailData.sendCount : emailData?.totalRow } {t("sentandwith")}.</p>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={12}>
                            <Card className="h-100">
                                <Card.Header className="d-sm-flex align-items-center justify-content-between">
                                    {t("Links.properties")}
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle className="dropdown-arrow-none p-0" variant="link" size="sm">
                                                <img src="../assets/images/icons/print-light.svg" alt="Print" className="h-20 cursor-pe" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="mh-456 overflow-auto">
                                                <Dropdown.Item>{t("formLabel.printScreen")}</Dropdown.Item>
                                                <Dropdown.Item>{t("Reports.summaryOfProperties")}</Dropdown.Item>
                                                <Dropdown.Item>{t("Realestatedetails")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Card.Header>
                                <Card.Body className="card-body py-5">
                                    {/* <!-- table start grom here --> */}
                                    <Table responsive className="table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th className="w-24">
                                                    <input type="checkbox" className="form-check-input checkbox-sm" />
                                                </th>
                                                {/* <th></th> */}
                                                <th>{t("property.immobile")}</th>
                                                <th></th>
                                                <th>{t("property.saleValue")}</th>
                                                <th>{t("rentValue")}</th>
                                                <th className="text-end"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ownerProperty && ownerProperty.length > 0 ? (
                                                ownerProperty.map((item, index) => {
                                                    return (
                                                        <tr key={index}> {/* Assuming you have a unique identifier for each item */}
                                                            <td>
                                                                <input type="checkbox" className="form-check-input checkbox-sm" />
                                                            </td>
                                                            {/* <td>
                                                                <img src="../assets/images/icons/home-person-lgiht.svg" alt="House icon" className="cursor-pe h-18" />
                                                            </td> */}
                                                            <td>
                                                                <Link to={"/property_detail/" + item.id}>
                                                                    <img src="../assets/images/icons/home_g.svg" className="me-1" alt="Home icon" />
                                                                    <span className="text-dark-70">{item.number} - {item.address}, {item.zipcode}</span>
                                                                </Link>
                                                                <span className="d-block">{item.city} - {item.state}</span>
                                                            </td>
                                                            <td className="text-dark-70">
                                                                {/* <span className="d-block">
                                                                    {
                                                                        item?.ownerResult?.length > 0 && item?.ownerResult?.length > 0 ? item.ownerResult.map((row, indx) => (
                                                                            <Link to={"/owner_details/" + row.ownerId} className="text-secondary text-nowrap" key={indx}>
                                                                                <img src="../assets/images/icons/user_g.svg" alt="User icon" /> {row.ownerName}
                                                                            </Link>
                                                                        )) : ''
                                                                    }
                                                                </span> */}
                                                                <span className="d-block">
                                                                    {
                                                                        item.contractResult.length > 0 && item.contractResult[0].tenantsResult && item.contractResult[0].tenantsResult.length > 0 ? item.contractResult[0].tenantsResult.map((row, indx) => (
                                                                            <Link to={"/profile_details/" + row.tenetId} className="text-secondary text-nowrap me-2 me-xl-3" key={indx}>
                                                                                <img src="../assets/images/icons/key_g.svg" className="me-1" alt="Key icon" /> {row.tenetName}
                                                                            </Link>
                                                                        )) : ''
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-nowrap"> R$ {item.sale_value}</span>
                                                                {/* <span className="text-dark-70 d-block">Sale 57/319</span> */}
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-nowrap">R$ {item.rent_amount}</span>
                                                                {/* <span className="text-dark-70 d-block">Contract 318/1</span> */}
                                                            </td>
                                                            <td className="text-end">
                                                                {
                                                                    item.contractResult.length > 0 ?
                                                                        userJson && userJson.length > 0 && (userJson.indexOf(2030) !== -1) &&
                                                                        <Link to={"/contract_details/" + item.contractResult[0].contractId} className="fw-bold me-2 me-xxl-4 me-lg-3 text-nowrap">
                                                                            {t('button.seeContract')}
                                                                        </Link>
                                                                        :
                                                                        ''
                                                                }

                                                                {
                                                                    userJson && userJson.length > 0 && (userJson.indexOf(2070) !== -1) &&
                                                                    <Link to={"/property_detail/" + item.id} className="fw-bold text-nowrap">
                                                                        {t('button.seeProperty')}
                                                                    </Link>

                                                                }
                                                                <br/>
                                                                { 
                                                                    item.emptyProperty=="" || item.emptyProperty==null ?
                                                                        <span className="text-white ms-1 badge bg-warning"> { t("emptyProperty") }</span>
                                                                    : 
                                                                        ""
                                                                }

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className="text-center p-1 text-secondary">{t('formLabel.noRecordsFounds')}</div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <p className="my-lg-4 my-3">{t("formLabel.listing")} {ownerProperty.length}  {t("Links.properties")}.</p>
                                    {
                                        userJson && userJson.length > 0 && (userJson.indexOf(2071) !== -1) &&
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm">
                                                        {t("formLabel.mark")}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">{t("all")}</Dropdown.Item>
                                                        <Dropdown.Item href="#">{t("formLabel.none")}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span
                                                className="px-1">{t("withtagged")} :</span></li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Button variant="light" size="sm">{t("property.changeAdministrator")}</Button>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Button variant="light" size="sm">{t("property.changeCondominium")}</Button>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Button variant="light" size="sm">{t("property.addManager")}</Button>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1">
                                                <Dropdown drop="up">
                                                    <Dropdown.Toggle variant="light" size="sm">
                                                        {t("more")}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="mh-456 overflow-auto">
                                                        <Dropdown.Item>{t("property.removeManager")}</Dropdown.Item>
                                                        <Dropdown.Item>{t("property.changeBranch")}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    }
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        {   
                            ownTransfer && ownTransfer.totalRow && ownTransfer.totalRow==1?
                                <Col lg={12}>
                                    <Card className="h-100">
                                        <Card.Header>{t("ownPayment")}</Card.Header>
                                        <Card.Body>
                                        {
                                            ownTransferStatus == "loading" ? (
                                                <Loader />
                                        ) : (
                                            <Table responsive className="table-custom table-custom-bordered mb-0">
                                                <thead>
                                                    <tr>  
                                                        <th></th>                                              
                                                        <th>{t("terminatelist.date")}</th>
                                                        <th>{t("Links.history")}</th>
                                                        <th className="text-end">{t("Links.expenses")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {   ownTransfer && ownTransfer.data && ownTransfer.data.length > 0 ? ownTransfer.data.map((item, index) => (
                                                         <tr key={index}>
                                                            <td className="text-dark-70"> 
                                                                { 
                                                                    item.FL_STATUS_MOV==1?                                                               
                                                                        <span className=" icon cursor-pe me-1 me-xl-2 me-xxl-2">
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icons/Liquidate.svg"} alt="Liquidate" className="h-20" />
                                                                        </span>
                                                                    :
                                                                        <span className="icon img-warning">
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/Shape.svg"} className="h-20" />
                                                                       </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-nowrap"> { item.DT_LANCAMENTO_MOV }</span>
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-nowrap">{ item.ST_HISTORICO_MOV }</span>
                                                            </td>
                                                            <td className="text-end">
                                                                <span className="d-block text-nowrap">R$ { item.VL_VALOR_MOV }</span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : 
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className="text-center p-1 text-secondary">{t('formLabel.noRecordsFounds')}</div>
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        )}
                                         <p className="my-lg-4 my-3">{t("formLabel.listing")} { ownTransfer &&  ownTransfer.data && ownTransfer.data.length ?  ownTransfer.data.length : 0 }  {t("Links.properties")}.</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            :  ''
                        }

                        {   
                            ownProperty && ownProperty.totalRow && ownProperty.totalRow==1?
                                <Col lg={12}>
                                    <Card className="h-100">
                                        <Card.Header>{t("ownProperty")}</Card.Header>
                                        <Card.Body>
                                        {
                                            ownPropertyStatus == "loading" ? (
                                                <Loader />
                                        ) : (
                                            <Table responsive className="table-custom table-custom-bordered mb-0">
                                                <thead>
                                                    <tr>  
                                                        <th>{t("property.immobile")}</th>         
                                                        <th>{t("reportSales.Commission")}</th>                                     
                                                        <th>{t("property.saleValue")}</th>
                                                        <th>{t("property.rentAmount")}</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {   ownProperty && ownProperty.data && ownProperty.data.length > 0 ? ownProperty.data.map((item, index) => (
                                                         <tr key={index}>
                                                            <td className="text-dark-70"> 
                                                                <span className="ps-1 d-inline text-dark-70">
                                                                    { item.ID_IMOVEL_IMO ? item.ID_IMOVEL_IMO + ' - ' : ''} { item.ST_TIPO_IMO ? item.ST_TIPO_IMO : "" } {t("in")} { item.ST_BAIRRO_IMO },
                                                                </span>
                                                                <span className="ps-1 d-inline">
                                                                    {item.ST_ENDERECO_IMO},  {item.ST_NUMERO_IMO} - { item.ST_COMPLEMENTO_IMO ? item.ST_COMPLEMENTO_IMO : ""} {t("CEP")} : {item.ST_CEP_IMO}
                                                                </span>                                                               
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-nowrap"> { item.COMMISSION }</span>
                                                            </td>
                                                            <td>
                                                                <span className="d-block text-nowrap"> { item.VL_VENDA_IMO ? "R$ "+item.VL_VENDA_IMO : '' }</span>
                                                            </td>
                                                            <td className="text-end">
                                                                <span className="d-block text-nowrap">R$ { item.VL_ALUGUEL_IMO }</span>
                                                                <span className="d-block text-nowrap">{ t("contracts") +' '+item.ST_IDENTIFICADOR_IMO+'/'+item.lease_count } </span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : 
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className="text-center p-1 text-secondary">{t('formLabel.noRecordsFounds')}</div>
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        )}
                                         <p className="my-lg-4 my-3">{t("formLabel.listing")} { ownProperty &&  ownProperty.data && ownProperty.data.length ?  ownProperty.data.length : 0 }  {t("Links.properties")}.</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            :  ''
                        }
                    </Row>
                </div>
            </main>
            {/* <!-- /Main content start end --> */}
            {/* new payment modal start here  */}
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title fs-4" id="newOwnerPaymentLabel">{t("NonPayment.newPaymentMethod")}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(mailSubmit)}>
                        <Modal.Body>
                            <Form.Group className="form-floating">
                                <Form.Select className={`form-select ${errors.payment ? 'is-invalid' : ''}`} id="payment" {...register('payment')} aria-label="payment">
                                    <option value="">{t("formLabel.select")}</option>
                                    <option value="1">ADM</option>
                                    <option value="2">Adm Cond</option>
                                    <option value="3">Am</option>
                                    <option value="4">Delta</option>
                                    <option value="5">Wing towers</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.payment?.message}
                                </Form.Control.Feedback>
                                <Form.Label className="mb-3">{t("formOfPayment")} </Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary">
                                {t("close")}
                            </Button>
                            <Button type="submit" variant="primary" name="send">
                                {t("button.toSend")}
                            </Button>
                        </Modal.Footer>
                    </Form>

                </Modal>
            </div>
            <div>
                <Modal show={ownerPayment} onHide={handleClose} id="maintscBankAccount">
                    <Modal.Header closeButton>
                        <Modal.Title className="modal-title fs-4" id="newNewPaymentPaymentLabel">{t("NonPayment.newPaymentMethod")}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(mailSubmit)}>
                        <input type="hidden" {...register('ID_AARIN_CON')} />
                        <Modal.Body>
                            <Form.Group className="form-floating">
                                <Form.Select className={`form-select ${errors.payment ? 'is-invalid' : ''}`} id="payment" {...register('payment')} aria-label="payment" onChange={ownerPaymentHide} disabled={(ArinBankId > 0) ? true : false}>
                                    <option value="">{t("formLabel.select")}</option>
                                    <option value="2">{t('customerAndEmployees.cashDeposit')}</option>
                                    <option value="5">{t('customerAndEmployees.bankTransfer')}</option>
                                    <option value="6">{t('customerAndEmployees.docTed')}</option>
                                    <option value="0">{t('customerAndEmployees.boleto')}</option>
                                    <option value="12">{t("customerAndEmployees.pix")}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.payment?.message}
                                </Form.Control.Feedback>
                                <Form.Label className="mb-3">{t("formOfPayment") + " *"}</Form.Label>
                            </Form.Group>
                            <br />
                            {/* Basic info for account add start from here */}
                            <div>
                                {(!pixTypeHide && !HidePixAlso) ?
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="pixKeyType" label={t("banks.pixkeyType") + " *"}>
                                                <Form.Select className={` ${errors.pixKeyType ? 'is-invalid' : ''}`} {...register('pixKeyType')} aria-label="Pix Key Type">
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    <option value="1">CPF</option>
                                                    <option value="2">CNPJ</option>
                                                    <option value="3">Celular</option>
                                                    <option value="4">E-mail</option>
                                                    <option value="5">Aleatoria</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.pixKeyType?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="pixKey" label={t("banks.pixKey")}>
                                                <Form.Control type="text" placeholder={t('customerAndEmployees.PixKey')} className={` ${errors.pixKey ? 'is-invalid' : ''}`}  {...register('pixKey')} />
                                                <Form.Control.Feedback type="invalid">{errors.pixKey?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    : ""}

                                {pixTypeHide ?
                                    <>
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                            <Col md={12} className={`${errors.bankname ? 'is-invalid' : ''}`}>
                                                <InputGroup>
                                                    <Form.Floating>
                                                        <div ref={searchContainerRef} className="position-relative">
                                                            <Form.Label htmlFor="bank">{t("banks.name") + " *"}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={t("banks.name") + " *"}
                                                                {...register('bankname')}
                                                                onChange={handleInputChange} // Use handleInputChange here
                                                                onFocus={handleInputFocus}
                                                                className={`${errors.bankname ? 'is-invalid' : ''}`}
                                                                id="bank"
                                                                autoComplete="off"
                                                                value={typedValue} // Show the typed value instead of selectedBankName
                                                            />

                                                            {isInputFocused && searchResults.length > 0 && (
                                                                <ListGroup
                                                                    className="shadow position-absolute overflow-auto start-0 mt-1"
                                                                    style={{ maxHeight: "260px", zIndex: "5", width: '100%' }}
                                                                >
                                                                    {searchResults.slice(0, 10).reverse().map((item, index) => (
                                                                        <ListGroup.Item
                                                                            className="px-3 cursor-pointer"
                                                                            key={index}
                                                                            onClick={(e) => HandleBankAccount(e, item)}
                                                                        >
                                                                            {item.bank_code} - {item.bank_name}
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            )}
                                                        </div>
                                                    </Form.Floating>
                                                </InputGroup>
                                                <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback >
                                            </Col>
                                            <Col md={6}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.account ? 'is-invalid' : ''}`} id="account"
                                                        placeholder="Account Number (With digit) *" {...register("account")} />
                                                    <Form.Control.Feedback type="invalid">{errors.account?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.accountNo")} {t("banks.withDigit") + " *"}</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.agency ? 'is-invalid' : ''}`} id="agency"
                                                        placeholder={t("banks.agency") + " *"} {...register("agency")} maxLength={8} />
                                                    <Form.Control.Feedback type="invalid">{errors.agency?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="floatingInputValue">{t("banks.agency") + " *"}  </Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="pixKeyType" label={t("banks.accountType") + " *"}>
                                                    <Form.Select className={` ${errors.pixKeyType ? 'is-invalid' : ''}`} {...register('pixKeyType')} aria-label="Pix Key Type">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t('currentAccount')}</option>
                                                        <option value="2">{t('SavingAccount')}</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.pixKeyType?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>


                                        {localDetail ?
                                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                                {/* <Col md={6}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.nameReference ? 'is-invalid' : ''}`} id="nameReference"
                                                        placeholder="Name Reference" {...register("nameReference")} />
                                                    <Form.Control.Feedback type="invalid" >{errors.nameReference?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="nameReference">{t("customerAndEmployees.Inthenameof")+ " *"}</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Floating>
                                                    <Form.Control type="text" className={`${errors.until ? 'is-invalid' : ''}`} id="until"
                                                        placeholder={t('formLabel.cnpjCpf')}  {...register("until")}  onChange={(e) => handleCPF_CNJPVaue(e, "both")}/>
                                                    <Form.Control.Feedback type="invalid" >{errors.until?.message}</Form.Control.Feedback >
                                                    <Form.Label htmlFor="until">{t('formLabel.cnpjCpf')+ " *"} </Form.Label>
                                                </Form.Floating>
                                            </Col> */}
                                            </Row>
                                            : ""
                                        }

                                    </>
                                    : ""
                                }
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <Form.Check type="checkbox" {...register('main_ac')} label={t('banks.mainAccount')} />
                                    </Col>
                                </Row>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={handleClose}>{t("button.close")}</Button>
                            <Button type="submit" variant="primary" name="send">{t("button.save")}</Button>
                        </Modal.Footer>

                    </Form>
                </Modal>

            </div>
            <NewOwner iddata={id} owner={owner} getOwner={getOwner} />
            {/* <EmailSeen showDisplayModal={showDisplayModal} setDisplayModal={setDisplayModal} emaildata={emaildata} allEmailList={allEmailList} setAllEmailList={setAllEmailList} /> */}
            {/*  <!-- /new payment modal end  */}
        </section>
    )
}

export default Owner_details