import React, { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Button, Card, Dropdown, Form, Table } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

import ChargeLiquidate from "../../../../../component/Pages/Income/ReceivableCharges/ChargeLiquidate";
import { Icon } from "../../../../components/Icon";
import Loader from "../../../../../component/Profile/Company/Loader";
import { useChargesToReceive } from "../../ChargesToReceiveProvider";
import { InvalidateChargesModal } from "../InvalidateChargesModal";
import { BankSlipProgress, DueDate, EmptyList } from "./components";
import { useHomologatedChargesList } from "./HomologatedChargesListProvider";
import "./styles.css";
import SettleLots from "../../../../../component/Pages/Income/ReceivableCharges/SettleLots";
import { useBankSlips } from "./useBankSlips";
import { incomeService } from "../../../../../services/incomeServices";
import { useChargeLiquidate } from "../../../../../component/Pages/Income/ReceivableCharges/useChargeLiquidate";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { deleteBillingTicketCron } from "../../../../../redux/slice/contractDataSlice";
import moment from "moment";

export function HomologatedChargesList() {
  const [chargesIdToInvalidate, setChargesIdToInvalidate] = useState<number[]>(
    []
  );
  const [showLiquidateSingleChargeModal, setShowLiquidateSingleChargeModal] =
    useState(false);
  const [showLiquidateInBatchModal, setShowLiquidateInBatchModal] =
    useState(false);
  const {
    homologatedCharges,
    selectedCharges,
    handleSelectAllCharges,
    handleSelectOneCharge,
    isAllChargesSelected,
    isFetchingCharges,
    deselectAllCharges,
  } = useHomologatedChargesList();
  const { handleDiscardOrCancelBankSlips } = useBankSlips();
  const { fetchAllCharges } = useChargesToReceive();
  const renderedRef = useRef(false);
  const notLiquidatedCharges = homologatedCharges.filter(
    (charge) => charge.FL_STATUS_RECB === 0
  );
  const chargesToBeLiquidated = homologatedCharges.filter(
    (charge) =>
      charge.statusBankSlip === "LIQUIDADO" && charge.FL_STATUS_RECB === 0
  );
  const { getLiquidityInfos } = useChargeLiquidate({});
  const dispatch = useDispatch<AppDispatch>();

  function handleOpenInvalidateChargeModal(chargesId: number[]) {
    if (chargesId.length === 0) {
      return;
    }

    setChargesIdToInvalidate(chargesId);
  }

  function handleInvalidateSingleCharge(chargeId: number) {
    handleOpenInvalidateChargeModal([chargeId]);
  }

  function handleLiquidateSingleChargeModal(chargeId: number) {
    document
      .querySelector("body")
      ?.setAttribute("charge_liquidity_id", String(chargeId));
    setShowLiquidateSingleChargeModal(true);
  }

  function handleCloseLiquidateChargeModal() {
    document.querySelector("body")?.setAttribute("charge_liquidity_id", "");
    setShowLiquidateSingleChargeModal(false);
  }

  async function handleDownloadBankSlip(url: string) {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = "boleto.pdf";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }

  useEffect(() => {
    if (isFetchingCharges) renderedRef.current = false;

    if (
      !renderedRef.current &&
      chargesToBeLiquidated.length > 0 &&
      !isFetchingCharges
    ) {
      renderedRef.current = true;

      chargesToBeLiquidated.forEach(async (charge) => {
        const getLiquidityData = await getLiquidityInfos(charge.ID_RECEBIMENTO_RECB);

        if (!getLiquidityData) return;

        const dateBrazil = moment
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DD HH:mm");
        const currentDate = moment
          .utc(dateBrazil)
          .subtract(1, "days")
          .format("YYYY-MM-DD");

        incomeService
          .postLiquidity({
            ID_FORMAPAGAMENTO_RECB:
              getLiquidityData.ID_FORMAPAGAMENTO_RECB.toString(),
            VL_TOTAL_RECB: parseFloat(
              getLiquidityData.VL_TOTAL_RECB.replace(",", ".")
            ),
            DT_LIQUIDACAO_RECB: currentDate,
            DT_RECEBIMENTO_RECB: getLiquidityData.DT_RECEBIMENTO_RECB,
            action: "update",
            DT_GERACAO_RECB: getLiquidityData.DT_GERACAO_RECB,
            DT_COMPETENCIA_RECB: getLiquidityData.DT_COMPETENCIA_RECB,
            ID_RECEBIMENTO_RECB: getLiquidityData.ID_RECEBIMENTO_RECB,
            settleAmount: getLiquidityData.settleAmount,
            origionalAmount: getLiquidityData.origionalAmount,
            VL_DESCONTOCALCULADO_RECB:
              getLiquidityData.VL_DESCONTOCALCULADO_RECB,
            print_receipt: getLiquidityData.print_receipt,
            debit_bank_fee: getLiquidityData.debit_bank_fee,
            VL_TXJUROS_RECB: parseFloat(
              getLiquidityData.VL_TXJUROS_RECB.replace(",", ".")
            ),
            VL_TXMULTA_RECB: parseFloat(
              getLiquidityData.VL_TXMULTA_RECB.replace(",", ".")
            ),
            vl_taxacobranca_recb: parseFloat(
              getLiquidityData.vl_taxacobranca_recb.replace(",", ".")
            ),
            ID_CHEQUE_PRE: getLiquidityData.ID_CHEQUE_PRE,
            NM_CHEQUE_PRE: getLiquidityData.NM_CHEQUE_PRE,
            ST_BANCO_PRE: getLiquidityData.ST_BANCO_PRE,
            ST_AGENCIA_PRE: getLiquidityData.ST_AGENCIA_PRE,
            ST_CONTA_PRE: getLiquidityData.ST_CONTA_PRE,
            DT_VENCIMENTO_PRE: getLiquidityData.DT_VENCIMENTO_PRE,
            ST_OBSERVACAOINTERNA_RECB:
              getLiquidityData.ST_OBSERVACAOINTERNA_RECB,
            ID_CONTA_CB: getLiquidityData.ID_CONTA_CB,
            ID_CONTRATO_CON: getLiquidityData.ID_CONTRATO_CON,
            isCharge: null,
            chargeDate: "",
            DT_VENCIMENTO_RECB: getLiquidityData.DT_RECEBIMENTO_RECB,
            userId: 1,
          })
          .then(() => {
            dispatch(deleteBillingTicketCron(charge.ID_RECEBIMENTO_RECB));
          });
      });

      return;
    }
  }, [chargesToBeLiquidated, isFetchingCharges]);

  return (
    <Card>
      <Card.Body>
        <Table
          responsive="lg"
          className="table table-custom table-custom-bordered mb-0"
        >
          <thead>
            <tr>
              <th className="w-24">
                <Form.Check
                  type="checkbox"
                  checked={isAllChargesSelected}
                  onChange={handleSelectAllCharges}
                />
              </th>
              <th style={{ width: "10%" }} className="text-start">
                Vencimento
              </th>
              <th className="text-start">Contrato / Cliente</th>
              <th style={{ width: "17%" }} className="text-start">
                Status
              </th>
              <th style={{ width: "10%" }} className="text-start">
                Valor
              </th>
              <th style={{ width: "16%" }}>
                <Dropdown
                  className="d-flex align-items-center justify-content-end"
                  drop="down-centered"
                >
                  <Dropdown.Toggle
                    className="p-0 fw-bold dropdown-arrow-none d-flex align-items-center gap-1"
                    variant="link"
                    disabled={selectedCharges.length === 0}
                  >
                    Ações
                    <Icon name="chevronDown" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => setShowLiquidateInBatchModal(true)}
                    >
                      Liquidar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleOpenInvalidateChargeModal(selectedCharges)
                      }
                    >
                      Invalidar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
            </tr>
          </thead>
          <tbody>
            {isFetchingCharges ? (
              <tr className="w-full border-0">
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            ) : !homologatedCharges || homologatedCharges.length <= 0 ? (
              <tr>
                <td colSpan={6}>
                  <EmptyList />
                </td>
              </tr>
            ) : (
              notLiquidatedCharges.map((charge) => (
                <tr key={charge.ID_RECEBIMENTO_RECB}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedCharges.includes(
                        charge.ID_RECEBIMENTO_RECB
                      )}
                      onChange={() =>
                        handleSelectOneCharge(charge.ID_RECEBIMENTO_RECB)
                      }
                    />
                  </td>
                  <td className={`text-dark-70`}>
                    <DueDate
                      className={`${charge.isCanceled && "text-canceled"}`}
                      date={charge.dueDateDisplay}
                      isOverdue={charge.isOverdue}
                      daysLate={charge.daysLateDisplay}
                    />
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <div
                        className={`d-flex align-items-center gap-1 text-dark-70 ${
                          charge.isCanceled && "text-canceled"
                        }`}
                      >
                        {charge.isSingle ? (
                          <Badge bg="success">Avulso</Badge>
                        ) : (
                          <span>{charge.contractNumber}</span>
                        )}
                        <span>{charge.payerName}</span>
                      </div>
                      <div>
                        <span>{`Cobrança: ${charge.ID_RECEBIMENTO_RECB}`}</span>
                        {charge.ourNumber && (
                          <span className="ms-3">
                            {`NN: ${charge.ourNumber}`}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <BankSlipProgress status={charge.statusBankSlip} />
                  </td>
                  <td
                    className={`text-dark-70 ${
                      charge.isCanceled && "text-canceled"
                    }`}
                  >
                    {charge.totalValueDisplay}
                  </td>
                  <td>
                    <Tooltip id="actions-tooltip" />
                    <div className="d-flex justify-content-end align-items-center gap-3">
                      <Button
                        data-tooltip-id="actions-tooltip"
                        data-tooltip-content="Liquidar"
                        variant="link"
                        className="p-0"
                        onClick={() =>
                          handleLiquidateSingleChargeModal(charge.id)
                        }
                      >
                        <Icon name="documentCheckmark" />
                      </Button>
                      <a
                        data-tooltip-id="actions-tooltip"
                        data-tooltip-content="Abrir"
                        href={charge.bankSlipUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="p-0"
                      >
                        <Button
                          disabled={!charge.bankSlipUrl}
                          variant="link"
                          className="p-0"
                        >
                          <Icon name="open" />
                        </Button>
                      </a>
                      <Button
                        data-tooltip-id="actions-tooltip"
                        data-tooltip-content="Download PDF"
                        disabled={!charge.bankSlipUrl}
                        variant="link"
                        className="p-0"
                        onClick={() =>
                          handleDownloadBankSlip(charge.bankSlipUrl)
                        }
                      >
                        <Icon name="arrowDownload" />
                      </Button>
                      {!charge.isCanceled && (
                        <Button
                          data-tooltip-id="actions-tooltip"
                          data-tooltip-content="Invalidar"
                          variant="link"
                          className="p-0"
                          onClick={() =>
                            handleInvalidateSingleCharge(charge.id)
                          }
                        >
                          <Icon name="dismissCircle" />
                        </Button>
                      )}
                      {/* {charge.isCanceled && (
                        <Button
                          data-tooltip-id="actions-tooltip"
                          data-tooltip-content="Duplicar"
                          variant="link"
                          className="p-0"
                        >
                          <Icon name="copySelect" />
                        </Button>
                      )} */}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <InvalidateChargesModal
            show={chargesIdToInvalidate.length > 0}
            onClose={() => setChargesIdToInvalidate([])}
            chargesId={chargesIdToInvalidate}
          />
          <ChargeLiquidate
            show={showLiquidateSingleChargeModal}
            onClose={handleCloseLiquidateChargeModal}
            handleDiscardOrCancelBankSlips={handleDiscardOrCancelBankSlips}
            fetchAllCharges={fetchAllCharges}
          />
          <SettleLots
            showSettleLotsModal={showLiquidateInBatchModal}
            settleLotsModalClose={() => setShowLiquidateInBatchModal(false)}
            id={selectedCharges}
            handleDiscardOrCancelBankSlips={handleDiscardOrCancelBankSlips}
            fetchAllCharges={fetchAllCharges}
            deselectAllCharges={deselectAllCharges}
          />
        </Table>
      </Card.Body>
    </Card>
  );
}
