import React from "react";
import { BankAccountStatusBadge } from "./BankAccountStatusBadge";

type BankAccount = {
    status: number;
    main_ac: number;
    nameReference: string;
    bankFullName: string;
    accountType: string;
    accountTypeDescription: string;
    agency: number;
    account: number | string;
    balance: string;
};

type BankAccountInfoProps = {
    bankAccount: BankAccount;
};

export function BankAccountInfo(props: BankAccountInfoProps) {
    const { bankAccount } = props;
    return (
        <div className="fs-12 d-flex flex-column gap-1">
            <div className="d-flex align-items-center">
                <BankAccountStatusBadge
                    isActive={bankAccount.status === 0}
                    isMainAccount={bankAccount.main_ac === 1}
                />
                <span className="ms-2 text-dark-80 fs-6">
                    {bankAccount.nameReference}
                </span>
            </div>
            {bankAccount.accountType !== "2" && (
                <>
                    <div>
                        <strong>Banco: </strong>
                        {bankAccount.bankFullName}
                    </div>
                    <div>
                        <strong>Tipo de conta: </strong>
                        {bankAccount.accountTypeDescription}
                    </div>
                    <div>
                        <strong>Agência: </strong>
                        {bankAccount.agency}
                        <strong className="ms-2">Conta: </strong>
                        {bankAccount.account}
                    </div>
                    <div>
                        <strong>Balanço: </strong>
                        R$ {bankAccount.balance}
                    </div>
                </>
            )}
        </div>
    );
}
