import React, { ComponentProps, ReactNode } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./style.css";

type DrawerProps = {
  placement?: ComponentProps<typeof Offcanvas>["placement"];
  show: boolean;
  onHide: () => void;
  children: ReactNode;
};

export function DrawerRoot({
  placement = "end",
  show,
  onHide,
  children,
}: DrawerProps) {
  return (
    <Offcanvas show={show} onHide={onHide} placement={placement} className="top-most">
      {children}
    </Offcanvas>
  );
}
