import { Form, Offcanvas, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { condosClose, administratorOpen, propertyOpen, insertCondos, condosOpen } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getCondos, getAdministrators } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { condosForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import NewAdministrator from './AdministratorService';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import customerService from '../../../../services/customerService';
import { CNJP_CPFValidation } from '../../../constants/utils';
const CondosService = (condosForm) => {
    const { administData, status } = useSelector((state: RootState) => state.propertyData)
    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { newCondosShow } = useSelector((state: RootState) => state.contractData)
    const [allStates, setAllStates] = useState([]);
    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, []);
    const [hide1, setHide1] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(true);
    const [admtype, setAdmType] = useState('')
    const step1 = () => { setHide1(true); setHide2(false); setHide3(false); }
    const step2 = (data: condosForm) => { setHide1(false); setHide2(true); setHide3(false); }
    const step3 = (data: condosForm) => { setHide1(false); setHide2(false); setHide3(true); }

    const newAdministratorOpen = () => {
        dispatch(administratorOpen(true))
        dispatch(condosClose(false))
        setAdmType('condos')
    }
    /*------------History modal hide function-----------*/

    const newCondosClose = () => {
        dispatch(propertyOpen(true));
        dispatch(condosClose(false));
        setDouble(false);
        setHide1(true);
        setHide2(false);
        setHide3(false);
        reset();
    }

    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const condosValidation1 = Yup.object().shape({
        name: Yup.string().trim()
        .required(t('requiredMmessage.fieldRequired')),
    });


    let frm = {
        resolver: yupResolver(condosValidation1)
    }
 

    /*------------Submit history form data-----------*/

    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<condosForm>(frm);
    const onSubmit = async (data: condosForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        let response = await dispatch(insertCondos(data));
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: response.payload.message,
                confirmButtonText: "OK"
                //timer : 2000
            })
            dispatch(getCondos())
            newCondosClose();
        }
        else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: response.payload.message,
                // icon: 'success',"error",
                confirmButtonText: "OK"
                // timer : 2000
            })
        }
    }
    const HandleInput = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('telephone');
        }
    }
    const [btn2, setBtn2] = useState(false);
    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
          `${inputType}/${newStr}`
        );
        let querySel: any = document.querySelector('.validZipcode [name="zipcode"]');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                setBtn2(false);
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                setBtn2(true);
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
            }
        } else {
            setBtn2(false);
            querySel.style.border = "0px solid red";
        }
    }
    const getLiquidatorLocation = async (value, inputType) => {
        let resp = await customerService.getAddressBasedOnZipcode(`${inputType}/${value}`);
        let querySel: any = document.querySelector('.liquidator [name="liquidator_zipcode"]');
        if (value != "") {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                setBtn2(false);
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                if (res[0]["city"]) {
                    setValue("liquidator_city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("liquidator_neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("liquidator_state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                    setValue("liquidator_address", data)
                }
                clearErrors("liquidator_zipcode");
            } else {
                setError("liquidator_zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                setBtn2(true);
                querySel.style.border = "2px solid red"
                setValue("liquidator_city", "")
                setValue("liquidator_neighborhood", "")
                setValue("liquidator_state", "")
                setValue("liquidator_address", "")
            }
        } else {
            setBtn2(false);
            querySel.style.border = "0px solid red";
        }
    }

    const handleCPF_CNJPVaue = (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
        } else {
            // errorCNPJCPF(e.target.value)
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Offcanvas className="border-sm-none" show={newCondosShow} onHide={newCondosClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">
                            <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t('property.newCondos')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p className="mb-lg-4 mb-3">{t('newContractDev.insertNewCondominium')}</p>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')+ " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.name') } className={`${errors.name ? 'is-invalid' : ''}`}  {...register('name')} />
                                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <FloatingLabel label={t('newContractDev.tradeName')}>
                                        <Form.Control type="text" placeholder={t('newContractDev.tradeName') } className={`${errors.fantasy_name ? 'is-invalid' : ''}`} {...register('fantasy_name')} />
                                        <Form.Control.Feedback type="invalid">{errors.fantasy_name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    {/*<InputGroup.Text onClick={newAdministratorOpen} title="condos">
                                    <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                </InputGroup.Text>*/}
                                </InputGroup>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cnpj')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cnpj')} className={`${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")} />
                                    <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.administrator')}>
                                        <Form.Select aria-label={t('formLabel.administrator')} className={`${errors.administrator ? 'is-invalid' : ''}`} {...register('administrator')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {administData && administData.length > 0 ? administData.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )) :
                                                <option value="">{t('formLabel.noRecordsFounds')}</option>
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.administrator?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text onClick={newAdministratorOpen}>
                                        <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20 cursor-pe" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={4}>
                                <InputGroup>
                                    <FloatingLabel className="validZipcode" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`${errors.zipcode ? 'is-invalid' : ''}`} {...register('zipcode')} onChange={(e) => getLocation(e, "zipcode")} maxLength={9} minLength={9} />
                                        <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text>
                                        <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                    <Form.Control type="text" placeholder={t('formLabel.address')} className={`${errors.address ? 'is-invalid' : ''}`} {...register('address')} />
                                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                    <Form.Control type="text" placeholder={t('formLabel.number')} className={`${errors.number ? 'is-invalid' : ''}`} {...register('number')} onChange={numericOnly} />
                                    <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                    <Form.Control type="text" placeholder={t('formLabel.complement')} className={`${errors.complement ? 'is-invalid' : ''}`} {...register('complement')} />
                                    <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                    <Form.Select aria-label={t('formLabel.state')} className={`${errors.state ? 'is-invalid' : ''}`} {...register('state')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {allStates.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                    <Form.Control type="text" placeholder={t('formLabel.city')} className={`${errors.city ? 'is-invalid' : ''}`} {...register('city')} />
                                    <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`${errors.neighborhood ? 'is-invalid' : ''}`} {...register('neighborhood')} />
                                    <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.contactInformation')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label="Cellphone (DDD + Number)">
                                    <Form.Control type="text" placeholder="Cellphone (DDD + Number)"  {...register('telephone')} onChange={HandleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('telephone')} onChange={HandleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" className={`${errors.email ? 'is-invalid' : ''}`} placeholder={t('formLabel.email')} {...register('email')} />
                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('others')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.billingMethod')}>
                                    <Form.Select aria-label={t('formLabel.billingMethod')} {...register('billing_method')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">Expired month</option>
                                        <option value="2">Month to expire</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.condoDueDate')}>
                                    <Form.Control type="date" placeholder={t('formLabel.condoDueDate')} {...register('due_date')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                    <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <div style={{ display: hide4 ? 'none' : 'block' }}>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('property.trustee')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                    <Form.Control type="text" placeholder={t('formLabel.name')} {...register('trustee_name')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('RG')}>
                                    <Form.Control type="text" placeholder={t('RG')} {...register('trustee_rg')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cpf')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('trustee_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('trustee_telephone')} onChange={HandleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('trustee_mobile')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} {...register('trustee_email')} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.liquidatorAddress')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={4}>
                                <InputGroup>
                                    <FloatingLabel className="mb-3 liquidator" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`${errors.liquidator_zipcode ? 'is-invalid' : ''}`}  {...register('liquidator_zipcode')} onChange={(e) => getLiquidatorLocation(e.target.value, "zipcode")} maxLength={8} minLength={8} onKeyUp={numericOnly} />
                                        <Form.Control.Feedback type="invalid">{errors.liquidator_zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <InputGroup.Text>
                                        <img src="./assets/images/icons/search.svg" className="h-16 cursor-pe" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                    <Form.Control type="text" placeholder={t('formLabel.address')} {...register('liquidator_address')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.number')}>
                                    <Form.Control type="text" placeholder={t('formLabel.number')} {...register('liquidator_number')} onChange={numericOnly} />
                                </FloatingLabel>
                            </Col>
                            <Col md={8}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                    <Form.Control type="text" placeholder={t('formLabel.complement')} {...register('liquidator_complement')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.state')}>
                                    <Form.Select aria-label={t('formLabel.state')} {...register('liquidator_state')}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {allStates.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                    <Form.Control type="text" placeholder={t('formLabel.city')} {...register('liquidator_city')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} {...register('liquidator_neighborhood')} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.caretaker')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                    <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('caretaker_name')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('RG')}>
                                    <Form.Control type="text" placeholder={t('RG')} {...register('caretaker_rg')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cpf')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('caretaker_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.caretakerContact')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} {...register('caretaker_telephone')} onChange={HandleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('caretaker_mobile')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} {...register('caretaker_email')} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.attorney')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                    <Form.Control type="text" placeholder={t('formLabel.name')} {...register('attorney_name')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('RG')}>
                                    <Form.Control type="text" placeholder={t('RG')} {...register('attorney_rg')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cpf')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('attorney_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.lawyerContact')}</h6>
                        <Row className="g-lg-4 g-3">
                            <Col md={12}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} {...register('lawyer_telephone')} onChange={HandleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('lawyer_mobile')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="email" placeholder={t('formLabel.email')} {...register('lawyer_email')} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        </div>
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {/* <div className="me-sm-2 mb-sm-0 mb-2 d-flex">
                                <Form.Group>
                                    <Form.Check type="checkbox" {...register('isAfter')} label="After saving register another" />
                                </Form.Group>
                            </div> */}
                        <Button variant="outline-primary" onClick={newCondosClose}>
                            {t('button.close')}
                        </Button>
                        {/* <Button variant="outline-primary" className="js-btn-step me-2" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)}  >
                            {t('button.previous')}
                        </Button>
                            <Button disabled={btn2 ? true : false} variant="primary" className="min-w-auto js-btn-step" onClick={handleSubmit(hide1 ? step2 : step3)}  >
                                {t('button.next')}
                            </Button>
                             */}
                        <Button variant="primary" type="submit" className="min-w-auto" onClick={openErrorbox}>
                            {t('button.save')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
            <NewAdministrator  title={admtype} setAdmType={setAdmType}/>
            {/* <!-- /New branch modal end --> */}
        </>
    )
}
export default CondosService;