import { Form, Accordion, Offcanvas, Modal, Button, Row, Col, FloatingLabel, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ownerClose, propertyOpen, insertOwner, GetRepresentativeService, getContractSetting } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getManager, getOwner } from "../../../../redux/slice/propertyDataSlice";
import { useTranslation } from 'react-i18next';
import { CustomersAndEmployeesForm, ownerForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import { getAllBillMethodListing } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, handleKeyDown, checkDuplicateCNJP_CPF, formatNumber, convertToNumber } from '../../../constants/utils';
import i18n from '../../../../i18n';
import RepresentativeService from './RepresentativeService';
import { add_Bank } from '../../../../redux/slice/bankSlice';
import customerService from '../../../../services/customerService';
import { integrateOwner } from '../../Contracts/Customer_and_employees/services/splitPayments/integrateOwner';


const OwnerService = (ownerForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { billingData } = useSelector((state: RootState) => state.expenditureData)
    const { banklist } = useSelector((state: RootState) => state.bank);
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    const [showHideField, setShowHideField] = useState<any>("")
    const [spouseHide, setSpouseHide] = useState(false)

    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(add_Bank())
        dispatch(getManager());
        handleRepresentative();
        dispatch(getContractSetting())
    }, [dispatch]);

    const [double, setDouble] = useState(false);
    const { id } = useParams();
    const [t] = useTranslation();
    const { newOwnerShow , ContractSettingData} = useSelector((state: RootState) => state.contractData)
    const [hide, setHide] = useState(false);
    const [hide1, setHide1] = useState("");
    const [pixTypeHide, setPixTypeHide] = useState(true);
    const [HidePixAlso, setHidePixAlso] = useState(false)
    const [AllStates, setAllStates] = useState([]);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [bankNotRequeire, setbankNotRequeire] = useState(false)
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const [repreData, setRepreData] = useState([])
    const [loader_on, setloader_on] = useState(false);
    const [checkhide, setCheckHide] = useState(false)
    const [valueCon, setValueCon] = useState('')
    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
    });

    /*------------History modal hide function-----------*/

    const newOwnerClose = () => {
        dispatch(propertyOpen(true));
        dispatch(ownerClose(false));
        setHide(false)
        setHide2(false);
        setHide3(false);
        setloader_on(false);
        setShowHideField("");
        setSpouseHide(false);
        reset();
    }

    /*------------Form validation-----------*/
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    let regexPattern = /^[^0-9./-]*$/;
    if (showHideField == "cnpj") {
        regexPattern = /^[a-zA-Z0-9 ]*$/;
    }
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const regexNotPattern = /^[0-9]+$/;

    var formValidationSchemaStep = Yup.object().shape({
        name: Yup.string()
            .trim()
            .nullable()
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(), // No validation if the field is empty
        // email: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .email(t('requiredMmessage.fieldNotValid')),
        // payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        // password: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(passwordRules, { message: t("requiredMmessage.password") }),
    })

    var formValidationSchemaStep2 = Yup.object().shape({
        name: Yup.string()
            .trim()
            .nullable()
            .matches(regexPattern, { message: t('requiredMmessage.numbernotallow') })
            .required(t('requiredMmessage.fieldRequired')),
        mobile: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
            .matches(/\(\d{2}\) \d{5}-\d{4}/, `${t("requiredMmessage.mobileError")}`),
        // email: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .email(t('requiredMmessage.fieldNotValid')),
        payment: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        // password: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(passwordRules, { message: t("requiredMmessage.password") }),
        bankname: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_acocunt: Yup.string().trim()
            .test('is-valid', "Por favor, insira apenas números", (value: any) => {
                return regexNotPattern.test(value);
            }).nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bank_agency: Yup.string().trim().nullable()
            .test('is-valid', "Por favor, insira apenas números", (value: any) => {
                return regexNotPattern.test(value);
            }).required(t("requiredMmessage.fieldRequired")).min(4, "Agency must be at least 4 characters long"),
        bank_accountType: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        address: Yup.string().trim().nullable()
            .max(40, t('requiredMmessage.40characters')), // Apply validation only if the field has content
        otherwise: Yup.string().nullable(),
    })

    let frm = {
        resolver: yupResolver(formValidationSchemaStep2)
    }
    // if (bankNotRequeire){
    //     frm = {
    //         resolver: yupResolver(formValidationSchemaStep)
    //     }
    // }
    if (!pixTypeHide) {
        frm = { resolver: yupResolver(formValidationSchemaStep) }
    }

    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });

    /*------------Submit history form data-----------*/

    const { register, reset, handleSubmit, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<ownerForm>(frm);
    const onSubmit = async (data: ownerForm, e: any) => {
        e.preventDefault();
        setloader_on(true);
        let bankName = '';
        banklist.forEach((curElem: any, index: number) => {
            if (curElem.bank_code == data.bankname) {
                bankName = curElem.bank_name;
            }
        })
        setDouble(true)
        let bloquendo: any = convertToNumber(valueCon ? String(valueCon) : String(data.bloquendo));
        let owner: any = {
            type: "New Owner",
            cnpj: data.cnpj,
            name: data.name,
            payment: data.payment,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email,
            password: data.password,
            mother: data.mother,
            father: data.father,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            marital_status: data.marital_status,
            nationality: data.nationality,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            employment_company: data.employment_company,
            employment_profession: data.employment_profession,
            employment_field_activity: data.employment_field_activity,
            employment_monthly_income: data.employment_monthly_income,
            employment_zipcode: data.employment_zipcode,
            employment_address: data.employment_address,
            employment_number: data.employment_number,
            employment_complement: data.employment_complement,
            employment_neighborhood: data.employment_neighborhood,
            employment_city: data.employment_city,
            employment_state: data.employment_state,
            representative_name: data.representative_name,
            retention: data.retention,
            observation: data.observation,
            is_vendor: data.is_vendor,
            corporateReason: data.corporateReason,
            stateRegistration: data.stateRegistration,
            municipalRegistration: data.municipalRegistration,

            bank_Name: bankName,
            bankname: data.bankname,
            bank_acocunt: `${data.bank_acocunt}-${data.bankAccountDigit ? data.bankAccountDigit : "0"}`,
            bank_agency: data.bank_agency,
            bank_accountType: data.bank_accountType,
            bank_branchCode: data.bank_branchCode,
            name_of: data.name_of,
            cnpjcpf: data.cnpj,

            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            sup_KeyType: data.pixKeyType,
            Keyfor_addressing: data.pixKey,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: bloquendo ?? ""
                }
            }
        }

        let response = await dispatch(insertOwner(owner));
        console.log("response---->", response)
        if (typeof response.payload !== "undefined" && response.payload.resCode === 202) {
            const ownerId = response.payload.data.length > 1 ? response.payload.data[1].insertedId : response.payload.data[0].insertedId;
            integrateOwner(ownerId).catch(err => {
              console.log("Falha ao integrar proprietário com pagamentos split", err)
            })
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
                timer: 2000
            })
            setTimeout(() => {
                dispatch(getOwner())
            }, 2000)
            newOwnerClose();
            setloader_on(false);
        } else {
            let error = response.payload.message;
            // if (response.payload.data && response.payload.data?.data && response.payload.data.data.length) {
            //     error = response.payload.data.data[0].message
            // }
            // if (response.payload.data && response.payload.data?.statusCode == 409) {
            //     error = response.payload.data.message
            // }
            // if (response.payload.resCode === 400 && response.payload.message == 'Owneremail!=AgencyEmail') {
            //     error = t("ownerEmailnotEqualAgencyEmail");
            // }
            setDouble(false)
            setloader_on(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: error ? error : t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    const HandleInput = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('telephone');
        }
    }

    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        setSpouseHide(false);
        if (checkCNJP?.["status"]) {
            if (e.target.value !== "") {
                clearErrors(name)
                setShowHideField(checkCNJP?.["type"]);
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                let boolVal = false
                boolVal = duplicateData["data"][0]?.FL_PROPRIETARIOBENEFICIARIO_PES == 1
                const fieldsMapping: any = {
                    email: "ST_EMAIL_PES",
                    name: "ST_NOME_PES",
                    mobile: "ST_CELULAR_PES",
                    complement: "ST_COMPLEMENTO_PES",
                    zipcode: "ST_CEP_PES",
                    city: "ST_CIDADE_PES",
                    address: "ST_ENDERECO_PES",
                    state: "ST_ESTADO_PES",
                    number: "ST_NUMERO_PES",
                    neighborhood: "ST_BAIRRO_PES",
                    telephone: "ST_TELEFONE_PES",
                    observation: "ST_OBSERVACAO_PES",
                    nationality: "ST_NACIONALIDADE_PES",
                    sex: "ST_SEXO_PES",
                    birth: "DT_NASCIMENTO_PES",
                    marital_status: "ST_ESTADOCIVIL_PES",
                    employment_company: "ST_TRABALHOCEP_PES",
                    employment_profession: "ST_PROFISSAO_PES",
                    employment_field_activity: "ST_RAMOATIVIDADE_PES",
                    employment_address: "ST_TRABALHOENDERECO_PES",
                    employment_number: "ST_TRABALHONUMERO_PES",
                    employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                    employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                    employment_city: "ST_TRABALHOCIDADE_PES",
                    employment_state: "ST_TRABALHOESTADO_PES",
                    employment_monthly_income: "VL_RENDAMENSAL_PES",
                    rg: "ST_RG_COJ",
                    retention: "VL_TXISSQN_PES",
                    mother: "ST_NOMEMAE_PES",
                    father: "ST_NOMEPAI_PES",
                    shipping_date: "DT_EXPEDICAORG_PES",
                    payment: "ID_FORMARECEBIMENTO_PES",
                    bankname: "ST_BANCO_PES",
                    bank_agency: "ST_AGENCIABANCO_PES",
                    bank_acocunt: "ST_CONTA_PES",
                    bank_accountType: "ST_TIPOCONTA_PES",
                    bank_branchCode: "ST_OPERACAO_PES",
                    name_of: "ST_NOMERECEBEDOR_PES",
                    block_balance: "VL_SALDOBLOQUEADO_PES",
                    manager: "ID_GESTOR_GES",
                    issue_agency: "ST_ORGAO_PES",
                    corporateReason: "ST_NOME_PES",
                    stateRegistration: "ST_IE_PES",
                    municipalRegistration: "ST_INSCMUNICIPAL_PES"
                };
                if (duplicateData.data.length > 0) {
                    setDouble(true);
                    if (boolVal) {
                        setError(name, {
                            type: "manual",
                            message: t("formLabel.ErrorCNPJCPF")
                        });
                        setcnpjcpferrormessage({
                            name: name,
                            message: t("formLabel.ErrorCNPJCPF")
                        })
                    } else {
                        setHide(true)


                        // Assuming `duplicateData` is correctly populated
                        const data: any = duplicateData?.["data"]?.[0];
                        if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as any, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV', data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC', data?.ID_SACADO_SAC)
                            });
                        }

                    }
                } else {
                    Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
                        setValue(fieldName as any, "");
                        setValue('ID_FAVORECIDO_FAV', "")
                        setValue('ID_SACADO_SAC', "")
                    });

                    setHide(true)
                    setHide1(checkCNJP?.["type"])
                    setDouble(false);
                }
            }
        } else {
            setHide(false)
        }
        if (checkCNJP?.["status"] || e.target.value == "") {

        } else {
            setDouble(true)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }

    const handleChangeMaritail = (e) => {
        setSpouseHide(false);
        if (e.target.value == "1" || e.target.value == "7") {
            setSpouseHide(true);
        }
    }

    /*----------Open the new representative modal form------------*/
    const [showNewRepresentativeModal, setNewRepresentativeModal] = useState(false);
    const newRepresentativeModalClose = () => {
        setNewRepresentativeModal(false);
        dispatch(ownerClose(true));
    }
    const newRepresentativeModalShow = () => {
        setNewRepresentativeModal(true);
        dispatch(ownerClose(false));
    }
    const getBankCode = (bankCode) => {
        if (bankCode !== "") {
            clearErrors("bankname")

        } else {
            setError("bankname", {
                type: "manual",
                message: `${(t('requiredMmessage.fieldRequired'))}`
            })
        }
        setValue("bank_branchCode", bankCode);
    }
    const ownerPaymentHide = (evt) => {
        setHidePixAlso(false);
        let payment = evt.target.value
        if (payment !== "") {
            clearErrors("payment")
        } else {
            setError(payment, {
                type: "manual",
                message: `${(t('requiredMmessage.fieldRequired'))}`
            })
        }
        if (evt.target.value == 12) {
            setPixTypeHide(false);
            setCheckHide(false)
        } else if (evt.target.value == 4) {
            setPixTypeHide(false);
            setHidePixAlso(true);
        } else if (evt.target.value == 3) {
            setPixTypeHide(false);
            setbankNotRequeire(false)
            setCheckHide(true)
        }
        else {
            setCheckHide(false)
            setPixTypeHide(true);
        }
        if (evt.target.value !== "0" || evt.target.value !== "12") {
            setbankNotRequeire(true)
            clearErrors('bankname')
            clearErrors('bank_acocunt')
            clearErrors('bank_agency')
            clearErrors('bank_accountType')
        } else {
            setbankNotRequeire(false)
        }

    }
    const [update, setUpdate] = useState(1);
    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [update]);
    useEffect(() => {
        if (ContractSettingData && ContractSettingData.length > 0) {
        
          if (ContractSettingData[0]?.ir_desconto_simplificado_loc == "1") {
            setValue("enableSimplifiedDeduction", true);
          } else {
            setValue("enableSimplifiedDeduction", false);
          }
        }
      }, [ContractSettingData])
    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');

        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str.trim(),
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str.trim()
            })
        }

        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                } else {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("employment_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("employment_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("employment_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("employment_address", data)
                    }
                }

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                } else {
                    querySelsecond.style.border = "2px solid red"
                    setValue("employment_city", "")
                    setValue("employment_neighborhood", "")
                    setValue("employment_state", "")
                    setValue("employment_address", "")
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }
    const handleNameof = (e) => {
        let { value } = e.target
        if (!checkhide) {
            setValue("name_of", value)
        }
        // Error validation
        if (value == "" || value == null || value == undefined) {
            // Set an error message using setError or update your error state
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('name');
        }
    }

    const handleRepresentative = async () => {
        let resp = await dispatch(GetRepresentativeService())
        if (resp.payload.type == "success" || resp.payload.type == "Success") {
            setRepreData(resp.payload.data)
        }
    }

    const handleChangeInput = (e: any, index: any, type: string, field: string) => {
        let value = e.target.value;
        addState[type][index][field] = value;
        let allState: any = addState;
        setAddState(allState);
    }

    const addAnother = (e: any, type: string) => {
        e.preventDefault();
        if (type == "retentionn") {
            let dataAdd: any = {
                ...addState,
                retentionn: [
                    ...addState["retentionn"],
                    { Tax: "", Aliquot: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "representative") {
            let dataAdd: any = {
                ...addState,
                representative: [
                    ...addState["representative"],
                    { name: "" }
                ]
            }
            setAddState(dataAdd)
        }
    }

    const removeAddAnother = (index: any, array: any, type: any) => {
        array.splice(index, 1);
        // reset(type);
        if (type == "retentionn") {
            array.forEach((item: any, index: number) => {
                let Tax: any = `retentionn.${index}.tax`;
                let Aliquot: any = `retentionn.${index}.Aliquot`;
                setValue(Tax, item.Tax);
                setValue(Aliquot, item.Aliquot);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "beneficiaries") {
            array.forEach((item: any, index: number) => {
                let name: any = `beneficiaries.${index}.name`;
                let transfer: any = `beneficiaries.${index}.transfer`;
                setValue(name, item.name);
                setValue(transfer, item.transfer);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "representative") {
            array.forEach((item: any, index: number) => {
                let name: any = `representative.${index}.name`;
                setValue(name, item.name);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }
    }
    const handleRepersen = (e: any, index) => {
        let target_name = e.target.name;
        let target_value = e.target.value;
        addState["representative"][`${index}`]["name"] = String(target_value);
        setAddState(addState)

    }

    const Handlebloquendo = (e) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        setValue("bloquendo", formatNumber(value))
        setValueCon(value)
    }
    const Handleaddress = (e) => {
        let { value } = e.target;
        let numberOfCharacters = value.length;
        // Handle the character length validation
        if (numberOfCharacters > 40) {
            setError("address", {
                type: "manual",
                message: t("requiredMmessage.40characters")
            });
        } else {
            clearErrors("address")
        }
    };
    return (
        <>
            {/* <!-- New branch modal start from here --> */}
            <Offcanvas className="border-sm-none ownerservicetsxTemplate" show={newOwnerShow} onHide={newOwnerClose} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        <Offcanvas.Title className="fw-semibold">
                            <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />{t("customerAndEmployees.Newownerorbeneficiary")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <input type="text" {...register('ID_SACADO_SAC')} hidden />
                        <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden />
                        <p className="mb-lg-4 mb-3">{t('newContractDev.insertNewOwnerOrBeneficiary')}</p>
                        <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingInput" label={t('formLabel.cnpjCpf') + " *"} >
                            <Form.Control type="text" placeholder={t('formLabel.cnpjCpf') + " *"} className={`${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                            <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        {hide ?
                            <>
                                {hide1 !== "cpf" ?
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t('newContractDev.corporateName') + " *"}>
                                                <Form.Control type="text" placeholder={t('newContractDev.corporateName') + " *"} className={`${errors.corporateReason ? 'is-invalid' : ''}`} {...register('name')} onChange={(e) => handleNameof(e)} />
                                                <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t('newContractDev.tradeName')}>
                                                <Form.Control type="text" placeholder={t('newContractDev.tradeName')}  {...register('tradeName')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t('formLabel.stateregistration')}>
                                                <Form.Control type="text" maxLength={20} placeholder={t('formLabel.stateregistration')} className={`${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                                <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="floatingInput" label={t('formLabel.municipalregistration')}>
                                                <Form.Control type="text" maxLength={20} placeholder={t('formLabel.municipalregistration')} className={`${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                                <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row> :
                                    <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                                        <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                        <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                }
                                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.payment')}</h6>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.defaultPaymentMethod') + " *"} onChange={(e) => ownerPaymentHide(e)}>
                                    <Form.Select className={` ${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')} >
                                        <option value="">{t('formLabel.select')}</option>
                                        <option value="0">{t('customerAndEmployees.splitpaymentKenloPay')}</option>
                                        <option value="12">{t("customerAndEmployees.pix")}</option>
                                        <option value="5">{t('customerAndEmployees.bankTransfer')}</option>
                                        <option value="6">{t('customerAndEmployees.docTed')}</option>
                                        <option value="2">{t('customerAndEmployees.cashDeposit')}</option>
                                        <option value="1">{t('customerAndEmployees.chequeDeposit')}</option>
                                        <option value="3">{t('customerAndEmployees.pickUpCheque')}</option>
                                        <option value="4">{t('customerAndEmployees.withdrawMoney')}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.payment?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                                <Row>
                                    <Col>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('Repassedia')}>
                                            <Form.Control type="tel" placeholder={t('Repassedia')}   {...register('Repassedia')} />
                                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('bloquendo')}>
                                            <Form.Control type="text" placeholder={t('bloquendo')}  {...register('bloquendo')} onChange={(e) => Handlebloquendo(e)} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                {(!pixTypeHide && !HidePixAlso) ?
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="pixKeyType" label={t("banks.pixkeyType")}>
                                                <Form.Select className={` ${errors.pixKeyType ? 'is-invalid' : ''}`} {...register('pixKeyType')} aria-label={t("banks.pixkeyType")}>
                                                    <option value="">{t('dropdownItem.select')}</option>
                                                    <option value="1">CPF</option>
                                                    <option value="2">CNPJ</option>
                                                    <option value="3">Celular</option>
                                                    <option value="4">E-mail</option>
                                                    <option value="5">Aleatoria</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">{errors.pixKeyType?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        {checkhide ?
                                            <Col md={6}>
                                                <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof")}>
                                                    <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")}   {...register('name_of')} />
                                                </FloatingLabel>
                                            </Col>
                                            :
                                            <Col md={6}>
                                                <FloatingLabel controlId="pixKey" label={t("banks.pixKey")}>
                                                    <Form.Control type="text" placeholder={t('customerAndEmployees.PixKey')} className={` ${errors.pixKey ? 'is-invalid' : ''}`}  {...register('pixKey')} />
                                                    <Form.Control.Feedback type="invalid">{errors.pixKey?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        }
                                    </Row>
                                    : ""
                                }

                                {pixTypeHide ?
                                    <>
                                        <Form.Floating className="mb-4">
                                            <Form.Select className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"  {...register("bankname")} onChange={(e) => getBankCode(e.target.value)} >
                                                <option value=''>{t("formLabel.select")}</option>
                                                {banklist.map((curElem: any, index: number) =>
                                                    <option key={index} value={curElem.bank_code}>{curElem.bank_code} - {curElem.bank_name}</option>
                                                )}
                                            </Form.Select >
                                            <Form.Label htmlFor="floatingInputValue">{t("banks.name") + " *"}</Form.Label >
                                            <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback >
                                        </Form.Floating>
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                            <Col md={6}>
                                              <Form.Floating>
                                                <Form.Control
                                                  className={`${errors.bank_acocunt ? 'is-invalid' : ''}`}
                                                  id="account"
                                                  placeholder="Conta"
                                                  {...register("bank_acocunt")}
                                                  autoComplete="off"
                                                  onChange={(e: any) => {
                                                    const newValue = e.target.value;
                                                    setValue("bank_acocunt", newValue.replace(/\D/, ""))
                                                  }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.bank_acocunt?.message}</Form.Control.Feedback >
                                                <Form.Label htmlFor="floatingInputValue">Conta *</Form.Label>
                                              </Form.Floating>
                                            </Col>
                                            <Col md={3}>
                                              <Form.Floating>
                                                <Form.Control
                                                  id="bankAccountDigit"
                                                  placeholder="Dígito"
                                                  maxLength={1}
                                                  {...register("bankAccountDigit")}
                                                />
                                                <Form.Label htmlFor="floatingInputValue">Dígito</Form.Label>
                                              </Form.Floating>
                                            </Col>
                                            <Col md={3}>
                                              <Form.Floating>
                                                <Form.Control
                                                  className={`${errors.bank_agency ? 'is-invalid' : ''}`}
                                                  id="bank_agency"
                                                  placeholder="Agência"
                                                  {...register("bank_agency")}
                                                  maxLength={4}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.bank_agency?.message}</Form.Control.Feedback >
                                                <Form.Label htmlFor="floatingInputValue">Agência *</Form.Label>
                                              </Form.Floating>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('banks.accountType') + " *"}>
                                                    <Form.Select className={`${errors.bank_accountType ? 'is-invalid' : ''}`}  {...register('bank_accountType')} aria-label="Account Type">
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t('currentAccount')}</option>
                                                        <option value="2">{t('SavingAccount')}</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.bank_accountType?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="bank_branchCode" label={t("customerAndEmployees.BranchCode")}>
                                                    <Form.Control disabled type="text" placeholder={t("customerAndEmployees.BranchCode")} className={` ${errors.bank_branchCode ? 'is-invalid' : ''}`}  {...register('bank_branchCode')} />
                                                    <Form.Control.Feedback type="invalid">{errors.bank_branchCode?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="name_of" label={t("customerAndEmployees.Inthenameof")}>
                                                    <Form.Control type="text" placeholder={t("customerAndEmployees.Inthenameof")} className={` ${errors.name_of ? 'is-invalid' : ''}`}  {...register('name_of')} />
                                                    <Form.Control.Feedback type="invalid">{errors.name_of?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </>
                                    : ""}

                                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.contact')}</h6>
                                <Row className="g-3 g-lg-4">
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingSelect" label={t('formLabel.mobileNumber') + " *"}>
                                            <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} className={`${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                            <div className="invalid-feedback">{errors.mobile?.message}</div>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingSelect" label={t('formLabel.telephone')}>
                                            <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                            <div className="invalid-feedback">{errors.telephone?.message}</div>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingSelect2" label={t('formLabel.email')}>
                                            <Form.Control type="email" placeholder={t('formLabel.email')} className={`${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel controlId="floatingInput" label={t('formLabel.password')} className="mb-3" >
                                            <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                                            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row> 
                                    <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('Beneficiaries')}</h6>
                                    {
                                        Object.keys(addState.beneficiaries).map((item: any, index: number) => {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col md={5}>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={t('formLabel.name')}
                                                                    {...register(`beneficary.${index}.name`)}
                                                                    onChange={(e) => handleChangeInput(e, index, "beneficiaries", "name")}
                                                                />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={5}>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('transfer')}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={t('transfer')}
                                                                    {...register(`beneficary.${index}.transfer`)}
                                                                    onChange={(e) => handleChangeInput(e, index, "beneficiaries", "transfer")}
                                                                />
                                                            </FloatingLabel>
                                                        </Col>
                                                        {
                                                            Object.keys(addState.beneficiaries) &&
                                                            Object.keys(addState.beneficiaries).length > 1 &&
                                                            <Col md={2}>
                                                                <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.beneficiaries, "beneficiaries")}>
                                                                    <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </>
                                            )
                                        })
                                    }

                                    <Button variant="link" className="fw-bold p-0" onClick={(e) => addAnother(e, "beneficiaries")}  >
                                        {t("newContractDev.AddAnother")}
                                    </Button>
                                </Row>
                                {showHideField == "cpf" ?
                                    <>
                                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.affiliation')}</h6>
                                        <Row className="g-3 g-lg-4">
                                            <Col>
                                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.motherName')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.motherName')}  {...register('mother')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.fatherName')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.fatherName')}  {...register('father')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </>
                                    : ""
                                }
                                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.address')} </h6>
                                <div className='accordion-custom'>
                                    <Row className="g-3 g-lg-4 ">
                                        <Col md={4}>
                                            <InputGroup>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                                    <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} maxLength={9} minLength={9} onChange={(e) => getLocation(e, "zipcode", "first")} />
                                                </FloatingLabel>
                                                <InputGroup.Text>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Search" className="h-18 cursor-pe" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col md={8}>
                                           
                                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.address')}>
                                                <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-property-id">{t("requiredMmessage.40characters")}</Tooltip>}
                                            >
                                                    <Form.Control type="text" placeholder={t('formLabel.address')} className={`${errors.address ? 'is-invalid' : ''}`} {...register('address')} onChange={(e) => Handleaddress(e)} />
                                                    </OverlayTrigger>
                                                    <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingSelect" label={t('number')}>
                                                <Form.Control type="text" placeholder={t('number')}  {...register('number')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={8}>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')} {...register('complement')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {
                                                        AllStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.city')}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={4}>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </div>
                                {hide1 === "cpf" &&
                                    <>
                                        <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.employmentInformation')}</h6>
                                        <Row className="g-lg-4 g-3">
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.company')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.company')}  {...register('employment_company')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('occupation')}>
                                                    <Form.Control type="text" placeholder={t('occupation')} {...register('employment_profession')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.businessIndustry')}>
                                                    <Form.Control type="text" placeholder={t('newContractDev.businessIndustry')}  {...register('employment_field_activity')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.monthlyIncome')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.monthlyIncome')}  {...register('employment_monthly_income')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <InputGroup>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}  {...register('employment_zipcode')} onChange={(e) => getLocation(e, "zipcode", "second")} />
                                                    </FloatingLabel>
                                                    <InputGroup.Text>
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/search.svg"} alt="Search" className="h-18 cursor-pe" />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                            <Col md={8}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('employment_address')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('employment_number')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={8}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('employment_complement')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select {...register('employment_state')} aria-label={t('formLabel.state')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        {
                                                            AllStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('employment_city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={4}>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('employment_neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('newContractDev.representatives')}</h6>
                                {/* <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col xs>
                                        <InputGroup>
                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                                <Form.Select  {...register('representative_name')} onClick={handleRepresentative}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {repreData?.map((curElem: any, index) => (
                                                        <option key={index} value={curElem.id}>{curElem.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                            <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                    <Col xs="auto">
                                        <Button className="btn btn-link bg-light min-w-auto border-light rounded-4">
                                            <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-20 cursor-pe" />
                                        </Button>
                                    </Col>
                                </Row> */}
                                <Row>
                                    {
                                        Object.keys(addState.representative).map((item: any, index: number) => {
                                            return (
                                                <Row className="mt-1">
                                                    <Col xs={11}>
                                                        <InputGroup>
                                                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.name')}>
                                                                <Form.Select {...register(`representative.${index}.name`)} onClick={handleRepresentative} onChange={(e) => handleRepersen(e, index)}>
                                                                    <option value="">{t('formLabel.select')}</option>
                                                                    {repreData?.map((curElem: any, index) => (
                                                                        <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                            <InputGroup.Text onClick={newRepresentativeModalShow}>
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/user-edit.svg"} alt="Documents add" className="h-20 cursor-pe" />
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                        {
                                                            Object.keys(addState.representative) &&
                                                            Object.keys(addState.representative).length > 1 &&
                                                            <Col md={1}>
                                                                <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.representative, "representative")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                            </Col>
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    < Col >
                                        <div className="text-center mt-1">
                                            <Button variant='link' className="p-0 fw-bold" onClick={(e) => addAnother(e, "representative")}>
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} alt="add icon" className="h-18 cursor-pe me-1" />{t('newContractDev.addAnotherRepresentative')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.taxesRetention')}</h6>
                                <Form.Group controlId="domiciled" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-lg-4 mb-3">
                                    <Form.Check type="checkbox" {...register('retention')} label={t('formLabel.withHoldIssqn')} />
                                </Form.Group>
                                <Form.Group controlId="simplified" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center mb-lg-4 mb-3">
                                    {/* <Form.Check type="checkbox" label={t('newContractDev.enableSimplifiedDeduction')} /> */}
                                    <Form.Check type="checkbox"  {...register('enableSimplifiedDeduction')} label={t('newContractDev.enableSimplifiedDeduction')} className='mt-1' />
                                    <span className="d-block fs-12 text-secondary ms-4 ps-1">{t('newContractDev.enableSimplifiedDeductionContent')}</span>
                                </Form.Group>
                                {
                                    showHideField == "cpf" ?
                                        <>
                                            <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('paidTobepaid.Others')}</h6>
                                            <Row className="g-3 g-lg-4">
                                                <Col md={4}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('newContractDev.RGNumber')}>
                                                        <Form.Control type="text" placeholder={t('newContractDev.RGNumber')} {...register('rg')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={4}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.issuingAgency')} {...register('issue_agency')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={4}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.shippingDate')}>
                                                        <Form.Control type="date" placeholder={t('formLabel.shippingDate')} {...register('shipping_date')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('newContractDev.gender')}>
                                                        <Form.Select {...register('sex')} aria-label={t('newContractDev.gender')}>
                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                            <option value="1">{t('masculine')}</option>
                                                            <option value="2">{t('feminine')}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.birth')}>
                                                        <Form.Control type="date" placeholder={t('formLabel.birth')}  {...register('birth')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.maritalStatus')}>
                                                        <Form.Select {...register('marital_status')} aria-label={t('formLabel.maritalStatus')} onChange={(e) => handleChangeMaritail(e)} >
                                                            <option value="">{t('formLabel.select')}</option>
                                                            <option value="1">{t("formLabel.married")}</option>
                                                            <option value="2">{t("formLabel.single")}</option>
                                                            <option value="3">{t("formLabel.widower")}</option>
                                                            <option value="4">{t("formLabel.legallySeparated")}</option>
                                                            <option value="5">{t("formLabel.disquitado")}</option>
                                                            <option value="6">{t("formLabel.divorced")}</option>
                                                            <option value="7">{t("formLabel.stableunion")}</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                                {/* <Col md={4}>
                                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.dateOfBirth')}>
                                                    <Form.Control type="date" placeholder={t('newContractDev.dateOfBirth')} {...register('birth')} />
                                                </FloatingLabel>
                                            </Col> */}
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="Birthplace" label={t('formLabel.Birthplace')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.birth')}  {...register('birth_place')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={6}>
                                                    <FloatingLabel controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.nationality')} {...register('nationality')} />
                                                    </FloatingLabel>
                                                </Col>
                                                {/* <Col md={6}>
                                                <FloatingLabel controlId="floatingSelect" label={t('newContractDev.placeOfBirth')}>
                                                    <Form.Control type="text" placeholder={t('newContractDev.placeOfBirth')} />
                                                </FloatingLabel>
                                            </Col> */}
                                                <Col md={6}>
                                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('dependentsNo')}>
                                                        <Form.Control type="text" placeholder={t('dependentsNo')}  {...register('dependentsNo')} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group controlId="domiciled_brazi" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                        {/* <Form.Check type="checkbox" label={t('newContractDev.notDomiciledBrazi')} /> */}
                                                        <Form.Check type="checkbox" {...register('non_recedential')} label={t('non_recedential')} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group controlId="billing_rule" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                                        {/* <Form.Check type="checkbox" label={t('newContractDev.doNotNotifyBillingRule')} /> */}
                                                        <Form.Check type="checkbox" {...register('donotnotify')} label={t('donotnotify')} />

                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                                        <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                                            <option value="">{t('formLabel.select')}</option>
                                                            {managerData?.map((item) => (
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            {spouseHide ?
                                                <>
                                                    <h6 className="fw-bold mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">{t('formLabel.spouse')}</h6>
                                                    <Row className="mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                                <Form.Control type="text" placeholder={t('RG')}  {...register('spouse_rg')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                                <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                                    <option value="1">{t('masculine')}</option>
                                                                    <option value="2">{t('feminine')}</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={('formLabel.cellPhone')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.cellPhone')}  {...register('spouse_cellphone')} onChange={HandleInputMob} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} onChange={HandleInput} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                                <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                                <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : ''}
                                            <Row className="mb-3 mb-lg-4 mt-lg-4 mt-3 pt-1">
                                                <Col md={12}>
                                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                                        <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                                    </FloatingLabel>
                                                </Col>
                                                <Col md={12} className='mt-2'>
                                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.Accountingintegration')}>
                                                        <Form.Control type="text" placeholder={t('formLabel.Accountingintegration')} {...register('accounting_system')} />
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </>
                                        : ""
                                }
                            </>
                            :
                            ""
                        }
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        {/* <div className="me-sm-2 mb-sm-0 mb-2 d-flex">
                        <Form.Group>
                        <Form.Check type="checkbox" {...register('isAfter')} label={t('button.afterSaving')} />
                        </Form.Group>
                        </div> */}
                        <Button variant="outline-primary" className="btn btn-outline-primary rounded-4" onClick={newOwnerClose}>
                            {t('button.close')}
                        </Button>
                        {/* <Button variant="secondary" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)} className="btn btn-outline-primary js-btn-step me-2" >
                        {t('button.previous')}
                        </Button>
                        <Button variant="secondary" onClick={handleSubmit(hide ? step2 : step3)} className="btn btn-primary min-w-auto js-btn-step" >
                        {t('button.next')}
                        </Button> */}
                        {
                            loader_on ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <Button className="min-w-auto" variant="primary" type="submit" onClick={openErrorbox}>
                                    {t('button.save')}
                                </Button>
                        }
                    </div>
                </Form>
            </Offcanvas >
            {/* <!-- /New branch modal end --> */}
            {/*  */}
            <RepresentativeService showNewRepresentative={showNewRepresentativeModal} newRepresentativeClose={newRepresentativeModalClose} newRepresentativeShow={newRepresentativeModalShow} />
        </>
    )
}
export default OwnerService;
