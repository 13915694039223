export const TAB_PANES = {
  charges: "cobrancas",
  homologatedAccount: "conta-homologada",
} as const

export const ID_FORMAPAGAMENTO_RECB = {
  homologatedBankSlip: 28,
}

export const DEFAULT_PAGE_SIZE = 20;
