import axios from "axios";

import { config } from "../../../../../../services/apiConfig";

export async function integrateOwner(ownerId: string) {
  try {
    const response = await axios.post(
      `${config.TRANSFER_API}/split-payments/integrate-owner/${ownerId}`
    );

    if (response.status !== 200) {
      if (response && response?.data?.message) {
        return {
          type: "error",
          message: response.data.message,
        };
      }
      throw new Error()
    }

    return {
      type: "success",
      message: response.data.message,
    };
  } catch (err: any) {
    console.log("err", err);

    return {
      type: "error",
      message:
        "Falha ao integrar proprietário com pagamentos split.",
    };
  }
}

