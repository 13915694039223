import { create } from "zustand";

type TransferAccountDrawerState = {
  transferAccountDrawerAction: "new" | undefined;
  transferData: any;
  setShowTransferAccountDrawer: ({
    action,
    transferData,
  }: {
    action: "new" | undefined;
    transferData?: any;
  }) => void;
};

const useTransferAccountStore = create<TransferAccountDrawerState>((set) => ({
  transferAccountDrawerAction: undefined,
  transferData: undefined,

  setShowTransferAccountDrawer: ({ action, transferData }) =>
    set({ transferAccountDrawerAction: action, transferData }),
}));

export function useTransferAccountDrawer() {
  return useTransferAccountStore((state) => state);
}
