import React from "react";

import { TransferAccountProvider } from "../TransferAccountForm";
import { TransferAccountDrawerContainer } from "./TransferAccountDrawerContainer";

export function TransferAccountDrawer() {
  return (
    <TransferAccountProvider>
      <TransferAccountDrawerContainer />
    </TransferAccountProvider>
  );
}
