import React from "react";
import { ProgressBar } from "react-bootstrap";

import "./styles.css";

const bankSlipStatus = {
  SALVO: {
    color: "success",
    progress: 0,
    feedbackMessage: "Boleto em processamento..."
  },
  FALHA: {
    color: "warning",
    progress: 33,
    feedbackMessage: "Boleto com falha"
  },
  EMITIDO: {
    color: "success",
    progress: 33,
    feedbackMessage: "Boleto gerado"
  },
  PENDENTE_RETENTATIVA: {
    color: "warning",
    progress: 0,
    feedbackMessage: "Boleto pendente de retentativa"
  },
  REGISTRADO: {
    color: "success",
    progress: 100,
    feedbackMessage: "Boleto registrado no banco"
  },
  REJEITADO: {
    color: "warning",
    progress: 100,
    feedbackMessage: "Erro ao gerar boleto"
  },
  BAIXADO: {
    color: "canceled",
    progress: 100,
    feedbackMessage: "Boleto cancelado no banco"
  },
  DESCARTADO: {
    color: "canceled",
    progress: 33,
    feedbackMessage: "Boleto descartado"
  },
  NAO_EMITIDO: {
    color: "",
    progress: 0,
    feedbackMessage: "Boleto não gerado"
  },
  LIQUIDADO:{
    color: "success",
    progress: 100,
    feedbackMessage: "Boleto em processo de liquidação"
  }
} as const

type BankSlipProgressProps = {
  status?: keyof typeof bankSlipStatus;
}

export function BankSlipProgress({ status = "NAO_EMITIDO" }: BankSlipProgressProps) {
  const { color, feedbackMessage, progress } = bankSlipStatus[status];

  return (
    <div className="w-75 text-center">
      <ProgressBar
        className={`h-10 bank-slip-progress-${color}`}
        now={progress}
      />
      <span style={{ color: "#808080" }} className="fs-12 fw-medium">{feedbackMessage}</span>
    </div>
  )
}