import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { contractStatusClose, insertContractStatus } from "../../../../redux/slice/contractDataSlice";
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { contractForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import { fetch_allFinancialIndexing } from '../../../../redux/slice/expenditureDataSlice';
import { ClllThis } from '../Readjustment/ReadjustmentIndexes';
const ContractStatusService = (contractForm) => {
  const { contractStatusShow, tenantsData, contractStNumber, ownerData, checkedId, contractStTitle } = useSelector((state: RootState) => state.contractData)
  const { indexData, indexStatus, billingData, billingStatus } = useSelector((state: RootState) => state.expenditureData)
  const [readName, setReadName] = useState<any>([]);
  let [hide, setHide] = useState(true);
  const [double, setDouble] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [branchState, setBranchState] = useState({
    start: "0",
    end: "100",
  });
  const [t] = useTranslation();
  const { branchList } = useSelector((state: RootState) => state.branch);

  useEffect(() => {
    (async () => {
      let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
      if (dataOutPut["payload"]) {
        let result = await ClllThis(dataOutPut["payload"]["data"], "");
        setReadName(result);
      }
    })()
  }, [])
  const contractStClose = () => {
    reset();
    dispatch(contractStatusClose(false));
  }
  let frm = {
    resolver: yupResolver(Yup.object().shape({}))
  }
  if (contractStNumber === 1) {
    let vald = {};
    let validation = {
      transferStatus: Yup.string().trim().nullable()
        .required(t('requiredMmessage.fieldRequired')),
    }
    vald = { ...validation }
    if (!hide) {
      let validation2 = {
        vacancyDate: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired'))
      }
      vald = { ...validation, ...validation2 };
    }
    frm = {
      resolver: yupResolver(Yup.object().shape(vald))
    }
  }
  if (contractStNumber === 3) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        branch: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
        bank_account: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
        billing_method: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 4) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        readjustment_index: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 5) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        dimob: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 6) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        split: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 7) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        invoice: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 8) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        billing_method: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 9) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        tenants: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 10) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        owner: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
        tenants: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  if (contractStNumber === 11) {
    frm = {
      resolver: yupResolver(Yup.object().shape({
        guarantee2: Yup.string().trim().nullable()
          .required(t('requiredMmessage.fieldRequired')),
      }))
    }
  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<contractForm>(frm);
  const onSubmit = async (data: contractForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    data.checkedId = checkedId;
    data.type = contractStNumber;
    let response = await dispatch(insertContractStatus(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon:'success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      contractStClose();
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.successfailed"),
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }
  const transferStatus = (evt) => {
    let st = evt.target.value;
    setHide(true);
    if (st == 2) {
      setHide(false);
    }
  }
  return (
    <>
      {/* <!-- New contract status start from here --> */}
      <Modal show={contractStatusShow} onHide={contractStClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{
              contractStNumber === 1 ?
                t('chgFncStatus') :
                contractStNumber === 2 ?
                  t('chgBankFee') :
                  contractStNumber === 3 ?
                    t('chgBankAccount')
                    : contractStNumber === 4 ?
                      t('chgRdIndex')
                      : contractStNumber === 5 ?
                        t('chgDIMOBOpt')
                        : contractStNumber === 6 ?
                          t('chgSplitOpt')
                          : contractStNumber === 7 ?
                            t('chgInvoiceOpt')
                            : contractStNumber === 8 ?
                              t('chgBillingMethod')
                              : contractStNumber === 9 ?
                                t('chgTenantBankFee') :
                                contractStNumber === 10 ?
                                  t('inviteOwnerAreas') :
                                  contractStNumber === 11 ?
                                    t('changeGtdTsfCnt') :
                                    contractStNumber === 12 ?
                                      t('changeGtdTsf') :
                                      ''
            }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              contractStNumber === 1 ?
                <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                  <Col md={6}>
                    <FloatingLabel controlId="floatingSelect" label="Status">
                      <Form.Select className={`form-control ${errors.transferStatus ? 'is-invalid' : ''}`} {...register('transferStatus')} onChange={transferStatus} aria-label="Status">
                        <option value="">{t('dropdownItem.select')}</option>
                        <option value="0">Normal</option>
                        <option value="1">Legal</option>
                        <option value="2">In vacancy</option>
                        <option value="3">Sinister</option>
                        <option value="4">Pending Features</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.transferStatus?.message}</Form.Control.Feedback >
                    </FloatingLabel>
                  </Col>
                  <Col md={6} style={{ display: hide ? "none" : "block" }}>
                    <FloatingLabel controlId="floatingInput" label="Expected vacancy date">
                      <Form.Control type="date" className={`form-control ${errors.vacancyDate ? 'is-invalid' : ''}`} {...register('vacancyDate')} />
                      <Form.Control.Feedback type="invalid">{errors.vacancyDate?.message}</Form.Control.Feedback >
                    </FloatingLabel>
                  </Col>
                </Row>
                : contractStNumber === 2 ?
                  <Form.Group className="mb-lg-4 mb-3">
                    <Form.Check type="checkbox" {...register('bank_fee')} label={t("chargeDocBankFee")} />
                  </Form.Group>
                  : contractStNumber === 3 ?
                    <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                      <Col md={12}>
                        <FloatingLabel controlId="floatingSelect" label={t("formLabel.branch")}>
                          <Form.Select className={`form-control ${errors.branch ? 'is-invalid' : ''}`} {...register('branch')} aria-label="Branch">
                            <option value="">{t('dropdownItem.select')}</option>
                            {branchList && branchList.length > 0 &&
                              branchList.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.branch?.message}</Form.Control.Feedback >
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel controlId="floatingSelect" label={t("bankAccount")}>
                          <Form.Select className={`form-control ${errors.bank_account ? 'is-invalid' : ''}`} {...register('bank_account')} aria-label="Bank account">
                            <option value="">{t('dropdownItem.select')}</option>
                            <option value="1">Santander Account (Approved)</option>
                            <option value="2">Marquezini account</option>
                            <option value="3">Friar Account</option>
                            <option value="4">Orgabi account</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.bank_account?.message}</Form.Control.Feedback >
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel controlId="floatingSelect" label={t("billingMethod")}>
                          <Form.Select className={`form-control ${errors.billing_method ? 'is-invalid' : ''}`} {...register('billing_method')} aria-label="Billing method">
                            <option value="">{t('dropdownItem.select')}</option>
                            {
                              billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                <option key={index} value={Number(item.id)}>{item.name}</option>
                              ))
                                : ''
                            }
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.billing_method?.message}</Form.Control.Feedback >
                        </FloatingLabel>
                      </Col>
                    </Row>
                    : contractStNumber === 4 ?
                      <FloatingLabel controlId="floatingSelect" label={t("readjustmentIndex")}>
                        <Form.Select className={`form-control ${errors.readjustment_index ? 'is-invalid' : ''}`} {...register('readjustment_index')} aria-label="Readjustment index">
                          <option value="">{t('dropdownItem.select')}</option>
                          {/* {
                            indexData.data && indexData.data.length > 0 ? indexData.data.map((item, index) => ( */}
                          {readName && readName.length > 0
                            ? readName.map((item, index) => (
                              <option key={index} value={( item.id + 1 )}>{item.name}</option>
                            )) : ''
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.readjustment_index?.message}</Form.Control.Feedback >
                      </FloatingLabel>
                      : contractStNumber === 5 ?
                        <Form.Group className="mb-lg-4 mb-3">
                          <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingSelect" label="DIMOB">
                            <Form.Select className={`form-control ${errors.dimob ? 'is-invalid' : ''}`} {...register('dimob')} aria-label="DIMOB">
                              <option value="">{t('dropdownItem.select')}</option>
                              <option value="0">Not included in DIMOB</option>
                              <option value="1">Include in DIMOB</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.dimob?.message}</Form.Control.Feedback >
                          </FloatingLabel>
                        </Form.Group>

                        : contractStNumber === 6 ?
                          <Form.Group className="mb-lg-4 mb-3">
                            <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingSelect" label="Split">
                              <Form.Select className={`form-control ${errors.split ? 'is-invalid' : ''}`} {...register('split')} aria-label="Split">
                                <option value="">{t('dropdownItem.select')}</option>
                                <option value="0">Unselect for Split</option>
                                <option value="1">Mark for Split</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">{errors.split?.message}</Form.Control.Feedback >
                            </FloatingLabel>
                          </Form.Group>
                          : contractStNumber === 7 ?
                            <FloatingLabel controlId="floatingSelect" label={t("invoice")}>
                              <Form.Select className={`form-control ${errors.invoice ? 'is-invalid' : ''}`} {...register('invoice')} aria-label="Invoice">
                                <option value="">{t('dropdownItem.select')}</option>
                                <option value="0">{t('doSystem')}</option>
                                <option value="1">{t('issueBySystem')}</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">{errors.invoice?.message}</Form.Control.Feedback >
                            </FloatingLabel>
                            : contractStNumber === 8 ?
                              <FloatingLabel controlId="floatingSelect" label={t("billingMethod")}>
                                <Form.Select className={`form-control ${errors.billing_method ? 'is-invalid' : ''}`} {...register('billing_method')} aria-label="Billing method">
                                  <option value="">{t('dropdownItem.select')}</option>
                                  {/*{  
                                    billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => {
                                      if (item.id == 0 || item.id == 1 || item.id == 2 || item.id == 12) {
                                        return (
                                          <option key={index} value={Number(item.id)}>{item.name}</option>
                                        )
                                      }
                                    })
                                      :
                                      ''
                                  }*/}
                                  <option value="0">{t('expiredMonth')}</option>
                                  <option value="1">{t('monthDue')}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.billing_method?.message}</Form.Control.Feedback >
                              </FloatingLabel>
                              : contractStNumber === 9 ?
                                <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                                  <Col md={12}>
                                    <FloatingLabel controlId="floatingInput" label={t("customerAndEmployees.tenants")}>
                                      <Form.Select className={`form-control ${errors.tenants ? 'is-invalid' : ''}`} {...register('tenants')} >
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {tenantsData && tenantsData.length > 0 &&
                                          tenantsData.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                          ))}
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">{errors.tenants?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                  </Col>
                                </Row>
                                : contractStNumber === 10 ?
                                  <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                                    <Col md={12}>
                                      <FloatingLabel controlId="floatingInput" label={t("notifications.owner")}>
                                        <Form.Select className={`form-control ${errors.owner ? 'is-invalid' : ''}`} {...register('owner')} >
                                          <option value="">{t('dropdownItem.select')}..</option>
                                          {ownerData && ownerData.length > 0 && ownerData.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                          ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.owner?.message}</Form.Control.Feedback >
                                      </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                      <FloatingLabel controlId="floatingInput" label={t("customerAndEmployees.tenants")}>
                                        <Form.Select className={`form-control ${errors.tenants ? 'is-invalid' : ''}`} {...register('tenants')} >
                                          <option value="">{t('dropdownItem.select')}..</option>
                                          {tenantsData && tenantsData.length > 0 &&
                                            tenantsData.map((item) => (
                                              <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.tenants?.message}</Form.Control.Feedback >
                                      </FloatingLabel>
                                    </Col>
                                  </Row>
                                  : contractStNumber === 11 ?
                                    <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingSelect" label={t('guaranteeContract')}>
                                      <Form.Select className={`form-control ${errors.guarantee2 ? 'is-invalid' : ''}`} {...register('guarantee2')} aria-label="Guarantee for the whole contract">
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="0">{t('doesNotHave')}</option>
                                        <option value="1">{t('guaranteeFewMonths')}</option>
                                        <option value="-1">{t('guaranteeContract')}</option>
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">{errors.guarantee2?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                    :
                                    contractStNumber === 12 ?
                                      <Form.Group className="mb-lg-4 mb-3">
                                        <Form.Check type="checkbox" {...register('guaranteeTransfer')} label="Do not pass on fines and interest in guaranteed transfers" />
                                      </Form.Group>
                                      :
                                      ''
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={contractStClose}>Close</Button>
            {
              contractStNumber > 0 ?
                <Button disabled={double} type="submit" variant="primary">Save</Button>
                :
                <Button disabled type="submit" variant="primary">Save</Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <!-- /New contract status modal end --> */}
    </>
  )
}
export default ContractStatusService;