import React from "react";

import { FeedbackDrawer } from "../FeedbackDrawer";
import { HomologatedChargesList } from "../HomologatedChargesList";
import { Summary } from "../Summary";
import { FeedbackDrawerProvider } from "../FeedbackDrawer";
import { HomologatedChargesListProvider } from "../HomologatedChargesList/HomologatedChargesListProvider";
import { ChargesListPagination } from "../ChargesListPagination";

export function HomologatedAccount() {

  return (
    <HomologatedChargesListProvider>
      <FeedbackDrawerProvider>
        <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
          <section>
            <Summary />
          </section>
          <section>
            <HomologatedChargesList />
          </section>
          <section className="mt-3">
            <ChargesListPagination />
          </section>
          <FeedbackDrawer />
        </main>
      </FeedbackDrawerProvider>
    </HomologatedChargesListProvider>
  );
}
