import axios from 'axios';
import { gettoken, config } from './apiConfig';
const companyV2_URL = config.companyV2_URL;
axios.defaults.headers.common['Content-Type'] = "application/json";
const Token = gettoken.Token;
axios.defaults.headers.common['x-auth-token']    = localStorage.getItem("tokenV2");//Token;
axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");
export const userService = {
    userPermission: async function (param) {
        axios.defaults.headers.common['connection-name'] = param.connectionName;
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");
    
        const response = await axios.request({
            url: config.customerV2 + '/v2/contract/getPermission?' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    agencyLogin: async function (param) {
        const response = await axios.request({
            url: companyV2_URL+ '/agency/ssouserlogin?' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    resetPassword: async function (param) {
        const response = await axios.request({
            url: companyV2_URL+ '/agency/password-recover?' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    cahngePassword: async function (param) {
        const response = await axios.request({
            url: companyV2_URL+ '/agency/password-reset?' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    findAgency: async function (param) {
        const response = await axios.request({
            url: companyV2_URL+ '/agency/findAgency?' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    getAllUsers: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + '/user/list_users?' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    updateAccessPermission: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + '/user/update_access_permission?1=1' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    },
    AddUsertoGroup: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + '/user/addUsertoGroup?1=1' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    }, 
    removeUserFromGroup: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + '/user/removeUserFromGroup?1=1' + fakeUrl(),
            method: "POST",
            data: param
        })
        return response.data
    }, 
    getUserData: async function (id) {
        const response = await axios.request({
            url: companyV2_URL+ `?id=` + id,
            method: "GET"
        })
        return response.data
    },
    search: async function (name, cancel = false) {
        const response = await axios.request({
            url: companyV2_URL+ "/products/search?" + fakeUrl(),
            method: "GET",
            params: {
                name: name,
            }
        })
        return response.data
    }, 
    createUser: async function (data, cancel = false) {
        const response = await axios.request({
            url: companyV2_URL + '/user/edit',
            method: "POST",
            data: data,
        })
        return response.data
    }, 
    createGroup: async function (data) {
        const response = await axios.request({
            url: companyV2_URL + `/user/group/edit`,
            method: "POST",
            data: data
        })
        return response.data
    }, 
    getAllGroups: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + `/user/group/list_group?page=${param.page}`,
            method: "GET",
        })
        return response.data
    }, 
    getGroupData: async function (param) {
        const response = await axios.request({
            url: `${companyV2_URL}/user/group/getGroupDetails?groupId=${param.groupId}`,
            method: "GET"
        })
        return response.data
    }, 
    getAllUserGroup: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + `/user/group/groupMembers?groupId=${param}`,
            method: "GET",
        })
        return response.data
    }, 
    deleteGroup: async function (param) {
        const response = await axios.request({
            url: `${companyV2_URL}/user/group/delete/${param.groupId}?1=1${fakeUrl}`,
            method: "DELETE",
        })
        return response.data
    }, 
    deleteEmalHistory: async function (param) {
        const response = await axios.request({
            url: `${companyV2_URL}/user/emailHistory/delete`,
            method: "POST",
            data: param
        })
        return response.data
    }, 
    getMyIp: async function () {
        const response = await axios.request({
            url: `${companyV2_URL}/get_ip?1=1${fakeUrl}`,
            method: "GET",
        })
        return response.data
    },
    deleteUser: async (userId) => {
        const response = await axios.request({
            url: `${companyV2_URL}/user/delete/${userId}?1=1${fakeUrl}`,
            method: "delete",
        })
        return response.data
    },
    updateStatusUser: async (param) => {
        const response = await axios.request({
            url: `${companyV2_URL}/user/statusUpdateBulk?1=1${fakeUrl}`,
            method: "post", data: param
        })
        return response.data
    }, 
    copyAccessUser: async function (data) {
        const response = await axios.request({
            url: companyV2_URL + '/user/copyAccessPermissions',
            method: "PUT",
            data: data,
        })
        return response.data
    },
    copyGroupAccessUser: async function (data) {
        const response = await axios.request({
            url: companyV2_URL + '/user/copyUserGroupAccess',
            method: "PUT",
            data: data,
        })
        return response.data
    },
    resetPasswordUserByAdmin: async function (data) {
        const response = await axios.request({
            url: companyV2_URL+ '/user/resetPassword/byAgency',
            method: "PUT",
            data: data,
        })
        return response.data
    },
    SingaldayAndTimeChange: async function (data) {
        const response = await axios.request({
            url: companyV2_URL + '/user/changeRestrictionsTimeLocation',
            method: "PUT",
            data: data,
        })
        return response.data
    },

     // Working
     getAll_default_permission: async function (param) {
        const response = await axios.request({
            url: companyV2_URL + '/company/all_permission_default',
            method: "POST"
        })
        return response.data
    },
}

const fakeUrl = () => {
    return `&tron_id=${randomString()}&operation_id=${randomString()}`
}
const randomString = (length) => {
    if (!length) { length = 10; }
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}