import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";

import { useTransferAccountDrawer } from "../../../TransferAccountDrawer";
import { useTransferAccount } from "../../TransferAccountProvider";

export function TransferAccountActionButtons() {
  const { setShowTransferAccountDrawer } = useTransferAccountDrawer();
  const { isSubmitting } = useFormState();
  const { reset } = useFormContext();

  return (
    <Row className="py-3 px-5">
      <Col sm={12} className="d-flex justify-content-end">
        <Button
          variant="link"
          onClick={() => {
            reset();
            setShowTransferAccountDrawer({
              action: undefined              
            });
          }}
          disabled={isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          className="ms-2"
          type="submit"
          form="transferAccountForm"
          disabled={isSubmitting}
        >
          Salvar
        </Button>
      </Col>
    </Row>
  );
}
