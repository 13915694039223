// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-slip-progress-success > .progress-bar {
  background-color: #A7D6C2 !important;
}

.bank-slip-progress-canceled > .progress-bar {
  background-color: #F1B4AB !important;
}
.bank-slip-progress-warning > .progress-bar {
  background-color: #F0D3A9 !important;
}`, "",{"version":3,"sources":["webpack://./src/newComponents/features/chargesToReceive/components/HomologatedChargesList/components/BankSlipProgress/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".bank-slip-progress-success > .progress-bar {\n  background-color: #A7D6C2 !important;\n}\n\n.bank-slip-progress-canceled > .progress-bar {\n  background-color: #F1B4AB !important;\n}\n.bank-slip-progress-warning > .progress-bar {\n  background-color: #F0D3A9 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
