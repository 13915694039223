import { z } from "zod";

const TransferAccountSchema = z.object({
  date: z.string().min(1, "Campo obrigatório"),
  transferAmount: z
    .string()
    .transform((val) => parseFloat(val.replace(/[^\d]/g, "")) / 100)
    .refine((val) => val > 0, { message: "O valor deve ser maior que R$ 0,00." }),
  originAccount: z.string().min(1, "Campo obrigatório"),
  destinationAccount: z.string().min(1, "Campo obrigatório"),
  complement: z.string().optional(),
});

export function getTransferAccountFormSchema() {
  return TransferAccountSchema;
}

export type TransferAccountFormType = z.infer<typeof TransferAccountSchema>;