import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { usePagination } from "./usePagination";

export function Pagination() {
    const {
      countTotal,
      hasPreviousAndNextButton,
      isOnFirstPage,
      isOnLastPage,
      goToPreviousPage,
      goToNextPage,
      handleChangePage,
      page,
      pagesToShow,
      rangeOfItems,
    } = usePagination();

    return (
      <Row>
        <Col xs={3} className="d-flex align-items-center">
          <span style={{ color: "#808080" }} className="fs-14">
          {countTotal > 0
            ? `Listando de ${rangeOfItems.start} a ${rangeOfItems.end} de ${countTotal} cobranças`
            : ""
          }
          </span>
        </Col>
        <Col className="d-flex flex-row w-full justify-content-center align-items-center gap-2">
          {hasPreviousAndNextButton &&
            <Button
              size="sm"
              disabled={isOnFirstPage}
              onClick={goToPreviousPage}
              className="rounded-pill btn-light"
            >
              Anterior
            </Button>
          }
          {pagesToShow.map(pageNumber => (
            <Button
              className={`d-flex align-items-center justify-content-center rounded-circle ${pageNumber !== page && "btn-light"} px-1`}
              key={pageNumber}
              onClick={() => handleChangePage(pageNumber)}
              size="sm"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              {pageNumber}
            </Button>
          ))}
          {hasPreviousAndNextButton &&
            <Button
              size="sm"
              disabled={isOnLastPage}
              onClick={(goToNextPage)}
              className="rounded-pill btn-light"
            >
              Próximo
            </Button>
          }
        </Col>
        <Col xs={3} />
      </Row>
    )
}