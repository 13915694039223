import { Form, Accordion, Modal, Button, Row, Col, FloatingLabel, InputGroup, ListGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { contractClose, insertWarranty, insuranceOpen, reInsuranceTp, updateWarranty, warrantyClose, warrantyOpen, contractDatails } from "../../../../redux/slice/contractDataSlice";
import { useTranslation } from 'react-i18next';
import { warrantyForm } from '../../../Interface';
import Swal from 'sweetalert2';
import '../../../../App.css';
import Loader from '../../../Profile/Company/Loader';
import customerService from '../../../../services/customerService';
import { convertToNumber, converter, encrypt_Decrypt, formatNumber } from '../../../constants/utils';

interface Guarantor {
  value: string;
  label: string;
}
const WarrantyService = ({ setGurantorLabel }) => {
  const [double, setDouble] = useState(false);
  const [warrantyData, setWarrantyData] = useState<any>([])
  const [gurantorDetails, setGurantorDetails] = useState<any>({})
  const [val, setVal] = useState('')
  const { id }: any = useParams();
  const dispatch = useDispatch<AppDispatch>()
  const [t] = useTranslation();
  const { newWarrantyShow, guarantorData, insuranceData, contractDatail, guarantors, status } = useSelector((state: RootState) => state.contractData)

  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    setUserJson(userResult);
  }, [])

  /*------------Warranty modal hide function-----------*/

  const newWarrantyClose = () => {
    dispatch(warrantyClose(false));
    reset()
  }

  const [guarant, setGuarant] = useState(0);
  const [guarType, setGuarType] = useState(0)
  const [state, setState] = useState({
    contract_id: id,
  })
  /*------------Form validation-----------*/

  const warrantyValidation = Yup.object().shape({
    guarantee: Yup.string().trim().nullable()
      .required(t('requiredMmessage.fieldRequired')),
    // value: Yup.string().trim().nullable()
    // .required(t('requiredMmessage.fieldRequired'))       
  });
  let frm = {
    resolver: yupResolver(warrantyValidation)
  }
  /*------------Submit warranty form data-----------*/

  const newWarrantyOpen = (evt) => {
    dispatch(warrantyOpen(true));

  }
  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm<warrantyForm>(frm);
  useEffect(() => {
    setValue("id", id);
    if (newWarrantyShow) {
      let showSelectData = warrantyData[0]?.guarantee;
      setGuarant(showSelectData);
      setGuarType(warrantyData[0]?.guaranteeType)
      // Access 'guarantee' property
      setValue("guarantee", showSelectData);
      setValue("guarantor", gurantorDetails?.id)
      setValue("guaranteeType", warrantyData[0]?.guaranteeType)
      setValue("responsible", warrantyData[0]?.responsible)
      setValue("description", warrantyData[0]?.description)
      setValue("identifier", warrantyData[0]?.identifier)
      setValue("in", warrantyData[0]?.in)
      setValue("until", warrantyData[0]?.until)
      setValue("value", warrantyData[0]?.value)
      setValue("observation2", warrantyData[0]?.observation2)
      setValue("insurer", warrantyData[0]?.insurer)
      setValue("policy", warrantyData[0]?.policy)
      setValue("bank", warrantyData[0]?.bank)
      setValue("bank", warrantyData[0]?.bank)
      setValue("numberInstallments", warrantyData[0]?.numberInstallments)
      setValue("surety", warrantyData[0]?.surety)
      setValue("backgroundType", warrantyData[0]?.backgroundType)
      setValue("applicationNumber", warrantyData[0]?.applicationNumber)
    }

    guarantorData.forEach((item) => {
      if (item.id === warrantyData[0]?.guaranterId) {
        setGurantorDetails(item)
      }
    })
  }, [guarantorData, warrantyData, newWarrantyShow, guarantors]);

  const onSubmit = async (data: warrantyForm, e: any) => {
    e.preventDefault();
    setDouble(true)
    let Valdata: any = convertToNumber(val);
    data["value"] = Valdata;
    let response = await dispatch(updateWarranty(data));
    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
      setDouble(false);
      Swal.fire({
        // icon: 'success','success',
        title: t("Sweetalert.Success"),
        text: t("Sweetalert.successget"),
        confirmButtonText: "OK",
        timer: 2000
      })
      dispatch(contractDatails(Number(id)));
      setState({
        ...state,
        contract_id: data.id
      })
      newWarrantyClose();
      guarantors.forEach((element: any) => {
        if (element.value == data.guarantee) {
          setGurantorLabel(element.label)
        }
      });
    }
    else {
      setDouble(false)
      Swal.fire({
        title: t("Sweetalert.Error"),
        text: t("Sweetalert.somethingWent"),
        // icon:"error",
        confirmButtonText: "OK",
        timer: 2000
      })
    }
  }
  const guarantee = (evt) => {
    setGuarant(Number(evt.target.value));
    setValue('guaranteeType', '');
    setValue("guarantor", "")
    setValue("guaranteeType", "")
    setValue("responsible", "")
    setValue("description", "")
    setValue("identifier", "")
    setValue("in", "")
    setValue("until", "")
    setValue("value", "")
    setValue("observation2", "")
    setValue("insurer", "")
    setValue("policy", "")
    setValue("bank", "")
    setValue("bank", "")
    setValue("numberInstallments", "")
    setValue("surety", "")
    setValue("backgroundType", "")
    setValue("applicationNumber", "")
  }

  const guaranteeType = (evt) => {
    setValue('responsible', '');
    setValue('description', '');
    setValue('identifier', '');
    setGuarType(Number(evt.target.value));
  }

  const newInsuranceOpen = (evt) => {
    dispatch(reInsuranceTp(evt.target.title));
    dispatch(insuranceOpen(true))
    dispatch(contractClose(false));
  }

  useEffect(() => {
    customerService.getAllWarranty(state).then((res) => {
      if (res.data.resCode == 201) {
        let resp = res.data?.data[0];
        setGuarant(resp.guarantee);
        setGuarType(Number(resp.guaranteeType));
        setWarrantyData(res.data?.data)
      }
    })
  }, [state])

  function formatDateToDDMMYYYY(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const formateValue = (e: any) => {
    let { value } = e.target;
    value = value.replace(/[^\d,]/g, '');
    setValue("value", formatNumber(value))
    setVal(value)
  }
  return (
    <>
      {
        warrantyData.length > 0 ?
          warrantyData[0]?.guarantee !== null ?
            warrantyData[0]?.guarantee !== 0 ?
              <>
                <Button onClick={newWarrantyOpen} variant="light" size="sm" className="mb-lg-4 mb-3 renewWarrantyContact">
                  {t('renewWarranty')}
                </Button>
                <ListGroup variant="flush">
                  { 
                    warrantyData[0].guarantee!== 5 || warrantyData[0].guarantee!== 1  ?                      
                      <ListGroup variant="flush">


                          { warrantyData && warrantyData.map((item , index) => (
                            <ListGroup.Item key={`wtg_${index}`}>
                              { item.ST_NOME_PES && 
                                <><img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} className="me-1" />{ item.ST_NOME_PES }</>
                              } 
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                    : ''
                  }
                  { 
                    warrantyData[0].guarantee!== 5 && warrantyData[0].guarantee!== 1 && warrantyData[0].guarantee!== 0 ? 
                     
                      <ListGroup.Item>
                        <Row>
                          <Col md={4} xl={4} xxl={3}>
                            {t('validity')}
                          </Col>
                          <Col xl md xxl>
                            { t('from') } { warrantyData.length > 0 && warrantyData[0].in ? formatDateToDDMMYYYY(warrantyData[0].in) : ''} { t('to') } {warrantyData.length > 0 && warrantyData[0].until ? formatDateToDDMMYYYY(warrantyData[0].until) : ''}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    : ''
                  }
                  { 
                    warrantyData[0].guarantee!== 5 && warrantyData[0].guarantee!== 1 && warrantyData[0].guarantee!== 0 ? 
                      
                      <ListGroup.Item>
                        <Row>
                          <Col md={4} xl={4} xxl={3}>
                            {t('value')}
                          </Col>
                          <Col xl md xxl>
                            {warrantyData.length > 0 && warrantyData[0].value ? converter(warrantyData[0].value) : ''}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    : ''
                  }
                  {
                    warrantyData[0].guaranteeType === 4 || warrantyData[0].guaranteeType === 6 ?
                      <ListGroup.Item>
                        <Row>
                          <Col md={4} xl={4} xxl={3}>
                            {t('description')}
                          </Col>
                          <Col xl md xxl>
                            {warrantyData.length > 0 ? warrantyData[0].description : ""}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      :
                      ""
                  }
                  {warrantyData[0].guarantee === 2 || warrantyData[0].bank ?
                    <ListGroup.Item>
                      <Row>
                        <Col md={4} xl={4} xxl={3}>
                          {t('bank')}
                        </Col>
                        <Col xl md xxl>
                          {warrantyData.length > 0 ? warrantyData[0].bank : ""}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    :
                    ""
                  }
                  {warrantyData[0].guarantee === 2 || warrantyData[0].guaranteeType === 4 || warrantyData[0].guaranteeType === 6 ?
                    <ListGroup.Item>
                      <Row>
                        <Col md={4} xl={4} xxl={3}>
                          {t('identifier')}
                        </Col>
                        <Col xl md xxl>
                          {warrantyData.length > 0 ? warrantyData[0].identifier : ""}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    :
                    ""
                  }
                  {warrantyData[0].backgroundType !== null && warrantyData[0].guarantee === 5 ?
                    <ListGroup.Item>
                      <Row>
                        <Col md={4} xl={4} xxl={3}>
                          {t('backgroundType')}
                        </Col>
                        <Col xl md xxl>
                          {warrantyData.length > 0 ? warrantyData[0].backgroundType : ""}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    :
                    ""
                  }
                  {warrantyData[0].applicationNumber !== null && warrantyData[0].guarantee === 5 ?
                    <ListGroup.Item>
                      <Row>
                        <Col md={4} xl={4} xxl={3}>
                          {t('applicationNumber')}
                        </Col>
                        <Col xl md xxl>
                          {warrantyData.length > 0 ? warrantyData[0].applicationNumber : ''}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    :
                    ""
                  }
                  {warrantyData[0].guaranteeType === 1 ?
                    <ListGroup.Item>
                      <Row>
                        <Col md={4} xl={4} xxl={3}>
                          {t('responsible')}
                        </Col>
                        <Col xl md xxl>
                          {warrantyData.length > 0 ? (
                            warrantyData[0].responsible === 0 || warrantyData[0].responsible == 0 ? t("realEstate") :
                              warrantyData[0].responsible === 2 || warrantyData[0].responsible == 2 ? t("tenant") :
                                warrantyData[0].responsible === 1  || warrantyData[0].responsible == 1 ? t("owner") :
                                  ''
                          ) : ''}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    :
                    ""
                  }
                  { 
                    warrantyData[0].guarantee!== 5 && warrantyData[0].guarantee!== 1 && warrantyData[0].guarantee!== 0 ? 
                      <ListGroup.Item>
                        <Row>
                          <Col md={4} xl={4} xxl={3}>
                            {t('observation')}
                          </Col>
                          <Col xl md xxl>
                            {warrantyData.length > 0 ? warrantyData[0].observation2 : ''}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    :
                      ''
                  }
                  </ListGroup>

              </>
              :

              <>
                {
                  userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
                  <>
                    <Button onClick={newWarrantyOpen} variant="light" size="sm" className="mb-lg-4 mb-3 renewWarrantyContact">
                      {t('renewWarranty')}
                    </Button>
                    <ListGroup variant="flush">
                      {warrantyData.map((item) => (
                          <ListGroup.Item>
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} className="me-1" />{item.ST_NOME_PES}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </>
                }
              </>
            :
            <div className='text-center'>
              {
                userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
                <>
                  <div className="text-secondary border rounded-4 text-center p-xl-4 p-3">{t("renewWarrantyTitle")}</div>
                  <Button variant="light" size="sm" className="mt-2" onClick={newWarrantyOpen}>
                    {t('addwarranty')}
                  </Button>
                </>
              }
            </div>
          : <div className='text-center'>
            {
              userJson && userJson.length > 0 && (userJson.indexOf(2031) !== -1) &&
              <>
                <div className="text-secondary border rounded-4 text-center p-xl-4 p-3">{t("renewWarrantyTitle")}</div>
                <Button variant="light" size="sm" className="mt-2" onClick={newWarrantyOpen}>
                  {t('addwarranty')}
                </Button>
              </>
            }
          </div>
      }
      {/* <!-- New Warranty modal start from here --> */}
      <Modal show={newWarrantyShow} onHide={newWarrantyClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("renewWarranty")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Control type="text" {...register('id')} hidden />
            <FloatingLabel className="mb-lg-4 mb-3" controlId="floatingSelect" label={t("guarantee") + " *"}>
              <Form.Select {...register('guarantee')} className={`form-control ${errors.guarantee ? 'is-invalid' : ''}`} onChange={guarantee} aria-label="Guarantee">
                <option value="">{t('dropdownItem.select')}</option>
                <option value="1">{t('guarantor')}</option>
                <option value="2">{t('securityDeposit')}</option>
                <option value="3">{t('suretyBond')}</option>
                <option value="4">{t('capitalizationBond')}</option>
                <option value="5">{t('surety')}</option>
                <option value="6">{t('fiduciaryAssignment')}</option>
                <option value="0">{t('hasNoWarranty')}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.guarantee?.message}</Form.Control.Feedback>
            </FloatingLabel>

            {/* <FloatingLabel controlId="floatingInput" label="Guarantor">
        <Form.Control type="text" placeholder="Guarantor" className={`form-control ${errors.guarantor ? 'is-invalid' : ''}`} {...register('guarantor')} />
        <Form.Control.Feedback type="invalid">{errors.guarantor?.message}</Form.Control.Feedback > 
      </FloatingLabel> */}

            {/* Guarantor */}

            <div style={{ display: guarant == 1 ? "block" : "none" }}>
              <InputGroup>
                <FloatingLabel controlId="floatingInput" label={t("guarantor")}>
                  <Form.Select className={`form-control ${errors.guarantor ? 'is-invalid' : ''}`} {...register('guarantor')} >
                    <option value="">{t('dropdownItem.select')}..</option>
                    {guarantorData.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.guarantor?.message}</Form.Control.Feedback >
                </FloatingLabel>
                <InputGroup.Text >
                  <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                </InputGroup.Text>
              </InputGroup>
            </div>

            {/* Security Deposit */}

            <div style={{ display: guarant == 2 ? "block" : "none" }}>
              <span className="d-block fw-bold mb-3">{t('details')}</span>
              <Row className="gy-3 gy-lg-4 mb-lg-4 mb-3">
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('type')}>
                    <Form.Select {...register('guaranteeType')} onChange={guaranteeType} className={`form-control ${errors.guaranteeType ? 'is-invalid' : ''}`} aria-label="Type">
                      <option value="">{t('select')}..</option>
                      <option value="1">{t('value')}</option>
                      <option value="3">{t('vehicle')}</option>
                      <option value="4">{t('letterGuarantee')}</option>
                      <option value="5">{t('immobile')}</option>
                      <option value="6">{t('others')}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.guaranteeType?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col md={6} style={{ display: guarType == 1 ? "block" : "none" }}>
                  <FloatingLabel controlId="floatingSelect" label={t('responsible')}>
                    <Form.Select {...register('responsible')} className={`form-control ${errors.responsible ? 'is-invalid' : ''}`} aria-label="Responsible">
                      <option value="">{t('select')}..</option>
                      <option value="1">{t('realEstate')}</option>
                      <option value="2">{t('tenant')}</option>
                      <option value="3">{t('owner')}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.responsible?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
              <div style={{ display: guarType == 1 || guarType == 3 || guarType == 5 ? "none" : "block" }} >
                <Row>
                  <Col>
                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('description')}>
                      <Form.Control type="text" placeholder="Description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} {...register('description')} />
                      <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback >
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('identifier')}>
                      <Form.Control type="text" placeholder="Identifier" className={`form-control ${errors.identifier ? 'is-invalid' : ''}`} {...register('identifier')} />
                      <Form.Control.Feedback type="invalid">{errors.identifier?.message}</Form.Control.Feedback >
                    </FloatingLabel>
                  </Col>
                </Row>
              </div>
            </div>

            {/* Surety Bond */}

            <div style={{ display: guarant == 3 ? "block" : "none" }}>
              <span className="d-block fw-bold mb-3">{t('details')}</span>
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6}>
                  <InputGroup>
                    <FloatingLabel controlId="floatingInput" label={t('insurers')}>
                      <Form.Select className={`form-control ${errors.insurer ? 'is-invalid' : ''}`} {...register('insurer')} >
                        <option value="">{t('dropdownItem.select')}..</option>
                        {insuranceData && insuranceData.length > 0 ?
                          insuranceData.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                          ))
                          :
                          <option value="">No records</option>
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.insurer?.message}</Form.Control.Feedback >
                    </FloatingLabel>
                    <InputGroup.Text onClick={newInsuranceOpen}>
                      <img title="contractInsurance" src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('numberInstallments')}>
                    <Form.Control type="text" placeholder={t('numberInstallments')} className={`form-control ${errors.numberInstallments ? 'is-invalid' : ''}`} {...register('numberInstallments')} />
                    <Form.Control.Feedback type="invalid">{errors.numberInstallments?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
            </div>

            {/* Capitalization Bond */}

            <div style={{ display: guarant == 4 || guarant == 3 ? "block" : "none" }}>
              {guarant != 3 ?
                <span className="d-block fw-bold mb-3" >{t('details')}</span>
                : ''
              }
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6} style={{ display: guarant == 3 ? "none" : "block" }}>
                  <FloatingLabel controlId="floatingInput" label={t('bank')}>
                    <Form.Control type="text" placeholder={t('bank')} className={`form-control ${errors.bank ? 'is-invalid' : ''}`} {...register('bank')} />
                    <Form.Control.Feedback type="invalid">{errors.bank?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('policy')}>
                    <Form.Control type="text" placeholder="Policy" className={`form-control ${errors.policy ? 'is-invalid' : ''}`} {...register('policy')} />
                    <Form.Control.Feedback type="invalid">{errors.policy?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
            </div>

            {/* Fiduciary Assignment */}

            <div style={{ display: guarant == 6 ? "block" : "none" }}>
              <span className="d-block fw-bold mb-3">{t('details')}</span>
              <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label={t('backgroundType')}>
                    <Form.Control type="text" placeholder={t('backgroundType')} className={`form-control ${errors.backgroundType ? 'is-invalid' : ''}`} {...register('backgroundType')} />
                    <Form.Control.Feedback type="invalid">{errors.backgroundType?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel className="mb-3 mb-lg-4" controlId="floatingInput" label={t('applicationNumber')}>
                    <Form.Control type="text" placeholder={t('applicationNumber')} className={`form-control ${errors.applicationNumber ? 'is-invalid' : ''}`} {...register('applicationNumber')} />
                    <Form.Control.Feedback type="invalid">{errors.applicationNumber?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
            </div>

            {/* Additional Fields */}

            <div style={{ display: guarant == 2 || guarant == 6 || guarant == 4 || guarant == 3 ? "block" : "none" }}   >
              <Row>
                <Col md={4}>
                  <FloatingLabel controlId="floatingInput" label={t('in')}>
                    <Form.Control type="date" placeholder={t('in')} className={`form-control ${errors.in ? 'is-invalid' : ''}`} {...register('in')} />
                    <Form.Control.Feedback type="invalid">{errors.in?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col md={4}>
                  <FloatingLabel controlId="floatingInput" label={t('until')}>
                    <Form.Control type="date" placeholder={t('until')} className={`form-control ${errors.until ? 'is-invalid' : ''}`} {...register('until')} />
                    <Form.Control.Feedback type="invalid">{errors.until?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
                <Col md={4}>
                  <FloatingLabel controlId="floatingInput" label={t('value')} >
                    <Form.Control type="text" placeholder={t('value')} className={`form-control ${errors.value ? 'is-invalid' : ''}`} {...register('value')} onChange={(e) => formateValue(e)} />
                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback >
                  </FloatingLabel>
                </Col>
              </Row>
              <br />
              <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                <Form.Control as="textarea" placeholder="Observation" {...register('observation2')} style={{ height: '100px' }} />
              </FloatingLabel>
            </div>

            {/* Surety */}

            <div style={{ display: guarant == 5 ? "block" : "none" }}>
              <InputGroup>
                <FloatingLabel controlId="floatingInput" label={t('surety')}>
                  <Form.Select className={`form-control ${errors.surety ? 'is-invalid' : ''}`} {...register('surety')} >
                    <option value="">{t('dropdownItem.select')}..</option>
                    {guarantorData.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.surety?.message}</Form.Control.Feedback >
                </FloatingLabel>
                <InputGroup.Text >
                  <img src={process.env.PUBLIC_URL + "/assets/images/icons/documents-add-light.svg"} alt="Documents add" className="h-20 cursor-pe" />
                </InputGroup.Text>
              </InputGroup>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={newWarrantyClose}>{t("button.close")}</Button>
            <Button disabled={double} type="submit" variant="primary">{t("button.save")}</Button>
          </Modal.Footer>
        </Form>
      </Modal>


      {/* <!-- /New Warranty modal end --> */}
    </>
  )
}
export default WarrantyService;