import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getBankList, handleEdit, handleOpnBank, Single_Bank, updateStatus } from '../../../../redux/slice/bankSlice';
import { addUpdateWayof, editBank, getFormsofReceipt } from '../../../../redux/slice/formsOfReceiptSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Newbankaccounts from '../BankAccounts/Newbankaccounts';
import { Modal, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import EditBankAccount from '../BankAccounts/EditBankAccount';
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { getAllAccounts } from '../../../../redux/slice/expenditureDataSlice';
import { integrateBankAccountWithSplitPayment } from './BankAccounts/services';
const Schema = yup.object().shape({
    selectedBank: yup.string().required('Monthly interest rate (%) is required'),
})
const BankCredits = ({ crtsld, setcrtSld, accounttype, setaccounttype }) => {
    // This line initializes the useDispatch hook and assigns it to the dispatch variable
    const dispatch = useDispatch<AppDispatch>()
    const [bankInfo, setBankInfo] = useState<any>()
    const [id, setId] = useState<any>()
    const { show } = useSelector((state: RootState) => state.forms)
    const { userbanklist } = useSelector((state: RootState) => state.bank)
    const { accountData } = useSelector((state: RootState) => state.expenditureData);
    // The useForm hook is used to handle form registration, submission, and validation.
    const { handleSubmit, register, setValue, formState: { errors }, } = useForm();
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation()
    // Retrieve the 'wayofreceiving' value from the Redux store
    const { wayofreceiving } = useSelector((state: RootState) => state.forms);

    useEffect(() => {
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
    }, [dispatch])

    const handleClose = () => {
        // Reset the values in the state and clear the form data
        setcrtSld({
            formid: [],
            bankid: [],
            status: "",
            accounttype: ""
        })
        // Set the value of "enviroments" to an empty string
        setValue("enviroments", "")
        setId("")
        setBankInfo("")
        // Dispatch an action to update the bank edit state
        dispatch(editBank(false))
    };
    // Call the bankCreditInfo function with an initial value of 0
    useEffect(() => {
        bankCreditInfo(0);
        setValue("enviroments", "")
    }, [crtsld])

    //Bank selected from onchange and Detals are Shown
    const bankCreditInfo = async (e) => {
        setBankInfo("")
        let Id: any;
        if (e == 0) {
            Id = crtsld.bankid[0];
        } else {
            Id = e.target.value;
        }
        setId(Id)
        let res = await dispatch(Single_Bank(Id))
        if (res?.payload?.type == "Success") {
            setValue("enviroments", res?.payload?.data[0]["id"])

            let bakInfo = res?.payload?.data[0];
            if( ( bakInfo.AARIN_ID && bakInfo.AARIN_ID != '' && bakInfo.ID_FORMA_FRECB  != null ) ){
                res.payload.data[0]['bankname'] = '';
            }
            setBankInfo(res?.payload?.data[0]);
        }
    }
    // Pop up show of new bank account on click
    const Open_New_Bank = () => {
        setValue("enviroments", "")
        dispatch(handleOpnBank(true))
    }
    const Edit_Bank = (id) => {
        setId(id)
        dispatch(handleEdit(true))
    }

    const handleSum = async () => {
        // Create the 'obj' object with the required properties
        let obj: any = {
            FL_BOLETOSUPERLOGICA_FRECB: Number(accounttype),
            FL_PRODUCAO_FRECB: crtsld.status !== null && crtsld.status !== "" ? crtsld.status : crtsld.status === null && crtsld.status !== "" ? -1 : 0,
            ID_CONTA_CB: Number(id),
            ID_FORMA_FRECB: crtsld.formid && (crtsld.formid).length > 0 ? crtsld.formid[0] : "",
            OTHERS: bankInfo
        }
        // Get the previous active item
        let perviousactive = wayofreceiving.filter(item => item["FL_PRODUCAO_FRECB"] == crtsld.status && item["FL_BOLETOSUPERLOGICA_FRECB"] == accounttype);
        obj["OLD_BANK_ID"] = perviousactive?.[0]?.["ID_CONTA_CB"] ?? "";
        // Determine the action (update or new) based on the presence of formid
        if (crtsld.formid && (crtsld.formid).length > 0) {
            obj["action"] = "update"
        } else {
            obj["action"] = "new"
        }

        // Dispatch the addUpdateWayof action
        let res = await dispatch(addUpdateWayof(obj))
        if (res.payload.type == "Success") {
            // Show success message
            Swal.fire({
                // icon: 'success',"success",
                title: `${t('Sweetalert.Success')}`,
                text: `${t('Sweetalert.statusChange')}`,
                showConfirmButton: false,
                timer: 2000,
            });
            setValue("enviroments", "")
            dispatch(getFormsofReceipt());
            if (obj.FL_BOLETOSUPERLOGICA_FRECB === 0) {
              await integrateBankAccountWithSplitPayment(obj.ID_CONTA_CB)
            }
            dispatch(editBank(false));
        } else {
            // Show an error message
            Swal.fire({
                position: "center",
                // icon: 'success',"error",
                title: `${t('Sweetalert.Error')}`,
                text: res.payload.data ? res?.payload?.data : `${t('Sweetalert.newExpence')}`,
                showConfirmButton: true
            });
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose} className='BankCreditstsx'>
                <Modal.Header closeButton>
                    <Modal.Title>{t("formReceipt.bankcreditTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="floatingSelectyhty" label={t("bankTransaction.selectbank")+ " *"} className="mb-lg-4 mb-3">
                        <Form.Select id="sele" {...register("enviroments")} onChange={(e) => bankCreditInfo(e)} aria-label="Produção">
                            <option value="">{t("formLabel.select")}</option>
                            {
                                userbanklist && userbanklist.length > 0 &&
                                userbanklist.map((curElem: any, ind: number) => {
                                    if (curElem.status == 0) {
                                        return (
                                            <option key={ind} value={curElem.id} >{curElem.nameReference}</option>
                                        )
                                    }
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                    <Alert variant="warning" className="mb-lg-4 mb-3">{t("formReceipt.alterTitle")}</Alert>
                    {bankInfo?.accountType == "2" ?
                     "" :
                      <>
                        <Alert variant="light" className="border-start border-5 border-top-0 border-end-0 border-bottom-0 mb-lg-4 mb-3">
                            <h5 className="fw-bold">{t("formReceipt.accountCredit")}</h5>
                            { ( bankInfo && bankInfo.AARIN_ID && bankInfo.AARIN_ID != '' && bankInfo.ID_FORMA_FRECB != null ) &&
                            <p className='mb-0 text-danger '>{t("AlreadyconnectedKenloSlip")} Kenlo pay </p>
                            }
                            <p className="mb-0">{t("banks.name")}: {bankInfo?.nameReference}</p>
                            <p className="mb-0">{t("banks.accountNo")}: {bankInfo?.account}</p>
                            <p className="mb-0">{t("amount")}: {bankInfo?.moneyaccount}</p>
                        </Alert>
                        </>
                        
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        {t("button.toClose")}
                    </Button>
                    {
                        ( bankInfo && bankInfo.bankname != '' ) ?
                            <Button variant="primary" onClick={handleSum}>
                                {t("button.next")}
                            </Button>
                            :
                            <Button variant="primary" disabled>
                                {t("button.next")}
                            </Button>
                    }

                </Modal.Footer>
            </Modal> 
        </>
    )
}

export default BankCredits

