import { z } from "zod";
import { BancoDoBrasilSchema } from "./components/BanksFields/BancoDoBrasilFields";
import { BradescoSchema } from "./components/BanksFields/BradescoFields";
import { C6Schema } from "./components/BanksFields/C6Fields";
import { CaixaEconomicaSchema } from "./components/BanksFields/CaixaEconomicaFields";
import { InterSchema } from "./components/BanksFields/InterFields";
import { ItauSchema } from "./components/BanksFields/ItauFields";
import { SafraSchema } from "./components/BanksFields/SafraFields";
import { SantanderSchema } from "./components/BanksFields/SantanderFields";
import { SicoobSchema } from "./components/BanksFields/SicoobFields";
import { SicrediSchema } from "./components/BanksFields/SicrediFields";
import { AccountType, EnabledBanksToHomologate } from "./types";

const BankAccountSchema = z.object({
  nameReference: z.string().min(1, "Campo obrigatório"),
  branchtype: z.string().min(1, "Campo obrigatório"),
  accountVariation: z.string().regex(/^[12]$/, "Campo obrigatório"),
  bankname: z.string().min(1, "Campo obrigatório"),
  accountType: z.string().regex(/^[13]$/, "Campo obrigatório"),
  agency: z.string().min(1, "Campo obrigatório"),
  account: z.string().min(1, "Campo obrigatório"),
  moneyaccount: z.string().min(1, "Campo obrigatório"),
  date: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Data inválida")
    .refine((date) => {
      const [year, month, day] = date.split("-").map(Number);
      const dateObj = new Date(year, month - 1, day);
      const currentYear = new Date().getFullYear();

      return (
        dateObj.getFullYear() <= currentYear &&
        dateObj.getFullYear() === year &&
        dateObj.getMonth() === month - 1 &&
        dateObj.getDate() === day
      );
    }, "Data inválida"),
  shouldHomologate: z.string().optional(),
});

const ManualAccountSchema = z.object({
  nameReference: z.string().min(1, "Campo obrigatório"),
  branchtype: z.string().min(1, "Campo obrigatório"),
  moneyaccount: z.string().min(1, "Campo obrigatório"),
  date: z.string().min(1, "Campo obrigatório"),
});

export function getBankAccountFormSchema({
  accountVariation,
  selectedBank,
  shouldHomologate,
}: {
  accountVariation: string;
  selectedBank: string;
  shouldHomologate: boolean;
}) {
  if (accountVariation === AccountType.MANUAL) return ManualAccountSchema;

  if (!shouldHomologate) return BankAccountSchema;

  const bankToHomologateSchema = {
    [EnabledBanksToHomologate.BancoDoBrasil]: BancoDoBrasilSchema,
    [EnabledBanksToHomologate.Santander]: SantanderSchema,
    [EnabledBanksToHomologate.Inter]: InterSchema,
    [EnabledBanksToHomologate.CaixaEconomica]: CaixaEconomicaSchema,
    [EnabledBanksToHomologate.Bradesco]: BradescoSchema,
    [EnabledBanksToHomologate.C6]: C6Schema,
    [EnabledBanksToHomologate.Itau]: ItauSchema,
    [EnabledBanksToHomologate.Safra]: SafraSchema,
    [EnabledBanksToHomologate.Sicredi]: SicrediSchema,
    [EnabledBanksToHomologate.Sicoob]: SicoobSchema,
  };

  return BankAccountSchema.merge(bankToHomologateSchema[selectedBank]);
}

export type BankAccountFormType = z.infer<typeof BankAccountSchema>;

export const companyInfosSchema = z.object({
  company_name: z
    .string({ message: "Nome da empresa é obrigatório" })
    .min(1, "Nome da empresa é obrigatório"),
  cnpj: z
    .string({ message: "CNPJ da empresa é obrigatório" })
    .min(1, "CNPJ da empresa é obrigatório"),
  email: z
    .string({ message: "E-mail de contato da empresa é obrigatório" })
    .min(1, "E-mail de contato da empresa é obrigatório"),
  telephone: z
    .string({ message: "Telefone de contato da empresa é obrigatório" })
    .min(1, "Telefone de contato da empresa é obrigatório"),
  address: z
    .string({ message: "Endereço da empresa é obrigatório" })
    .min(1, "Endereço da empresa é obrigatório"),
  number: z
    .string({ message: "Número do endereço da empresa é obrigatório" })
    .min(1, "Número do endereço da empresa é obrigatório"),
  neighborhood: z
    .string({ message: "Bairro do endereço da empresa é obrigatório" })
    .min(1, "Bairro do endereço da empresa é obrigatório"),
  city: z
    .string({ message: "Cidade do endereço da empresa é obrigatória" })
    .min(1, "Cidade do endereço da empresa é obrigatória"),
  state: z
    .string({ message: "Estado do endereço da empresa é obrigatório" })
    .min(1, "Estado do endereço da empresa é obrigatório"),
  zipcode: z
    .string({ message: "CEP da empresa é obrigatório" })
    .min(1, "CEP da empresa é obrigatório"),
  cityCode: z
    .string({ message: "Cidade e/ou estado do endereço da empresa inválido(s)" })
    .min(1, "Cidade e/ou estado do endereço da empresa inválido(s)"),
});