import React from "react";

import { Drawer } from "../../../../../components/Drawer";
import { RemittanceStatus } from "./RemittanceStatus";
import { useFeedbackDrawer } from "../FeedbackDrawerProvider";

export function FeedbackDrawerContainer() {
  const { hideFeedbackDrawer, isShowingFeedbackDrawer } = useFeedbackDrawer();

  return (
    <Drawer.Root
      show={isShowingFeedbackDrawer}
      onHide={hideFeedbackDrawer}
    >
      <Drawer.Header>Resumo do arquivo de retorno</Drawer.Header>
      <Drawer.Body>
        <div id="filter-area">
          {/* <Filter /> */}
        </div>
        <div id="status-area"className="mt-3">
          <RemittanceStatus />
        </div>
      </Drawer.Body>
    </Drawer.Root>
  )
}