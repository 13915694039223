import React from "react";

export const iconLibrary = {
  arrowDownload: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M18.2498 20.5029C18.664 20.5027 19 20.8384 19 21.2526C19 21.6669 18.6644 22.0027 18.2502 22.0029L5.25022 22.0066C4.836 22.0067 4.5 21.6711 4.5 21.2568C4.5 20.8426 4.83557 20.5067 5.24978 20.5066L18.2498 20.5029ZM11.6482 2.01466L11.75 2.00781C12.1297 2.00781 12.4435 2.28997 12.4932 2.65604L12.5 2.75781L12.499 16.4428L16.2208 12.7225C16.4871 12.4562 16.9038 12.4321 17.1974 12.65L17.2815 12.7226C17.5477 12.9889 17.5719 13.4056 17.354 13.6991L17.2814 13.7833L12.2837 18.7799C12.0176 19.046 11.6012 19.0703 11.3076 18.8527L11.2235 18.7802L6.22003 13.7836C5.92694 13.4909 5.92661 13.016 6.21931 12.7229C6.48539 12.4565 6.90204 12.432 7.1958 12.6496L7.27997 12.7222L10.999 16.4358L11 2.75781C11 2.37812 11.2822 2.06432 11.6482 2.01466L11.75 2.00781L11.6482 2.01466Z" />
    </svg>
  ),
  checkmarkCircle: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6ZM19.2197 12.9697L14.75 17.4393L12.7803 15.4697C12.4874 15.1768 12.0126 15.1768 11.7197 15.4697C11.4268 15.7626 11.4268 16.2374 11.7197 16.5303L14.2197 19.0303C14.5126 19.3232 14.9874 19.3232 15.2803 19.0303L20.2803 14.0303C20.5732 13.7374 20.5732 13.2626 20.2803 12.9697C19.9874 12.6768 19.5126 12.6768 19.2197 12.9697Z" />
    </svg>
  ),
  chevronDown: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" />
    </svg>
  ),
  copySelect: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M9.5 3.25C8.80964 3.25 8.25 3.80964 8.25 4.5V5.125C8.25 5.47018 7.97018 5.75 7.625 5.75C7.27982 5.75 7 5.47018 7 5.125V4.5C7 3.11929 8.11929 2 9.5 2H10.125C10.4702 2 10.75 2.27982 10.75 2.625C10.75 2.97018 10.4702 3.25 10.125 3.25H9.5ZM8.25 14.5C8.25 15.1904 8.80964 15.75 9.5 15.75H10.125C10.4702 15.75 10.75 16.0298 10.75 16.375C10.75 16.7202 10.4702 17 10.125 17H9.5C8.11929 17 7 15.8807 7 14.5V13.875C7 13.5298 7.27982 13.25 7.625 13.25C7.97018 13.25 8.25 13.5298 8.25 13.875V14.5ZM8.25 7.625C8.25 7.27982 7.97018 7 7.625 7C7.27982 7 7 7.27982 7 7.625V11.375C7 11.7202 7.27982 12 7.625 12C7.97018 12 8.25 11.7202 8.25 11.375V7.625ZM19.5 3.25C20.1904 3.25 20.75 3.80964 20.75 4.5V5.125C20.75 5.47018 21.0298 5.75 21.375 5.75C21.7202 5.75 22 5.47018 22 5.125V4.5C22 3.11929 20.8807 2 19.5 2H18.875C18.5298 2 18.25 2.27982 18.25 2.625C18.25 2.97018 18.5298 3.25 18.875 3.25H19.5ZM19.5 15.75C20.1904 15.75 20.75 15.1904 20.75 14.5V13.875C20.75 13.5298 21.0298 13.25 21.375 13.25C21.7202 13.25 22 13.5298 22 13.875V14.5C22 15.8807 20.8807 17 19.5 17H18.875C18.5298 17 18.25 16.7202 18.25 16.375C18.25 16.0298 18.5298 15.75 18.875 15.75H19.5ZM21.375 7C21.0298 7 20.75 7.27982 20.75 7.625V11.375C20.75 11.7202 21.0298 12 21.375 12C21.7202 12 22 11.7202 22 11.375V7.625C22 7.27982 21.7202 7 21.375 7ZM12.625 2C12.2798 2 12 2.27982 12 2.625C12 2.97018 12.2798 3.25 12.625 3.25H16.375C16.7202 3.25 17 2.97018 17 2.625C17 2.27982 16.7202 2 16.375 2H12.625ZM12 16.375C12 16.0298 12.2798 15.75 12.625 15.75H16.375C16.7202 15.75 17 16.0298 17 16.375C17 16.7202 16.7202 17 16.375 17H12.625C12.2798 17 12 16.7202 12 16.375ZM4.5 7H5.75V8.25H4.5C3.80964 8.25 3.25 8.80964 3.25 9.5V17.625C3.25 19.3509 4.64911 20.75 6.375 20.75H14.5C15.1904 20.75 15.75 20.1904 15.75 19.5V18.25H17V19.5C17 20.8807 15.8807 22 14.5 22H6.375C3.95875 22 2 20.0412 2 17.625V9.5C2 8.11929 3.11929 7 4.5 7Z" />
    </svg>
  ),
  dismiss: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M10.5886 10.7157L10.6464 10.6464C10.82 10.4729 11.0894 10.4536 11.2843 10.5886L11.3536 10.6464L16 15.293L20.6464 10.6464C20.8417 10.4512 21.1583 10.4512 21.3536 10.6464C21.5488 10.8417 21.5488 11.1583 21.3536 11.3536L16.707 16L21.3536 20.6464C21.5271 20.82 21.5464 21.0894 21.4114 21.2843L21.3536 21.3536C21.18 21.5271 20.9106 21.5464 20.7157 21.4114L20.6464 21.3536L16 16.707L11.3536 21.3536C11.1583 21.5488 10.8417 21.5488 10.6464 21.3536C10.4512 21.1583 10.4512 20.8417 10.6464 20.6464L15.293 16L10.6464 11.3536C10.4729 11.18 10.4536 10.9106 10.5886 10.7157L10.6464 10.6464L10.5886 10.7157Z" />
    </svg>
  ),
  dismissCircle: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM15.4462 8.39705L15.5303 8.46967C15.7966 8.73594 15.8208 9.1526 15.6029 9.44621L15.5303 9.53033L13.061 12L15.5303 14.4697C15.7966 14.7359 15.8208 15.1526 15.6029 15.4462L15.5303 15.5303C15.2641 15.7966 14.8474 15.8208 14.5538 15.6029L14.4697 15.5303L12 13.061L9.53033 15.5303C9.26406 15.7966 8.8474 15.8208 8.55379 15.6029L8.46967 15.5303C8.2034 15.2641 8.1792 14.8474 8.39705 14.5538L8.46967 14.4697L10.939 12L8.46967 9.53033C8.2034 9.26406 8.1792 8.8474 8.39705 8.55379L8.46967 8.46967C8.73594 8.2034 9.1526 8.1792 9.44621 8.39705L9.53033 8.46967L12 10.939L14.4697 8.46967C14.7359 8.2034 15.1526 8.1792 15.4462 8.39705Z" />
    </svg>
  ),
  documentArrowDown: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M7.20313 2.39844C5.87764 2.39844 4.80313 3.47295 4.80313 4.79844V11.0469C5.18986 10.9375 5.59103 10.8625 6.00313 10.8253V4.79844C6.00313 4.1357 6.54038 3.59844 7.20313 3.59844H12.0031V7.79844C12.0031 8.79255 12.809 9.59844 13.8031 9.59844H18.0031V19.1984C18.0031 19.8612 17.4659 20.3984 16.8031 20.3984H12.4835C12.264 20.8278 11.9989 21.2299 11.6945 21.5984H16.8031C18.1286 21.5984 19.2031 20.5239 19.2031 19.1984V8.89549C19.2031 8.4181 19.0135 7.96027 18.6759 7.6227L13.9789 2.92565C13.6413 2.58808 13.1835 2.39844 12.7061 2.39844H7.20313ZM17.7546 8.39844H13.8031C13.4718 8.39844 13.2031 8.12981 13.2031 7.79844V3.84697L17.7546 8.39844ZM6.60313 22.7984C9.58546 22.7984 12.0031 20.3808 12.0031 17.3984C12.0031 14.4161 9.58546 11.9984 6.60313 11.9984C3.62079 11.9984 1.20312 14.4161 1.20312 17.3984C1.20312 20.3808 3.62079 22.7984 6.60313 22.7984ZM3.77886 17.8227C3.54455 17.5884 3.54455 17.2085 3.77886 16.9742C4.01318 16.7399 4.39307 16.7399 4.62739 16.9742L6.00313 18.3499L6.00312 14.9984C6.00313 14.6671 6.27175 14.3984 6.60313 14.3984C6.9345 14.3984 7.20313 14.6671 7.20313 14.9984V18.3499L8.57886 16.9742C8.81318 16.7399 9.19307 16.7399 9.42739 16.9742C9.6617 17.2085 9.6617 17.5884 9.42739 17.8227L7.02739 20.2227C6.96986 20.2802 6.90356 20.3236 6.83279 20.3529C6.76309 20.3818 6.68677 20.398 6.60672 20.3984L6.60313 20.3984L6.59953 20.3984C6.51948 20.398 6.44316 20.3818 6.37346 20.3529C6.30373 20.3241 6.23834 20.2815 6.18141 20.2252L6.17838 20.2222L3.77886 17.8227Z" />
    </svg>
  ),
  documentArrowUp: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M7.20313 2.39844C5.87764 2.39844 4.80312 3.47295 4.80312 4.79844V11.0469C5.18986 10.9375 5.59103 10.8625 6.00312 10.8253V4.79844C6.00312 4.1357 6.54038 3.59844 7.20313 3.59844H12.0031V7.79844C12.0031 8.79255 12.809 9.59844 13.8031 9.59844H18.0031V19.1984C18.0031 19.8612 17.4659 20.3984 16.8031 20.3984H12.4835C12.264 20.8278 11.9989 21.2299 11.6945 21.5984H16.8031C18.1286 21.5984 19.2031 20.5239 19.2031 19.1984V8.89549C19.2031 8.4181 19.0135 7.96027 18.6759 7.6227L13.9789 2.92565C13.6413 2.58808 13.1835 2.39844 12.7061 2.39844H7.20313ZM17.7546 8.39844H13.8031C13.4718 8.39844 13.2031 8.12981 13.2031 7.79844V3.84697L17.7546 8.39844ZM6.60313 22.7984C9.58546 22.7984 12.0031 20.3808 12.0031 17.3984C12.0031 14.4161 9.58546 11.9984 6.60313 11.9984C3.62079 11.9984 1.20312 14.4161 1.20312 17.3984C1.20312 20.3808 3.62079 22.7984 6.60313 22.7984ZM9.42736 16.9742C9.66167 17.2086 9.66167 17.5885 9.42736 17.8228C9.19304 18.0571 8.81314 18.0571 8.57883 17.8228L7.20309 16.447L7.20309 19.7985C7.20309 20.1299 6.93446 20.3985 6.60309 20.3985C6.27172 20.3985 6.00309 20.1299 6.00309 19.7985L6.00309 16.447L4.62736 17.8228C4.39304 18.0571 4.01314 18.0571 3.77883 17.8228C3.54451 17.5885 3.54451 17.2086 3.77883 16.9742L6.17883 14.5742C6.23635 14.5167 6.30266 14.4733 6.37342 14.444C6.44312 14.4151 6.51945 14.399 6.59949 14.3985L6.60309 14.3985L6.60669 14.3985C6.68673 14.399 6.76306 14.4151 6.83276 14.444C6.90249 14.4729 6.96787 14.5154 7.0248 14.5717L7.02783 14.5747L9.42736 16.9742Z" />
    </svg>
  ),
  documentCatchUp: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M18 20.5C18.276 20.5 18.5 20.275 18.5 20V10H14C12.896 10 12 9.104 12 8V3.5H6C5.724 3.5 5.5 3.725 5.5 4V9.25C5.5 9.66421 5.16421 10 4.75 10C4.33579 10 4 9.66421 4 9.25V4C4 2.896 4.896 2 6 2H12.172C12.1999 2 12.2271 2.00371 12.2542 2.00741C12.2738 2.01008 12.2933 2.01274 12.313 2.014C12.528 2.029 12.74 2.07 12.937 2.152C12.9944 2.17648 13.0488 2.20797 13.103 2.23933C13.1197 2.24897 13.1363 2.25859 13.153 2.268C13.1685 2.27647 13.1845 2.28426 13.2005 2.29207C13.2281 2.30548 13.2557 2.31894 13.281 2.336C13.359 2.389 13.429 2.452 13.5 2.516C13.5115 2.5262 13.5238 2.53567 13.5363 2.54519C13.5531 2.55808 13.57 2.57105 13.585 2.586L19.414 8.414C19.789 8.789 20 9.298 20 9.828V20C20 21.104 19.104 22 18 22H6C4.896 22 4 21.104 4 20V16.25C4 15.8358 4.33579 15.5 4.75 15.5C5.16421 15.5 5.5 15.8358 5.5 16.25V20C5.5 20.275 5.724 20.5 6 20.5H18ZM17.378 8.5L13.5 4.621V8C13.5 8.275 13.724 8.5 14 8.5H17.378ZM2.75 13L5.25508 13L6.81175 9.4489C7.06044 8.88157 7.8355 8.85277 8.13885 9.35798L8.14458 9.36751L8.1949 9.4712L10.6081 15.4977L12.0348 13.3979C12.1462 13.1882 12.3493 13.0454 12.5802 13.0091L12.5909 13.0074L12.6971 12.9999H14.697C15.1112 12.9999 15.447 13.3357 15.447 13.7499C15.447 14.1255 15.1708 14.4369 14.8101 14.4915L14.7998 14.493L14.697 14.4999H13.1428L11.1641 17.5937C10.8889 18.12 10.1499 18.124 9.85818 17.6344L9.85261 17.6251L9.80375 17.524L7.46737 11.6894L6.4321 14.0511C6.33564 14.2712 6.14148 14.4284 5.9136 14.4809C5.89841 14.4844 5.88306 14.4874 5.86759 14.49L5.85645 14.4918L5.7452 14.5L2.75 14.5C2.33579 14.5 2 14.1642 2 13.75C2 13.3744 2.27619 13.063 2.63692 13.0085L2.64718 13.0069L2.75 13Z" />
    </svg>
  ),
  documentCheckmark: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M18.5 20C18.5 20.275 18.276 20.5 18 20.5H12.2678C11.9806 21.051 11.6168 21.5557 11.1904 22H18C19.104 22 20 21.104 20 20V9.828C20 9.298 19.789 8.789 19.414 8.414L13.585 2.586C13.57 2.57105 13.5531 2.55808 13.5363 2.5452C13.5238 2.53567 13.5115 2.5262 13.5 2.516C13.429 2.452 13.359 2.389 13.281 2.336C13.2557 2.31894 13.2281 2.30548 13.2005 2.29207C13.1845 2.28426 13.1685 2.27647 13.153 2.268C13.1363 2.25859 13.1197 2.24897 13.103 2.23933C13.0488 2.20797 12.9944 2.17648 12.937 2.152C12.74 2.07 12.528 2.029 12.313 2.014C12.2933 2.01274 12.2738 2.01008 12.2542 2.00741C12.2271 2.00371 12.1999 2 12.172 2H6C4.896 2 4 2.896 4 4V11.4982C4.47417 11.3004 4.97679 11.1572 5.5 11.0764V4C5.5 3.725 5.724 3.5 6 3.5H12V8C12 9.104 12.896 10 14 10H18.5V20ZM13.5 4.621L17.378 8.5H14C13.724 8.5 13.5 8.275 13.5 8V4.621Z" />
      <path d="M12 17.5C12 20.5376 9.53757 23 6.5 23C3.46243 23 1 20.5376 1 17.5C1 14.4624 3.46243 12 6.5 12C9.53757 12 12 14.4624 12 17.5ZM9.85355 15.1464C9.65829 14.9512 9.34171 14.9512 9.14645 15.1464L5.5 18.7929L3.85355 17.1464C3.65829 16.9512 3.34171 16.9512 3.14645 17.1464C2.95118 17.3417 2.95118 17.6583 3.14645 17.8536L5.14645 19.8536C5.34171 20.0488 5.65829 20.0488 5.85355 19.8536L9.85355 15.8536C10.0488 15.6583 10.0488 15.3417 9.85355 15.1464Z" />
    </svg>
  ),
  open: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M6.24992 4.5C5.28344 4.5 4.49996 5.2835 4.49996 6.25V17.75C4.49996 18.7165 5.28344 19.5 6.24992 19.5H17.7496C18.7161 19.5 19.4996 18.7165 19.4996 17.75V13.75C19.4996 13.3358 19.8354 13 20.2496 13C20.6638 13 20.9995 13.3358 20.9995 13.75V17.75C20.9995 19.5449 19.5445 21 17.7496 21H6.24992C4.45504 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45504 3 6.24992 3H10.2498C10.664 3 10.9998 3.33579 10.9998 3.75C10.9998 4.16421 10.664 4.5 10.2498 4.5H6.24992ZM12.9997 3.75C12.9997 3.33579 13.3355 3 13.7497 3H20.25C20.6642 3 21 3.33579 21 3.75V10.25C21 10.6642 20.6642 11 20.25 11C19.8358 11 19.5 10.6642 19.5 10.25V5.56074L14.28 10.7804C13.9871 11.0732 13.5123 11.0732 13.2194 10.7803C12.9265 10.4874 12.9265 10.0125 13.2194 9.71964L18.4395 4.5H13.7497C13.3355 4.5 12.9997 4.16421 12.9997 3.75Z" />
    </svg>
  ),
  search: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 11.7319 16.6427 13.3219 15.6295 14.5688L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2641 20.7966 19.8474 20.8208 19.5538 20.6029L19.4697 20.5303L14.5688 15.6295C13.3219 16.6427 11.7319 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75ZM10 4.25C6.82436 4.25 4.25 6.82436 4.25 10C4.25 13.1756 6.82436 15.75 10 15.75C13.1756 15.75 15.75 13.1756 15.75 10C15.75 6.82436 13.1756 4.25 10 4.25Z" />
    </svg>
  ),
}