import React from "react";

import { Stack } from "react-bootstrap";

export function RemittanceStatus() {
  return (
    <Stack as="ul" className="list-unstyled list-group">
      <RemittanceStatusItem amount={20} description="Conforme" />
      <RemittanceStatusItem amount={3} description="Duplicado" />
      <RemittanceStatusItem amount={0} description="Inconforme" />
      <RemittanceStatusItem amount={4} description="Diferente" />
    </Stack>
  )
}

type RemittanceStatusItemProps = {
  amount: number;
  description: "Conforme" | "Duplicado" | "Inconforme" | "Diferente";
}

function RemittanceStatusItem(props: RemittanceStatusItemProps) {
  const { amount, description } = props;

  return (
    <li className="list-group-item px-5 py-3">
      <div className="d-flex align-items-center">
        <strong className="fs-3">{amount}</strong>
        <span className="ms-3">{description}</span>
      </div>
    </li>
  )
}