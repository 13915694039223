import React from "react";

type DueDateProps = {
  date: string;
  isOverdue: boolean;
  daysLate: string;
  className?: string;
}

export function DueDate({ date, isOverdue, daysLate, className = "" }: DueDateProps) {
  return (
    <div className={`d-flex flex-column ${isOverdue && "text-danger-40"} ${className}`}>
      {date}
      {isOverdue &&
        <span style={{ color: "#6C2B22" }} className={`fs-12 fw-medium ${className}`}>
          {daysLate}
        </span>
      }
    </div>
  )
}