import { useEffect, useRef, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useTransferAccount } from "../../TransferAccountProvider";
import { TransferAccountFormType } from "../../schema";
import { getAccountsList } from "../../services/getAccountsList";

type Accounts = Array<{
  id: number;
  nameReference: string;
  bankname: string;
  agency: string;
  account: string;
  accountType: number;
  status: number;
}>;

export function useDefaultFields() {
  const {
    setSelectedOriginAccount,
    setSelectedDestinationAccount,
  } = useTransferAccount();
  const [accounts, setAccounts] = useState<Accounts>([]);
  const [isBankSearchFocused, setIsBankSearchFocused] = useState(false);
  const [isOriginAccountSearchFocused, setIsOriginAccountSearchFocused] = useState(false);
  const [isDestinationAccountSearchFocused, setIsDestinationAccountSearchFocused] = useState(false);
  const [searchedOriginAccount, setSearchedOriginAccount] = useState("");
  const [searchedDestinationAccount, setSearchedDestinationAccount] = useState("");
  const bankSearchRef = useRef<HTMLDivElement>(null);
  const originSearchRef = useRef<HTMLDivElement>(null);
  const destinationSearchRef = useRef<HTMLDivElement>(null);
  const { errors, defaultValues } = useFormState<TransferAccountFormType>();
  const { register, setValue } = useFormContext<TransferAccountFormType>();

  const [initialBalance, setInitialBalance] = useState(() =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(Number(defaultValues?.transferAmount ?? 0))
  );
  const [enableEditDate, setEnableEditDate] = useState(false);

  useEffect(() => {
    getAccountsList().then((accounts) => {
      const mappedAccounts = accounts.map((_acc) => ({
        id: _acc.id,
        nameReference: _acc.nameReference,
        bankname: _acc.bankname,
        agency: _acc.agency,
        account: _acc.account,
        accountType: _acc.accountType,
        status: _acc.status
      }));

      setAccounts(mappedAccounts);
    });
  }, []);

  useEffect(() => {
    const handleClickOutsideOrigin = (event: MouseEvent) => {
      const isOutsideClick =
        originSearchRef.current &&
        !originSearchRef.current.contains(event.target as Node);

      if (isOutsideClick) return setIsOriginAccountSearchFocused(false);
    };

    document.addEventListener("click", handleClickOutsideOrigin);

    return () => {
      document.removeEventListener("click", handleClickOutsideOrigin);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideDestination = (event: MouseEvent) => {
      const isOutsideClick =
        destinationSearchRef.current &&
        !destinationSearchRef.current.contains(event.target as Node);

      if (isOutsideClick) return setIsDestinationAccountSearchFocused(false);
    };

    document.addEventListener("click", handleClickOutsideDestination);

    return () => {
      document.removeEventListener("click", handleClickOutsideDestination);
    };
  }, []);

  function getAccountsToShow(field: "origin" | "destination") {
    const searchQuery =
      field === "origin" ? searchedOriginAccount : searchedDestinationAccount;

    const lowercasedQuery = searchQuery?.toLowerCase().trim() || "";

    return accounts
      .filter((account) => {
        if (!lowercasedQuery) return true;

        const matchesNameReference = account.nameReference.toLowerCase().includes(lowercasedQuery);
        const matchesAgency = account.agency.toLowerCase().includes(lowercasedQuery);
        const matchesAccount = account.account.toLowerCase().includes(lowercasedQuery);

        return matchesNameReference || matchesAgency || matchesAccount;
      })
      .sort((a, b) => {
        const bankComparison = a.bankname.localeCompare(b.bankname);
        if (bankComparison !== 0) return bankComparison;

        const agencyComparison = Number(a.agency) - Number(b.agency);
        if (agencyComparison !== 0) return agencyComparison;

        return a.bankname.localeCompare(b.bankname);
      })
      .splice(0, 10);
  }

  function handleChangeAccount(
    accountBank: {
      id: number;
      nameReference: string;
      bankname: string;
      agency: string;
      account: string;
      accountType: number;
      status: number;
    },
    field: "origin" | "destination"
  ) {
    const { nameReference, agency, account } = accountBank;
    const formattedValue = `${nameReference} - Agência: ${agency} - Conta: ${account}`;

    if (field === "origin") {
      setSelectedOriginAccount(accountBank);
      setValue("originAccount", formattedValue);
      setSearchedOriginAccount(formattedValue);
      setIsOriginAccountSearchFocused(false);
    } else if (field === "destination") {
      setSelectedDestinationAccount(accountBank);
      setValue("destinationAccount", formattedValue);
      setSearchedDestinationAccount(formattedValue);
      setIsDestinationAccountSearchFocused(false);
    }
  }

  return {
    bankSearchRef,
    setIsBankSearchFocused,
    isBankSearchFocused,
    initialBalance,
    setInitialBalance,
    errors,
    defaultValues,
    register,
    handleChangeAccount,
    enableEditDate,
    setEnableEditDate,
    originSearchRef,
    destinationSearchRef,
    isOriginAccountSearchFocused,
    setIsOriginAccountSearchFocused,
    isDestinationAccountSearchFocused,
    setIsDestinationAccountSearchFocused,
    getAccountsToShow,
    searchedOriginAccount,
    setSearchedOriginAccount,
    searchedDestinationAccount,
    setSearchedDestinationAccount    
  };
}
