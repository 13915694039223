import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import { TransferAccountActionButtons, TransferAccountForm } from "../TransferAccountForm";
import { getTransferAccountFormSchema } from "../TransferAccountForm/schema";
import { Drawer } from "../Drawer";
import { useTransferAccountDrawer } from "./TransferAccountDrawerStore";

export function TransferAccountDrawerContainer() {
  const { transferAccountDrawerAction, setShowTransferAccountDrawer, transferData } =
    useTransferAccountDrawer();

  const form = useForm({
    resolver: zodResolver(getTransferAccountFormSchema()),
    values: {
      ...transferData,
      date: transferData?.date ?? "",
      transferAmount: transferData?.moneyaccount?.toString() ?? "0",
      originAccount: transferData?.originAccount ?? "",
      destinationAccount: transferData?.destinationAccount ?? "",
      complement: transferData?.complement ?? "",
    },
  });

  const showBankAccountDrawer = !!transferAccountDrawerAction;

  return (
    <FormProvider {...form} key={transferData?.id}>
      <Drawer.Root
        show={showBankAccountDrawer}
        onHide={() => {
          form.reset();
          setShowTransferAccountDrawer({ action: undefined });
        }}
      >
        <Drawer.Header>Transferência entre contas</Drawer.Header>
        <Drawer.Body>
          <TransferAccountForm />
        </Drawer.Body>
        <Drawer.Footer>
          <TransferAccountActionButtons />
        </Drawer.Footer>
      </Drawer.Root>
    </FormProvider>
  );
}
